import { findLocation } from "data";
import { dequal } from "dequal";
import { useAtomValue, useSetAtom } from "jotai";
import React, { FC, useCallback, useRef, useState } from "react";
import { CURRENT_DEFAULT_MAP, iLocation, iLocationMap, isClassSpecLoc } from "typings";
import { createContext } from 'use-context-selector';
import { lastUsedLocationState } from "../global/state/global";
import { signupLocationMapState } from "../global/state/raid";


type LocationContextType = {
  location: iLocation
  setLocation: React.Dispatch<React.SetStateAction<iLocation>>
  map: iLocationMap
  setMap: (map: iLocationMap) => void
}
export const LocationContext = createContext<LocationContextType>(null!);

interface LocationMapProps {
  children: React.ReactNode
  useMap?: iLocationMap
}

export const LocationProvider: FC<LocationMapProps> = ({ children, useMap }) => {
  
  const signupLocation = useAtomValue(signupLocationMapState)
  const setLastUsedLocation = useSetAtom(lastUsedLocationState)

  const findDefaultLoc = useCallback(() => {
    try {
      const lastUsedMapString = (typeof window !== "undefined" && localStorage?.getItem?.("lastUsedLocation")) || ""
      let lastUsedMap: iLocationMap | undefined = !!lastUsedMapString
        ? JSON.parse(lastUsedMapString)
        : undefined
      
      // NOTE: temporary - can be deleted in Aug2024
      if(dequal(lastUsedMap, {tier1: "online", tier2: "games", tier3: "wow", tier4: "wotlk"})){
        const replaceMap: iLocationMap = { tier1: "online", tier2: "games", tier3: "wow", tier4: "classic-prog-1" }
        lastUsedMap = replaceMap
        setLastUsedLocation(replaceMap)
      }

      const map = useMap || signupLocation || lastUsedMap
      if(!map) throw new Error("No map given")
      return findLocation(map)
    } catch (error) {
      return findLocation(CURRENT_DEFAULT_MAP)
    }
  }, [useMap])

  const [location, setLocation] = useState<iLocation>(findDefaultLoc())
  const locationInitialised = useRef<iLocationMap | null>(null)

  const setCSSClassColors = (location: iLocation) => {
    if(isClassSpecLoc(location) === false) return
    // FIXME: not super clean to have this inline on html root
    for (const gameClass of Object.values(location.game?.classes || {})) {
      if(gameClass.gameClass === "priest") continue;
      document.documentElement.style.setProperty("--wow-" + gameClass.gameClass.replace(" ", "-"), gameClass.colorDark);
    }
  }

  const initLocationWithMap = (map: iLocationMap) => {
    if(dequal(map, locationInitialised.current)) return
    const loc = findLocation(map)
    setCSSClassColors(loc)
    setLocation(loc)
    locationInitialised.current = loc.locMap
  }

  return (
    <LocationContext.Provider
      value={{
        location, setLocation,
        map: location?.locMap, setMap: initLocationWithMap,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
