import { eFSColl, iRaidData } from 'typings';
import { firebase } from '../client'
import { standardCRUD } from '../documentAPI';

const raidRataAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const collectionName = eFSColl.RAIDDATA
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iRaidData>(collectionName, db, deleteField)

  return ({
    get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath,
  })
}

export default raidRataAPI;