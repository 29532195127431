import { Node, mergeAttributes } from "@tiptap/core"
import { Node as ProseMirrorNode } from "@tiptap/pm/model"
import { PluginKey } from "@tiptap/pm/state"
import { SuggestionOptions } from "@tiptap/suggestion"
import { jotaiStore } from "../../../../pages/_app"
import { preventDismissModalAtom } from "../../../../global/state/global"
import { setCustomMode } from "../../../../global/state/customMode"
import { getCellRefContentAtom, getGridColumnLetter } from "../../../../global/state/raid"
import { ReactNodeViewRenderer } from "@tiptap/react"
import CellRefComponent from "./cellRefComponent"
import { ReplaceStep } from "@tiptap/pm/transform"
import { atom } from "jotai"
import { Editor } from "@tiptap/core"

export type CellRefOptions = {
  HTMLAttributes: Record<string, any>
  renderLabel: (props: { options: CellRefOptions; node: ProseMirrorNode }) => string
  suggestion: Omit<SuggestionOptions, "editor">
}

const cellRefID = "cellRef"
export const CellRefPluginKey = new PluginKey(cellRefID)

export const CellRef = Node.create<CellRefOptions>({
  name: cellRefID,
  group: "inline",
  content: 'inline*',
  inline: true,
  

  onTransaction({ transaction }) {
    const editor: Editor = this.editor

    let step = transaction?.steps?.[0] as ReplaceStep
    // @ts-ignore
    const lastLetterAdded = step?.slice?.content?.content?.[0]?.text
    if (lastLetterAdded === "=") {
      jotaiStore.set(setCellRefModeAtom, step, editor)
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(CellRefComponent)
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {}
          }

          return {
            "data-id": attributes.id
          }
        }
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {}
          }

          return {
            "data-label": attributes.label
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    const cellContent = jotaiStore.set(getCellRefContentAtom, node.attrs.id)
    const className = cellContent?.data?.member?.character?.charClass?.replace(" ", "")
    return [
      "span",
      mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes, {
        class: `${className || "no-class"}`
      }),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isCellRef = false
          const { selection } = state
          const { empty, anchor } = selection

          if (!empty) {
            return false
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isCellRef = true
              tr.insertText(this?.options?.suggestion?.char || "", pos, pos + node.nodeSize)

              return false
            }
          })

          return isCellRef
        })
    }
  }
})

export default CellRef


export const setCellRefModeAtom = atom(
  null,
  (get, set, step: ReplaceStep, editor: Editor) => {

    try {
      const toggleSelectCellMode = (enable: boolean) => {
        const modalContainer = document.getElementById("modal-container")

        if (!modalContainer) return
        if (enable) {
          editor.chain().blur().run()
        } else {
          editor.chain().focus().run()
        }
        modalContainer.classList.toggle("hidden")
        jotaiStore.set(preventDismissModalAtom, enable ? ["all"] : [])
      }

      jotaiStore.set(setCustomMode, {
        mode: "select-cell",
        modeLabel: "Select cell",
        selectWillClose: true,
        onOpen() {
          toggleSelectCellMode(true)
        },
        onSelect: (data) => {
          try {
            const cellRef = `${data?.assignment?.id}:${getGridColumnLetter(data?.colIndex)}${data?.rowIndex}`

            const cellContent = jotaiStore.set(getCellRefContentAtom, cellRef)
            const charName = cellContent?.data?.member?.character?.charName

            editor
              .chain()
              .insertContentAt(
                { from: step.from, to: step.from + 1 },
                {
                  type: cellRefID,
                  text: "123",
                  attrs: { id: cellRef, label: data?.assignment?.headline?.text || "" }
                }
              )
              .focus()
              .run()
          } catch (error) {
            console.error(error)
          }
        },
        onClose: () => {
          toggleSelectCellMode(false)
        }
      })

    } catch (error) {
      console.error(error)
      // toast.error(getErrorMessage(error))
    }
  }
)