import { iErrorProps } from "./customError"

export const getStandardErrorBy = ({ status }: { status: number }) => {
  if(status === 400) return ER400_BAD_REQUEST
  if(status === 401) return ER401_UNAUTHORIZED
  if(status === 403) return ER403_FORBIDDEN
  if(status === 404) return ER404_NOT_FOUND
  if(status === 405) return ER405_METHOD_NOT_ALLOWED
  if(status === 408) return ER408_REQUEST_TIMEOUT
  if(status === 409) return ER409_CONFLICT
  if(status === 413) return ER413_PAYLOAD_TOO_LARGE
  if(status === 415) return ER415_UNSUPPORTED_MEDIA_TYPE
  if(status === 429) return ER429_TOO_MANY_REQUESTS
  if(status === 500) return ER500_SERVER_ERROR
  if(status === 501) return ER501_NOT_IMPLEMENTED
  if(status === 502) return ER502_BAD_GATEWAY
  if(status === 503) return ER503_SERVICE_UNAVAILABLE
  if(status === 504) return ER504_GATEWAY_TIMEOUT
  return ER520_UNKNOWN_ERROR
}

export const ER400_BAD_REQUEST: iErrorProps = {
  title: {
    en: "Bad Request",
    fr: "Mauvaise demande",
    es: "Solicitud incorrecta"
  },
  message: {
    en: "The server cannot process the request.",
    fr: "Le serveur ne peut pas traiter la demande.",
    es: "El servidor no puede procesar la solicitud."
  },
  statusCode: 400
}

export const ER401_UNAUTHORIZED: iErrorProps = {
  title: {
    en: "Unauthorized",
    fr: "Non autorisé",
    es: "No autorizado"
  },
  message: {
    en: "Access to the resource is unauthorized.",
    fr: "L'accès à la ressource n'est pas autorisé.",
    es: "El acceso al recurso no está autorizado."
  },
  statusCode: 401
}

export const ER403_FORBIDDEN: iErrorProps = {
  title: {
    en: "Forbidden",
    fr: "Interdit",
    es: "Prohibido"
  },
  message: {
    en: "Access to the requested resource is forbidden.",
    fr: "L'accès à la ressource demandée est interdit.",
    es: "El acceso al recurso solicitado está prohibido."
  },
  statusCode: 403
}

export const ER404_NOT_FOUND: iErrorProps = {
  title: {
    en: "Not Found",
    fr: "Non trouvé",
    es: "No encontrado"
  },
  message: {
    en: "The requested resource was not found.",
    fr: "La ressource demandée n'a pas été trouvée.",
    es: "No se encontró el recurso solicitado."
  },
  statusCode: 404
}

export const ER405_METHOD_NOT_ALLOWED: iErrorProps = {
  title: {
    en: "Method Not Allowed",
    fr: "Méthode non autorisée",
    es: "Método no permitido"
  },
  message: {
    en: "The request method is not allowed for the resource.",
    fr: "La méthode de demande n'est pas autorisée pour la ressource.",
    es: "El método de solicitud no está permitido para el recurso."
  },
  statusCode: 405
}

export const ER408_REQUEST_TIMEOUT: iErrorProps = {
  title: {
    en: "Request Timeout",
    fr: "Délai d'attente de la demande",
    es: "Tiempo de espera de la solicitud"
  },
  message: {
    en: "The server did not receive a complete request within the time that it was prepared to wait.",
    fr: "Le serveur n'a pas reçu de demande complète dans le délai qu'il était prêt à attendre.",
    es: "El servidor no recibió una solicitud completa dentro del tiempo que estaba preparado para esperar."
  },
  statusCode: 408
}

export const ER409_CONFLICT: iErrorProps = {
  title: {
    en: "Conflict",
    fr: "Conflit",
    es: "Conflicto"
  },
  message: {
    en: "The request could not be completed due to a conflict with the current state of the resource.",
    fr: "La demande n'a pas pu être complétée en raison d'un conflit avec l'état actuel de la ressource.",
    es: "La solicitud no se pudo completar debido a un conflicto con el estado actual del recurso."
  },
  statusCode: 409
}

export const ER413_PAYLOAD_TOO_LARGE: iErrorProps = {
  title: {
    en: "Payload Too Large",
    fr: "Charge utile trop grande",
    es: "Carga útil demasiado grande"
  },
  message: {
    en: "The request payload is too large for the server to process.",
    fr: "La charge utile de la demande est trop grande pour que le serveur puisse la traiter.",
    es: "La carga útil de la solicitud es demasiado grande para que el servidor la procese."
  },
  statusCode: 413
}

export const ER415_UNSUPPORTED_MEDIA_TYPE: iErrorProps = {
  title: {
    en: "Unsupported Media Type",
    fr: "Type de média non pris en charge",
    es: "Tipo de medio no soportado"
  },
  message: {
    en: "The server does not support the media type specified in the request.",
    fr: "Le serveur ne prend pas en charge le type de média spécifié dans la demande.",
    es: "El servidor no admite el tipo de medio especificado en la solicitud."
  },
  statusCode: 415
}

export const ER429_TOO_MANY_REQUESTS: iErrorProps = {
  title: {
    en: "Too Many Requests",
    fr: "Trop de demandes",
    es: "Demasiadas solicitudes"
  },
  message: {
    en: "The user has sent too many requests in a given amount of time.",
    fr: "L'utilisateur a envoyé trop de demandes dans un laps de temps donné.",
    es: "El usuario ha enviado demasiadas solicitudes en un período de tiempo determinado."
  },
  statusCode: 429
}

export const ER500_SERVER_ERROR: iErrorProps = {
  title: {
    en: "Internal Server Error",
    fr: "Erreur interne du serveur",
    es: "Error interno del servidor"
  },
  message: {
    en: "An internal server error occurred.",
    fr: "Une erreur interne du serveur s'est produite.",
    es: "Se ha producido un error interno del servidor."
  },
  statusCode: 500
}

export const ER501_NOT_IMPLEMENTED: iErrorProps = {
  title: {
    en: "Not Implemented",
    fr: "Non implémenté",
    es: "No implementado"
  },
  message: {
    en: "The server does not recognize the request method and cannot fulfill the request.",
    fr: "Le serveur ne reconnaît pas la méthode de demande et ne peut pas répondre à la demande.",
    es: "El servidor no reconoce el método de solicitud y no puede cumplir con la solicitud."
  },
  statusCode: 501
}

export const ER502_BAD_GATEWAY: iErrorProps = {
  title: {
    en: "Bad Gateway",
    fr: "Passerelle incorrecte",
    es: "Puerta de enlace incorrecta"
  },
  message: {
    en: "The server received an invalid response from the upstream server while trying to fulfill the request.",
    fr: "Le serveur a reçu une réponse non valide du serveur en amont lors de la tentative de répondre à la demande.",
    es: "El servidor recibió una respuesta no válida del servidor ascendente mientras intentaba cumplir con la solicitud."
  },
  statusCode: 502
}

export const ER503_SERVICE_UNAVAILABLE: iErrorProps = {
  title: {
    en: "Service Unavailable",
    fr: "Service non disponible",
    es: "Servicio no disponible"
  },
  message: {
    en: "The server is currently unable to handle the request due to temporary overloading or maintenance of the server.",
    fr: "Le serveur est actuellement incapable de traiter la demande en raison d'une surcharge temporaire ou de la maintenance du serveur.",
    es: "El servidor no puede manejar la solicitud en este momento debido a una sobrecarga temporal o mantenimiento del servidor."
  },
  statusCode: 503
}

export const ER504_GATEWAY_TIMEOUT: iErrorProps = {
  title: {
    en: "Gateway Timeout",
    fr: "Délai d'attente de passerelle",
    es: "Tiempo de espera de la puerta de enlace"
  },
  message: {
    en: "The server did not receive a timely response from the upstream server while acting as a gateway or proxy.",
    fr: "Le serveur n'a pas reçu de réponse en temps opportun du serveur en amont lorsqu'il agissait comme une passerelle ou un proxy.",
    es: "El servidor no recibió una respuesta oportuna del servidor ascendente mientras actuaba como puerta de enlace o proxy."
  },
  statusCode: 504
}

export const ER520_UNKNOWN_ERROR: iErrorProps = {
  title: {
    en: "Unknown Error",
    fr: "Erreur inconnue",
    es: "Error desconocido"
  },
  message: {
    en: "An unknown error occurred.",
    fr: "Une erreur inconnue s'est produite.",
    es: "Se ha producido un error desconocido."
  },
  statusCode: 520
};
