export enum eFeature {
  // empty fallback
  VOID = "void",

  // premium
  SPLIT_SIGNUPS = "split-signups",
  AUTOMATIONS = "automations",
  DISCORD_EMBEDS_INSTANT_SYNC = "discord-embeds-instant-sync",
  ROSTER_CATEGORIES = "roster-categories",
  CUSTOM_FIELDS = "custom-fields",
  
  // core
  SIGNUPS = "signups",
  MULTI_CHAR_SIGNUPS = "multi-char-signups",
  MULTI_SPEC_SIGNUPS = "multi-spec-signups",
  LIVE_UPDATES = "live-updates",
  PRE_MADE_TEMPLATES = "pre-made-templates",
  PLACEHOLDERS = "placeholders",
  ROLES = "roles",
  ONLY_MY_ASSIGNMENTS = "only-my-assignments",
  REPLACE_WITH = "replace-with",
  SMART_LISTS = "smart-lists",
  CUSTOM_SMART_LISTS = "custom-smart-lists",

  DISCORD_BOT = "discord-bot",
  DISCORD_SIGNUPS = "discord-signups",
  DISCORD_LINK_SIGNUP = "discord-link-signups",

  PROMOTE_ADMINS = "promote-admins",
  COLLABORATIVE_EDITING = "collaborative-editing",
}

export type iPrepiFeature = {
  labelShort: string
  // labelLong: string
  description: string
  pricingTableUnit?: string
  suffix?: string
}

export const prepiFeatures: { [key in eFeature]: iPrepiFeature} = {
  [eFeature.VOID]: { labelShort: "", description: "" },

  [eFeature.SPLIT_SIGNUPS]: { labelShort: "Split signups", description: "Include multiple potential events in a single signup to see and plan member availability." },
  [eFeature.AUTOMATIONS]: { labelShort: "Automations", description: "Create automations and let Prepi do your weekly chores." },
  [eFeature.DISCORD_EMBEDS_INSTANT_SYNC]: { labelShort: "Discord embeds instant sync", description: "Updates & signups done through web app sync instantly to Discord embeds.", pricingTableUnit: "embed", suffix: "(per type)" },
  [eFeature.CUSTOM_FIELDS]: { labelShort: "Custom signup fields", description: "Prompts members to fill in up to 5 additional customizable fields." },
  
  [eFeature.SIGNUPS]: { labelShort: "Signups", description: "Create a signup event and let members sign up." },
  [eFeature.ROSTER_CATEGORIES]: { labelShort: "Roster categories", description: "Create roster categories and let members signup with as one of these categories." },
  [eFeature.MULTI_CHAR_SIGNUPS]: { labelShort: "Multi-char signups", description: "Let members sign up with multiple characters at once to give admins more flexibility." },
  [eFeature.MULTI_SPEC_SIGNUPS]: { labelShort: "Multi-spec signups", description: "Let members sign up with characters that can fulfill different roles." },
  [eFeature.LIVE_UPDATES]: { labelShort: "Live updates", description: "Changes published by admins will instantly sync to all members viewing the document." },
  [eFeature.PRE_MADE_TEMPLATES]: { labelShort: "Pre-made templates", description: "Get started quickly with one of the included pre-made templates." },
  [eFeature.PLACEHOLDERS]: { labelShort: "Placeholders", description: "Don't let slow signups hold you back from being able to start planning." },
  [eFeature.ROLES]: { labelShort: "Roles", description: "Have multiple assignments you want the same member to do? Create a role!" },
  [eFeature.ONLY_MY_ASSIGNMENTS]: { labelShort: "Only my assignments filter", description: "'Only my assignments' filter out unimportant noise from your assignments." },
  [eFeature.REPLACE_WITH]: { labelShort: "Replace with search", description: "Search quickly through your members to find someone to fill the spot." },
  [eFeature.SMART_LISTS]: { labelShort: "Smart lists", description: "Automatically spread your members into assignments using conditional logic." },
  [eFeature.CUSTOM_SMART_LISTS]: { labelShort: "Custom smart lists", description: "Create your own custom smart lists to use in assignments." },

  [eFeature.DISCORD_BOT]: { labelShort: "Discord bot", description: "Interact with Prepi signups through our Discord bot." },
  [eFeature.DISCORD_SIGNUPS]: { labelShort: "Discord signups", description: "Sign up directly in a Discord channel." },
  [eFeature.DISCORD_LINK_SIGNUP]: { labelShort: "Embed signup in channel", description: "Embed a Prepi signup directly in a Discord channel." },

  [eFeature.PROMOTE_ADMINS]: { labelShort: "Promote admins", description: "Give another member access to edit a signup document." },
  [eFeature.COLLABORATIVE_EDITING]: { labelShort: "Collaborative Editing", description: "Changes to signup document sync instantly between admins." },
}