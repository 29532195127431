import { eActionRequired, withLanguage } from "typings";
import { isBetween } from "../mathUtilities";

export interface iErrorProps {
  title: withLanguage;
  message: withLanguage;
  statusCode: number;
  actionRequired?: eActionRequired;
  useSentry?: boolean;
  data?: Record<string, any>;
}

export class CustomError extends Error {
  statusCode: number
  useSentry: boolean
  errorTitle: string
  isUserError: boolean
  actionRequired?: eActionRequired
  data?: Record<string, any>

  constructor(params: iErrorProps, locale = "en") {
    const { title, message, statusCode, actionRequired, useSentry, data } = params

    super(message[locale])
    this.errorTitle = title[locale]
    this.statusCode = statusCode
    this.isUserError = isBetween(statusCode, 400, 499)
    this.useSentry = useSentry ?? this.isUserError ? false : true
    if (!!actionRequired) this.actionRequired = actionRequired
    if (!!data) this.data = data

    // Setting prototype correctly
    Object.setPrototypeOf(this, CustomError.prototype)
  }

  getErrorMessage() {
    return this.message
  }
}