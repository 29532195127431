import { eGroupsFormat, eUserCacheType } from "../../types/enum";
import { removeUndefinedProperties } from "../../types/factory";
import { iRaidCreation } from "../iRaid";
import { iUser } from "../iUser";
import { gameRoleType, iMember } from "../raid/iMember";
import { iLocationMap } from "../raid/raid";
import { teamPermissionsClaims } from "../team/team";

export type themePreference = "auto" | "light" | "dark"
export type serverName = string
export type characterName = string
export type discordChannelLocation = {
  guildID: string
  guildName: string
  channelID: string
  channelName: string
}

export interface iCharacter {
  charClass: string,
  charSpec: string,
  charName: string,
  gameRole: gameRoleType // "tank" | "healer" etc
  
  isMain?: boolean
  altSpecs?: string[]
}
export interface iCharacterFull extends iCharacter {
  location: iLocationMap,
  server: string,
  faction: string
}

export type iSignupSet = {
  id: string
  set: iMember
  label: string
  isMain: boolean
  locationMap: iLocationMap
  server?: string
}

export type iSignupSets = Record<string, iSignupSet>

export interface iUserSecrets {
  email?: string,
  discordUser?: any
  discordUserGuilds?: any
}

export interface iUserClaims {
  teamPermissions?: teamPermissionsClaims
}

export type iGroupsFormat = eGroupsFormat
export interface iUserSettings {
  groupsFormat?: iGroupsFormat
  panelTextExport?: {
    maxColumnWidth?: number
  }
  timeZone?: string
  signupDefaults?: Partial<iRaidCreation>
}

export type iUserCache = {
  id: string
  type: eUserCacheType
  validUntil: number
  value: string // tied to iMemberCustomFieldReplies
  forceUpdate?: true
}

export type iUserSubscriptionStatus =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid"


export type iStripeItem = {
  priceID: string
  quantity: number
}

export type iUserSubscription = {
  id: string
  items: iStripeItem[]
  status: iUserSubscriptionStatus
}

export interface iUserPremiumBoostDistribution {
  [teamID: string]: {
    boostsSent: number
  }
}

export interface iUserPremium {
  stripeCustomerID: string
  availableBoosts: number
  boostDistribution: iUserPremiumBoostDistribution
  subscriptions: {
    [subscriptionID: string]: iUserSubscription
  }
}

export const User = () => {

  const create = ({
    userID,
    displayName,
    themePreference,
    discordID,
    discordUID,
    img,
    locale,
    mainLocation,
    signupSets = {},
    timeZone,
    cache
  }: {
    userID: string
    displayName: string
    themePreference: themePreference
    discordID: string
    discordUID?: string
    img?: string
    locale?: string
    mainLocation?: iLocationMap
    signupSets?: iSignupSets
    timeZone?: string
    cache?: iUserCache[]
  }) => {
    const user: iUser = {
      userID,
      displayName,
      themePreference,
      discordID,
      characters: [],
      flags: [],
      createdAt: Date.now(),
      signupSets,
      settings: {}
    }
    if (discordUID) user.discordUID = discordUID
    if (img) user.img = img
    if (locale) user.locale = locale
    if (mainLocation) user.mainLocation = mainLocation
    if (cache) user.cache = cache
    if(!!timeZone) user.settings.timeZone = timeZone

    removeUndefinedProperties(user)

    return user
  }


  return ({
    create,
  })
}

export default User;
