import { Active, Over } from "@dnd-kit/core";
import { dequal } from "dequal";
import copy from "fast-copy";
import { CustomError, ER400_BAD_REQUEST, ER_SPLITDATA_NOT_FOUND_500, capitalize, charIDToRosterTargetID, charIDToString, countMemberInSplit, createNewSplitList, findCharInMembers, findMemberInSession, findRunsIn, getDistributeAllocation, getErrorMessage, getKeysToArray, getRosterListID, iDistributeLog, iRunAllocations, isInRun, isSignedMember, moveCharIDToRunID, moveTargetIDToAnotherList, removeTargetIDFromList, rosterTargetIDToCharID, runIDToIndexes, runIDToListID, splitDataAndRunIDToRun, splitDataToAllocation, transformGroupedArrays } from "functions";
import { atom } from "jotai";
import { RESET, atomWithReset, selectAtom } from "jotai/utils";
import toast from "react-hot-toast";
import { Areas, Target, assertIsDragDataMember, charID, eDragType, eKeyOrderLayout, gameRoleType, iGrouping, iList, iLocation, iMember, iRaid, iSmartListCacheType, iSplitSignup, iTarget, isBoolean, moveCharIDUpdateType } from "typings";
import { getDragAndDropData } from "../../components/dnd_kit/utilities/createRange";
import { userPreferencesState, ydocAtom } from "./global";
import { showDistributeLogToastState } from "./notifications";
import { raidState, registerListsState, registerTargetsState, signupGroupingAtom, unregisterListsState, unregisterTargetsState } from "./raid";
import { smartListToMemberCacheAtom, smartListsAtom } from "./smartListState";
import { updateMemberState } from "./updateMemberState";

export const splitDataState = atomWithReset<iSplitSignup | null>(null)
export const splitListsInitialisedState = atom(false)
export const isSplitsInitialisedAtom = atomWithReset<boolean>(false)
export const filterSplitBenchAtom = atomWithReset<string[] | null>(null)
export const expandedMatchesAtom = atomWithReset<string[]>([])
export const currentRunSizeAtom = atomWithReset<string | "default">("default")

export const resetSplitStateAtom = atom(
  null,
  (get, set) => {
    console.log("Jotai - resetting split state")
    set(splitDataState, RESET)
    set(isSplitsInitialisedAtom, RESET)
    set(filterSplitBenchAtom, RESET)
    set(expandedMatchesAtom, RESET)
    set(splitListsInitialisedState, false)
  }
)

export const timeWindowKeysState = selectAtom<iSplitSignup | null, string[]>(splitDataState, (s) => {
  return Object.keys(s?.timeWindows || {}).sort()
}, dequal)
export const timeWindowsCollapsedAtom = atom<string[]>([])

export const memberLimitState = selectAtom<iSplitSignup | null, number>(splitDataState, (s) => {
  return s?.memberMaxPerSplit || 0
}, dequal)

export const updateSplitDateState = atom(null,
  (get, set, update: iSplitSignup | null) => {
    const y = get(ydocAtom)
    const splitData = copy(update)
    if(!splitData) return
    
    console.log("set split", splitData)
    if(update === null){
      y.transactSystem(() => {
        y.splits.delete()
      })
      set(splitListsInitialisedState, false)
      set(isSplitsInitialisedAtom, RESET)
      return
    }

    y.transactUser(() => {
      y.splits.set(splitData)
    })
  }
)

export const removeFromSplitState = atom(null,
  (get, set, { indexLocation, location }: {indexLocation: string, location: iLocation}) => {
    // "A" for time window A
    // "AB" for session B in time window A
    // "ABC" for run C in session B in time window A
    try {
      const strLength = indexLocation?.length
      if(typeof indexLocation !== "string") {
        throw new Error("Remove from split indexLocation is not a string.")
      }
      if(strLength > 3 || strLength < 1) {
        throw new Error("Remove from split indexLocation length incorrect.")
      }
      const charArr = indexLocation.split("")
  
      const y = get(ydocAtom)
      const grouping = get(signupGroupingAtom)
      const splitData = copy(y.splits.get())
      if(!grouping || !splitData) return
      const removedRunIDs: string[] = []
  
      const remove = (splitData: iSplitSignup) => {
        // time window
        if(!splitData.timeWindows[charArr[0]]) {
          console.error("Cannot find that time window", { splitData, charArr });
          throw new Error("Cannot find that time window")
        }
        if(strLength === 1){
          removedRunIDs.push(...findRunsIn(splitData, charArr[0]).map(obj => obj.runID))
          delete splitData.timeWindows[charArr[0]]
          return
        }
  
        // session
        if(!splitData.timeWindows[charArr[0]].sessions[charArr[1]]) {
          console.error("Cannot find that session", { splitData, charArr });
          throw new Error("Cannot find that session")
        }
        if(strLength === 2){
          removedRunIDs.push(...findRunsIn(splitData, charArr[0], charArr[1]).map(obj => obj.runID))
          delete splitData.timeWindows[charArr[0]].sessions[charArr[1]]
          return
        }
        
        // run
        if(!splitData.timeWindows[charArr[0]].sessions[charArr[1]].splits[charArr[2]]) {
          console.error("Cannot find that run", { splitData, charArr });
          throw new Error("Cannot find that run")
        }
        removedRunIDs.push(charArr[0] + charArr[1] + charArr[2])
        delete splitData.timeWindows[charArr[0]].sessions[charArr[1]].splits[charArr[2]]
      }
      
      remove(splitData)
  
      // find run lists
      const categoriesHorisontal = grouping.getGroupingKeyOrder({ mode: "specific", orderLayout: eKeyOrderLayout.HORISONTAL })
      const gameRoleToBenched: Record<string, string[]> = getKeysToArray<string>(categoriesHorisontal)
      const removeLists: iList[] = []
      for (const runID of removedRunIDs) {
        for (const category of Object.values(categoriesHorisontal)) {
          const list = y.lists.get(runIDToListID(runID, category))
          if(!list) continue
          removeLists.push(list)
          
          gameRoleToBenched[category].push(...list.targetIDs)
        }
      }
      splitData.runIDs = splitData.runIDs.filter(runID => removedRunIDs.includes(runID) === false)
  
      y.transactUser(() => {
        // put members on bench
        for (const [gameRole, targetIDs] of Object.entries(gameRoleToBenched)) {
          const benchListID = getRosterListID("bench", gameRole)
          const list = copy(y.lists.get(benchListID))
          if(!list) continue
          list.targetIDs = [...list.targetIDs, ...targetIDs]
          y.lists.set(list.id, copy(list))
        }
  
        // unregister lists
        set(unregisterListsState, removeLists.map(list => list.id), false)
  
        // update splitData
        y.splits.set(splitData)
      })

    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)


export const moveCharIDToRunIDState = atom(
  null,
  ( get, set,
    { fromRunID, toRunID, memberID, charName, fromListID, toListID,
      targetListIndex, sameMemberIDs, skipIfInSession, isDistribute = false }: moveCharIDUpdateType
  ) => {
    // console.log("move", { fromRunID, toRunID, memberID, charName, fromListID, toListID })
    const y = get(ydocAtom)
    const splitData = copy(y.splits.get())
    
    if (!splitData) {
      throw new CustomError({
        ...ER_SPLITDATA_NOT_FOUND_500,
        message: { en: `Split roster has not been initialised.` }
      })
    }
      

    // checking if member is already in wanted session with another char
    let memberFoundInRunID: null | { charID: charID; runID: string; } = null
    if(toRunID !== "bench" && sameMemberIDs !== true){
      const { twIndex, sessionIndex } = runIDToIndexes(toRunID)
      memberFoundInRunID = findMemberInSession(memberID, splitData, twIndex, sessionIndex)
      if(!!memberFoundInRunID && skipIfInSession) return
    }

    // cancel if member is already in run
    const checkAlreadyInRun = () => {
      if(toRunID === "bench") return false
      const toRun = splitDataAndRunIDToRun(splitData, toRunID)
      const alreadyInRun = isInRun(toRun, memberID, charName)
      return alreadyInRun
    }
    if(checkAlreadyInRun()) return

    // check member limit
    if(splitData.memberMaxPerSplit > 0 && toRunID !== "bench" && fromRunID === "bench"){
      const memberCount = countMemberInSplit(splitData, memberID)
      
      if(memberCount >= splitData.memberMaxPerSplit && (!memberFoundInRunID && !isDistribute && !sameMemberIDs)) {
        if(!isDistribute) {
          throw new CustomError({
            ...ER400_BAD_REQUEST,
            title: { en: "Max limit reached" },
            message: {
              en: `Cannot move ${capitalize(charName)} into run. Max member character limit reached.`
            }
          })
        }
        return
      }
    }
    
    moveCharIDToRunID({ splitData, fromRunID, toRunID, memberID, charName })

    const fromList = copy(y.lists.get(fromListID))
    const toList = copy(y.lists.get(toListID))
    if(!fromList || !toList) return
    
    // remove from old list - add to new list
    const targetID = removeTargetIDFromList(fromList, charIDToRosterTargetID({memberID, charName}))
    if(!targetID) return
    if(toList?.targetIDs.includes(targetID) === false) {
      if(typeof targetListIndex === "number") {
        toList.targetIDs.splice(targetListIndex, 0, targetID)
      } else {
        toList.targetIDs.push(targetID)
      }
    }

    y.doc.transact(() => {

      // move other char in session to bench
      if(memberFoundInRunID && memberFoundInRunID.charID.charName !== charName) {

        const { charID, runID } = memberFoundInRunID
        const raid = get(raidState)
        if(isBoolean(raid)) throw new Error("Signup not initialised")

        const char = findCharInMembers(charID, raid.members)
        if(!char) return // FIXME: GS
        const memberOldListID = runIDToListID(runID, char.gameRole)
        const benchListID = getRosterListID("bench", char.gameRole)
        
        const oldList =
          memberOldListID === toListID ? toList : 
          memberOldListID === fromListID ? fromList : 
          copy(y.lists.get(memberOldListID))
          
          
        const benchList = 
          benchListID === toListID ? toList : 
          benchListID === fromListID ? fromList : 
          copy(y.lists.get(benchListID))
        
        if(!oldList || !benchList) return
          
        moveCharIDToRunID({ splitData, fromRunID: runID, toRunID: "bench", memberID: charID.memberID, charName: charID.charName })
        moveTargetIDToAnotherList(oldList, benchList, charID.memberID, charID.charName)
        
        y.lists.set(oldList.id, oldList)
        y.lists.set(benchList.id, benchList)
      }

      y.lists.set(fromList.id, fromList)
      y.lists.set(toList.id, toList)

      // add target to new list
      y.splits.set(splitData)
    }, "user")
  }
)

type swapSplitCharIDs = {
  fromTargetID: string
  toTargetID: string

  fromRunID: string
  toRunID: string
  fromListID: string
  toListID: string
  fromListIndex: number
  toListIndex: number
}
export const swapCharIDsInRunsState = atom(
  null,
  (get, set, { fromRunID, toRunID, fromTargetID, toTargetID, fromListID, toListID, fromListIndex, toListIndex }: swapSplitCharIDs) => {
    try {
      const y = get(ydocAtom)
      const fromCharID = rosterTargetIDToCharID(fromTargetID)
      const toCharID = rosterTargetIDToCharID(toTargetID)
      const sameMemberIDs = fromCharID.memberID === toCharID.memberID

      y.doc.transact(() => {
        set(moveCharIDToRunIDState, { fromRunID: fromRunID, toRunID: toRunID, memberID: fromCharID.memberID, charName: fromCharID.charName, fromListID, toListID, targetListIndex: toListIndex, sameMemberIDs })
        set(moveCharIDToRunIDState, { fromRunID: toRunID, toRunID: fromRunID, memberID: toCharID.memberID, charName: toCharID.charName, toListID: fromListID, fromListID: toListID, targetListIndex: fromListIndex, sameMemberIDs })
      }, "user")

    } catch (error: unknown) {
      if(!(error instanceof CustomError)){
        console.error(error)
        toast.error("Move character: unknown error")
        return
      }
      toast.error(getErrorMessage(error))
    }
  }
)

export const getSplitInfoFrom = (splitData: iSplitSignup, signup: boolean | iRaid, grouping: iGrouping) => {
  const runs: { runID: string, categoryToCharIDs: Record<string, charID[]> }[] = []
  if (isBoolean(signup)) return { categoryToBenched: {}, runs }
  const { getGroupedMembers, getMemberVariants } = grouping
  
  const signedMemberVariants = Object.values(signup.members)
    .filter(isSignedMember)
    .reduce((acc: iMember[], member) => {
      acc.push(...getMemberVariants(member))
      return acc
    }, [])
  
  // find charIDs from runs
  for (const [index1, tw] of Object.entries(splitData.timeWindows)) {
    for (const [index2, session] of Object.entries(tw.sessions)) {
      for (const [index3, run] of Object.entries(session.splits)) {
        
        const memberVariants: iMember[] = []
        for (const charID of Object.values(run.members)) {
          const memberVariantIndex = signedMemberVariants.findIndex(_member => {
            if(!!_member.character && !!charID.charName) {
              return _member.userID === charID.memberID && _member?.character?.charName === charID.charName
            }
            return _member.userID === charID.memberID
          })
          if (memberVariantIndex === -1) {
            // TODO: that charID no longer exists - remove charID from run?
            continue
          }
          const memberVariant = signedMemberVariants.splice(memberVariantIndex, 1)[0]
          memberVariants.push(memberVariant)
        }

        const categoryToMembers = getGroupedMembers(memberVariants)
        const categoryToCharIDs = transformGroupedArrays(categoryToMembers, (member) => {
          const charID: charID = { memberID: member.userID, charName: member?.character?.charName || "" }
          return charID
        })
        
        runs.push({
          runID: index1 + index2 + index3,
          categoryToCharIDs
        })
      }
    }
  }
  
  // add any remaining members to bench categoryToCharIDs
  const categoryToBenchedMembers = getGroupedMembers(signedMemberVariants)
  const categoryToBenchedCharIDs = transformGroupedArrays(categoryToBenchedMembers, (member) => {
    const charID: charID = { memberID: member.userID, charName: member?.character?.charName || "" }
    return charID
  })

  return {
    categoryToBenchedCharIDs,
    runs
  }
}

type registerRunParams = {
  runID: string
  categoryToCharIDs: Record<string, charID[]>
  location: iLocation
}
export const registerRunState = atom(
  null,
  (get, set, { runID, categoryToCharIDs }: registerRunParams) => {
    const y = get(ydocAtom)
    const signup = copy(get(raidState))
    const grouping = get(signupGroupingAtom)
    if (!signup || isBoolean(signup) || !grouping) return

    const categoriesTwoCol = grouping.getGroupingKeyOrder({ mode: "specific", orderLayout: eKeyOrderLayout.TWOCOL })

    try {
      const newTargets: iTarget[] = []
      const newLists: iList[] = []

      // init target for run
      const target = Target().create({
        area: Areas.ROSTER, 
        containType: null,
        containID: null, 
        targetIDstr: `run-${runID}`
      })
      newTargets.push(target)

      for (const category of categoriesTwoCol) {
        const listID = runIDToListID(runID, category)
        const listName = capitalize(category)
        const { list, targets } = createNewSplitList(listID, listName, categoryToCharIDs?.[category] || [])
        newTargets.push(...targets)
        newLists.push(list)
      }

      y.doc.transact(() => {
        set(registerListsState, newLists)
        set(registerTargetsState, newTargets)
      }, "system")

    } catch (error) {
      console.error(error)
    }
  }
)

export const adjustRunSizeState = atom(
  null,
  (get, set, { runID, adjustment }: { runID: string; adjustment: number }) => {
    const y = get(ydocAtom)
    const signup = get(raidState)
    const splitData = copy(y.splits.get())
    try {
      if(isBoolean(signup)) throw new Error("Signup not initialised yet.")
      const run = splitData.timeWindows?.[runID[0]].sessions?.[runID[1]].splits?.[runID[2]]
      if(!run) throw new Error(`Could not find run ${runID}`)

      if(run?.overrides?.maxSize === undefined) {
        run.overrides = {
          maxSize: signup.maxSize + adjustment
        }
      } else {
        run.overrides.maxSize = run.overrides.maxSize + adjustment
      }
      
      y.doc.transact(() => {
        y.splits.set(splitData)
      }, "user")
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)

export const renamePlaceholderInSplitState = atom(
  null,
  (get, set, { oldCharID, newCharID, gameRole, isSplit }: { oldCharID: charID, newCharID: charID, gameRole: gameRoleType, isSplit: boolean }) => {
    const y = get(ydocAtom)
    const splitData = copy(y.splits.get())

    const FixSplitRunLists = () => {
      const runs = findRunsIn(splitData)

      const FixList = (listID: string) => {
        // remove from run list
        const oldTargetID = charIDToRosterTargetID(oldCharID)
        const fromList = copy(y.lists.get(listID))
        if(!fromList) throw new Error("From list not found")
        fromList.targetIDs = fromList.targetIDs.filter(tarID => tarID !== oldTargetID)
        set(unregisterTargetsState, [oldTargetID])
        
        // add back to run/ bench list
        const memberTargetIDstring = charIDToRosterTargetID({ memberID: newCharID?.memberID, charName: newCharID?.charName })
        const newTarget = Target().create({
          area: Areas.ROSTER, 
          containType: eDragType.MEMBER, 
          containID: charIDToString(oldCharID), 
          targetIDstr: memberTargetIDstring
        })
        set(registerTargetsState, [newTarget])
        const toList = copy(y.lists.get(listID))
        if(!toList) throw new Error("To list not found")
        toList.targetIDs.push(newTarget.id)

        y.lists.set(fromList.id, fromList)
        y.lists.set(toList.id, toList)
      }

      for (const {run, runID} of runs) {
        const charIDIndex = run.members.findIndex(c => dequal(c, oldCharID))
        if(charIDIndex === -1) continue;
        
        // replace old with new in run
        run.members.splice(charIDIndex, 1, newCharID)
        
        FixList(runIDToListID(runID, gameRole))
        return
      }

      FixList(getRosterListID("bench", gameRole))
    }

    try {
      y.transactUser(() => {
        if(isSplit) FixSplitRunLists()

        set(updateMemberState, {
          memberID: newCharID.memberID,
          callback: (member) => {
            if(member.character) member.character.charName = newCharID.charName
          }
        })
  
        y.splits.set(splitData)
      })
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)

export const handleSplitDropAtom = atom(null, (get, set, active: Active, over: Over) => {
  const { dragData, dropData } = getDragAndDropData(active, over)
  
  assertIsDragDataMember(dragData)
  if (
    !dragData.targetID ||
    !dragData.targetID ||
    !dropData.targetID ||
    !dragData.inList ||
    !dropData.list?.id ||
    !dragData?.runID ||
    !dropData.runID ||
    !dragData.listIndex ||
    !dropData.listIndex
  ) {
    throw new Error("Handle split drop - parameters missing")
  }
    
  set(swapCharIDsInRunsState, {
    fromTargetID: dragData.targetID,
    toTargetID: dropData.targetID,
    fromListID: dragData.inList,
    toListID: dropData.list?.id,
    fromRunID: dragData?.runID,
    toRunID: dropData.runID,
    fromListIndex: dragData.listIndex,
    toListIndex: dropData.listIndex
  })
})


interface executeRunAllocationProps {
  runAllocationsInput?: iRunAllocations
  splitDataInput?: iSplitSignup,
  signupInput?: iRaid,
  gameRoleOrderInput?: gameRoleType[],
  smartListCacheInput?: iSmartListCacheType
}

export const currentAllocationAtom = atom((get) => {
  const signup = copy(get(raidState))
  const split = copy(get(splitDataState))
  const smartListCache = copy(get(smartListToMemberCacheAtom))
  const smartLists = copy(get(smartListsAtom))
  if (isBoolean(signup) || !split) return {}

  const allocation = splitDataToAllocation(split, signup, smartListCache, smartLists)
  return allocation
})

export const getAndExecuteAllocationAtom = atom(
  null,
  (get, set, props: executeRunAllocationProps) => {
    const { signupInput, splitDataInput  } = props
    const y = get(ydocAtom)
    const signup = signupInput || copy(get(raidState))
    const split = splitDataInput || copy(get(splitDataState))
    const smartListCache = copy(get(smartListToMemberCacheAtom))
    const smartLists = copy(get(smartListsAtom))
    const userPreferences = copy(get(userPreferencesState))
    const grouping = get(signupGroupingAtom)
    if (isBoolean(signup) || !split || !grouping) return

    try {
      const log: iDistributeLog = []

      const categoriesTwoCol = grouping.getGroupingKeyOrder({
        mode: "specific",
        orderLayout: eKeyOrderLayout.TWOCOL
      })
      const allocation = getDistributeAllocation(
        split,
        signup,
        categoriesTwoCol,
        smartListCache,
        smartLists,
        userPreferences,
        log
      )

      y.transactUser(() => {
        for (const [runID, runAllocation] of Object.entries(allocation)) {
          for (const allocated of runAllocation.allocations) {
            set(moveCharIDToRunIDState, {
              charName: allocated.charID.charName,
              memberID: allocated.charID.memberID,
              fromRunID: "bench",
              toRunID: runID,
              fromListID: getRosterListID("bench", allocated.category),
              toListID: runIDToListID(runID, allocated.category),
              skipIfInSession: true,
              isDistribute: true
            })
          }
        }
      })

      // toast.success("Distribute success - show log")
      set(showDistributeLogToastState, { log })
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)