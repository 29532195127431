import { newID } from "../../types/typeUtils";
import { iTeam } from "../iTeam";
import { RaidAdmin } from "../raid/raid";

export enum eTeamPermission {
  MANAGE_TEAM = "manage team",
  MANAGE_TAGS = "manage tags",
  MANAGE_MEMBERS = "manage members",
  MANAGE_SIGNUPS = "manage signups",
  SIGNUPS_ASSISTANT = "signups assistant",
  MANAGE_AUTOMATIONS = "manage automations",
}
export const permissionsInCustomClaims: iTeamPermissionType[] = [eTeamPermission.MANAGE_SIGNUPS, eTeamPermission.SIGNUPS_ASSISTANT, eTeamPermission.MANAGE_AUTOMATIONS]
export type teamPermissionsClaims = { // i.e { "manage signups": ["teamID1", "teamID2"] }
  [P in iTeamPermissionType]?: string[];
}
export type iTeamPermissionType = eTeamPermission
export type teamPermissions = {
  [P in iTeamPermissionType]?: true;
}
export type teamTags = {
  owner: iTeamMemberTag
  everyone: iTeamMemberTag
  [tagID: string]: iTeamMemberTag
}

export interface iTeamMember {
  userID: string
  displayName: string
  discordID: string
}

export interface iTeamMemberTag {
  id: string // short string ID
  colorHex: string
  label: string
  permissions: teamPermissions
}

export interface iTeamAutoAcceptRole {
  roleID: string
  roleLabel: string
  giveTagIDs: string[]
}

export type iTeamBonus = {
  id: string
  label: string
  description: string
  emoji: string
  calculateBoostCallback: (boosts: number) => number // modifies how many boosts a team has
}

export interface iTeamPremium {
  tierID: string,
  bonuses: string[] // team bonus IDs
  boostsReceived: {
    [userID: string]: number // userID: numberOfBoosts
  }
}

export const Team = () => {
  
  const create = (
    ownerTeamMember: iTeamMember,
    {
      teamName,
      discordServerID,
      owner,
    }: {
      teamName: string
      discordServerID: string
      owner: RaidAdmin
    }
  ) => {
    const teamID = newID(8)
    const createdAt = Date.now()

    const tags = {
      owner: {
        id: "owner",
        label: "Owner",
        colorHex: "#00CC9B",
        permissions: {}
      },
      everyone: {
        id: "everyone",
        label: "Everyone",
        colorHex: "#00CC9B",
        permissions: {}
      }
    }

    const memberTags = {}
    const memberPermissions = {}
    const autoAcceptDiscordRoles: iTeamAutoAcceptRole[] = []

    const team: iTeam = {
      id: teamID,
      teamName,
      discordServerID,
      autoAcceptDiscordRoles,
      owner,

      tags,
      createdAt: createdAt,
      memberTags,
      memberPermissions,
      members: {
        [ownerTeamMember.userID]: ownerTeamMember
      },
    }

    return team
  }

  return {
    create,
  }
}

export const TeamMember = () => {
  
  const create = ({ userID, displayName, discordID }: iTeamMember) => {

    const teamMember: iTeamMember = {
      userID,
      displayName,
      discordID,
    }

    return teamMember
  }

  return {
    create,
  }
}

export const TeamTag = () => {
  
  const create = ({
    label,
    colorHex = "#00CC9B",
    permissions = {}
  }: {
    label: string
    colorHex?: string
    permissions?: teamPermissions
  }) => {
    const id = newID(4)

    const tag: iTeamMemberTag = {
      id,
      label,
      colorHex,
      permissions
    }

    return tag
  }

  return {
    create,
  }
}


export const findTeamMembersWith = (team: iTeam, permission: iTeamPermissionType): iTeamMember[] => {
  if(!team) return []
  const validTagIDs = Object.values(team.tags).filter(tag => tag.permissions[permission] === true).map(tag => tag.id)
  const memberIDs = Object.entries(team.memberTags)
    .filter(([memberID, tagIDs]) => tagIDs.some((tagID) => validTagIDs.includes(tagID)))
    .map(([memberID, tagIDs]) => team.members[memberID])
  return memberIDs
}

export const getTeamMembersWithPermissions = (team: iTeam, permissions: iTeamPermissionType[]): iTeamMember[] => {
  const teamSignupAdmins = []
  for (const permission of permissions) {
    teamSignupAdmins.push(...findTeamMembersWith(team, permission))
  }

  const uniqueMembersMap = new Map<string, iTeamMember>()
  for (const teamMember of teamSignupAdmins) {
    uniqueMembersMap.set(teamMember.userID, teamMember)
  }

  return Array.from(uniqueMembersMap.values())
}