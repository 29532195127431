import { dequal } from "dequal";
import React, { CSSProperties, useMemo } from "react";
import { useMemoCompare } from "../../../global/hooks";
import { popOverState } from "../../../global/state/global";
import { iPopOver } from "typings";
import PopOver from "../popOver/popOver";
import { useAtomValue } from "jotai";
import { useResetAtom } from "jotai/utils";

const PopOverLocalizedContainer = () => {
  const popOver = useAtomValue(popOverState)
  const dismissPopOver = useResetAtom(popOverState)
  
  return (
    <PopOverLocalized
      popOver={popOver}
      dismissPopOver={() => {
        dismissPopOver()
        popOver?.events?.onClose?.()
      }}
    />
  )
}

interface PopOverLocalizedProps { popOver: iPopOver | null, dismissPopOver: () => void }

const PopOverLocalized = React.memo(
  ({ popOver, dismissPopOver }: PopOverLocalizedProps) => {

    const side = popOver?.side || "top"

    const popOverMemo: iPopOver = useMemoCompare(popOver, dequal)

    const style: CSSProperties = useMemo(() => {
      if(!popOver) return {}
      const tweakY = side === "bottom" ? popOver.domRect.height
                   : side === "center" ? -(popOver.domRect.height / 2)
                                       : 0
      
      const styleObj: CSSProperties = {}
      if(side === "bottom") {
        styleObj.top = 10 + window.scrollY + popOver.domRect.y + tweakY
        styleObj.left = window.scrollX + popOver.domRect.x + (popOver.domRect.width / 2)
      } else {
        styleObj.bottom = 10 + window.innerHeight - (popOver.domRect.y + window.scrollY) + tweakY
        styleObj.left = popOver.domRect.x + window.scrollX + (popOver.domRect.width / 2)
      }
      if(popOver?.options?.zIndex) styleObj.zIndex = popOver?.options?.zIndex
      return styleObj
    }, [popOver?.domRect])

  if(!popOver) return <></>;
  
  return (
    // wrapping in a div with height: 0
    // prevents layout shift when the
    // height of the popover changes
    <div style={{height: 0}}> 
      <PopOver
        style={{ ...style }}
        side={side}
        content={popOverMemo.content}
        dismiss={dismissPopOver}
        modal={popOver?.options?.modal}
      />
    </div>
  )
},
(prev, next) => {
  const sameProps = dequal(prev, next)
  // const diff1 = diff(prev, next)
  // console.log("popover - sameProps", sameProps, diff1)
  return sameProps
}
)

export default PopOverLocalizedContainer;