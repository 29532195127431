import { membersToCharIDs, isSignedMember, createNewSplitList, capitalize, getRosterListID } from "functions";
import { atom } from "jotai";
import { Areas, iList, iTarget, Target, eKeyOrderLayout, iLocation, iRaid } from "typings";
import { registerListsState, registerTargetsState, signupGroupingAtom } from "./raid";
import { ydocAtom } from "./global";
import { splitListsInitialisedState, getSplitInfoFrom, registerRunState, splitDataState } from "./split";
import { initialisedCharIDsInSplitAtom } from "./updateSplitMembersState";

type initSplitListsParams = {
  signup: iRaid
  location: iLocation
}
export const initSplitListsAtom = atom(
  null,
  (get, set, { signup, location }: initSplitListsParams) => {

    const y = get(ydocAtom);
    const hasInitialised = get(splitListsInitialisedState);
    const split = signup?.splitData;

    const grouping = get(signupGroupingAtom);
    if (hasInitialised === true || !grouping || !split) return;

    const categoriesVertical = grouping.getGroupingKeyOrder({
      mode: "specific",
      orderLayout: eKeyOrderLayout.VERTICAL
    });

    try {
      const { categoryToBenchedCharIDs, runs } = getSplitInfoFrom(split, signup, grouping);
      const newLists: iList[] = [];
      const newTargets: iTarget[] = [];

      // init target for split bench
      const splitBenchTarget = Target().create({
        area: Areas.ROSTER,
        containType: null,
        containID: null,
        targetIDstr: `split-bench`
      });
      newTargets.push(splitBenchTarget);

      // init bench lists
      for (const category of Object.values(categoriesVertical)) {
        const listID = getRosterListID("bench", category);
        const listName = `${capitalize(category)} Bench`;
        const { list, targets } = createNewSplitList(listID, listName, categoryToBenchedCharIDs?.[category] || []);
        newTargets.push(...targets);
        newLists.push(list);
      }

      y.doc.transact(() => {
        // init roster lists
        for (const run of runs) {
          set(registerRunState, {
            runID: run.runID,
            categoryToCharIDs: run.categoryToCharIDs,
            location
          });
        }

        set(registerListsState, newLists);
        set(registerTargetsState, newTargets);
      }, "system");

      set(splitDataState, split);
      set(splitListsInitialisedState, true);
      set(initialisedCharIDsInSplitAtom, membersToCharIDs(Object.values(signup.members), isSignedMember));

    } catch (error) {
      console.error(error);
    }
  }
);
