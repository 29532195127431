import { documentAPI } from "api";
import copy from "fast-copy";
import { addLogItem, capitalize, charIDToRosterTargetID, getErrorMessage, swapAltMainOnMember, updateDiscordSyncTo } from "functions";
import { atom } from "jotai";
import toast from "react-hot-toast";
import { eSideEffects, eSignupLogType, gameRoleType, iMember, iSideEffect, iSideEffectMoveToList, isBoolean } from "typings";
import { UserDataState, deleteRaidSyncKeysState, ydocAtom } from "./global";
import { raidMembersState, raidState, raidStateCore, rosterFullState } from "./raid";
import { updateSplitMembersState } from "./updateSplitMembersState";


export const updateMemberState = atom(
  null,
  (get, set, { callback, memberID }: { memberID: string; callback: (member: iMember, sideEffects: iSideEffect[]) => void; }) => {
    const y = get(ydocAtom);
    const members = get(raidMembersState);

    try {
      const member = copy(members[memberID]);
      const sideEffects: iSideEffect[] = [];
      callback(member, sideEffects);

      y.transactUser(() => {
        y.raidSync.set(`members.${member.userID}`, member);
        if (sideEffects.length > 0) {
          set(runSideEffectsState, { sideEffects, updatedMember: copy(member) });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
);


export const runSideEffectsState = atom(
  null,
  (get, set, { sideEffects, updatedMember }: { sideEffects: iSideEffect[], updatedMember: iMember }) => {
    if(Object.values(sideEffects)?.length < 1) return
    const y = get(ydocAtom)

    const moveToList = (moveToListSE: iSideEffectMoveToList) => {
      if(moveToListSE.fromListID === moveToListSE.toListID) return
      const oldList = copy(y.lists.get(moveToListSE.fromListID))
      const newList = copy(y.lists.get(moveToListSE.toListID))
      if(!oldList || !newList) return
      const targetID = charIDToRosterTargetID(moveToListSE.charID)
      const oldListIndex = oldList?.targetIDs.findIndex(_ => _ === targetID)
      if(oldListIndex === -1) return
      
      // remove from old list
      oldList?.targetIDs.splice(oldListIndex, 1)
      y.lists.set(oldList.id, oldList)

      // add to new list
      y.lists.set(newList.id, {...newList, targetIDs: [...newList.targetIDs, targetID]})
    }


    try {
      y.doc.transact(() => {
        for (const sideEffect of Object.values(sideEffects)) {
          switch (sideEffect.type) {
            case eSideEffects.MOVE_TO_LIST: moveToList(sideEffect); break;
            case eSideEffects.UPDATE_SPLITS: set(updateSplitMembersState, { updatedMember }); break;
          
            default: break;
          }
        }
      }, "user")
    } catch (error) {
      console.error(error);
    }
  }
)

export const updateAllMembersState = atom(
  null,
  (get, set, { callback }: { callback: (member: iMember, sideEffects: iSideEffect[]) => void }) => {
    const y = get(ydocAtom)
    const members = copy(get(raidMembersState))

    try {

      y.transactUser(() => {
        for (const member of Object.values(members)) {
          const sideEffects: iSideEffect[] = []
          callback(member, sideEffects)

          y.raidSync.set(`members.${member.userID}`, member)
          if(sideEffects.length > 0) {
            set(runSideEffectsState, { sideEffects, updatedMember: copy(member) })
          }
        }
      })
    } catch (error) {
      console.error(error);
    }
  }
)

export const swapAltMainCharState = atom(
  null,
  (get, set, { member: inputMember, altCharName, altSpec, gameRole }: {member: iMember, altCharName: string, altSpec?: string, gameRole?: gameRoleType}) => {

    try {
      const memberID = inputMember.userID
      set(updateMemberState, {
        memberID,
        callback: (member) => swapAltMainOnMember(member, altCharName, altSpec, gameRole)
      })

    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)

export const updateMemberRosterStatusState = atom(
  null,
  (get, set, { memberID, toRoster }: {memberID: string, toRoster: boolean}) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    
    if(typeof raid === "boolean") return
    const rosterFull = get(rosterFullState)
    if(toRoster === true && rosterFull){
      toast.error("The signup roster is already full.")
      return
    }
    
    const existingSpotIndex = raid.groupOrder.findIndex(spot => spot === memberID)
    const shouldRemoveSpot = toRoster === false && existingSpotIndex !== -1
    const firstOpenSpot = raid.groupOrder.findIndex(spot => spot === null)
    const giveSpot = existingSpotIndex !== -1 ? existingSpotIndex : firstOpenSpot
    
    y.doc.transact(() => {
      set(updateMemberState, {
        memberID,
        callback: (member) => {
          member.onRoster = toRoster
        }
      })
    
      if(shouldRemoveSpot) {
        y.raidSync.set(`groupOrder.${existingSpotIndex}`, null)
      } else {
        y.raidSync.set(`groupOrder.${giveSpot}`, memberID)
      }
    })
  }
)

type overrideSpecAtomProps = {
  member: iMember
  charName: string
  charSpec: string
  gameRole: gameRoleType
  isAltChar: boolean
}
export const overrideSpecState = atom(
  null,
  (get, set, { member: inputMember, charName, charSpec, gameRole, isAltChar = false }: overrideSpecAtomProps) => {
  
  try {

    if (isAltChar) {
      // update member.altCharacter[index]
      set(updateMemberState, {
        memberID: inputMember.userID,
        callback: (member) => {
          const altChars = member.altCharacters || []
          const altCharIndex = (altChars).findIndex((c) => c.charName === charName)
          if (altCharIndex === -1) throw new Error("Couldn't find that character")
          altChars[altCharIndex].charSpec = charSpec
          altChars[altCharIndex].gameRole = gameRole
        }
      })
      return
    }

    // update member.character
    set(updateMemberState, {
      memberID: inputMember.userID,
      callback: (member) => {
        if(!member.character) return
        member.character.charSpec = charSpec
        member.character.gameRole = gameRole
      }
    })

  } catch (error) {
    console.error(error)
    toast.error(getErrorMessage(error))
  }
})


export const resetMembersCharRaidSyncState = atom(null, (get, set, memberIDs: string[]) => {

  const raid = get(raidState)
  const y = get(ydocAtom)
  if(memberIDs?.length < 1 || isBoolean(raid)) return

  const raidSyncKeys = Array.from(y.raidSync.keys())
  const charNames = memberIDs.map(memberID => capitalize(raid.members[memberID]?.character?.charName))
  const removeKeys = memberIDs.reduce(
    (acc, memberID) => [...acc, `members.${memberID}`],
    [] as string[]
  )

  let didUpdate = false
  y.doc.transact(() => {
    for (const key of raidSyncKeys) {
      if(!removeKeys.some(str => key.startsWith(str))) continue;
      y.raidSync.delete(key)
      didUpdate = true
    }
  }, "system")
  
  if(didUpdate){
    toast(
      `${charNames.join(", ")} signup${charNames?.length > 0 ? "s" : ""} was updated: removing local changes.`,
      { duration: 5000 }
    )
  }
})

export const deleteMemberAtom = atom(null, async (get, set, memberID: string) => {
  const raidCore = copy(get(raidStateCore))
  const userData = get(UserDataState)
  if (isBoolean(raidCore) || !memberID) throw new Error("Signup not initialized or target memberID not provided")
  if(!userData) throw new Error("User data not defined.")
  const toBeDeletedMember = raidCore.members?.[memberID]
  if(!toBeDeletedMember) throw new Error("To be deleted member doesn't exist.")
  
  set(deleteRaidSyncKeysState, `members.${memberID}`, [memberID])
  const newGroupOrder = raidCore.groupOrder.map((spot) => (spot === memberID ? null : spot))
  addLogItem(raidCore, {
    type: eSignupLogType.DELETE_MEMBER,
    memberID: userData.userID,
    displayName: userData.displayName,
    data: {
      deletedUserID: toBeDeletedMember.userID,
      deletedDisplayName: toBeDeletedMember.displayName,
      ...(!!toBeDeletedMember?.character?.charName && {
        deletedCharName: toBeDeletedMember?.character?.charName
      })
    }
  })

  await documentAPI().raidAPI.updateMultiPath(
    raidCore?.raidID,
    { groupOrder: newGroupOrder, log: raidCore.log },
    [`members.${memberID}`]
  )

  updateDiscordSyncTo(raidCore)
})