import { iLocation } from "typings"
import { wowExpansions } from "./online/games/wow/expansions"
import { generalOnline } from "./online/online"

export type iLocationTier = {
  label: string
  prefix?: string
  location?: iLocation
  showLocationInPicker?: boolean
  next?: Record<string, iLocationTier>
}

export const allLocations: iLocationTier = {
  label: "Root",
  next: {
    "online": {
      label: "General",
      location: generalOnline,
      showLocationInPicker: true,
      next: {
        "games": {
          label: "Games",
          next: {
            "wow": {
              label: "WoW",
              prefix: "WoW",
              next: {

                // static
                "vanilla": {
                  label: "Classic Era",
                  location: wowExpansions.vanilla,
                  showLocationInPicker: true
                },
                "sod": {
                  label: "SoD",
                  location: wowExpansions.sod,
                  showLocationInPicker: true
                },
                "tbcc": {
                  label: "TBCC",
                  location: wowExpansions.tbcc,
                },
                "wotlk": {
                  label: "WOTLK",
                  location: wowExpansions.wotlk,
                },
                "cata": {
                  label: "Cata",
                  location: wowExpansions.cata,
                },

                // progressive
                "classic-prog-1": {
                  label: "Classic",
                  location: wowExpansions.classic,
                  showLocationInPicker: true
                },
                "retail": {
                  label: "Retail",
                  location: wowExpansions.retail,
                  showLocationInPicker: true
                }
              }
            }
          }
        }
      }
    }
  }
}

// Events
// ├── In-Person
// │   ├── Entertainment
// │   │   ├── Concerts
// │   │   ├── Theater
// │   │   ├── Movies
// │   │   └── Sports
// │   │       ├── Football
// │   │       ├── Basketball
// │   │       ├── Soccer
// │   │       └── Baseball
// │   ├── Cultural
// │   │   ├── Art Exhibitions
// │   │   ├── Music Festivals
// │   │   ├── Dance Performances
// │   │   └── Literary Events
// │   ├── Educational
// │   │   ├── Workshops
// │   │   ├── Seminars
// │   │   └── Conferences
// │   └── Social
// │       ├── Parties
// │       ├── Networking Events
// │       ├── Galas
// │       └── Fundraisers
// └── Online
//     ├── Gaming
//     │   ├── Multiplayer Games
//     │   ├── Tournaments
//     │   └── Virtual Reality
//     ├── Webinars
//     │   ├── Training
//     │   ├── Product Demos
//     │   └── Panel Discussions
//     ├── Virtual Concerts
//     ├── Online Courses
//     └── Virtual Gatherings

// Events
// ├── Online
// │   ├── Gaming Tournaments
// │   ├── Virtual Classes
// │   ├── Webinars
// │   └── Virtual Conferences
// ├── In-Person
// │   ├── Concerts
// │   ├── Theater Shows
// │   ├── Workshops
// │   └── Social Gatherings
// └── Hybrid
//     ├── Hybrid Conferences
//     ├── Hybrid Classes
//     ├── Hybrid Workshops
//     └── Mixed Social Events