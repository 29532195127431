import { getMemberCharVariants, getMemberNoVariants, groupBy, sortCharRoleClassSpecName } from "functions"
import { GroupedArray, SignupStates, eLocationType, eMemberGroupingFunction, iCategoryMeta, iGrouping, iLocation, iLocationClassSpec, iMember, iMemberGroup, iRaid, isArrayWithLength } from "typings"

export const getGrouping = (
  groupingFunction: eMemberGroupingFunction,
  signup: iRaid,
  location: iLocation
): iGrouping => {
  if (isArrayWithLength(signup?.rosterCategories?.categoryArray)) {
    return groupMembersByRosterCategories(signup, location)
  }
  if (
    groupingFunction === eMemberGroupingFunction.CHARACTER_CLASS &&
    location.type === eLocationType.CLASS_SPEC
  ) {
    return groupMembersByCharClass(signup, location)
  }
  if (
    groupingFunction === eMemberGroupingFunction.CHARACTER_GAMEROLE &&
    location.type === eLocationType.CLASS_SPEC
  ) {
    return groupMembersByCharGameRole(signup, location)
  }
  // if (groupingFunction === eMemberGroupingFunction.ROSTER_CATEGORIES && isArrayWithLength(signup?.rosterCategories?.categoryArray)) {
  //   return groupMembersByRosterCategories(signup, location)
  // }
  return groupMembersBySignupState(signup, location)
}

export const groupMembersByCharGameRole = (signup: iRaid, location: iLocationClassSpec): iGrouping => {
  return {
    id: eMemberGroupingFunction.CHARACTER_GAMEROLE,
    groupingLabel: "game role",
    groupingLabelPlural: "game roles",
    categoryMeta: location.game.gameRoleMeta,
    getMemberVariants: getMemberCharVariants,
    getGroupingKeyOrder(params) {
      if (params.mode === "specific") return location.game.gameRoleLayouts[params.orderLayout]
      const { numberOfCols } = params
      if (numberOfCols === 1) return location.game.gameRoleLayouts.vertical
      if (numberOfCols === 2) return location.game.gameRoleLayouts.twoCol
      return location.game.gameRoleLayouts.horisontal
    },
    getGroupedMembers(memberArray) {
      return groupBy(memberArray, ["character.gameRole"], (a, b) =>
        sortCharRoleClassSpecName(a?.character, b?.character)
      )
    }
  }
}

export const groupMembersByCharClass = (signup: iRaid, location: iLocationClassSpec): iGrouping => {

  const categoryMeta = Object.values(location.game?.classes).reduce((acc: iCategoryMeta, gameClass) => {
    return {...acc, [gameClass.gameClass]: {
      label: gameClass.gameClass,
      labelPlural: gameClass.gameClass + "s",
      colorDark: gameClass.colorDark,
      emoji: gameClass.emoji,
      iconImgPath: gameClass.iconImgPath,
      colorLight: gameClass.colorLight
    }}
  }, {})

  return {
    id: eMemberGroupingFunction.CHARACTER_CLASS,
    groupingLabel: "game class",
    groupingLabelPlural: "game classes",
    categoryMeta: categoryMeta,
    getMemberVariants: getMemberCharVariants,
    getGroupingKeyOrder(params) {
      return Object.keys(location.game?.classes || {})
    },
    getGroupedMembers(memberArray) {
      return groupBy(memberArray, ["character.charClass"], (a, b) =>
        sortCharRoleClassSpecName(a?.character, b?.character)
      )
    }
  }
}

export const groupMembersBySignupState = (signup: iRaid, location: iLocation): iGrouping => {

  const signupCategoryMeta: iCategoryMeta<SignupStates> = {
    [SignupStates.SIGNED]: {
      emoji: "✅",
      label: SignupStates.SIGNED,
      labelPlural: SignupStates.SIGNED,
      columns: "max-columns"
    },
    [SignupStates.AVAILABLE]: {
      emoji: "🤝",
      label: SignupStates.AVAILABLE,
      labelPlural: SignupStates.AVAILABLE,
      mergeMembersIntoCategoryID: SignupStates.SIGNED
    },
    [SignupStates.TENTATIVE]: {
      emoji: "🤔",
      label: SignupStates.TENTATIVE,
      labelPlural: SignupStates.TENTATIVE,
    },
    [SignupStates.LATE]: {
      emoji: "⏰",
      label: SignupStates.LATE,
      labelPlural: SignupStates.LATE,
    },
    [SignupStates.ABSENT]: {
      emoji: "🌴",
      label: SignupStates.ABSENT,
      labelPlural: SignupStates.ABSENT,
      hideInSignupEmbed: true
    },
    [SignupStates.NONE]: {
      emoji: "🫥",
      label: SignupStates.NONE,
      labelPlural: SignupStates.NONE,
      hideInSignupEmbed: true
    },
    [SignupStates.GHOST]: {
      emoji: "👻",
      label: SignupStates.GHOST,
      labelPlural: SignupStates.GHOST,
      hideInSignupEmbed: true
    },
  }

  return {
    id: eMemberGroupingFunction.SIGNUP_STATE,
    groupingLabel: "signup state",
    groupingLabelPlural: "signup states",
    categoryMeta: signupCategoryMeta,
    getMemberVariants: getMemberNoVariants,
    getGroupingKeyOrder(params) {
      return Object.values(SignupStates)
    },
    getGroupedMembers(memberArray) {
      return groupBy(memberArray, ["signupState"])
    }
  }
}

export const groupMembersByRosterCategories = (signup: iRaid, location: iLocation): iGrouping => {

  const categoryMeta = (signup.rosterCategories?.categoryArray || [])?.reduce(
    (acc, rosterCategory) => {
      return {
        ...acc,
        [rosterCategory.uniqueID]: {
          label: rosterCategory.label,
          labelPlural: rosterCategory.label,
          emoji: ""
        }
      }
    },
    {} as iCategoryMeta
  )

  return {
    id: eMemberGroupingFunction.ROSTER_CATEGORIES,
    groupingLabel: "roster category",
    groupingLabelPlural: "roster categories",
    categoryMeta,
    getMemberVariants: getMemberNoVariants,
    getGroupingKeyOrder(params) {
      return Object.values(signup.rosterCategories?.categoryArray || []).map((category) => category.uniqueID)
    },
    getGroupedMembers(memberArray) {
      return groupBy(memberArray, ["rosterCategory"])
    }
  }
}

export const groupedToMemberGroupArray = (grouped: GroupedArray<iMember>, order: string[]) => {
  const memberGroups: iMemberGroup[] = []

  for (const discriminator of Object.values(order)) {
    memberGroups.push({
      id: discriminator,
      label: discriminator,
      members: grouped?.[discriminator] || []
    })
  }

  return memberGroups
}