import { dequal } from "dequal"
import { eDocType, eSortDirection, eUserCacheType, gameRoleType, iCharacter, iCharacterFull, iGuest, iLocationClassSpec, iMember, iMemberCharAlternatives, iUser, iUserCache, isDefined } from "typings"
import { capitalize } from "../../utilities/utilities"
import { sortByKey } from "../raidData/smartListUtilities"

export const getCharFromFull = ({
  charClass,
  charName,
  charSpec,
  gameRole,
  isMain,
  altSpecs
}: iCharacterFull | iCharacter): iCharacter => {
  const char: iCharacter = { charClass, charName, charSpec, gameRole }
  if(!!altSpecs) char.altSpecs = altSpecs
  if (!(charClass && charName && charSpec && gameRole)) {
    throw `${
      !!charName ? capitalize(charName) : "That character"
    } does not have required data. If you see this error again, try deleting it and creating a new one.`
  }
  if (isMain) char.isMain = isMain
  return char
}

export const getMemberChars = (member: iMember): iCharacter[] => {
  const chars: iCharacter[] = []
  if(!!member?.character) chars.push(member?.character)
  if(!!member?.altCharacters && member?.altCharacters?.length > 0) {
    chars.push(...member?.altCharacters)
  }
  return chars
}

const applySpecGameRoleToChar = (char: iCharacter, spec: string, gameRole: gameRoleType) => {
  if(!char?.altSpecs && char.charSpec === spec) return
  if(!char?.altSpecs) throw new Error("That character does not have alternative specs.")
  char.altSpecs.push(char.charSpec)
  char.altSpecs = char.altSpecs.filter(s => s !== spec)
  char.charSpec = spec
  char.gameRole = gameRole
}

export const swapAltMainOnMember = (member: iMember, charName: string, altSpec?: string, gameRole?: gameRoleType) => {
  if(!member?.altCharacters || !member?.character) {
    throw new Error(`Member or character not found - trying to swap main on ${charName}`)
  }
  const isMain = member?.character?.charName === charName
  if(isMain){
    const char = member.character
    if(!gameRole || !altSpec) throw new Error("New spec and game role not specified.")
    applySpecGameRoleToChar(char, altSpec, gameRole)
    return
  }
  
  const altCharIndex = member?.altCharacters.findIndex(c => c.charName === charName)
  if(altCharIndex === -1) throw new Error(`Couldn't find character ${charName} on that member.`)
  const altChar = member.altCharacters.splice(altCharIndex, 1, member.character)[0]
  if(!!altSpec && !!gameRole){
    applySpecGameRoleToChar(altChar, altSpec, gameRole)
  }
  member.character = altChar
}


export const getCharWithAltSpecString = (char: iCharacter, altSpec: string, location: iLocationClassSpec) => {
  const locationSpec = location.game?.classes?.[char.charClass]?.specs?.[altSpec]
  const gameRole = locationSpec?.gameRole
  if(!gameRole) throw new Error(`Couldn't find alt spec ${altSpec} for ${char.charName}`)
  const c: iCharacter = {
    ...char,
    gameRole,
    charSpec: altSpec
  }
  return {
    char: c,
    locationSpec
  }
}

export const getAltSpecVariants = (member: iMember, location: iLocationClassSpec, gameRole?: string, ignoreAlts = false) => {
  const alternatives: iMemberCharAlternatives = {
    alts: [],
    altRespecs: [],
    respec: []
  }
  
  // add alt specs
  const mainChar = member.character
  if(mainChar?.altSpecs){
    for (const altSpec of mainChar?.altSpecs) {
      const { char, locationSpec } = getCharWithAltSpecString(mainChar, altSpec, location)
      if(!!gameRole && locationSpec.gameRole !== gameRole) continue;
      alternatives.respec.push(char)
    }
  }

  for (const altChar of member?.altCharacters || []) {
    // add alts
    if(!!gameRole && altChar.gameRole === gameRole && ignoreAlts === false) {
      alternatives.alts.push(altChar)
    }

    // add alt respecs
    for (const altSpec of altChar?.altSpecs || []) {
      const { char, locationSpec } = getCharWithAltSpecString(altChar, altSpec, location)
      if(!!gameRole && locationSpec.gameRole !== gameRole) {
        continue;
      }
      alternatives.altRespecs.push(char)
    }
  }

  const altLength = alternatives.altRespecs.length + alternatives.alts.length + alternatives.respec.length
  if(altLength < 1) return null

  return alternatives
}

export const getCharFromMember = (member: iMember, charName: string) => {
  if(member?.character?.charName === charName) return member.character
  const altChar = member?.altCharacters?.find(c => c.charName === charName)
  if(altChar) return altChar
  throw new Error(`Cannot find character ${charName} in member ${member.displayName}`)
}

export const isValidCharacterName = (name: string): boolean => {
  const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿА-Яа-я]{2,12}$/;
  // The name must be between 2-12 characters long.
  // Accented characters are supported.
  // Numbers and symbols are not supported.
  // Mixed capitals and spaces are not supported.

  return nameRegex.test(name)
};

// should probably rename to getMemberID
export const getUserID = (user: iUser | iGuest) => {
  const memberID = user?.docType === eDocType.GUEST ? user.discordID : user.userID
  return memberID
}

export const getValidCache = (cacheArr: iUserCache[], type?: eUserCacheType, filter?: (object: iUserCache) => boolean) => {
  const now = Date.now()
  const validCacheArr: iUserCache[] = []
  const hasValidFilter = isDefined(filter) && typeof filter === "function"

  for (const obj of cacheArr) {
    if(!!type && obj.type !== type) continue // incorrect type
    if(obj.validUntil < now) continue // not valid anymore
    if(hasValidFilter){
      const valid = filter(obj)
      if(valid === false) continue
    }
    validCacheArr.push(obj)
  }

  return validCacheArr.sort(sortByKey("validUntil", eSortDirection.ASC))
}

export const addOrReplaceCharacter = (
  characters: iCharacterFull[],
  newCharacters: iCharacterFull[]
): iCharacterFull[] => {

  // Filter out the characters that match the charName, server, and location
  const updatedCharacters = characters.filter((character) => {
    for (const newChar of newCharacters) {
      const { charName, server, location } = newChar
      const sameName = character.charName.toLowerCase() === charName.toLowerCase()
      const sameServer = character.server.toLowerCase() === server.toLowerCase()
      const sameLocation = dequal(character.location, location)
      const matchesNewChar = sameName && sameServer && sameLocation
      if(matchesNewChar) return false
    }
    return true
  })

  updatedCharacters.push(...newCharacters);

  return updatedCharacters;
}
