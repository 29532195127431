import { Areas, GameRole, eLocationType, eRequired, iLocationClassSpec } from "typings";
import { SmartListDistribution_Wrath, SmartListExamples_Wrath } from "../../../../examples/examples";
import { Layout_ClassSpecDefault } from "../../../../layouts/layouts";
import { getIconLookupURL, getIconURL } from "../games";
import { wowClassesByExpansion } from "./classes";
import { wowServers } from "./servers";
import { classSpecToWowheadRaidCompToolLetter, getFullRaidCharactersTBC, getFullRaidCharactersWotlk } from "./wow";
import { namespaces_WOW_CLASSIC_PROG_1, namespaces_WOW_ERA_SOD, wowAPI } from "./wowAPI";

const templatesByWoWExpansion = {
  blank: [{ label: "Blank", value: "wH0HzxaV" }],
  vanilla: [],
  sod: [],
  tbcc: [
    { label: "BT + MH", value: "Msjk4Qq_" },
    { label: "SSC + TK", value: "ahNpymMt" }
  ],
  wotlk: [
    { label: "Tier 7", value: "cEOpB1c9" },
    { label: "Tier 8", value: "DpT4Ma4G" }
  ],
  cata: [],
  mop: [],
  wod: [],
  legion: [],
  bfa: [],
  df: [],
  tww: []
} as const

type PickedLocation = Pick<iLocationClassSpec, "type" | "locationIcons" | "getIconURL" | "ui" | "signupRules">;
type PickedLocationGame = Pick<NonNullable<iLocationClassSpec['game']>, "factionOptions" | "filterCharacters" | "gameRoleLayouts" | "gameRoleMeta">;
type wowBase = PickedLocation & { game: PickedLocationGame };
const wowExpansionBase: wowBase = {
  type: eLocationType.CLASS_SPEC,
  signupRules: {
    server: eRequired.ALWAYS,
    character: eRequired.ALWAYS
  },
  game: {
    factionOptions: ["horde", "alliance"],
    filterCharacters: {
      filterByFaction: true,
      filterByServer: true
    },
    gameRoleLayouts: {
      horisontal: [GameRole.TANK, GameRole.MELEE, GameRole.RANGED, GameRole.HEALER],
      vertical: [GameRole.TANK, GameRole.HEALER, GameRole.MELEE, GameRole.RANGED],
      twoCol: [GameRole.TANK, GameRole.HEALER, GameRole.MELEE, GameRole.RANGED]
    },
    gameRoleMeta: {
      [GameRole.TANK]: { emoji: ":shield:", label: GameRole.TANK, labelPlural: "tanks" },
      [GameRole.HEALER]: { emoji: ":helmet_with_cross:", label: GameRole.HEALER, labelPlural: "healers" },
      [GameRole.MELEE]: { emoji: ":crossed_swords:", label: GameRole.MELEE, labelPlural: "melee" },
      [GameRole.RANGED]: { emoji: ":bow_and_arrow:", label: GameRole.RANGED, labelPlural: "ranged" },
    }
  },
  locationIcons: [
    { iconLabel: "skull", iconImgPath: "/img/icon/wowMarkers/skull.png" },
    { iconLabel: "cross", iconImgPath: "/img/icon/wowMarkers/cross.png" },
    { iconLabel: "square", iconImgPath: "/img/icon/wowMarkers/square.png" },
    { iconLabel: "moon", iconImgPath: "/img/icon/wowMarkers/moon.png" },
    { iconLabel: "triangle", iconImgPath: "/img/icon/wowMarkers/triangle.png" },
    { iconLabel: "diamond", iconImgPath: "/img/icon/wowMarkers/diamond.png" },
    { iconLabel: "circle", iconImgPath: "/img/icon/wowMarkers/circle.png" },
    { iconLabel: "star", iconImgPath: "/img/icon/wowMarkers/star.png" }
  ],
  getIconURL: (fileName: string) => getIconURL("wow", fileName),
  ui: {
    layoutData: {
      defaultLayout: Layout_ClassSpecDefault
    },
    web: {
      areas: {
        [Areas.ROSTER]: {
          default: "category",
          available: ["category", "table"]
        },
        [Areas.GROUPS]: true,
        [Areas.ROLES]: true,
        [Areas.SMARTLISTS]: true,
        [Areas.ASSIGNMENTS]: true,
      }
    },
  }
}

const vanilla: iLocationClassSpec = {
  ...wowExpansionBase,
  locationLabel: "Vanilla",
  locationLabelExtended: "Vanilla",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "vanilla" },
  iconLookupURL: getIconLookupURL("wow", "era"),
  defaultMaxSize: 40,
  templates: [
    ...templatesByWoWExpansion.blank
  ],
  game: {
    ...wowExpansionBase.game,
    classes: wowClassesByExpansion.vanilla,
    getFullSignupPlaceholders: getFullRaidCharactersTBC,
    gameAPI: {
      getCharacter: wowAPI(namespaces_WOW_ERA_SOD).getCharacter,
      getFactionAuctions: wowAPI(namespaces_WOW_ERA_SOD).getFactionAuctions
    },
    gameServerZones: wowServers.vanilla,
  },
  ui: {
    ...wowExpansionBase.ui,
    web: {
      ...wowExpansionBase.ui.web,
      warcraftLogsPrefix: "vanilla"
    }
  }
}

const sod: iLocationClassSpec = {
  ...wowExpansionBase,
  locationLabel: "SoD",
  locationLabelExtended: "Season of Discovery",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "sod" },
  iconLookupURL: getIconLookupURL("wow", "era"),
  defaultMaxSize: 40,
  templates: [
    ...templatesByWoWExpansion.blank
  ],
  game: {
    ...wowExpansionBase.game,
    classes: wowClassesByExpansion.sod,
    getFullSignupPlaceholders: getFullRaidCharactersTBC,
    gameAPI: {
      getCharacter: wowAPI(namespaces_WOW_ERA_SOD).getCharacter,
      getFactionAuctions: wowAPI(namespaces_WOW_ERA_SOD).getFactionAuctions
    },
    gameServerZones: wowServers.sod,
  },
  ui: {
    ...wowExpansionBase.ui,
    web: {
      ...wowExpansionBase.ui.web,
      warcraftLogsPrefix: "sod"
    }
  }
}

const tbcc: iLocationClassSpec = {
  ...wowExpansionBase,
  locationLabel: "TBCC",
  locationLabelExtended: "The Burning Crusade",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "tbcc" },
  iconLookupURL: getIconLookupURL("wow", "tbc"),
  defaultMaxSize: 25,
  templates: [
    ...templatesByWoWExpansion.blank,
    ...templatesByWoWExpansion.tbcc,
  ],
  game: {
    ...wowExpansionBase.game,
    classes: wowClassesByExpansion.tbcc,
    getFullSignupPlaceholders: getFullRaidCharactersTBC,
    gameServerZones: [],
  },
}

const wotlk: iLocationClassSpec = {
  ...wowExpansionBase,
  locationLabel: "WOTLK",
  locationLabelExtended: "Wrath of the Lich King",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "wotlk" },
  iconLookupURL: getIconLookupURL("wow", "wotlk"),
  defaultMaxSize: 25,
  templates: [
    ...templatesByWoWExpansion.blank,
    ...templatesByWoWExpansion.wotlk,
  ],
  groupCompTools: [
    {
      label: "Raid Composition",
      data: {
        img: "/img/external/wowhead/logo-256.png",
        type: "link",
        url: (signup, raidData) => {
          const characters = signup.groupOrder
            .map((memberID) => (!!memberID ? signup.members?.[memberID]?.character : null))
            .slice(0, 40)

          const letterArray: string[] = characters.map((char) => {
            if (!char) return "0"
            return classSpecToWowheadRaidCompToolLetter(char.charClass, char.charSpec, "wotlk")
          })
          const nameArray: string[] = characters.map((char) => {
            if (!char?.charName) return ";"
            return `${char?.charName?.charAt(0)?.toUpperCase() + char?.charName?.slice(1)};`
          })
          const url = "https://www.wowhead.com/wotlk/raid-composition#0"
          return `${url}${letterArray.join("")};${nameArray.join("")}`
        }
      }
    }
  ],
  game: {
    ...wowExpansionBase.game,
    classes: wowClassesByExpansion.wotlk,
    getFullSignupPlaceholders: getFullRaidCharactersWotlk,
    gameAPI: {
      getCharacter: wowAPI(namespaces_WOW_CLASSIC_PROG_1).getCharacter,
      getFactionAuctions: wowAPI(namespaces_WOW_CLASSIC_PROG_1).getFactionAuctions
    },
    gameServerZones: [],
  },
  examples: {
    splitDistribution: SmartListDistribution_Wrath,
    smartLists: SmartListExamples_Wrath
  },
  ui: {
    ...wowExpansionBase.ui,
    web: {
      ...wowExpansionBase.ui.web,
      warcraftLogsPrefix: "classic"
    }
  }
}

const cata: iLocationClassSpec = {
  ...wotlk,
  locationLabel: "Cata",
  locationLabelExtended: "Cataclysm",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "cata" },
  iconLookupURL: getIconLookupURL("wow", "cata"),
  templates: [
    ...templatesByWoWExpansion.blank
  ],
}

const dragonflight: iLocationClassSpec = {
  ...wowExpansionBase,
  locationLabel: "Dragonflight",
  locationLabelExtended: "Dragonflight",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "dragonflight" },
  iconLookupURL: getIconLookupURL("wow", "df"),
  defaultMaxSize: 20,
  templates: [
    ...templatesByWoWExpansion.blank
  ],
  groupCompTools: [{
    label: "Raid Composition",
    data: { img: "/img/external/wowhead/logo-256.png", type: "link", url: (signup, raidData) => {

      const characters = signup.groupOrder
        .map((memberID) => (!!memberID ? signup.members?.[memberID]?.character : null))
        .slice(0, 40)

      const letterArray: string[] = characters.map((char) => {
        if (!char) return "0"
        return classSpecToWowheadRaidCompToolLetter(char.charClass, char.charSpec, "retail")
      })
      const nameArray: string[] = characters.map((char) => {
        if (!char?.charName) return ";"
        return `${char?.charName?.charAt(0)?.toUpperCase() + char?.charName?.slice(1)};`
      })
      return `https://www.wowhead.com/raid-composition#0${letterArray.join("")};${nameArray.join("")}`
    }}
  }],
  game: {
    ...wowExpansionBase.game,
    classes: wowClassesByExpansion.df,
    getFullSignupPlaceholders: getFullRaidCharactersWotlk,
    filterCharacters: {
      filterByFaction: false,
      filterByServer: false
    },
    // gameAPI: {
    //   getCharacter: wowAPI(namespaces_WOW_RETAIL).getCharacter
    // },
    gameServerZones: [],
  },
  examples: {
    splitDistribution: SmartListDistribution_Wrath,
    smartLists: SmartListExamples_Wrath
  },
  ui: {
    ...wowExpansionBase.ui,
    web: {
      ...wowExpansionBase.ui.web,
      warcraftLogsPrefix: ""
    }
  }
}

const tww: iLocationClassSpec = {
  ...dragonflight,
  locationLabel: "TWW",
  locationLabelExtended: "The War Within",
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "tww" },
  iconLookupURL: getIconLookupURL("wow", "tww"),
  templates: [
    ...templatesByWoWExpansion.blank
  ],
}



// dynamic 
const classic: iLocationClassSpec = {
  ...cata,
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "classic-prog-1" },
  templates: [
    ...templatesByWoWExpansion.blank,
    ...templatesByWoWExpansion.cata,
    ...templatesByWoWExpansion.wotlk,
    ...templatesByWoWExpansion.tbcc,
    ...templatesByWoWExpansion.vanilla,
  ],
  game: {
    ...cata.game,
    gameServerZones: wowServers.classic
  }
}

const retail: iLocationClassSpec = {
  ...tww,
  locMap: { tier1: "online", tier2: "games", tier3: "wow", tier4: "retail" },
  templates: [
    ...templatesByWoWExpansion.blank
  ],
  game: {
    ...tww.game,
    gameServerZones: wowServers.retail
  }
}

export type iWoWGameVersions = keyof typeof wowExpansions
export const wowExpansions = {
  // inactive
  tbcc,
  wotlk,

  // active
  vanilla,
  cata,
  sod,

  // dynamic
  classic,
  retail,
} as const