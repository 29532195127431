import { HocuspocusProvider } from '@hocuspocus/provider';
import { findLowestTierThatHasAccessTo } from 'data';
import { dequal } from 'dequal';
import copy from 'fast-copy';
import { getAdminStatus, sleep } from 'functions';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { MouseEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Areas, eFeature, eTeamPermission, group, iModal, iPopOver, iPopOverConfirm, iPopOverContent, iPopOverSide, iRaid, iRaidData, iUserPreferences, isBoolean, isDefined, isTeamOwned } from 'typings';
import { useContextSelector } from 'use-context-selector';
import { executePublishAtom } from '../components/1_atom/buttons/publishChangesButton';
import PopoverWarning from '../components/1_atom/popOver/usecases/popoverWarning';
import { iEditSignupMode } from '../components/2_molecule/modals/createRaidModal';
import Context from '../context/MainContext';
import { useAuth } from '../context/authContext';
import { jotaiStore } from '../pages/_app';
import { useLocation } from './location';
import { editModeOnState, hocusAwarenessState, hocusProviderState, hocusRoomState, hocusStatusState, modalState, popOverState, resetHocusProviderAndYdoc, setUserPreferenceState, showModalState, showRaidAreasState, userPreferencesState, ydocAtom, ydocState } from './state/global';
import { raidOwnerState, signupTeamAccessState, teamAccessState } from './state/raid';
import { scrollToTargetAdjusted } from './util';

type EventHandler = (...args: any[]) => void;

// AUTHOR: Dan Abramov
// LINK: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
export function useEvent(handler: EventHandler): EventHandler {
  const handlerRef = useRef<EventHandler | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any[]) => {
    const fn = handlerRef.current;
    if (fn) {
      return fn(...args);
    }
  }, []);
}

// AUTHOR: Gabe Ragland
// LINK: https://usehooks.com/useOnClickOutside/
// MODIFIED BY: PHILIP AARSETH
// LINK: https://github.com/philipaarseth
// USAGE: useOnClickOutside(ref, () => setModalOpen(false));
export function useOnClickOutside(ref: any, handler: (event: globalThis.MouseEvent) => void, disable?: boolean) {
  useEffect(
    () => {
      if(disable) return
      const listener = (event: any) => {
        const stopOnClickOutside = event?.target?.classList?.contains("stopOnClickOutside")
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target) || stopOnClickOutside === true) {
          return;
        }

        const triggerHandler = () => {
          if(!handler) return
          handler(event);
          document.removeEventListener('mouseup', triggerHandler);
        }

        document.addEventListener('mouseup', triggerHandler);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, disable]
  );
}


// AUTHOR: Gabe Ragland
// LINK: https://usehooks.com/useWindowSize/

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: undefined | number
    height: undefined | number
  }>({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}


// AUTHOR: jjenzz
// LINK: https://codesandbox.io/s/recursing-goldberg-7mtvc?file=/src/App.js:421-1730
// Taken from https://gist.github.com/gragland/a32d08580b7e0604ff02cb069826ca2f
// but modified to use `mouseenter` and `mouseleave`
export function useHover(delayMS?: number) {
  const [value, setValue] = useState<boolean | undefined>(undefined);
  const [hovering, setHovering] = useState<boolean | undefined>(undefined);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseEnter = useCallback(() => setValue(true), []);
  const handleMouseLeave = useCallback(() => setValue(false), []);

  useEffect(() => {
    if(value) return setHovering(value)
    
    const timer = setTimeout(() => {
      setHovering(value)
    }, delayMS || 0);

    return () => clearTimeout(timer);

  }, [value])

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref: { current: any } = useRef();

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    (node: any) => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseEnter);
        ref.current.removeEventListener("mouseleave", handleMouseLeave);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseenter", handleMouseEnter);
        ref.current.addEventListener("mouseleave", handleMouseLeave);
      }
    },
    [handleMouseEnter, handleMouseLeave]
  );

  return [callbackRef, hovering];
}

export function useHoverOverOut(initialState = false) {
  const [value, setValue] = useState(initialState);

  const ref = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}


// AUTHOR: Gabe Ragland
// LINK: https://usehooks.com/useMemoCompare

export function useMemoCompare(next: any, compare: any) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}

export type onAwarenessUpdateType = Array<{
  clientId: number
  user: {
    name: string
    color: string
  }
}>

export const useHocusJotai = () => {
  const [provider, sethocusProvider] = useAtom(hocusProviderState)
  const ydoc = useAtomValue(ydocState)
  const roomName = useAtomValue(hocusRoomState)
  const resetHocus = useSetAtom(resetHocusProviderAndYdoc)

  const sethocusStatus = useSetAtom(hocusStatusState)
  const sethocusAwareness = useSetAtom(hocusAwarenessState)

  const prevRoomNameRef = useRef<string>()
  const awarenessRef = useRef<onAwarenessUpdateType>()
  const timerRef = useRef<number>(0)

  useEffect(() => {
    if(roomName && roomName !== prevRoomNameRef.current) {
      console.log(`Sync server: Connecting to room: ${roomName}`)
      prevRoomNameRef.current = roomName
  
      sethocusProvider(new HocuspocusProvider({
        // url: 'ws://127.0.0.1:1234',
        url: 'wss://prepi-hocus.herokuapp.com',
        name: roomName || 'example-document',
        document: ydoc,
        broadcast: false,
        onStatus: (status) => {
          sethocusStatus(status.status)
        },
        onDisconnect: () => {
          console.log(`Sync server: ❌ disconnected to room: ${roomName}`, (Date.now() - timerRef.current) / 1000)
          timerRef.current = Date.now()
        },
        onConnect: () => {
          // console.log(`Sync server: ✅ Connected to room: ${roomName}`, (Date.now() - timerRef.current) / 1000)
          timerRef.current = Date.now()
        },
        onDestroy: () => {
          console.log(`Sync server: 🗑 destroyed provider in room: ${roomName}`, provider)
        },
        onAwarenessChange: (data) => {
          // @ts-ignore
          const { states }: {states: onAwarenessUpdateType} = data
          // return // todo
          const obj = copy(states)
          if(dequal(awarenessRef.current, obj)) return
          // console.log("hocus provider awareness change", obj)
          awarenessRef.current = obj
          sethocusAwareness(obj)
        },
      }))
    }

  }, [roomName]);
  
  useEffect(() => {
    // unmount -> Remove provider
    return () => {
      resetHocus()
    }
  }, [])
}

export const useModal = (): [(modal: iModal) => void, () => void] => {
  const setShowModal = useSetAtom(showModalState);
  const setModal = useSetAtom(modalState);

  const triggerModal = (modal: iModal) => {
    setModal(modal);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return [triggerModal, closeModal];
};

export const useTeams = () => {

  const startLoadTeams = useContextSelector(Context, state => state.startLoadTeams);
  const teams = useContextSelector(Context, state => state.teams);
  const loadTeamsState = useContextSelector(Context, state => state.loadTeamsState);

  useEffect(() => {
    startLoadTeams()
  }, [])

  return {
    loadTeamsState,
    teams
  }
}

export const usePremiumFeatureInSignup = (feature: eFeature) => {

  const lowestTier = findLowestTierThatHasAccessTo(feature)
  const teamAccess = useAtomValue(signupTeamAccessState)
  const signupOwner = useAtomValue(raidOwnerState)
  const check = useContextSelector(Context, state => state.checkHasAccess);
  const lowestTierMsg = `This signup needs to be owned by a team with ${lowestTier.label} access or higher to access this feature.`
  const hasAccess = !!feature ? teamAccess?.[feature] : true
  
  const checkHasAccess = (callback?: (...args: any[]) => any): string | boolean => {
    
    if(!feature || feature === eFeature.VOID) {
      callback?.()
      return true
    }
    
    if(isBoolean(signupOwner)) return false
    const ownerID = isTeamOwned(signupOwner) ? signupOwner.teamID : signupOwner.userID
    return check(signupOwner.type, ownerID, feature, callback)
  }
  
  return { hasAccess, checkHasAccess, lowestTier, lowestTierMsg }
}

export const usePremiumFeature = (feature: eFeature, ownerType: "user" | "team", teamID?: string) => {
  
  const lowestTier = findLowestTierThatHasAccessTo(feature)
  const access = useAtomValue(teamAccessState)
  const check = useContextSelector(Context, state => state.checkHasAccess);
  const lowestTierMsg = `Your team needs to be ${lowestTier.label} or higher to access this feature.`
  const hasAccess = teamID && isDefined(access?.[teamID]?.[feature]) ? access?.[teamID]?.[feature] : false

  const checkHasAccess = (callback?: (...args: any[]) => any): string | boolean => {
    if(!teamID) return false
    return check(ownerType, teamID, feature, callback)
  }

  return { hasAccess, checkHasAccess, lowestTier, lowestTierMsg }
}

export const usePopOver = (
  side: iPopOverSide = "top",
  popoverOptions?: Omit<iPopOver, "side" | "content" | "domRect">
) => {
  const setDomRect = useSetAtom(popOverState)
  const clearPopOverDomRect = useResetAtom(popOverState)

  const triggerPopOver = (event: MouseEvent, content: iPopOverContent) => {
    clearPopOverDomRect()
    setDomRect({
      side,
      // @ts-ignore
      domRect: event.target?.getBoundingClientRect(),
      content,
      ...popoverOptions
    })
    popoverOptions?.events?.onOpen?.(event)
  }

  return {
    triggerPopOver,
    closePopOver: clearPopOverDomRect
  }
}

export const usePopOverConfirm = () => {
  const { triggerPopOver, closePopOver } = usePopOver()

  const getComponent = (popOverConfirm: iPopOverConfirm) => {
    if(popOverConfirm.type === "warning") return <PopoverWarning {...popOverConfirm} />
    return <PopoverWarning {...popOverConfirm} />
  }

  const showPopOverConfirm = (event: MouseEvent, popOverConfirm: iPopOverConfirm) => {
    
    triggerPopOver(event, {
      mode: "component",
      component: getComponent({
        ...popOverConfirm,
        confirmCallback: (event) => {
          closePopOver()
          popOverConfirm.confirmCallback(event)
        },
        cancelCallback: popOverConfirm?.cancelCallback || closePopOver
      })
    })
  }

  return {
    showPopOverConfirm
  }
}

export const useYdoc = () => {
  const y = useAtomValue(ydocAtom)
  return y
}

export const useShortcuts = (getLocalRaidData: (raidID: string) => iRaidData, isSignupAdmin?: boolean) => {
  
  // go to area
  const [showRaidAreas, setShowRaidAreas] = useAtom(showRaidAreasState)
  const showAndScroll = async (area: Areas, exclusively: boolean) => {
    if(exclusively){
      setShowRaidAreas({ [area]: true });
      await sleep(15)
      scrollToTargetAdjusted(area)
      return
    }
    if(showRaidAreas?.[area] === true) {
      scrollToTargetAdjusted(area)
      return
    }
    setShowRaidAreas({...showRaidAreas, [area]: !showRaidAreas[area]});
    await sleep(15)
    scrollToTargetAdjusted(area)
  }
  const showAndScrollToArea = (area: Areas, exclusively = false) => { showAndScroll(area, exclusively) }

  useHotkeys("r", () => showAndScrollToArea(Areas.ROSTER), [showRaidAreas])
  useHotkeys("alt+r", () => showAndScrollToArea(Areas.ROSTER, true), [showRaidAreas])

  useHotkeys("g", () => showAndScrollToArea(Areas.GROUPS), [showRaidAreas])
  useHotkeys("alt+g", () => showAndScrollToArea(Areas.GROUPS, true), [showRaidAreas])

  // useHotkeys("r", () => showAndScrollToArea(Areas.ROLES), [showRaidAreas]) // can't also use R - same as roster
  
  useHotkeys("s", () => showAndScrollToArea(Areas.SMARTLISTS), [showRaidAreas])
  useHotkeys("alt+s", () => showAndScrollToArea(Areas.SMARTLISTS, true), [showRaidAreas])

  useHotkeys("a", () => showAndScrollToArea(Areas.ASSIGNMENTS), [showRaidAreas])
  useHotkeys("alt+a", () => showAndScrollToArea(Areas.ASSIGNMENTS, true), [showRaidAreas])


  // save publish changes
  const executePublish = useSetAtom(executePublishAtom)
  const nonAsyncPublish = (e: KeyboardEvent) => {
    executePublish(getLocalRaidData)
    e.preventDefault()
    e.stopPropagation()
  }
  useHotkeys("cmd+s, ctrl+s", (e) => nonAsyncPublish(e), { enabled: isSignupAdmin })
  

  // toggle edit mode
  useHotkeys("e", () => {
    jotaiStore.set(editModeOnState, !jotaiStore.get(editModeOnState))
  }, {
    enabled: isSignupAdmin
  })
}

export const useTheme = () => {
  const [theme, setTheme] = useState<"light" | "dark">("dark")

  useEffect(() => {
    const value = document.documentElement.getAttribute('data-theme');
    if(!!value && value !== "auto"){
      setTheme(value as "light" | "dark")
      return
    }

    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const isDarkMode = prefersDarkMode.matches
    setTheme(isDarkMode ? "dark" : "light")

    const handleChange = (event: MediaQueryListEvent) => {
      const isDarkMode_Event = event.matches
      setTheme(isDarkMode_Event ? "dark" : "light")
    };

    prefersDarkMode.addEventListener('change', handleChange);

    return () => {
      prefersDarkMode.removeEventListener('change', handleChange);
    };
  }, []);

  return theme
}

type useTemplateProps = {
  filterOnTeamID?: string
  loadTeamTemplates?: boolean
  loadUserTemplates?: boolean
  editSignup?: {
    mode: iEditSignupMode
    signupID?: string
  }
}
export const useTemplates = (props: useTemplateProps) => {

  const { filterOnTeamID, loadTeamTemplates = true, loadUserTemplates = true, editSignup } = props
  const { userData, claims } = useAuth()
  const location = useLocation()

  const allUserRaidTemplates = useContextSelector(Context, (state) => state.signupColumns.templates)
  const getTeamTemplates = useContextSelector(Context, (state) => state.getTeamTemplates)
  const setLoadRaids = useContextSelector(Context, (state) => state.setLoadRaids)
  const [teamTemplates, setTeamTemplates] = useState<iRaid[] | null>(null)

  useEffect(() => {
    const initTeamTemplates = async () => {
      const templates = await getTeamTemplates()
      setTeamTemplates(templates)
    }
    if(loadTeamTemplates) initTeamTemplates()
    if(loadUserTemplates) setLoadRaids(true)
  }, [])

  const userRaidTemplates: iRaid[] | null = useMemo(() => {
    if(!allUserRaidTemplates) return null
    return allUserRaidTemplates.filter(template => {
      const adminStatus = getAdminStatus(
        userData?.userID,
        claims,
        template.owner,
        template.admins,
        eTeamPermission.MANAGE_SIGNUPS,
        eTeamPermission.SIGNUPS_ASSISTANT
      )
      return adminStatus !== "none"
    })
  }, [allUserRaidTemplates])

  const allTemplates = useMemo(() => {
    const templates: iRaid[] = []
    if(userRaidTemplates) templates.push(...userRaidTemplates)
    if(!teamTemplates) return templates
    for (const teamTemplate of teamTemplates) {
      if(templates.find(tem => tem.raidID === teamTemplate.raidID)) continue; // don't add duplicates
      templates.push(teamTemplate)
    }
    return templates
  }, [userRaidTemplates, teamTemplates])

  const templateGroups: group<string>[] = useMemo(() => {
    const groups: group<string>[] = [];
    if(editSignup?.mode === "edit" && editSignup.signupID){
      groups.push({
        label: "Current signup",
        options: [{
          label: "Current signup",
          value: editSignup.signupID
        }]
      })
    }

    const templatesByTeam =
      teamTemplates === null
        ? {}
        : teamTemplates
            .filter((t) => dequal(t?.location, location.map))
            .reduce((acc, item) => {
              if (item.owner.type === "user") return acc
              if (!acc[item.owner.teamName.toLowerCase()]) {
                acc[item.owner.teamName.toLowerCase()] = [item]
                return acc
              }
              acc[item.owner.teamName.toLowerCase()].push(item)
              return acc
            }, {} as Record<string, iRaid[]>)
     
    for (const [teamName, templates] of Object.entries(templatesByTeam)) {
      groups.push({
        label: teamName,
        options: templates.map(t => ({
          label: t.title,
          value: t.raidID
        }))
      })
    }

    const locationUserTemplates = !userRaidTemplates ? [] : userRaidTemplates
      .filter(t => dequal(t?.location, location.map))
      .filter(t => !groups.some(g => {
        return g.options.some(gT => gT.value === t.raidID)
      }))

    groups.push({
      label: "User",
      options: locationUserTemplates.map(t => ({
        label: t.title,
        value: t.raidID
      }))
    })

    if(!!location?.location?.templates){
      groups.push({
        label: "Examples",
        options: [...location.location.templates]
      })
    }

    return groups
  }, [location?.location?.templates, userRaidTemplates, teamTemplates])

  return { allTemplates, templateGroups, userRaidTemplates, teamTemplates }
}

export const useLocalPreferences = (): [iUserPreferences, (update: Partial<iUserPreferences>) => void] => {
  const userPreferences = useAtomValue(userPreferencesState)
  const setUserPreferences = useSetAtom(setUserPreferenceState)
  return [userPreferences, setUserPreferences]
}