import { eFSColl, iGuest } from 'typings';
import { firebase } from '../client'
import { standardCRUD } from '../documentAPI';

const guestAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iGuest>(eFSColl.GUESTS, db, deleteField)

  return { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath };
}

export default guestAPI;