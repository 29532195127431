import { FC } from "react";
import Styles from "./textEditor.module.scss";
import { generateHTML } from '@tiptap/html';
import Link from "@tiptap/extension-link";
import StarterKit from '@tiptap/starter-kit';
// import Emoji, { emojis } from '@tiptap-pro/extension-emoji'
// import { useLocation } from "../../../global/location";
import { CellType, iMember, iRole, iTextData } from "typings";
import { getTextAssignmentData } from "../../../global/util";
import { useAtomValue } from "jotai";
import { getCellRefContentAtom, roleIDToMemberAtom } from "../../../global/state/raid";
import Mention from "./mention";
import CellRef from "./cellRef/cellRef";
import { Emoji } from "./customEmojiExtension";
import { jotaiStore } from "../../../pages/_app";
import { capitalize } from "functions";
import { Extensions } from "@tiptap/react";
import { FontSize } from "./extensions/fontSize/fontSize";
import TextStyle from "@tiptap/extension-text-style";
import { assignmentsState } from "../../../global/state/raidData";
import { iPanelIDToOverrides } from "../../../global/state/smartListState";


interface TextEditorContentProps {
  textData: iTextData
  background?: "bg1" | "bg2" | "bg3" | "bg4"
}

export const getContentExtensionArray = (
  roleIDToMemberMap: Map<string, {
    role: iRole;
    member: iMember;
  }>,
  panelOverrides?: iPanelIDToOverrides
): Extensions => {
  const extensionArray = [
    StarterKit,
    Link,
    // Emoji.configure({
    //   emojis: [...customEmojis, ...emojis]
    // }),
    Emoji.configure({}),
    FontSize.configure(),
    TextStyle.configure(),
    Mention.configure({
      renderLabel({ options, node }) {
        const [ mentionType, mentionValue ] = node.attrs.id.split(":")
        if(mentionType === "role"){
          const { member, role } = roleIDToMemberMap.get(mentionValue) || {}
          if(!member) return role?.roleName || ""
          return capitalize(member?.character?.charName) || "member character name n/a"
        }
        return "ooo"
      },
      HTMLAttributes: {
        class: `use-class-container use-inherit-color`,
      },
    }),
    CellRef.configure({
      renderLabel({ options, node }) {
        const cellContent = jotaiStore.set(getCellRefContentAtom, node.attrs.id, panelOverrides)
        if(cellContent?.cellType === CellType.TEXT) return cellContent?.text || ""
        return capitalize(cellContent?.data?.member?.character?.charName) || ""
      },
      HTMLAttributes: {
        class: `use-class-container use-inherit-color`,
      },
    }),
  ]
  return extensionArray
}


const TextEditorContent: FC<TextEditorContentProps> = ({ textData, background = "bg4" }) => {

  const roleIDToMemberMap = useAtomValue(roleIDToMemberAtom)
  const assignments = useAtomValue(assignmentsState)

  const doc = getTextAssignmentData(textData)
  // const location = useLocation()
  // const customEmojis = location.location.locationIcons.map(icon => ({
  //   name: `${location.location.locationLabel} ${icon.iconLabel}`,
  //   shortcodes: [`${icon.iconLabel}`],
  //   fallbackImage: icon.iconImgPath,
  //   tags: [],
  //   group: location.location.locationLabel
  // }))

  if (doc?.type === "doc") {
    const extensionArray = getContentExtensionArray(roleIDToMemberMap)
    let html = generateHTML(doc, extensionArray)

    return (
      <div className={`${Styles.contentContainer} ${background} ProseMirror`}>
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      </div>
    );
  }

  return (
    <div className={`${Styles.contentContainer} ${background} ProseMirror`}>
    </div>
  );
}

export default TextEditorContent;