import { eDragType, eSmartListItemType, iDragData, iSmartListItem, iSmartListSubList, iSmartList, isDefined, iSmartListCharacterItem, iDragDataMember, newID } from "typings";
import { dequal } from "dequal";
import copy from "fast-copy";

export const getListItemFromDragData = (dragData: iDragData): {
  listItem: iSmartListItem
  subList?: iSmartListSubList
} => {

  if(dragData.dragType !== eDragType.SMARTLISTITEM && dragData.dragType !== eDragType.SMARTLIST){
    throw new Error("Invalid drop type in on new list drop")
  }
  
  const { smartList } = dragData?.smartListData
  const newListItemID = newID(4)

  if(dragData.dragType === eDragType.SMARTLIST){
    const dummySmartListItem: iSmartListItem = {
      itemID: newListItemID,
      limit: 0,
      smartListID: smartList.id,
      type: eSmartListItemType.SMARTLIST
    }
    return { listItem: dummySmartListItem }
  }

  const { listItem } = dragData?.smartListData

  if (!listItem) throw new Error("Dragging smart list item without data.")
  if(listItem?.type === eSmartListItemType.SMARTLIST){
    return { listItem: {...listItem, itemID: newListItemID } }
  }
  
  if (listItem?.type === eSmartListItemType.SUBLIST) {
    const newSubList = { ...smartList.subLists[listItem.subListID], id: newID(4) }
    return {
      listItem: { ...listItem, itemID: newListItemID, subListID: newSubList.id },
      subList: newSubList
    }
  }
  
  if(listItem?.type === eSmartListItemType.CHARACTER){
    return { listItem: {...listItem, itemID: newListItemID } }
  }

  throw new Error("List item type not handled")
}

export const removeItemIDFromSmartList = (
  smartList: iSmartList,
  itemID: string
): {
  removedItem: iSmartListItem
  removedAtIndex: number
  removedSubList?: iSmartListSubList
} => {
  // remove item
  const removedItem = copy(smartList.items?.[itemID])
  if (!removedItem) throw new Error("removeItemIDFromSmartList - invalid itemID")
  delete smartList.items[itemID]
  const removedAtIndex = smartList.itemOrder.findIndex((_itemID) => _itemID === itemID)
  smartList.itemOrder = smartList.itemOrder.filter((_itemID) => _itemID !== itemID)

  // remove subList
  const subListID =
    removedItem?.type === eSmartListItemType.SUBLIST ? removedItem.subListID : undefined
  if (subListID) {
    const removedSubList = smartList.subLists[subListID]
    delete smartList.subLists[subListID]
    return {
      removedItem,
      removedAtIndex,
      removedSubList
    }
  }

  return {
    removedAtIndex,
    removedItem
  }
}

export const addListItemToSmartList = (
  smartList: iSmartList,
  listItem: iSmartListItem,
  subList?: iSmartListSubList,
  listIndex?: number
) => {

  // skip if already exists
  if(listItem.type === eSmartListItemType.CHARACTER){
    for (const item of Object.values(smartList.items)) {
      if(item.type !== eSmartListItemType.CHARACTER) continue;
      if(dequal(item.charID, listItem.charID)) {
        return
      }
    }
  }

  // if(!!smartList.items?.[listItem.itemID]) return
  smartList.items[listItem.itemID] = listItem
  if (isDefined(listIndex)) {
    smartList.itemOrder.splice(listIndex, 0, listItem.itemID)
  } else {
    smartList.itemOrder.push(listItem.itemID)
  }
  if (listItem.type === eSmartListItemType.SUBLIST) {
    if (!subList)
      throw new Error("Adding sub list to new smart list - but no sub list data provided.")
    smartList.subLists[subList.id] = subList
  }
}

export const moveItemToSmartList = (
  fromSmartList: iSmartList,
  toSmartList: iSmartList,
  fromIndex: number,
  toIndex: number
) => {
  const dragItemID = fromSmartList?.itemOrder?.[fromIndex]
  if (!dragItemID) {
    throw new Error("moveItemToSmartList - that from index does not exist.")
  }

  // remove from old list
  const { removedItem, removedSubList } = removeItemIDFromSmartList(fromSmartList, dragItemID)

  // add to new list
  addListItemToSmartList(toSmartList, removedItem, removedSubList, toIndex)
}

export const deleteItemFromSmartList = (smartList: iSmartList, itemID: string) => {
  const item = smartList?.items?.[itemID]
  if(!item) throw new Error("Delete smart list item - that item does not exist.")

  if(item.type === eSmartListItemType.SUBLIST){
    if(!smartList.subLists?.[item.subListID]) {
      throw new Error("Delete smart list item - That sublist does not exist.")
    }
    delete smartList.subLists?.[item.subListID]
  }

  // remove from itemOrder & delete item
  smartList.itemOrder = smartList.itemOrder.filter(_itemID => _itemID !== itemID)
  delete smartList.items[itemID]
}

export const getMemberItemFromDragData = (dragData: iDragDataMember): iSmartListCharacterItem | null => {
  if(!dragData.character) return null
  const charListItem: iSmartListCharacterItem = {
    type: eSmartListItemType.CHARACTER,
    character: dragData.character,
    charID: {
      charName: dragData.character.charName,
      memberID: dragData.member.userID
    },
    itemID: newID(4)
  }
  return charListItem
}