import { eSortDirection, iSmartListCondition } from "typings";
import { getNestedValue } from "../../../utilities/utilities";


export const dynamicFilter = (data: any[], conditions: iSmartListCondition[], search?: string, matchConditions: "all" | "any" = "all"): any[] => {
  return data.filter(obj => {
    const filter = (condition: iSmartListCondition) => {

      // if(search) {
      //   return valueIsValid && JSON.stringify(value).match(new RegExp(search, 'i'));
      // }

      const allMatches = condition.criteria.every(({keyPath: key, value}) => {
        const nestedProperty = getNestedValue(obj, key);
        
        // Both are arrays
        if (Array.isArray(nestedProperty) && Array.isArray(value)) {
          return value.some(v => nestedProperty.includes(v));
        }

        // Only nestedProperty is an array
        if (Array.isArray(nestedProperty)) {
          return nestedProperty.includes(value);
        }

        // Only value is an array
        if (Array.isArray(value)) {
          return value.includes(nestedProperty);
        }

        // Both are primitives
        return nestedProperty === value;
      });

      return condition?.inverse ? !allMatches : allMatches;
    }

    if (matchConditions === "any") {
      return conditions.some(filter);
    }

    return conditions.every(filter);
  });
}

export const sortByKey = <T>(sortKey: keyof T, sortOrder: eSortDirection = eSortDirection.ASC) =>
  (a: T, b: T) => {
    const valueA = getNestedValue(a, sortKey as string);
    const valueB = getNestedValue(b, sortKey as string);
    
    if (valueA < valueB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    return 0;
  };

export const limitObjects = <T>(data: T[], limit: number): T[] => {
  if(limit === 0) return data
  return data.slice(0, limit);
}

export const filterObjects = <T>(
  data: T[],
  conditions: iSmartListCondition[],
  sortKey: string,
  sortOrder: eSortDirection = eSortDirection.ASC,
  limit: number = 0,
  matchConditions: "all" | "any" = "all",
  search?: string,
): T[] => {
  // TODO: dynamicRaidFilter
  // use iRaid data like group positioning in filter
  const filteredData = conditions?.length < 1 ? data : dynamicFilter(data, conditions, search, matchConditions)
  const sortedData = filteredData.sort(sortByKey(sortKey, sortOrder))
  return limitObjects(sortedData, limit)
}