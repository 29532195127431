import { iList, lists } from "typings"
import { charIDToRosterTargetID } from "./targetUtilities"

// listID
export const getRosterListID = (rosterOrBench: "roster" | "bench", category: string) =>
  `list-${rosterOrBench}-${category}`

export const removeTargetIDFromList = (list: iList, targetID: string) => {
  const index = list.targetIDs.findIndex(tarID => tarID === targetID)
  if(index === -1) return
  const removedTargetID = list.targetIDs.splice(index, 1)[0]
  return removedTargetID
}

export const listIDToGameRole = (listID: string) => {
  return listID.slice(listID.lastIndexOf("-") + 1)
}

export const moveTargetIDToAnotherList = (fromList: iList, toList: iList, memberID: string, charName: string) => {
  const targetID = removeTargetIDFromList(fromList, charIDToRosterTargetID({ memberID, charName }))
  if(!targetID) return
  toList.targetIDs.push(targetID)
}

export const findListByContainTargetID = (targetID: string, lists: lists) => {
  return Object.values(lists).find((list) => list.targetIDs.includes(targetID));
};