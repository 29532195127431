import { eDocType, eMemberType, SignupStates } from "../../types/enum"
import { iGuest } from "../iGuest"
import { iMember } from "../raid/iMember"
import { iCharacter, iCharacterFull, iSignupSets } from "../user/user"
import { newID } from "../../types/typeUtils"

export const CreateGuest = ({
  discordID,
  displayName,
  signupSets = {},
  characters = []
}: {
  discordID: string
  displayName: string
  characters?: iCharacterFull[]
  signupSets: iSignupSets
}): iGuest => {

  const guest: iGuest = {
    discordID,
    characters: characters || [],
    displayName,
    signupSets,
    docType: eDocType.GUEST,
    createdAt: Date.now()
  }
  
  return guest
}

export const getPlaceholder = (char?: iCharacter, id?: string) => {
  const placeholderID = id || `placeholder-member-${newID(3)}`

  const placeholderMember: iMember = {
    character: char,
    onRoster: false,
    isConfirmed: false,
    discordID: "1234",
    userID: placeholderID,
    signupState: SignupStates.SIGNED,
    displayName: `PH ${placeholderID}`,
    signedUpAt: Date.now(),
    lastUpdate: Date.now(),
    type: eMemberType.PLACEHOLDER
  }
  if(!!char){
    placeholderMember.character = char
    placeholderMember.displayName = `PH ${char.charName}`
  }

  return placeholderMember
}