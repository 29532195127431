import { dequal } from "dequal";
import { CustomError, ER404_NOT_FOUND, ER500_SERVER_ERROR, getUserID } from "functions";
import Fuse from 'fuse.js';
import { CURRENT_DEFAULT_MAP, SignupStates, eDocType, eKeyOrderLayout, eLocationType, eMemberType, iCharacter, iCharacterFull, iGameServerZone, iGuest, iLocation, iLocationClassSpec, iLocationMap, iMember, iRaid, iSignupSet, iSignupSets, iSpec, iUser, isClassSpecLoc, isValidLocationMap, newID } from "typings";
import { allLocations, iLocationTier } from "../locations/allLocations";
import { getGrouping } from "./groupMemberFunctions";

export const getServerString = (locMap: iLocationMap, serverZoneIndex: number, server: string) => {
  const locMapString = getLocationMapValues(locMap).join(".")
  return `${locMapString}.${serverZoneIndex}.${server}`
}

export const parseServerString = (serverString: string) => {
  const parts = serverString.split(".")
  const server = parts.splice(-1, 1)[0]
  const serverZoneIndex = parseInt(parts.splice(-1, 1)[0])
  const locationMap = locationMapArrayToLocMap(parts)
  const location = findLocation(locationMap)
  
  if(isClassSpecLoc(location) === false){
    throw new CustomError(ER500_SERVER_ERROR)
  }
  const serverZone = location?.game?.gameServerZones?.[serverZoneIndex]

  if(!serverZone || serverZone?.servers?.includes(server) === false){
    throw new CustomError({
      ...ER404_NOT_FOUND,
      title: { en: "Server zone not found." },
      message: { en: `Couldn't find that server zone.` }
    })
  }

  return {
    server,
    location,
    serverZone
  }
}

const locationMapArrayToLocMap = (sortedTierIds: string[]): iLocationMap => {
  return sortedTierIds.reduce((acc, item, index) => {
    const key = `tier${index + 1}`
    acc[key as keyof iLocationMap] = item
    return acc;
  }, {} as iLocationMap)
};



export const getLocationMapValues = (locationMap: iLocationMap): string[] => {
  return Object.keys(locationMap)
    .sort()
    .map((key) => locationMap[key as keyof iLocationMap])
    .filter((value): value is string => value !== undefined)
}

export function findLocation(locationMap: iLocationMap) {
  if(isValidLocationMap(locationMap) === false) throw new Error(`Invalid location map`)
  const tiers = getLocationMapValues(locationMap)
    .filter((value) => !!value)

  return findLocationRecursively(allLocations, tiers)
}

function findLocationRecursively(currentNode: iLocationTier, path: string[]): iLocation {
  if (path.length === 0 && currentNode.location) {
    return currentNode.location;
  }

  const nextKey = path[0];
  const nextNode = currentNode.next?.[nextKey];

  if (!nextNode) {
    throw new CustomError({
      ...ER404_NOT_FOUND,
      title: { en: "Location not found." },
      message: { en: `Couldn't find that location. Looking for ${nextKey} in ${currentNode?.label}` }
    })
  }

  return findLocationRecursively(nextNode, path.slice(1));
}

export function getAllLocations(): LocationWithLabel[] {
  return getLocationsFrom(allLocations);
}

interface LocationWithLabel {
  location: iLocation;
  label: string;
  prefix?: string;
}

function getLocationsFrom(node: iLocationTier, currentPrefix?: string): LocationWithLabel[] {
  let locations: LocationWithLabel[] = [];
  const prefixToUse = node.prefix || currentPrefix;

  if (node.location && node.showLocationInPicker) {
    locations.push({ location: node.location, label: node.label, prefix: prefixToUse });
  }

  if (node.next) {
    Object.values(node.next).forEach(nextNode => {
      const childLocations = getLocationsFrom(nextNode, prefixToUse);
      locations = locations.concat(childLocations);
    });
  }

  return locations;
}

export const findServerZone = (server: string, location: iLocation): iGameServerZone | null => {
  if(!server) return null
  if(isClassSpecLoc(location) === false) return null
  if(!location?.game?.gameServerZones) return null
  const fuse = new Fuse(location?.game?.gameServerZones, {
    threshold: 0.2,
    keys: ["servers"],
    fieldNormWeight: 1
  });
  const zoneMatches = fuse.search(server).map(match => match.item)
  return zoneMatches?.[0] || null
}

export const getMainSignupSet = (signupSets: iSignupSets, locationMap: iLocationMap, server?: string): iSignupSet | null => {
  if(!signupSets || Object.keys(signupSets).length < 1){
    return null
  }
  const serverSets = filterSignupSetsBy(signupSets, locationMap, server)
  const mainSet = serverSets.find(set => set.isMain === true)
  if(!!mainSet) return mainSet
  return null
}

export const createSignupSet = ({
  inputMember,
  signupSetLabel,
  locationMap,
  server,
  userOrGuest: userOrGuest,
  existingSet
}: {
  signupSetLabel: string
  userOrGuest: iUser | iGuest
  inputMember: Partial<iMember>
  locationMap: iLocationMap
  server?: string
  existingSet?: iSignupSet
}) => {
  const member: iMember = {
    userID: getUserID(userOrGuest),
    discordID: userOrGuest?.discordID,
    signupState: SignupStates.SIGNED,
    displayName: userOrGuest?.displayName || "Guest",
    onRoster: false,
    isConfirmed: false,
    signedUpAt: Date.now(),
    lastUpdate: Date.now(),
    type: userOrGuest?.docType === eDocType.GUEST ? eMemberType.GUEST : eMemberType.MEMBER
  }
  if(inputMember.character) member.character = inputMember.character
  if(inputMember.altCharacters) member.altCharacters = inputMember.altCharacters

  const existingMainSet = getMainSignupSet(userOrGuest?.signupSets || {}, locationMap, server)

  const set: iSignupSet = {
    id: existingSet?.id || newID(4),
    locationMap: existingSet?.locationMap || locationMap,
    isMain: existingMainSet === null,
    label: signupSetLabel || "New signup set",
    set: member
  }
  if(server) set.server = server?.toLowerCase()
  
  return set
}

export const findValidCharacter = (user: iUser | iGuest, signup: iRaid, charName?: string): iCharacterFull | null => {
  const validChars = user.characters.filter(char => filterCharactersBy(char, signup.location, signup?.server))
  if(validChars?.length < 1) return null
  if(!!charName){
    const foundChar = validChars.find(char => char.charName === charName)
    return foundChar || null
  }
  const mainChar = validChars.find(char => !!char?.isMain)
  if(mainChar) return mainChar
  return validChars[0]
}

export const getCategoryToSpecs = (signup: iRaid): Record<string, iSpec[]> => {
  const location = findLocation(signup.location)
  if(location?.type !== eLocationType.CLASS_SPEC) return {}

  const rosterLayout = location.ui.layoutData.defaultLayout.web.roster
  const grouping = getGrouping(rosterLayout.groupingFunction, signup, location)
  const categoriesHorisontal = grouping.getGroupingKeyOrder({
    mode: "specific",
    orderLayout: eKeyOrderLayout.HORISONTAL
  })

  const roleToSpec = categoriesHorisontal.reduce((acc, category) => {
    return { ...acc, [category]: [] }
  }, {} as Record<string, iSpec[]>)

  for (const gameClass of Object.values(location?.game?.classes || {})) {
    for (const gameSpec of Object.values(gameClass.specs)) {
      if(!roleToSpec[gameSpec.gameRole] || !Array.isArray(roleToSpec[gameSpec.gameRole])) {
        roleToSpec[gameSpec.gameRole] = []
      }
      roleToSpec[gameSpec.gameRole].push(gameSpec)
    }
  }

  return roleToSpec
}


export const filterCharactersBy = (char: iCharacterFull, locationMap: iLocationMap, server?: string, faction?: string) => {
  const location = findLocation(locationMap)
  
  const charFaction = char?.faction || "horde" // optionals here for old characters without this data
  const charLoc = char?.location || CURRENT_DEFAULT_MAP
  const matchLocation = dequal(charLoc, locationMap)

  if(!matchLocation) return false
  const sameServer = char.server?.toLowerCase() === server?.toLowerCase()
  if(isClassSpecLoc(location) === false) return true
  if(location.game.filterCharacters.filterByServer && !!server &&  !sameServer) return false
  if(location.game.filterCharacters.filterByFaction && !!faction && charFaction !== faction) return false
  return true
}


export const filterSignupSetsBy = (
  signupSets: iSignupSets,
  locationMap: iLocationMap,
  server?: string
) => {
  const signupSetsArr = Object.values(signupSets || {})
    .filter((set) => {
      const locMapMatch = dequal(set.locationMap, locationMap)
      if (!locMapMatch) return false
      const serverMatch = set.server?.toLowerCase() === server?.toLowerCase()
      if (!!server && !serverMatch) return false
      return true
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1))

  return signupSetsArr
}

export const getMemberCharacterEmojis = (member: iMember, location: iLocation): string => {
  if(isClassSpecLoc(location) === false) return ""
  if(!member.character) return ""
  const mainCharEmoji = getEmojiForCharacter(member.character, location)
  const altCharEmojis = member.altCharacters?.map(char => getEmojiForCharacter(char, location)) || []
  return ` (${mainCharEmoji}${altCharEmojis.length > 0 ? " + " + altCharEmojis.join(", ") : ""})`
}

export const getEmojiForCharacter = (character: iCharacter, location: iLocationClassSpec): string => {
  const gameClass = location.game.classes[character.charClass];
  if (!gameClass) return ""
  const spec = gameClass.specs[character.charSpec];
  if (!spec) return ""
  return spec.emoji;
}