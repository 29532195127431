import { dequal } from "dequal";
import React, { CSSProperties, FC, useEffect, useRef } from "react";
import { useOnClickOutside } from "../../../global/hooks";
import { iMenuItem, isNever } from "typings";
import Styles from './popOver.module.scss'
import PopOverMenu from "./popOverMenu";

export type PopOverContent = { mode: "component", component: JSX.Element } | { mode: "menu", menuItems: iMenuItem[] }

interface PopOverProps {
  content: PopOverContent
  dismiss: (...args: any[]) => any
  side?: string
  style?: React.CSSProperties
  modal?: boolean
}

const PopOver: FC<PopOverProps> = ({ dismiss, content, style, side = "top", modal = false }) => {

  useEffect(() => {
    const checkForEsc = (event: KeyboardEvent) => {
      if(event.key === "Escape") dismiss()
    }
    document.addEventListener("keydown", checkForEsc, false);
    return () => {
      document.removeEventListener("keydown", checkForEsc, false);
    }
  }, [])
  

  const Component = () => {
    const ref = useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, dismiss)
    if(!("component" in content)) return <></>
    
    return (
      <div ref={ref}>
        {React.cloneElement(content.component, { dismiss })}
      </div>
    )
  }

  const Content = () => {
    if(content.mode === "component") return <Component />;
    if(content.mode === "menu") return (
      <PopOverMenu menuItems={content?.menuItems} modal={modal} />
    )
    isNever(content)
    return <></>
  }

  const popOverStyle: CSSProperties = {...style}
  if(content.mode === "menu") {
    popOverStyle.padding = 0
    popOverStyle.zIndex = 110
    popOverStyle.boxShadow = 'none'
  } else {
    popOverStyle.zIndex = 105
  }

  return (
    <div className={`${Styles.popOver} ${Styles[side]}`} style={popOverStyle}>
      <div className={Styles.tooltipArrow}></div>
      <Content />
    </div>
  );
}

const areEqual = (prev: unknown, next: unknown) => {
  const sameProps = dequal(prev, next);
  // const diff1 = diff(prev, next)
  // console.log("popover - sameProps", sameProps, diff1)
  return sameProps;
}

export default React.memo(PopOver, areEqual);