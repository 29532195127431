import { gameRoleType, iCharacter, iLocationClassSpec, iSpec } from "typings";

export const charNames = [
  "Rhuhsinun","Trozonu","Lirtei","Arsun","Tzanzula","Lantu","Rhazsa","Shisho","Traigsen","Xusaiz","Cranky","Frame","Tweak","Cabe","Witness","User","Hal","Sparky","Omega","Gnomebotnik","Machina","Dimension","Gnomebotnik","Hex","Wankel","Guardian","Screwsie","Ranger","Student","Beezleburt","Carer","Cyl","Sirballister","Search","Hammer","Fahrenheit","Twobit","Hexidecimal","Scrap","Gizmo","Pixel","Trashcan","Ghost","Unit","Care","Tinkerbolt","Jigsaw","Boomer","Discovery","Jiggatron","Mechi","Tec","Ohm","Corius","Face",
]

export const getRandomLocationChar = (
  location: iLocationClassSpec,
  input: { mode: "spec", spec: iSpec } 
        | { mode: "role", role: gameRoleType, roleToSpecs: { [category: string]: iSpec[] } }
        | { mode: "random" }
): iCharacter => {
  const charName = charNames[Math.floor(Math.random() * charNames.length)];

  // spec
  if(!!input && input.mode === "spec"){
    const char: iCharacter = {
      charClass: input.spec.gameClass,
      charSpec: input.spec.gameSpec,
      charName: charName,
      gameRole: input.spec.gameRole
    };
  
    return char;
  }

  // role
  if(!!input && input.mode === "role"){
    const viableSpecs = input.roleToSpecs[input.role];
    const { gameClass, gameSpec } = viableSpecs[Math.floor(Math.random() * viableSpecs.length)];
  
    return {
      charClass: gameClass,
      charSpec: gameSpec,
      charName: charName,
      gameRole: input.role
    };
  }

  // random
  const locationClasses = location.game?.classes || {}
  const classes = Object.keys(locationClasses);
  const randomClass = classes[Math.floor(Math.random() * classes.length)];

  const specs = Object.values(locationClasses?.[randomClass]?.specs || {});
  const { gameSpec, gameRole } = specs[Math.floor(Math.random() * specs.length)];

  return {
    charClass: randomClass,
    charSpec: gameSpec,
    charName: charName,
    gameRole
  };
};

export const getIconURL = (game: string, fileName: string) => {
  return `https://cdn.prepi.io/${game}/icons/${fileName}`
}

export const getIconLookupURL = (folder: string, fileName: string) => {
  return `https://cdn.prepi.io/${folder}/iconLookup/${fileName}.json`
}
