import { eSmartListItemType, eSmartListSortType, iSmartList } from "typings";

const Tanks: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:LzXC",
  "itemOrder": [
    "jhoe",
    "IEmV",
    "WViN"
  ],
  "items": {
    "IEmV": {
      "itemID": "IEmV",
      "subListID": "AKjD",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "WViN": {
      "itemID": "WViN",
      "subListID": "Lvlj",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "jhoe": {
      "itemID": "jhoe",
      "subListID": "hlFe",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Tanks",
  "lastUpdated": 1699384734145,
  "limit": 0,
  "priority": 95,
  "subLists": {
    "AKjD": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.gameRole",
              "value": "tank"
            }
          ],
          "id": "gamerole:tank",
          "label": "Tank"
        }
      ],
      "id": "AKjD",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "Lvlj": {
      "conditionMode": "all",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.altSpecs",
              "value": [
                "protection",
                "guardian",
                "protection",
                "blood"
              ]
            }
          ],
          "id": "can_go:gamerole:tank",
          "label": "Can go: Tank"
        },
        {
          "criteria": [
            {
              "keyPath": "character.gameRole",
              "value": "tank"
            }
          ],
          "id": "not:gamerole:tank",
          "inverse": true,
          "label": "Tank"
        }
      ],
      "id": "Lvlj",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "hlFe": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "protection"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:protection",
          "label": "Protection Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/protection_paladin.jpg"
          }
        }
      ],
      "id": "hlFe",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 2,
    "default": 2
  }
}

const Healers: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:3I-G",
  "itemOrder": [
    "Vyu3",
    "CV0f",
    "w2gr",
    "Vnc-",
    "_SvX"
  ],
  "items": {
    "CV0f": {
      "itemID": "CV0f",
      "subListID": "Y0GH",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "Vnc-": {
      "itemID": "Vnc-",
      "subListID": "ddVy",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "Vyu3": {
      "itemID": "Vyu3",
      "subListID": "1txe",
      "target": {
        "10": 1,
        "default": 2
      },
      "type": eSmartListItemType.SUBLIST
    },
    "_SvX": {
      "itemID": "_SvX",
      "subListID": "S0g4",
      "target": {
        "10": 1,
        "default": 0
      },
      "type": eSmartListItemType.SUBLIST
    },
    "w2gr": {
      "itemID": "w2gr",
      "subListID": "T8Ea",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Healers",
  "lastUpdated": 1699377929862,
  "limit": 0,
  "priority": 90,
  "subLists": {
    "1txe": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "holy"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:holy",
          "label": "Holy Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/holy_paladin.jpg"
          }
        }
      ],
      "id": "1txe",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "S0g4": {
      "conditionMode": "all",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.altSpecs",
              "value": [
                "restoration",
                "restoration",
                "holy",
                "discipline",
                "holy"
              ]
            },
          ],
          "id": "can_go:gamerole:healer",
          "label": "Can go: Healer"
        },
        {
          "criteria": [
            {
              "keyPath": "character.gameRole",
              "value": "healer"
            }
          ],
          "id": "not:gamerole:healer",
          "inverse": true,
          "label": "Healer"
        }
      ],
      "id": "S0g4",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "T8Ea": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "restoration"
            },
            {
              "keyPath": "character.charClass",
              "value": "shaman"
            }
          ],
          "id": "class:shaman.spec:restoration",
          "label": "Restoration Shaman",
          "styling": {
            "color": "var(--wow-shaman)",
            "icon": "/img/icon/wowClass/restoration_shaman.jpg"
          }
        }
      ],
      "id": "T8Ea",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "Y0GH": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "discipline"
            },
            {
              "keyPath": "character.charClass",
              "value": "priest"
            }
          ],
          "id": "class:priest.spec:discipline",
          "label": "Discipline Priest",
          "styling": {
            "color": "var(--wow-priest)",
            "icon": "/img/icon/wowClass/discipline_priest.jpg"
          }
        }
      ],
      "id": "Y0GH",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "ddVy": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "restoration"
            },
            {
              "keyPath": "character.charClass",
              "value": "druid"
            }
          ],
          "id": "class:druid.spec:restoration",
          "label": "Restoration Druid",
          "styling": {
            "color": "var(--wow-druid)",
            "icon": "/img/icon/wowClass/restoration_druid.jpg"
          }
        }
      ],
      "id": "ddVy",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 3,
    "default": 5
  }
}

const Bloodlust: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:mJad",
  "itemOrder": [
    "uwtA"
  ],
  "items": {
    "uwtA": {
      "itemID": "uwtA",
      "subListID": "4Aer",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Bloodlust",
  "lastUpdated": 1699380472877,
  "limit": 0,
  "priority": 25,
  "subLists": {
    "4Aer": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "shaman"
            }
          ],
          "id": "class:shaman",
          "label": "Shaman",
          "styling": {
            "color": "var(--wow-shaman)",
            "icon": "/img/icon/wowClass/shaman.png"
          }
        }
      ],
      "id": "4Aer",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 1,
    "default": 1
  }
}

const DSacs: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:aB4L",
  "itemOrder": [
    "bVlR"
  ],
  "items": {
    "bVlR": {
      "itemID": "bVlR",
      "subListID": "ILfT",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "DSacs",
  "lastUpdated": 1699384842484,
  "limit": 0,
  "priority": 65,
  "subLists": {
    "ILfT": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "holy"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:holy",
          "label": "Holy Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/holy_paladin.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "protection"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:protection",
          "label": "Protection Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/protection_paladin.jpg"
          }
        }
      ],
      "id": "ILfT",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 1,
    "default": 2
  }
}

const AMs: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:2xDz",
  "itemOrder": [
    "a0c9"
  ],
  "items": {
    "a0c9": {
      "itemID": "a0c9",
      "subListID": "e2Ol",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "AMs",
  "lastUpdated": 1699384838845,
  "limit": 0,
  "priority": 60,
  "subLists": {
    "e2Ol": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "retribution"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:retribution",
          "label": "Retribution Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/retribution_paladin.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "holy"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:holy",
          "label": "Holy Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/holy_paladin.jpg"
          }
        }
      ],
      "id": "e2Ol",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 1,
    "default": 2
  }
}

const SpellPower: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:M3cN",
  "itemOrder": [
    "qUzv"
  ],
  "items": {
    "qUzv": {
      "itemID": "qUzv",
      "subListID": "NWxZ",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Spell power",
  "lastUpdated": 1699380217700,
  "limit": 0,
  "priority": 60,
  "subLists": {
    "NWxZ": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "demonology"
            },
            {
              "keyPath": "character.charClass",
              "value": "warlock"
            }
          ],
          "id": "class:warlock.spec:demonology",
          "label": "Demonology Warlock",
          "styling": {
            "color": "var(--wow-warlock)",
            "icon": "/img/icon/wowClass/demonology_warlock.jpg"
          }
        }
      ],
      "id": "NWxZ",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 0,
    "default": 1
  }
}

const MagicDebuff: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:N32z",
  "itemOrder": [
    "NLBn",
    "655M",
    "fYeY"
  ],
  "items": {
    "655M": {
      "itemID": "655M",
      "subListID": "lJjj",
      "type": eSmartListItemType.SUBLIST
    },
    "NLBn": {
      "itemID": "NLBn",
      "subListID": "SUWH",
      "type": eSmartListItemType.SUBLIST
    },
    "fYeY": {
      "itemID": "fYeY",
      "subListID": "8Uls",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Magic Debuff",
  "lastUpdated": 1699380227450,
  "limit": 0,
  "priority": 55,
  "subLists": {
    "8Uls": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "warlock"
            }
          ],
          "id": "class:warlock",
          "label": "Warlock",
          "styling": {
            "color": "var(--wow-warlock)",
            "icon": "/img/icon/wowClass/warlock.png"
          }
        }
      ],
      "id": "8Uls",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "SUWH": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "unholy"
            },
            {
              "keyPath": "character.charClass",
              "value": "death knight"
            }
          ],
          "id": "class:death-knight.spec:unholy",
          "label": "Unholy Death Knight",
          "styling": {
            "color": "var(--wow-death-knight)",
            "icon": "/img/icon/wowClass/unholy_death_knight.jpg"
          }
        }
      ],
      "id": "SUWH",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "lJjj": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "balance"
            },
            {
              "keyPath": "character.charClass",
              "value": "druid"
            }
          ],
          "id": "class:druid.spec:balance",
          "label": "Balance Druid",
          "styling": {
            "color": "var(--wow-druid)",
            "icon": "/img/icon/wowClass/balance_druid.jpg"
          }
        }
      ],
      "id": "lJjj",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 1,
    "default": 1
  }
}

const Blessings: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:8fJT",
  "itemOrder": [
    "J74K",
    "I-Sk",
    "vTl8"
  ],
  "items": {
    "I-Sk": {
      "itemID": "I-Sk",
      "subListID": "A8rh",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "J74K": {
      "itemID": "J74K",
      "subListID": "NL9K",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "vTl8": {
      "itemID": "vTl8",
      "subListID": "HqR_",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Blessings",
  "lastUpdated": 1699380588436,
  "limit": 0,
  "priority": 50,
  "subLists": {
    "A8rh": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "retribution"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:retribution",
          "label": "Retribution Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/retribution_paladin.jpg"
          }
        }
      ],
      "id": "A8rh",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "HqR_": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "protection"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:protection",
          "label": "Protection Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/protection_paladin.jpg"
          }
        }
      ],
      "id": "HqR_",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "NL9K": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "holy"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:holy",
          "label": "Holy Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/holy_paladin.jpg"
          }
        }
      ],
      "id": "NL9K",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 2,
    "default": 4
  }
}

const ThreatMisdirect: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:1xyQ",
  "itemOrder": [
    "cNsy",
    "cmGM"
  ],
  "items": {
    "cNsy": {
      "itemID": "cNsy",
      "subListID": "BZ-d",
      "target": {
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "cmGM": {
      "itemID": "cmGM",
      "subListID": "mL8a",
      "target": {
        "default": 2
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Threat misdirect",
  "lastUpdated": 1699378501119,
  "limit": 0,
  "priority": 40,
  "subLists": {
    "BZ-d": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "rogue"
            }
          ],
          "id": "class:rogue",
          "label": "Rogue",
          "styling": {
            "color": "var(--wow-rogue)",
            "icon": "/img/icon/wowClass/rogue.png"
          }
        }
      ],
      "id": "BZ-d",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "mL8a": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "hunter"
            }
          ],
          "id": "class:hunter",
          "label": "Hunter",
          "styling": {
            "color": "var(--wow-hunter)",
            "icon": "/img/icon/wowClass/hunter.png"
          }
        }
      ],
      "id": "mL8a",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 1,
    "default": 3
  }
}

const Replenishment: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:VEEi",
  "itemOrder": [
    "92cT"
  ],
  "items": {
    "92cT": {
      "itemID": "92cT",
      "subListID": "WzX1",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Replenishment",
  "lastUpdated": 1699380204878,
  "limit": 0,
  "priority": 35,
  "subLists": {
    "WzX1": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "survival"
            },
            {
              "keyPath": "character.charClass",
              "value": "hunter"
            }
          ],
          "id": "class:hunter.spec:survival",
          "label": "Survival Hunter",
          "styling": {
            "color": "var(--wow-hunter)",
            "icon": "/img/icon/wowClass/survival_hunter.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "retribution"
            },
            {
              "keyPath": "character.charClass",
              "value": "paladin"
            }
          ],
          "id": "class:paladin.spec:retribution",
          "label": "Retribution Paladin",
          "styling": {
            "color": "var(--wow-paladin)",
            "icon": "/img/icon/wowClass/retribution_paladin.jpg"
          }
        }
      ],
      "id": "WzX1",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  },
  "target": {
    "10": 0,
    "default": 2
  }
}

const MeleeBuffs: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:FxvR",
  "itemOrder": [
    "uhZD",
    "Vnut",
    "tly_",
    "Y0R-"
  ],
  "items": {
    "Vnut": {
      "itemID": "Vnut",
      "subListID": "Mc9c",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "Y0R-": {
      "itemID": "Y0R-",
      "subListID": "rHFo",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "tly_": {
      "itemID": "tly_",
      "subListID": "Y4LN",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "uhZD": {
      "itemID": "uhZD",
      "subListID": "7TfP",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Melee buffs",
  "lastUpdated": 1699380423439,
  "limit": 0,
  "priority": 30,
  "subLists": {
    "7TfP": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "blood"
            },
            {
              "keyPath": "character.charClass",
              "value": "death knight"
            }
          ],
          "id": "class:death-knight.spec:blood",
          "label": "Blood Death Knight",
          "styling": {
            "color": "var(--wow-death-knight)",
            "icon": "/img/icon/wowClass/blood_death_knight.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "enhancement"
            },
            {
              "keyPath": "character.charClass",
              "value": "shaman"
            }
          ],
          "id": "class:shaman.spec:enhancement",
          "label": "Enhancement Shaman",
          "styling": {
            "color": "var(--wow-shaman)",
            "icon": "/img/icon/wowClass/enhancement_shaman.jpg"
          }
        }
      ],
      "id": "7TfP",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "Mc9c": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "death knight"
            }
          ],
          "id": "class:death-knight",
          "label": "Death Knight",
          "styling": {
            "color": "var(--wow-death-knight)",
            "icon": "/img/icon/wowClass/death_knight.webp"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "enhancement"
            },
            {
              "keyPath": "character.charClass",
              "value": "shaman"
            }
          ],
          "id": "class:shaman.spec:enhancement",
          "label": "Enhancement Shaman",
          "styling": {
            "color": "var(--wow-shaman)",
            "icon": "/img/icon/wowClass/enhancement_shaman.jpg"
          }
        }
      ],
      "id": "Mc9c",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "Y4LN": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "rogue"
            }
          ],
          "id": "class:rogue",
          "label": "Rogue",
          "styling": {
            "color": "var(--wow-rogue)",
            "icon": "/img/icon/wowClass/rogue.png"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "warrior"
            }
          ],
          "id": "class:warrior",
          "label": "Warrior",
          "styling": {
            "color": "var(--wow-warrior)",
            "icon": "/img/icon/wowClass/warrior.png"
          }
        }
      ],
      "id": "Y4LN",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "rHFo": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "combat"
            },
            {
              "keyPath": "character.charClass",
              "value": "rogue"
            }
          ],
          "id": "class:rogue.spec:combat",
          "label": "Combat Rogue",
          "styling": {
            "color": "var(--wow-rogue)",
            "icon": "/img/icon/wowClass/combat_rogue.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "arms"
            },
            {
              "keyPath": "character.charClass",
              "value": "warrior"
            }
          ],
          "id": "class:warrior.spec:arms",
          "label": "Arms Warrior",
          "styling": {
            "color": "var(--wow-warrior)",
            "icon": "/img/icon/wowClass/arms_warrior.jpg"
          }
        }
      ],
      "id": "rHFo",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  }
}

const SpellBuffsDebuffs: iSmartList = {
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:yu01",
  "itemOrder": [
    "Hkkf",
    "MOOz",
    "0cVz"
  ],
  "items": {
    "0cVz": {
      "itemID": "0cVz",
      "subListID": "DBu6",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "Hkkf": {
      "itemID": "Hkkf",
      "subListID": "3DUF",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    },
    "MOOz": {
      "itemID": "MOOz",
      "subListID": "6TwO",
      "target": {
        "10": 0,
        "default": 1
      },
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Spell buffs / debuffs",
  "lastUpdated": 1699380597004,
  "limit": 0,
  "priority": 30,
  "subLists": {
    "3DUF": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charClass",
              "value": "warlock"
            }
          ],
          "id": "class:warlock",
          "label": "Warlock",
          "styling": {
            "color": "var(--wow-warlock)",
            "icon": "/img/icon/wowClass/warlock.png"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "fire"
            },
            {
              "keyPath": "character.charClass",
              "value": "mage"
            }
          ],
          "id": "class:mage.spec:fire",
          "label": "Fire Mage",
          "styling": {
            "color": "var(--wow-mage)",
            "icon": "/img/icon/wowClass/fire_mage.jpg"
          }
        }
      ],
      "id": "3DUF",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "6TwO": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "balance"
            },
            {
              "keyPath": "character.charClass",
              "value": "druid"
            }
          ],
          "id": "class:druid.spec:balance",
          "label": "Balance Druid",
          "styling": {
            "color": "var(--wow-druid)",
            "icon": "/img/icon/wowClass/balance_druid.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "shadow"
            },
            {
              "keyPath": "character.charClass",
              "value": "priest"
            }
          ],
          "id": "class:priest.spec:shadow",
          "label": "Shadow Priest",
          "styling": {
            "color": "var(--wow-priest)",
            "icon": "/img/icon/wowClass/shadow_priest.jpg"
          }
        }
      ],
      "id": "6TwO",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    },
    "DBu6": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "balance"
            },
            {
              "keyPath": "character.charClass",
              "value": "druid"
            }
          ],
          "id": "class:druid.spec:balance",
          "label": "Balance Druid",
          "styling": {
            "color": "var(--wow-druid)",
            "icon": "/img/icon/wowClass/balance_druid.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "elemental"
            },
            {
              "keyPath": "character.charClass",
              "value": "shaman"
            }
          ],
          "id": "class:shaman.spec:elemental",
          "label": "Elemental Shaman",
          "styling": {
            "color": "var(--wow-shaman)",
            "icon": "/img/icon/wowClass/elemental_shaman.jpg"
          }
        }
      ],
      "id": "DBu6",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  }
}

const Appealing: iSmartList = {
  "appeal": {
    "default": 300
  },
  "conditionMode": "any",
  "conditions": [
    {
      "criteria": [
        {
          "keyPath": "character.isMain",
          "value": true
        }
      ],
      "id": "is:main",
      "label": "Is: main"
    }
  ],
  "id": "EX:ATuy",
  "itemOrder": [
    "vFJR"
  ],
  "items": {
    "vFJR": {
      "itemID": "vFJR",
      "limit": 0,
      "smartListID": "basic:anyone",
      "type": eSmartListItemType.SMARTLIST
    }
  },
  "label": "Appealing",
  "lastUpdated": 1699363591328,
  "limit": 0,
  "subLists": {}
}

const Unappealing: iSmartList = {
  "appeal": {
    "default": -1000
  },
  "conditionMode": "any",
  "conditions": [],
  "id": "EX:mfjs",
  "itemOrder": [
    "pmxK"
  ],
  "items": {
    "pmxK": {
      "itemID": "pmxK",
      "subListID": "0yHd",
      "type": eSmartListItemType.SUBLIST
    }
  },
  "label": "Anappealing",
  "lastUpdated": 1699378567796,
  "limit": 0,
  "subLists": {
    "0yHd": {
      "conditionMode": "any",
      "conditions": [
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "frost"
            },
            {
              "keyPath": "character.charClass",
              "value": "mage"
            }
          ],
          "id": "class:mage.spec:frost",
          "label": "Frost Mage",
          "styling": {
            "color": "var(--wow-mage)",
            "icon": "/img/icon/wowClass/frost_mage.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "subtlety"
            },
            {
              "keyPath": "character.charClass",
              "value": "rogue"
            }
          ],
          "id": "class:rogue.spec:subtlety",
          "label": "Subtlety Rogue",
          "styling": {
            "color": "var(--wow-rogue)",
            "icon": "/img/icon/wowClass/subtlety_rogue.jpg"
          }
        },
        {
          "criteria": [
            {
              "keyPath": "character.charSpec",
              "value": "destruction"
            },
            {
              "keyPath": "character.charClass",
              "value": "warlock"
            }
          ],
          "id": "class:warlock.spec:destruction",
          "label": "Destruction Warlock",
          "styling": {
            "color": "var(--wow-warlock)",
            "icon": "/img/icon/wowClass/destruction_warlock.jpg"
          }
        }
      ],
      "id": "0yHd",
      "limit": 0,
      "sortBy": {
        "type": eSmartListSortType.USERID
      }
    }
  }
}

export const SmartListDistribution_Wrath: iSmartList[] = [
  Tanks,
  Healers,
  DSacs,
  AMs,
  SpellPower,
  MagicDebuff,
  Blessings,
  ThreatMisdirect,
  Replenishment,
  MeleeBuffs,
  SpellBuffsDebuffs,
  Bloodlust,
  Appealing,
  Unappealing
]

const meleeInterrupts: iSmartList = {
  conditionMode: 'all',
  conditions: [],
  id: 'RF7J',
  itemOrder: [
    'qI-F',
    'RF3O',
  ],
  items: {
    RF3O: {
      itemID: 'RF3O',
      subListID: 'NVs_',
      type: eSmartListItemType.SUBLIST
    },
    'qI-F': {
      itemID: 'qI-F',
      subListID: 'CE9f',
      type: eSmartListItemType.SUBLIST
    },
  },
  label: 'Melee Interrupts',
  lastUpdated: 1687186494467,
  limit: 0,
  subLists: {
    CE9f: {
      conditionMode: 'any',
      conditions: [
        {
          criteria: [
            {
              keyPath: 'character.charClass',
              value: 'death knight',
            },
          ],
          id: 'class:death-knight',
          label: 'Death Knight',
          styling: {
            color: 'var(--wow-death-knight)',
            icon: '/img/icon/wowClass/death_knight.webp',
          },
        },
        {
          criteria: [
            {
              keyPath: 'character.charSpec',
              value: 'enhancement',
            },
            {
              keyPath: 'character.charClass',
              value: 'shaman',
            },
          ],
          id: 'class:shaman.spec:enhancement',
          label: 'Enhancement Shaman',
          styling: {
            color: 'var(--wow-shaman)',
            icon: '/img/icon/wowClass/enhancement_shaman.jpg',
          },
        },
      ],
      id: 'CE9f',
      limit: 0,
      sortBy: {
        type: eSmartListSortType.USERID
      },
    },
    NVs_: {
      conditionMode: 'any',
      conditions: [
        {
          criteria: [
            {
              keyPath: 'character.charClass',
              value: 'rogue',
            },
          ],
          id: 'class:rogue',
          label: 'Rogue',
          styling: {
            color: 'var(--wow-rogue)',
            icon: '/img/icon/wowClass/rogue.png',
          },
        },
        {
          criteria: [
            {
              keyPath: 'character.charClass',
              value: 'warrior',
            },
          ],
          id: 'class:warrior',
          label: 'Warrior',
          styling: {
            color: 'var(--wow-warrior)',
            icon: '/img/icon/wowClass/warrior.png',
          },
        },
      ],
      id: 'NVs_',
      limit: 0,
      sortBy: {
        type: eSmartListSortType.USERID
      },
    },
  },
}

const dsacs: iSmartList = {
  conditionMode: "any",
  conditions: [],
  id: "aB4L",
  itemOrder: ["bVlR"],
  items: {
    bVlR: {
      itemID: "bVlR",
      subListID: "ILfT",
      type: eSmartListItemType.SUBLIST
    }
  },
  label: "DSacs",
  lastUpdated: 1688475967311,
  limit: 0,
  subLists: {
    ILfT: {
      conditionMode: "any",
      conditions: [
        {
          criteria: [
            {
              keyPath: "character.charSpec",
              value: "holy"
            },
            {
              keyPath: "character.charClass",
              value: "paladin"
            }
          ],
          id: "class:paladin.spec:holy",
          label: "Holy Paladin",
          styling: {
            color: "var(--wow-paladin)",
            icon: "/img/icon/wowClass/holy_paladin.jpg"
          }
        },
        {
          criteria: [
            {
              keyPath: "character.charSpec",
              value: "protection"
            },
            {
              keyPath: "character.charClass",
              value: "paladin"
            }
          ],
          id: "class:paladin.spec:protection",
          label: "Protection Paladin",
          styling: {
            color: "var(--wow-paladin)",
            icon: "/img/icon/wowClass/protection_paladin.jpg"
          }
        }
      ],
      id: "ILfT",
      limit: 0,
      sortBy: {
        type: eSmartListSortType.USERID
      }
    }
  }
}

const threatMisdirect: iSmartList = {
  conditionMode: "any",
  conditions: [],
  id: "1xyQ",
  itemOrder: ["cNsy", "cmGM"],
  items: {
    cNsy: {
      itemID: "cNsy",
      subListID: "BZ-d",
      type: eSmartListItemType.SUBLIST
    },
    cmGM: {
      itemID: "cmGM",
      subListID: "mL8a",
      type: eSmartListItemType.SUBLIST
    }
  },
  label: "Threat misdirect",
  lastUpdated: 1688476229862,
  limit: 0,
  subLists: {
    "BZ-d": {
      conditionMode: "any",
      conditions: [
        {
          criteria: [
            {
              keyPath: "character.charClass",
              value: "rogue"
            }
          ],
          id: "class:rogue",
          label: "Rogue",
          styling: {
            color: "var(--wow-rogue)",
            icon: "/img/icon/wowClass/rogue.png"
          }
        }
      ],
      id: "BZ-d",
      limit: 0,
      sortBy: {
        type: eSmartListSortType.USERID
      }
    },
    mL8a: {
      conditionMode: "any",
      conditions: [
        {
          criteria: [
            {
              keyPath: "character.charClass",
              value: "hunter"
            }
          ],
          id: "class:hunter",
          label: "Hunter",
          styling: {
            color: "var(--wow-hunter)",
            icon: "/img/icon/wowClass/hunter.png"
          }
        }
      ],
      id: "mL8a",
      limit: 0,
      sortBy: {
        type: eSmartListSortType.USERID
      }
    }
  }
}

export const SmartListExamples_Wrath: iSmartList[] = [
  meleeInterrupts,
  dsacs,
  threatMisdirect,
]