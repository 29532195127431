import { ConfirmEmbedLayout_Class_spec, ConfirmEmbedLayout_Normal, SignupEmbedLayout_Class_spec, SignupEmbedLayout_Normal, WebRosterLayout_Class_spec, WebRosterLayout_Normal, eLocationType, eMemberConfirmLayout, eMemberGroupToField, eMemberGroupingFunction, eMemberJoinIdentifier, eMemberToString, eMemberToStringEmoji, eMembersToString, iLayout_ClassSpec, iLayout_Normal } from "typings";

// Class spec layouts
export const webRosterLayout_ClassSpecDefault: WebRosterLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC,
  groupingFunction: eMemberGroupingFunction.CHARACTER_GAMEROLE 
}

export const SignupEmbedLayout_ClassSpecDefault: SignupEmbedLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC,
  columns: 2,
  memberFields: {
    groupingFunction: eMemberGroupingFunction.CHARACTER_GAMEROLE,
    memberGroupToField: {
      type: eMemberGroupToField.EXTRA_STRING,
      membersToString: {
        type: eMembersToString.JOIN_SIMILAR,
        joinIdentifier: eMemberJoinIdentifier.CHAR_CLASS_SPEC,
        memberToString: {
          type: eMemberToString.CHARACTER_NAME,
          showEmoji: eMemberToStringEmoji.CLASS_SPEC
        }
      }
    }
  }
}

export const ConfirmEmbedLayout_ClassSpecDefault: ConfirmEmbedLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC,
  fieldLayout: eMemberConfirmLayout.GROUPS,
  memberFields: {
    membersToString: {
      type: eMembersToString.NEW_LINE,
      memberToString: {
        type: eMemberToString.CHARACTER_NAME,
        showLink: "isConfirmed",
        showEmoji: eMemberToStringEmoji.CLASS_SPEC
      }
    }
  },
  standbyMemberToString: { type: eMemberToString.CHARACTER_NAME, showEmoji: eMemberToStringEmoji.CLASS_SPEC, showLink: "isConfirmed" }
}


export const Layout_ClassSpecDefault: iLayout_ClassSpec = {
  web: {
    roster: webRosterLayout_ClassSpecDefault
  },
  discord: {
    embeds: {
      signup: SignupEmbedLayout_ClassSpecDefault,
      confirm: ConfirmEmbedLayout_ClassSpecDefault
    }
  }
}


// Normal layouts
export const webRosterLayout_NormalDefault: WebRosterLayout_Normal = {
  type: eLocationType.NORMAL,
  groupingFunction: eMemberGroupingFunction.SIGNUP_STATE 
}

export const SignupEmbedLayout_NormalDefault: SignupEmbedLayout_Normal = {
  type: eLocationType.NORMAL,
  columns: 2,
  memberFields: {
    groupingFunction: eMemberGroupingFunction.SIGNUP_STATE,
    memberGroupToField: {
      type: eMemberGroupToField.EXTRA_STRING,
      membersToString: {
        type: eMembersToString.NEW_LINE,
        memberToString: {
          type: eMemberToString.USER_MENTION,
          showEmoji: eMemberToStringEmoji.NONE
        }
      }
    }
  }
}

export const ConfirmEmbedLayout_NormalDefault: ConfirmEmbedLayout_Normal = {
  type: eLocationType.NORMAL,
  fieldLayout: eMemberConfirmLayout.GROUPS,
  memberFields: {
    membersToString: {
      type: eMembersToString.NEW_LINE,
      memberToString: {
        type: eMemberToString.USER_MENTION,
        showLink: "isConfirmed",
        showEmoji: eMemberToStringEmoji.NONE
      }
    }
  },
  standbyMemberToString: { type: eMemberToString.DISPLAY_NAME, showEmoji: eMemberToStringEmoji.NONE, showLink: "isConfirmed" }
}


export const Layout_NormalDefault: iLayout_Normal = {
  web: {
    roster: webRosterLayout_NormalDefault
  },
  discord: {
    embeds: {
      signup: SignupEmbedLayout_NormalDefault,
      confirm: ConfirmEmbedLayout_NormalDefault
    }
  }
}