import { eSmartListItemType, iSmartList } from "typings";
import { uniqueFilter } from "../../../utilities/utilities";

type iSubscriberPair = { subscriber: string, subscribed: string }

export const getSmartListSubscriberPairs = (smartLists: Record<string, iSmartList>) => {
  const subscriberPairs: iSubscriberPair[] = []
  
  for (const smartList of Object.values(smartLists)) {
    for (const itemID of smartList.itemOrder) {
      const item = smartList.items[itemID]
      if(item.type !== eSmartListItemType.SMARTLIST) continue;
      subscriberPairs.push({
        subscribed: item.smartListID,
        subscriber: smartList.id
      })
    }
  }
  
  return subscriberPairs
}

export const getDirectSubscribersTo = (smartListID: string, subscriberPairs: iSubscriberPair[]) => {
  return subscriberPairs
    .filter((pair) => pair.subscribed === smartListID)
    .map((pair) => pair.subscriber)
}

// Get nested smart list subscription or dependant
export const getNestedSubscriptionOrDependantTo = (
  smartListID: string,
  subscriberPairs: iSubscriberPair[],
  subscriberMode = true
) => {
  const smartListIDs: string[] = []

  findNestedSubscriberOrDependantTo(smartListID, subscriberPairs, smartListIDs, subscriberMode)

  const uniqueNestedSubscribers = smartListIDs.filter(uniqueFilter)
  return uniqueNestedSubscribers
}


const findNestedSubscriberOrDependantTo = (
  smartListID: string,
  subscriberPairs: iSubscriberPair[],
  results: string[],
  subscriberMode = true,
) => {
  for (const subscriberPair of subscriberPairs) {
    const check = subscriberMode ? subscriberPair.subscriber : subscriberPair.subscribed
    const match = subscriberMode ? subscriberPair.subscribed : subscriberPair.subscriber

    if (match === smartListID) {
      results.push(check)
      findNestedSubscriberOrDependantTo(
        check,
        subscriberPairs,
        results,
        subscriberMode
      )
    }
  }
}