import { capitalize } from "functions";
import Button from "../../buttons/button";
import SvgIcon from "../../buttons/svgIcon";
import { iPopOverConfirm } from "typings";

const PopoverWarning = ({ cancelCallback, confirmCallback, description, title, type, confirmButtonText = "confirm" }: iPopOverConfirm) => {

  const GetIcon = () => {
    if(type === "warning") return <SvgIcon icon="warning" fill color="warning" />
    return <></>
  }

  return (
    <div className="flex-col align-center" style={{ width: 200 }}>
      {GetIcon()}
      <h5 className="text-center">{title}</h5>
      <p className="weak-text" style={{ textAlign: "center" }}>
        {description}
      </p>
      <div className="flex-row gap1 mt0 w100">
        <Button
          fullWidth
          text="Cancel"
          variant="soft"
          color="white-on-dark"
          size="mini"
          click={cancelCallback}
        />
        <Button
          fullWidth
          text={capitalize(confirmButtonText)}
          size="mini"
          color={type === "warning" ? "tertiary" : "primary"}
          click={confirmCallback}
        />
      </div>
    </div>
  )
}

export default PopoverWarning;