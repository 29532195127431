import { getCollectionData, getDocData } from 'functions';
import { eFSColl, iCharacter, iCharacterFull, iUser, iUserSecrets } from 'typings';
import { firebase } from '../client';
import { standardCRUD } from '../documentAPI';

const userAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const collectionName = eFSColl.USERS
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iUser>(collectionName, db, deleteField)

  const saveNewCharacter = async (userID: string, character: iCharacterFull) => {
    if(!userID) return false
    const userRef = db.collection(eFSColl.USERS).doc(userID);
    return userRef.update({
      characters: firebase.firestore.FieldValue.arrayUnion(character)
    });
  }

  const deleteCharacter = async (userID: string, character: iCharacter) => {
    if(!userID) return false
    const userRef = db.collection(eFSColl.USERS).doc(userID);
  
    try {
      await userRef.update({
        characters: firebase.firestore.FieldValue.arrayRemove(character)
      });
    } catch (error) {
      console.error("Didnt find character to delete", error);
      return false
    }
    
    return true
  }

  const updateCharacters = async (userID: string, characters: iCharacter[]) => {
    if(!userID) return false
    const userRef = db.collection(eFSColl.USERS).doc(userID);
  
    try {
      await userRef.update({
        characters: characters
      });
    } catch (error) {
      console.error("Couldn't update characters", error);
      return false
    }
    
    return true
  }


  const getPrivate = async (userID: string, docID?: string) => {
    if(docID) {
      const privateRef = db.collection(`${collectionName}/${userID}/private`).doc(docID);

      const userSecrets = await privateRef
        .get()
        .then((response) => {
          return getDocData(response) as iUserSecrets
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
        
      return userSecrets;
    }

    const privateRef = db.collection(`${collectionName}/${userID}/private`);

    const raidData = await privateRef
      .get()
      .then((response) => {
        const data = response.docs ? getCollectionData(response) : getDocData(response);
        return data
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
      
    return raidData;
  };

  const getByDiscordID = async (discordID: string): Promise<iUser | null> => {
    if(!discordID) return null
    const usersDocRef = db.collection(collectionName).where(`discordID`, "==", discordID)

    const users = await usersDocRef.get().then((response) => {
      const data = response.docs ? getCollectionData(response) : getDocData(response)
      return data as iUser[]
    })

    if (users?.length !== 1) return null
    return users[0]
  }

  return ({
    get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath,
    saveNewCharacter,
    deleteCharacter,
    updateCharacters,
    getPrivate,
    getByDiscordID
  })
}

export default userAPI;