// AUTHOR: Poissoj

import { option, options } from "./ui/autoComplete"
import { iLocationMap, RaidAdmin, TeamOwned } from "../documents/raid/raid"
import { iDragData, iDragDataMember } from "./ui/iDragData"
import { eDragType, eLocationType } from "./enum"
import { iLocation, iLocationClassSpec, iLocationNormal } from "./iLocation"

export const isString = (str: string | null): str is string => {
  return typeof str === "string"
}

export const isDefined = <T extends unknown>(item: T | null | undefined): item is T => {
  if(item === undefined) return false
  if(item === null) return false
  return true
}

type NonEmptyArray<T> = [T, ...T[]];
export const isArrayWithLength = <T>(arr?: T[]): arr is NonEmptyArray<T> => {
  return isDefined(arr) && Array.isArray(arr) && arr?.length > 0
}


export const isNever = (shouldBeNever: never) => { }
export const shouldBe = <T = 'A type parameter is required.', AT extends T = T>(variable: AT) => {}

export const allDefinedInArray = (arr: unknown[]) => {
  if(arr.some(obj => obj === null || obj === undefined)) return false
  return true
}

export const isBoolean = (item: unknown): item is boolean => typeof item === "boolean"

export const isTeamOwned = (docOwner: RaidAdmin | TeamOwned): docOwner is TeamOwned => docOwner?.type === "team"
export const isUserOwned = (docOwner: RaidAdmin | TeamOwned): docOwner is RaidAdmin => docOwner?.type === undefined || docOwner?.type === "user"

export const isOptions = <T extends unknown>(item: option<T> | options<T>): item is options<T> => Array.isArray(item)

export function assertIsDragDataMember(dragData: iDragData): asserts dragData is iDragDataMember {
  if (dragData.dragType !== eDragType.MEMBER) {
    throw new Error("Is not drag data member.")
  }
}

export const isClassSpecLoc = (location?: iLocation | null): location is iLocationClassSpec => isDefined(location) && location?.type === eLocationType.CLASS_SPEC
export const isNormalLoc = (location?: iLocation | null): location is iLocationNormal => isDefined(location) && location?.type === eLocationType.NORMAL

export const isValidLocationMap = (obj: any): obj is iLocationMap => {
  return !!obj && typeof obj === 'object' && "tier1" in obj
}