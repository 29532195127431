import { iUser } from "../documents/iUser";
import { iMember } from "../documents/raid/iMember";
import { GetRequiredProperties, GetOptionalProperties } from "../types";


// intellisense does not work for these functions
// without tsconfig strictNullchecks: true
const createInstance = <T>(
  requiredProps: GetRequiredProperties<T>,
  optionalProps?: GetOptionalProperties<T>
): T => {
  const instance = { ...requiredProps } as T;

  for (const [key, value] of Object.entries(optionalProps || {})) {
    if (!key || value === undefined) continue;
    instance[key as keyof T] = value as T[keyof T];
  }

  return instance;
}

const createMemberInstance = (
  memberProps: GetRequiredProperties<iMember>,
  memberOptionalProps?: GetOptionalProperties<iMember>
): iMember => {
  const defaultValues: Partial<iMember> = {
    signedUpAt: Date.now(),
    lastUpdate: Date.now()
  }

  const instance = createInstance<iMember>(memberProps, {
    ...defaultValues,
    ...memberOptionalProps
  })
  return instance
}

const createUserInstance = (
  userProps: GetRequiredProperties<iUser>,
  userOptionalProps?: GetOptionalProperties<iUser>
): iUser => {
  const defaultValues: Partial<iUser> = {
  }

  const instance = createInstance<iUser>(userProps, {
    ...defaultValues,
    ...userOptionalProps
  })
  return instance
}

export const removeUndefinedProperties = <T extends Record<string, any>>(obj: T): void => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
}