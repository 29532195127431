import React, { useState, FC, CSSProperties } from 'react';
import SvgIcon, { IconProps } from './svgIcon';
import { classNames } from 'functions';

export interface iButtonIcon extends IconProps {
  imgURL?: string;
}
export type buttonStyle = 'normal' | 'soft' | 'transparent' | 'outline'

export interface ButtonProps {
  /**
   * The button text.
   */
  text?: string;
  /**
   * Align text left or right, default is center, only works if fullWidth = true
   */
  textAlign?: 'left' | 'right';
  /**
   * The size of the button. Either 'small', 'big' or 'fullwidth'.
   */
  size?: string;
  /**
   * Boolean forces button width 100%
   */
  fullWidth?: boolean;
  /**
   * Boolean forces button width 100%
   */
  fullHeight?: boolean;
  /**
   * The color of the button. Accepts "primary" will look for var(--primary)
   */
  color?: string;
  /**
   * The color of the button. Accepts "#000000"
   */
  colorHex?: string;
  /**
   * The color of the button when hovered. Accepts "#000000"
   */
  colorHexHover?: string;
  /**
   * The styling of the button. Either 'normal', 'soft', 'transparent'  or 'outline'.
   */
  variant?: buttonStyle;
  /**
   * Makes the button turn on and off.
   */
  toggleable?: boolean;
  /**
   * Callback function that is fired once button is clicked.
   */
   click?: (...args: any[]) => void;
  /**
   * To add any other class to the button.
   */
  addClass?: string;
  /**
   * Add icon left of the text.
   */
  iconLeft?: iButtonIcon
  /**
   * Add icon right of the text.
   */
  iconRight?: iButtonIcon
  style?: CSSProperties
  darkText?: boolean
  textStyle?: CSSProperties
  "data-state"?: string
}

export const Button: FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(({  
  text,
  textAlign = "center",
  size,
  fullWidth = false,
  fullHeight = false,
  color,
  colorHex,
  colorHexHover,
  variant = 'normal',
  toggleable = false,
  darkText = false,
  click,
  addClass,
  iconLeft,
  iconRight,
  style,
  textStyle,
  ...props
}, ref) => {

  const [ isOn, setIsOn ] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {

    if(toggleable){
      setIsOn(!isOn)
    }
    if(click) click(e)
  };

  const classBuilder = () => {
    let classString = "btn"
    if(color){ classString += " " + color }
    if(variant && variant !== "normal" && props?.['data-state'] !== 'open'){ classString += " " + variant }
    if(size){ classString += " " + size }
    if(click){ classString += " clickable" }
    if(darkText){ classString += " darkText" }
    if(fullWidth){ classString += " fullwidth" }
    if(fullHeight){ classString += " fullHeight" }
    if(iconLeft?.imgURL || iconRight?.imgURL){ classString += " has-img" }
    if(textAlign !== "center"){ classString += ` align-text-${textAlign}` }
    if(addClass){ classString += " " + addClass }
    return classString
  };

  const btnCustomProperties: CSSProperties = {
    ...style
  }
  // @ts-ignore
  if(colorHex) btnCustomProperties["--ec-btn-bg-color"] = colorHex
  // @ts-ignore
  if(colorHexHover || colorHex) btnCustomProperties["--ec-btn-bg-color-hover"] = colorHexHover || colorHex

  return (
    <button ref={ref} type="button" onClick={handleClick} className={classBuilder()} style={btnCustomProperties} {...props}>

      {(iconLeft?.icon && !iconLeft?.imgURL) && (
        <SvgIcon icon={iconLeft?.icon} fill={iconLeft?.fill} strokeWidth={iconLeft?.strokeWidth} title={iconLeft?.title} addClass={iconLeft?.addClass} style={iconLeft?.style} height={16} width={16} color="currentColor" />
      )}
      {iconLeft?.imgURL && (
        <img src={iconLeft?.imgURL} alt={iconLeft.icon} style={{height: 20, width: 20, zIndex: 0, borderRadius: 2}} />
      )}

      {text && (
        <div
          className={classNames(
            `text-container text-cut`,
            iconLeft?.icon && "icon-left",
            iconRight?.icon && "icon-right"
          )}
          style={textStyle}
        >
          {text}
        </div>
      )}

      {(iconRight?.icon && !iconRight?.imgURL) && (
        <SvgIcon icon={iconRight?.icon} fill={iconRight?.fill} strokeWidth={iconRight?.strokeWidth} title={iconRight?.title} addClass={iconRight?.addClass} style={iconRight?.style} height={16} width={16} color="currentColor" />
      )}
      {iconRight?.imgURL && (
        <img src={iconRight?.imgURL} alt={iconRight.icon} style={{height: 20, width: 20, zIndex: 0, borderRadius: 2}} />
      )}
    </button>
  );
});

export default Button;
