import { styled } from "../../../global/styles/stitches"

const DividerBox = styled("div", {
  background: 'var(--text-color-weak)',
  opacity: 0.15,
  flexShrink: 0,
  
  '@sm': {
    margin: '16px 0px'
  },
  '@lg': {
    margin: '24px 0px'
  },

  variants: {
    halfSize: {
      true: { margin: '16px 0px' },
    },
    topMarginOnly: {
      true: { margin: '24px 0px 0px 0px' },
    },
    bottomMarginOnly: {
      true: { margin: '0px 0px 24px 0px' },
    },
    orientation: {
      horizontal: {
        width: '100%',
        height: 1.5,
      },
      vertical: {
        width: 1.5,
        height: '100%',
        margin: '0px 24px',

        '@md': {
          margin: '0px 16px'
        },
        '@lg': {
          margin: '0px 24px'
        },
      },
    },
  },
})

type DividerOrientation = "horizontal" | "vertical"

const Divider = ({
  topMarginOnly = false,
  bottomMarginOnly = false,
  halfSize = false,
  orientation = "horizontal"
}: {
  topMarginOnly?: boolean
  bottomMarginOnly?: boolean
  halfSize?: boolean
  orientation?: DividerOrientation
}) => {
  return (
    <DividerBox
      bottomMarginOnly={bottomMarginOnly}
      topMarginOnly={topMarginOnly}
      halfSize={halfSize}
      orientation={orientation === "vertical" ? "vertical" : "horizontal"}
    />
  )
}

export default Divider