import { Areas, Target, iDragData, isDefined } from "typings";
import { iDropData } from "../droppable/iDropData";
import { Active, Over } from "@dnd-kit/core";

const defaultInitializer = (index: number) => index;

export function createRange<T = string>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index));
}


export function createTargets(
  length: number,
) {
  return [...new Array(length)].map((_, index) => {
    return Target().create({ area: Areas.ROSTER })
  });
}

export const checkCanDrop = (dragData?: iDragData, dropData?: iDropData) => {
  if(!dropData?.dropFence) return true
  if(!isDefined(dragData)) return false
  const { acceptTypes, listIDs, acceptFromAreas, checkValidDrop } = dropData.dropFence
  if(!!checkValidDrop && typeof checkValidDrop === "function" && checkValidDrop?.(dragData, dropData) === false) {
    return false
  }
  if(!!acceptFromAreas && "area" in dragData && acceptFromAreas?.includes(dragData?.area) === false) return false
  if(!!acceptTypes && acceptTypes?.includes(dragData?.dragType) === false) return false
  if(!!listIDs && dragData?.inList && listIDs?.includes(dragData?.inList) === false) return false
  return true
}

export const getDragDataFromActive = (active: Active): iDragData => {
  const dragData = active?.data?.current?.dragData
  if(isDefined(dragData)) return dragData as iDragData
  throw new Error("No drag data on active")
}

export const getDropDataFromOver = (over: Over): iDropData => {
  return over?.data?.current?.dropData as iDropData
}

export const getDragAndDropData = (active: Active, over: Over) => {
  return {
    dragData: getDragDataFromActive(active),
    dropData: getDropDataFromOver(over)
  }
}

