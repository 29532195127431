import { Areas, GameRole, GridData, charID, eDragType, eSmartListItemType, eSmartListSortType, iColumnCell, iCriteria, iDragDataSmartListInstance, iLocation, iPanelGrid, iSmartList, iSmartListCondition, iSmartListInstance, iSmartListSubList, smartLists } from "typings";
import { capitalizeEveryWord, generateNextStringID, isInArray, nextCharFromKeysIn } from "../../../utilities/utilities";
import { deletePairsByCondition } from "../../../utilities/utilities";
import { minMax } from "../../../utilities/mathUtilities";
import { getCellFromPanel } from "../panelUtilities";
import copy from "fast-copy";

export const addSmartListDisplayToGridData = (
  gridData: GridData,
  xy: string,
  smartListID: string,
  instanceInput?: iSmartListInstance
) => {
  if (!gridData?.smartListDisplay) {
    gridData.smartListDisplay = {
      priority: [],
      instances: {}
    }
  }
  
  const display = gridData.smartListDisplay
  const id = generateNextStringID(Object.keys(display.instances))

  const defaultInstance: iSmartListInstance = {
    id,
    xyTopLeft: xy,
    smartListID,
    direction: 0,
    offset: 0,
    xSpan: 1,
    ySpan: 1
  }
  const instance = instanceInput ? Object.assign(instanceInput, { id }) : defaultInstance

  display.priority.push(id)
  display.instances[id] = instance
}

export const getCoordsFromXYString = (xy: string) => {
  const arr = xy.split(".")
  return {
    col: parseInt(arr[0]),
    row: parseInt(arr[1])
  }
}

export const checkValidInstanceSpread = (gridPanel: iPanelGrid, instance: iSmartListInstance) => {
  const columns = gridPanel.gridData.columns.length
  const rows = gridPanel.gridData.columns[0].cells.length
  const root = getCoordsFromXYString(instance.xyTopLeft)

  // check if root is located in grid
  const rootCell = gridPanel.gridData.columns?.[root.col]?.cells?.[root.row]
  if(!rootCell) throw new Error("Check valid spread - invalid root.")
  
  // check if span goes out of grid
  const remainingColumns = columns - root.col
  const remainingRows = rows - root.row
  if(instance.xSpan > remainingColumns) {
    console.error("xSpan too large", { xSpan: instance.xSpan, remainingColumns, gridPanel });
    throw new Error("Check valid spread - instance too big horisontally")
  }
  if(instance.ySpan > remainingRows) {
    console.error("ySpan too large", { ySpan: instance.ySpan, remainingRows, gridPanel });
    throw new Error("Check valid spread - instance too big vertically")
  }

  return { root }
}

export const checkAllInstancesValid = (gridPanel: iPanelGrid) => {
  try {
    const priority = gridPanel?.gridData?.smartListDisplay?.priority || []
    const instances = gridPanel?.gridData?.smartListDisplay?.instances || {}
    for (const instanceID of priority) {
      const instance = instances?.[instanceID]
      checkValidInstanceSpread(gridPanel, instance)
    }
  } catch (error) {
    return false
  }
  return true
}

export const getCellsForSmartListInstance = (
  gridPanel: iPanelGrid,
  instance: iSmartListInstance
) => {
  const cellArray: {xy: { col: number, row: number}, cell: iColumnCell}[] = []
  const { root } = checkValidInstanceSpread(gridPanel, instance)

  // vertical spread
  if (instance.direction === 1) {
    for (let i = root.col; i < instance.xSpan + root.col; i++) {
      for (let u = root.row; u < instance.ySpan + root.row; u++) {
        const cell = getCellFromPanel(gridPanel, i, u)
        if (!cell) continue
        cellArray.push({ xy: { col: i, row: u } , cell})
      }
    }

    return cellArray
  }

  // horisontally spread
  for (let u = root.row; u < instance.ySpan + root.row; u++) {
    for (let i = root.col; i < instance.xSpan + root.col; i++) {
      const cell = getCellFromPanel(gridPanel, i, u)
      if (!cell) continue
      cellArray.push({ xy: { col: i, row: u } , cell})
    }
  }

  return cellArray
}


export const findFirstNonUsedCharID = (
  availableCharIDs: charID[],
  usedCharIDs: charID[]
): charID | null => {
  for (const availableCharID of availableCharIDs) {
    if (!isInArray(availableCharID, usedCharIDs)) {
      usedCharIDs.push(availableCharID)
      return availableCharID;
    }
  }
  return null
}

export const smartListToInstanceDrag = (smartList: iSmartList) => {
  const instanceDragData: iDragDataSmartListInstance = {
    area: Areas.SMARTLISTS,
    dragType: eDragType.SMARTLIST_INSTANCE,
    targetID: "",
    smartListData: {
      smartList,
      instanceData: {
        color: "var(--primary)",
        useDarkText: false,
        instancePrio: 0,
        panelID: "",
        instance: {
          direction: 0,
          id: "A",
          offset: 0,
          smartListID: smartList.id,
          xSpan: 1,
          ySpan: 1,
          xyTopLeft: "0.0"
        }
      }
    },
  }
  return instanceDragData
}

export const validatePanelInstances = (panel: iPanelGrid) => {
  if(!panel?.gridData?.smartListDisplay) return
  const columnsLength = panel.gridData.columns.length
  const rowLength = panel.gridData.columns[0].cells.length
  const smartListDisplay = panel.gridData.smartListDisplay

  // remove any instances that have xyTopLeft in rows / columns that no longer exist
  const deletedInstanceIDs = deletePairsByCondition(smartListDisplay.instances, (instance) => {
    const root = getCoordsFromXYString(instance.xyTopLeft)
    const columnValid = root.col + 1 <= columnsLength
    const rowValid = root.row + 1 <= rowLength
    return !columnValid || !rowValid
  })

  smartListDisplay.priority = panel?.gridData?.smartListDisplay.priority.filter(
    (instanceID) => deletedInstanceIDs.includes(instanceID) === false
  )

  // adjust span length for remaining instances
  for (const instance of Object.values(smartListDisplay.instances)) {
    const root = getCoordsFromXYString(instance.xyTopLeft)
    const xMaxSpan = columnsLength - root.col
    const yMaxSpan = rowLength - root.row
    instance.xSpan = minMax(instance.xSpan, 1, xMaxSpan)
    instance.ySpan = minMax(instance.ySpan, 1, yMaxSpan)
  }
}

export const createSmartListsWithoutLimits = (smartListsInput: smartLists) => {
  const smartLists = copy(smartListsInput)

  for (const smartList of Object.values(smartLists)) {
    smartList.limit = 0

    for (const subList of Object.values(smartList.subLists)) {
      subList.limit = 0
    }

    for (const smartListItem of Object.values(smartList.items)) {
      if(smartListItem.type === eSmartListItemType.SMARTLIST){
        smartListItem.limit = 0
      }
    }
  }
  
  return smartLists
}

export const subListToLabel = (subList: iSmartListSubList): string => {
  if(!subList?.conditions || subList.conditions.length < 1) return ""
  const strArray = subList.conditions.reduce((acc, condition) => {
    return [...acc, capitalizeEveryWord(condition.label)]
  }, [] as string[])
  return strArray.join(", ")
}