import { Areas, CURRENT_DEFAULT_MAP, Member, SignupStates, eDragType, iRaid, iRosterCategories, iUser, isDefined, members, newID, signupModes } from "typings";
import { eAdminStatus, eMemberType, eSignupDiscoverability, eSignupLogType, eSignupVisibility } from "../../types/enum";
import { removeUndefinedProperties } from "../../types/factory";
import { iSplitSignup } from '../iSplitSignup';
import { iCustomField } from "./customFields";

export type raidGroupOrder = (string | null)[]
export type dragType = eDragType
export type raidAreaType = Areas
export type iTemplateData = {
  isPublic: boolean
}

// public -> accessable in upcoming "discover" page
// private -> only existing admins can access
// link -> anyone can read data, but won't be accessable in "discover"
export type raidSignupMode = signupModes.ROSTERFIRST | signupModes.BENCHFIRST
export type iAdminStatus = `${eAdminStatus}`//"admin" | "roster" | "open" // todo: remove
export type raidVisibility = `${eSignupVisibility}`//"admin" | "roster" | "open" // todo: remove
export type raidLink = {
  href: string
  linkName: string
}
export interface iLocationMap {
  tier1: string
  tier2?: string
  tier3?: string
  tier4?: string
} // i.e { tier1: "online", tier2: "games", tier3: "wow", tier4: "wotlk" }

export type admins = {
  [userID: string]: RaidAdmin
}

export type RaidAdmin = {
  type: "user"
  userID: string
  discordID: string
  displayName: string
}

// Log and notify
export type iSignupLogNotify = {
  [logType in eSignupLogType]?: string[] // discordIDs
}

export type iSignupLog = Record<string, iSignupLogItem>

export type iSignupLogItem =
  | {
      type: eSignupLogType.SIGNUP
      memberID: string
      displayName: string
    }
  | {
      type: eSignupLogType.SIGNOFF
      memberID: string
      displayName: string
    }
  | {
      type: eSignupLogType.PUBLISH
      memberID: string
      displayName: string
    }
  | {
      type: eSignupLogType.RESTORE
      memberID: string
      displayName: string
      data: {
        unixMS: string
      }
    }
  | {
      type: eSignupLogType.DUPLICATE_SIGNUP
      memberID: string
      displayName: string
      data: {
        fromSignupID: string
      }
    }
  | {
      type: eSignupLogType.DELETE_MEMBER
      memberID: string
      displayName: string
      data: {
        deletedUserID: string
        deletedDisplayName: string
        deletedCharName?: string
      }
    }

export type TeamOwned = { type: "team", teamID: string, teamName: string, discordID?: string }
export type iRaidCreate = {
  owner: RaidAdmin | TeamOwned,
  title: string
  start: number
  end: number
  createdAt: number
  isSignupOnly?: boolean
  isTemplate?: boolean
  signupMode?: raidSignupMode
  admins?: admins
  members?: members
  membersCanConfirm?: boolean
  groupOrder?: raidGroupOrder
  
  server?: string
  links?: raidLink[]
  maxSize?: number
  groupSize?: number
  flags?: string[]
  description?: string
  location?: iLocationMap
  visibility?: raidVisibility
  splitData?: iSplitSignup | null
  splitRef?: string
  log?: iSignupLog
  notify?: iSignupLogNotify
  templateData?: iTemplateData
  templateIDUsed?: string
  customFields?: iCustomField[]
  rosterCategories?: iRosterCategories
}

export const createRaidID = () => newID(8)

export const Raid = () => {

  const create = (
    raidID: string,
    raidData: iRaidCreate,
    initialUser: iUser
  ) => {
    
    const {
      owner,
      links,
      title,
      start,
      end,
      server,
      membersCanConfirm,
      isTemplate = false,
      isSignupOnly = false,
      maxSize = 25,
      groupSize = 5,
      signupMode = signupModes.ROSTERFIRST,
      location = CURRENT_DEFAULT_MAP,
      flags,
      admins,
      groupOrder,
      description,
      visibility = "open",
      createdAt = Date.now(),
      splitData,
      splitRef,
      log = {},
      notify = {},
      templateData,
      templateIDUsed,
      customFields,
      rosterCategories
    } = raidData;

    const raid: iRaid = {
      raidID: raidID,

      // required
      owner,
      title,
      start,
      end,
      createdAt,

      // permanent defaults
      maxSize,
      groupOrder: groupOrder || [...Array(maxSize)].map((i) => null),
      groupSize,
      admins: admins || {},
      members: {
        [initialUser.userID]: Member().create({
          userID: initialUser.userID,
          discordID: initialUser.discordID,
          displayName: initialUser.displayName,
          signupState: SignupStates.NONE,
          onRoster: false,
          isConfirmed: false,
          type: eMemberType.MEMBER
        })
      },
      signupMode,
      membersCanConfirm: membersCanConfirm || false,
      isSignupOnly,
      isArchived: false,
      isTemplate,
      location,
      flags: flags || [],
      log,
      notify,

      // not in use yet
      discoverability: eSignupDiscoverability.LINK,
      visibility,
    }
    if(links) raid.links = links
    if(isDefined(description)) raid.description = description
    if(splitData) raid.splitData = splitData
    if(splitRef) raid.splitRef = splitRef
    if(templateData) raid.templateData = templateData
    if(templateIDUsed) raid.templateIDUsed = templateIDUsed
    if(customFields) raid.customFields = customFields
    if(rosterCategories) raid.rosterCategories = rosterCategories
    if(server) raid.server = server

    removeUndefinedProperties(raid)

    return raid
  };


  return ({
    create,
  })
}