import { Areas, eLocationType, eRequired, iLocationNormal } from "typings";
import { Layout_NormalDefault } from "../../layouts/layouts";
import { getIconURL } from "./games/games";

export const generalOnline: iLocationNormal = {
  type: eLocationType.NORMAL,
  locationLabel: "General",
  locationLabelExtended: "General",
  defaultMaxSize: 25,
  getIconURL: (fileName: string) => getIconURL("general", fileName),
  locMap: { tier1: "online" },
  iconLookupURL: getIconURL("online", "general"),
  locationIcons: [],
  templates: [
    { label: "Blank", value: "eMouTZCC" }
  ],
  examples: {},
  groupCompTools: [],
  signupRules: {
    character: eRequired.NEVER,
    server: eRequired.NEVER
  },
  ui: {
    layoutData: {
      defaultLayout: Layout_NormalDefault
    },
    web: {
      areas: {
        [Areas.ROSTER]: {
          default: "table",
          available: ["table", "category"]
        },
        [Areas.GROUPS]: true,
        [Areas.ROLES]: true,
        [Areas.SMARTLISTS]: true,
        [Areas.ASSIGNMENTS]: true,
      }
    },
  }
}