import { isDefined } from "../../../types/typeGuards";
import {
  AssignmentType,
  iPanel,
  iPanelGrid,
  iPanelSpecSwap,
  iPanelTarget,
  iPanelImage,
  iPanelSpacer,
  iPanelText,
  iPanelVideo
} from "../iPanel"

export const isPanel = (panel: iPanel): panel is iPanel => {
  const hasID = !!panel?.id
  const hasValidType = Object.values(AssignmentType).includes(panel.type as AssignmentType);
  const hasColSpan = !!panel?.colSpan
  return hasID && hasValidType && hasColSpan
}

export const isGridPanel = (panel?: iPanel): panel is iPanelGrid => {
  return isDefined(panel) && panel?.type === AssignmentType.GRID
}

export const isTargetPanel = (panel?: iPanel): panel is iPanelTarget => {
  return isDefined(panel) && panel?.type === AssignmentType.TARGET
}

export const isSpecSwapPanel = (panel?: iPanel): panel is iPanelSpecSwap => {
  return isDefined(panel) && panel?.type === AssignmentType.SPEC_SWAP
}

export const isImagePanel = (panel?: iPanel): panel is iPanelImage => {
  return isDefined(panel) && panel?.type === AssignmentType.IMAGE
}

export const isSpacerPanel = (panel?: iPanel): panel is iPanelSpacer => {
  return isDefined(panel) && panel?.type === AssignmentType.SPACER
}

export const isTextPanel = (panel?: iPanel): panel is iPanelText => {
  return isDefined(panel) && panel?.type === AssignmentType.TEXT
}

export const isVideoPanel = (panel?: iPanel): panel is iPanelVideo => {
  return isDefined(panel) && panel?.type === AssignmentType.VIDEO
}
