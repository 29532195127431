import { mergeAttributes, Node } from "@tiptap/core"

export interface EmojiOptions {
  HTMLAttributes: Record<string, any>
}

export const Emoji = Node.create<EmojiOptions>({
  name: "emoji",

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      name: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      node.attrs.name
    ]
  }
})
