import { isArrayWithLength, iTeam, iTeamBonus } from "typings"


export const getTeamBonuses = (team: iTeam) => {
  const bonuses = (team?.premium?.bonuses || [])
    .filter((bonusID) => !!teamBonuses?.[bonusID])
    .map((bonusID) => teamBonuses?.[bonusID])
    .sort((a, b) => a.id > b.id ? 1 : -1)
  return bonuses
}

export const addTeamBonuses = (totalBoosts: number, team: iTeam): number => {
  if(!team?.premium?.bonuses) return totalBoosts
  if(isArrayWithLength(team?.premium?.bonuses) === false) return totalBoosts

  const bonuses = getTeamBonuses(team)

  for (const bonus of bonuses) {
    totalBoosts = bonus.calculateBoostCallback(totalBoosts)
  }
  return totalBoosts
}

export const getTeamTotalBoosts = (team: iTeam) => {
  const boostsReceived = team?.premium?.boostsReceived || {}

  const totalBoostsReceived = Object.values(boostsReceived).reduce(
    (acc, item) => {
      return acc + item
    },
    0
  )

  const afterBonuses = addTeamBonuses(totalBoostsReceived, team)

  return afterBonuses
}

export const teamBonuses: Record<string, iTeamBonus> = {
  // 1xx - additive
  "100": {
    id: "100",
    label: "Very cool team",
    description: "Adds 100 team boosts.",
    emoji: "👍",
    calculateBoostCallback: (boosts) => {
      return boosts + 100
    }
  },

  // 4xx - multiplicative
  "400": {
    id: "400",
    label: "Alpha tester",
    description: "Boosts multiplied by 1.5x.",
    emoji: "🐛",
    calculateBoostCallback: (boosts) => {
      return Math.ceil(boosts * 1.5)
    }
  },
}