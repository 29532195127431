import axios from 'axios'
import cookie from 'cookie'

export interface iDiscordAccessToken {
  access_token: string
  expires_in: number
  refresh_token: string
  scope: string
  token_type: string
}

export interface iDiscordCookie extends iDiscordAccessToken {
  expires: number // unix MS
}

export type iOAuthConfig = {
  host: string
  tokenCacheID: string
  clientID: string
  clientSecret: string
}

export const discordTokenExpired = (cookie: iDiscordCookie) => {
  if(!cookie?.expires) return true
  return Date.now() > cookie.expires
}

export const discordAccessTokenToDiscordCookie = (token: iDiscordAccessToken): iDiscordCookie => {
  const discordCookie: iDiscordCookie = {
    ...token,
    expires: Date.now() + 1000 * token.expires_in,
  }
  return discordCookie
}

export const getRefreshedDiscordCookieString = async (cookie: iDiscordCookie) => {
  if(!cookie?.refresh_token) throw new Error("cookie does not have refresh token")
  if(!process.env.DISCORD_CLIENT_ID) throw new Error("No Discord client id")
  if(!process.env.DISCORD_SECRET) throw new Error("No Discord client secret")

  const response = await axios.post(
    `https://discord.com/api/oauth2/token`,
    new URLSearchParams({
      client_id: process.env.DISCORD_CLIENT_ID,
      client_secret: process.env.DISCORD_SECRET,
      grant_type: "refresh_token",
      refresh_token: cookie.refresh_token,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  )

  if(!response.data?.access_token) throw new Error("Discord OAuth response invalid")
  const discordCookie = discordAccessTokenToDiscordCookie(response.data as iDiscordAccessToken)

  return {
    cookieString: discordAccessTokenToCookieString(discordCookie),
    refreshedCookie: response.data
  }
}

export const discordAccessTokenToCookieString = (discordCookie: iDiscordCookie) => {
  return cookie.serialize('discordToken', JSON.stringify(discordCookie), {
    secure: process.env.CONTEXT !== 'dev',
    domain: process.env.CONTEXT === 'dev' ? 'localhost' : '.prepi.io',
    httpOnly: true,
    sameSite: true,
    // maxAge: discordToken.expires_in,
    // expires: new Date(Date.now() + (1000 * discordToken.expires_in))
  })
}

export const netlifyFunctionRefreshedTokenReturn = (cookieString: string, statusCode = 200) => {
  return {
    statusCode,
    headers: {
      "Cache-Control": "no-cache",
    },
    multiValueHeaders: {
      "Set-Cookie": [cookieString],
    },
  }
}

export enum eActionRequired {
  RENEW_FIREBASE_AUTH_TOKEN = "renew-fb-auth-token",
  PROMPT_CUSTOM_FIELDS = "prompt-custom-fields",
  PROMPT_ROSTER_CATEGORY = "prompt-roster-category",
  CREATE_CHAR = "create-char"
}