import { eFeature } from "typings"

export const getUserPremiumField = () => {
  if(process.env.CONTEXT === "dev") return "premiumDev"
  if(process.env.NODE_ENV === "development") return "premiumDev"
  return "premium"
}

export enum ePrepiFeature {
  CGA = "clean group assigns"
}

export type iPriceTier = {
  family: string
  label: string
  labelShort: string
  priceID?: string
  priceMonthly: number
  priceMonthlyBeforeDiscount: number
  // description: string
  // summaryFeatures: string[]
  intervalMonths: number
  showInPricing: boolean
  showInSettings: boolean
  boosts: number
  discount: number
}

type stringOrBool = string | boolean
export interface iPricingTableSection {
  name: string
  features: eFeature[]
  // features: {
  //   name: string
  //   tiers: { core?: stringOrBool; plus?: stringOrBool; pro?: stringOrBool }
  // }[]
}

export const prepiPricingTableFeatures: iPricingTableSection[] = [
  {
    name: "Features",
    features: [
      eFeature.SIGNUPS,
      eFeature.ROSTER_CATEGORIES,
      eFeature.MULTI_CHAR_SIGNUPS,
      eFeature.MULTI_SPEC_SIGNUPS,
      eFeature.LIVE_UPDATES,
      eFeature.PRE_MADE_TEMPLATES,
      eFeature.PLACEHOLDERS,
      eFeature.ROLES,
      eFeature.ONLY_MY_ASSIGNMENTS,
      eFeature.REPLACE_WITH,
      eFeature.SMART_LISTS,
      eFeature.CUSTOM_SMART_LISTS,
      eFeature.CUSTOM_FIELDS,
    ]
  },
  {
    name: "Discord bot",
    features: [
      eFeature.DISCORD_BOT,
      eFeature.DISCORD_SIGNUPS,
      eFeature.DISCORD_LINK_SIGNUP,
      eFeature.DISCORD_EMBEDS_INSTANT_SYNC
    ]
    // [
    //   { name: "Discord bot", tiers: { core: true, plus: true, pro: true } },
    //   { name: "Signup through Discord", tiers: { core: true, plus: true, pro: true } }, // TODO:
    //   {
    //     name: "Create new or Link to existing signup",
    //     tiers: { core: "Unlimited", plus: "Unlimited", pro: "Unlimited" } // TODO:
    //   },
    // ]
  },
  {
    name: "Sync",
    features: [eFeature.PROMOTE_ADMINS, eFeature.COLLABORATIVE_EDITING]
    // [
    //   { name: "Promote admins", tiers: { core: true, plus: true, pro: true } },
    //   { name: "Collaborative editing", tiers: { core: true, plus: true, pro: true } }
    // ]
  },
  {
    name: "Automations",
    features: [eFeature.AUTOMATIONS]
    // [ TODO:
    //   {
    //     name: "Manually trigger whole automation or individual sequence",
    //     tiers: { plus: true, pro: true }
    //   },
    //   { name: "Schedule sequences to run at specific times", tiers: { plus: true, pro: true } },
    //   { name: "Create signups from templates", tiers: { plus: true, pro: true } },
    //   { name: "Archive signups", tiers: { plus: true, pro: true } },
    //   { name: "Post signups in Discord channel", tiers: { plus: true, pro: true } },
    //   { name: "Post messages in Discord channel", tiers: { plus: true, pro: true } },
    //   { name: "Send messages to admins", tiers: { plus: true, pro: true } },
    //   { name: "Request confirmation from members", tiers: { plus: true, pro: true } }
    // ]
  },
  {
    name: "Split signups",
    features: [eFeature.SPLIT_SIGNUPS]
    // [ TODO:
    //   { name: "Replace all weekly signups with one", tiers: { plus: true, pro: true } },
    //   { name: "Members sign up to time windows", tiers: { plus: true, pro: true } },
    //   { name: "Admins create runs inside time windows", tiers: { plus: true, pro: true } }
    // ]
  }
  // {
  //   name: 'Support',
  //   features: [
  //     { name: 'Community support.', tiers: { Core: true, plus: true, pro: true } },
  //     { name: 'Premium support', tiers: { plus: true, pro: true } },
  //   ],
  // },
]

export const getPriceTiers = () => {
  const isDev = process.env.NODE_ENV === 'development'
  
  const prepiPriceTiers: iPriceTier[] = [
    {
      family: "free",
      label: "Free",
      labelShort: "Free",
      priceID: "",
      priceMonthly: 0,
      priceMonthlyBeforeDiscount: 0,
      intervalMonths: 0,
      showInPricing: false,
      showInSettings: false,
      boosts: 0,
      discount: 0
    },
    {
      family: "boosts",
      label: "1 Boost - Quarterly",
      labelShort: "1 Boost",
      priceID: isDev ? "price_1MPQ3qEC5jM3mOW7i0i49l6L" : "price_1MQAuZEC5jM3mOW7Dw0SCAzi",
      priceMonthly: 2,
      priceMonthlyBeforeDiscount: 2,
      intervalMonths: 3,
      showInPricing: true,
      showInSettings: true,
      boosts: 1,
      discount: 0
    },
    {
      family: "boosts",
      label: "1 Boost - Yearly",
      labelShort: "1 Boost",
      priceID: isDev ? "price_1MPQ3qEC5jM3mOW7OTNTdYLC" : "price_1MQAuZEC5jM3mOW7ghcBA3oZ",
      priceMonthly: 1.5,
      priceMonthlyBeforeDiscount: 2,
      intervalMonths: 12,
      showInPricing: true,
      showInSettings: true,
      boosts: 1,
      discount: 0.25
    },
    {
      family: "boosts",
      label: "3 Boosts - Quarterly",
      labelShort: "3 Boosts",
      priceID: isDev ? "price_1MQ8oOEC5jM3mOW745zsCusx" : "price_1MQAweEC5jM3mOW7XCobw1Z5",
      priceMonthly: 5.3,
      priceMonthlyBeforeDiscount: 6,
      intervalMonths: 3,
      showInPricing: true,
      showInSettings: true,
      boosts: 3,
      discount: 0.1
    },
    {
      family: "boosts",
      label: "3 Boosts - Yearly",
      labelShort: "3 Boosts",
      priceID: isDev ? "price_1MQ8oOEC5jM3mOW7jtoCRIMH" : "price_1MQAweEC5jM3mOW7NZzxAGhJ",
      priceMonthly: 4,
      priceMonthlyBeforeDiscount: 6,
      intervalMonths: 12,
      showInPricing: true,
      showInSettings: true,
      boosts: 3,
      discount: 0.33
    },
  ]
  return prepiPriceTiers
}