import { CustomError, ER404_NOT_FOUND } from 'functions';
import { eFSColl, iRaid, iRaidData } from 'typings';
import { firebase } from '../client';
import { standardCRUD } from '../documentAPI';

const multidocAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const signupAPI = standardCRUD<iRaid>(eFSColl.SIGNUPS, db, deleteField)
  const raidDataAPI = standardCRUD<iRaidData>(eFSColl.RAIDDATA, db, deleteField)
  
  const getSignupAndRaidData = async (signupID: string) => {
    const [signup, raidData] = await Promise.all([
      signupAPI.get(signupID),
      raidDataAPI.get(signupID)
    ])
    if(!signup) throw new CustomError(ER404_NOT_FOUND)
    return { signup, raidData }
  }

  return {
    getSignupAndRaidData
  };
}

export default multidocAPI;