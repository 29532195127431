import { transform, isEqual, isArray, isObject } from 'lodash';

export const isTouchDevice = () => {
  if ("ontouchstart" in window || window.TouchEvent)
      return true;

  if (window.DocumentTouch && document instanceof DocumentTouch)
      return true;

  const prefixes = ["", "-webkit-", "-moz-", "-o-", "-ms-"];
  const queries = prefixes.map(prefix => `(${prefix}touch-enabled)`);

  return window.matchMedia(queries.join(",")).matches;
}

export const isTouchDeviceTwo = () => {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error);
    }
    return
  }

  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return clipboardData.setData('Text', text)
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}



// AUTHOR: Helder Avila
// LINK: https://www.codegrepper.com/code-examples/javascript/javascript+compare+two+objects+and+get+differences
export const diff = (origObj, newObj) => {
  if(!(origObj && newObj)) return
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}