import { eDuplicateKeep } from "../../types/enum";
import { allDefinedInArray } from "../../types/typeGuards";
import { createRaidID } from "../raid/raid";

export type iActionChannelLocation = {
  channelLocation: string // "guildID.channelID"
  channelLocationLabels: {
    guild: string,
    channel: string
  }
}

// action
export type MessageRecipient = {
  displayName: string
  discordID: string
}
export type AutomationAction = actionUseTemplate | actionSendDM | actionPostInChannel | actionArchiveSignup | actionRequestConfirmation | actionPostSignups
export enum eActionType {
  USE_TEMPLATE = "use template",
  SEND_DM = "send dm",
  POST_SIGNUPS = "post signups",
  POST_IN_CHANNEL = "post in channel",
  REQUEST_CONFIRMATION = "request confirmation",
  ARCHIVE = "archive"
}

export type actionType =
  | eActionType.USE_TEMPLATE
  | eActionType.SEND_DM
  | eActionType.POST_IN_CHANNEL
  | eActionType.POST_SIGNUPS
  | eActionType.ARCHIVE
  | eActionType.REQUEST_CONFIRMATION;

export interface actionUseTemplate {
  type: eActionType.USE_TEMPLATE
  templateID: string
  templateDurationMS: number
  keepFromTemplate: eDuplicateKeep[]
  newTitle: string
  delayBy: string
  newSignupID: string
}

export interface actionSendDM {
  type: eActionType.SEND_DM
  message: string
  messageRecipients: MessageRecipient[]
}

export interface actionPostInChannel extends iActionChannelLocation {
  type: eActionType.POST_IN_CHANNEL
  message: string
}

export interface actionPostSignups extends iActionChannelLocation {
  type: eActionType.POST_SIGNUPS
  newSignupID: string
}

export interface actionRequestConfirmation extends iActionChannelLocation {
  type: eActionType.REQUEST_CONFIRMATION
  newSignupID: string
}

export interface actionArchiveSignup {
  type: eActionType.ARCHIVE
  newSignupID: string
}

export const isUseTemplateAction = (action?: AutomationAction): action is actionUseTemplate => {
  return action?.type === eActionType.USE_TEMPLATE
}

export const isSendDMAction = (action?: AutomationAction): action is actionSendDM => {
  return action?.type === eActionType.SEND_DM
}

export const isPostInChannelAction = (action?: AutomationAction): action is actionPostInChannel => {
  return action?.type === eActionType.POST_IN_CHANNEL
}

export const isPostSignupsAction = (action?: AutomationAction): action is actionPostSignups => {
  return action?.type === eActionType.POST_SIGNUPS
}

export const isRequestConfirmationAction = (action?: AutomationAction): action is actionRequestConfirmation => {
  return action?.type === eActionType.REQUEST_CONFIRMATION
}

export const isArchiveAction = (action?: AutomationAction): action is actionArchiveSignup => {
  return action?.type === eActionType.ARCHIVE
}

export const Action = () => {

  const create = (action: AutomationAction) => {
    console.log(action)
    if(action.type === eActionType.USE_TEMPLATE){
      const { type, templateID, newTitle, delayBy, templateDurationMS, keepFromTemplate, newSignupID } = action
      if(!allDefinedInArray([templateID, newTitle, delayBy, templateDurationMS, keepFromTemplate])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionUseTemplate = {
        type, templateID, newTitle, delayBy, templateDurationMS, keepFromTemplate, newSignupID: newSignupID || createRaidID()
      }
      return ac
    }
    
    if(action.type === eActionType.SEND_DM){
      const { type, message, messageRecipients } = action
      if(!allDefinedInArray([message, messageRecipients])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionSendDM = {
        type, messageRecipients, message
      }
      return ac
    }
    
    if(action.type === eActionType.POST_IN_CHANNEL){
      const { type, message, channelLocation, channelLocationLabels } = action
      if(!allDefinedInArray([message, channelLocation, channelLocationLabels])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionPostInChannel = {
        type, channelLocation, message, channelLocationLabels
      }
      return ac
    }

    if(action.type === eActionType.POST_SIGNUPS){
      const { type, newSignupID, channelLocation, channelLocationLabels } = action
      if(!allDefinedInArray([newSignupID, channelLocation, channelLocationLabels])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionPostSignups = {
        type, channelLocation, newSignupID, channelLocationLabels
      }
      return ac
    }
    
    if(action.type === eActionType.REQUEST_CONFIRMATION){
      const { type, newSignupID, channelLocation, channelLocationLabels } = action
      if(!allDefinedInArray([newSignupID, channelLocation, channelLocationLabels])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionRequestConfirmation = {
        type, channelLocation, newSignupID, channelLocationLabels
      }
      return ac
    }
    
    if(action.type === eActionType.ARCHIVE){
      const { type, newSignupID } = action
      if(!allDefinedInArray([newSignupID])) throw new Error("Action.create - not all variables defined")
      
      const ac: actionArchiveSignup = {
        type, newSignupID
      }
      return ac
    }

    throw new Error("Action.create - Unknown type")
  }

  return {
    create
  }
}