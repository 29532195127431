import { Extensions, JSONContent, TextSerializer, getSchema } from "@tiptap/react"
import { Node, Schema } from "@tiptap/pm/model";

// from tiptap library, had to modify because rendering non-text nodes were bugged
export function generateText(
  doc: JSONContent,
  extensions: Extensions,
  options?: {
    blockSeparator?: string
    textSerializers?: Record<string, TextSerializer>
  },
): string {
  const { blockSeparator = '\n', textSerializers = {} } = options || {}
  const schema = getSchema(extensions)
  const contentNode = Node.fromJSON(schema, doc)

  return getText(contentNode, {
    blockSeparator,
    textSerializers: {
      ...getTextSerializersFromSchema(schema),
      ...textSerializers,
    },
  })
}

export function getTextSerializersFromSchema(schema: Schema): Record<string, TextSerializer> {
  return Object.fromEntries(
    Object.entries(schema.nodes)
      .filter(([, node]) => node.spec.toText)
      .map(([name, node]) => [name, node.spec.toText]),
  )
}

export function getText(
  node: Node,
  options?: {
    blockSeparator?: string
    textSerializers?: Record<string, TextSerializer>
  },
) {
  const range = {
    from: 0,
    to: node.content.size,
  }

  return getTextBetween(node, range, options)
}

type Range = { from: number, to: number }
export function getTextBetween(
  startNode: Node,
  range: Range,
  options?: {
    blockSeparator?: string
    textSerializers?: Record<string, TextSerializer>
  },
): string {
  const { from, to } = range
  const { blockSeparator = '\n', textSerializers = {} } = options || {}
  let text = ''
  let separated = true

  startNode.nodesBetween(from, to, (node, pos, parent, index) => {
    const textSerializer = textSerializers?.[node.type.name]
    if (textSerializer) {
      if (node.isBlock && !separated) {
        console.log("+")
        text += blockSeparator
        separated = true
      }

      if (parent) {
        text += textSerializer({
          node,
          pos,
          parent,
          index,
          range,
        })
        separated = false // added
      }
    } else if (node.isText) {
      text += node?.text?.slice(Math.max(from, pos) - pos, to - pos) // eslint-disable-line
      separated = false
    } else if (node.isBlock && separated === false) {
      text += blockSeparator
      separated = true
    }
  })

  return text
}