import { newID } from '../../types/typeUtils';

export interface iRole {
  id: string
  roleName: string
  memberTargetID: string  // role drop target
}

export const Role = () => {

  const create = (roleName: string, memberTargetID: string) => {
    const roleID = "role-" + newID(process.env.NODE_ENV === "development" ? 3 : 10)

    const roleObj: iRole = {
      id: roleID,
      roleName: roleName,
      memberTargetID,
    };

    return roleObj;
  };


  return ({
    // no protection
    create,
  })
}