import copy from "fast-copy";
import { Areas, charID, eDragType, eMemberType, eRequired, GameRole, gameRoleType, iCharacter, iLocation, iLocationClassSpec, iMember, iMemberCharAlternatives, iRaid, iRaidCreation, iRaidData, iSignupLogItem, iSplitSignup, isTeamOwned, isUserOwned, iTarget, iUser, members, RaidAdmin, raidGroupOrder, SignupStates, Target } from "typings";
import { clearMembersFromRuns, findRunsIn } from "../split/splitUtilities";
import { getAltSpecVariants, getMemberChars } from "../user/userUtilities";

export const isSignedMember = (member: iMember) => {
  if(member.signupState === SignupStates.NONE) return false
  if(member.signupState === SignupStates.ABSENT) return false
  if(member.signupState === SignupStates.GHOST) return false
  return true
}

export const isUnsignedMember = (member: iMember) => {
  if(member.signupState === SignupStates.NONE) return true
  if(member.signupState === SignupStates.ABSENT) return true
  if(member.signupState === SignupStates.GHOST) return true
  return false
}

export const memberPossiblyPresent = (signupState?: SignupStates) => {
  if(!signupState) return false
  if(signupState === SignupStates.NONE) return false
  if(signupState === SignupStates.ABSENT) return false
  return true
}

export const sortCharRoleClassSpecName = (a?: iCharacter, b?: iCharacter) => {
  if(!a?.charName || !b?.charName) {
    console.error("sortCharRoleClassSpecName member without character");
    return -1
  }

  const roleOrder = Object.values(GameRole);
  const roleComparison = roleOrder.indexOf(a.gameRole) - roleOrder.indexOf(b.gameRole);
  if (roleComparison !== 0) return roleComparison;

  const classComparison = a.charClass.localeCompare(b.charClass, undefined, { sensitivity: 'base' });
  if (classComparison !== 0) return classComparison;

  const specComparison = a.charSpec.localeCompare(b.charSpec, undefined, { sensitivity: 'base' });
  if (specComparison !== 0) return specComparison;

  return a.charName.localeCompare(b.charName, undefined, { sensitivity: 'base' });
}

export const fixRaidGrouporder = (r: iRaid) => {
  const raid = copy(r)
  if(!raid?.groupOrder) raid.groupOrder = [...Array(raid.maxSize)].map(i => null)
    
  // assign groupOrder to unAssigned
  const unAssignedMemberIDs = Object.values(raid?.members)
    .filter(member => member.onRoster === true)
    .filter(member => !raid.groupOrder.includes(member.userID))
    .sort((a, b) => sortCharRoleClassSpecName(a?.character, b?.character))
    .map(member => member.userID);

  // check groups for unsigned members
  const removeIndexes: (number | null)[] = raid.groupOrder
    .map((spot, index) => {
      if(spot === null) return null
      const member = raid.members[spot]
      if (!member) return index
      if (member?.onRoster !== true) return index
      if (isUnsignedMember(member)) return index

      // remove duplicates
      const alreadyExistsIndex = raid.groupOrder.findIndex((_) => _ === spot)
      const hasIndex = alreadyExistsIndex !== -1
      const isntThisIndex = alreadyExistsIndex !== index
      const indexIsBigger = index > alreadyExistsIndex
      if (hasIndex && isntThisIndex && indexIsBigger) return index
      return null
    })
    .filter(spot => spot !== null)

  // remove unsigned members from groups
  raid.groupOrder = raid.groupOrder.map((memberID, index) => removeIndexes.includes(index) ? null : memberID)
  
  // assign roster members to groups
  for (let i = 0; i < raid.groupOrder.length; i++) {
    if(raid.groupOrder[i] !== null) continue;
    if(!unAssignedMemberIDs[0]) break;
    raid.groupOrder[i] = unAssignedMemberIDs.splice(0,1)[0]
  }

  return raid
}

export const clearSignupsKeepAdmins = (raid: iRaid, saveIDs?: string[]): {
  members: members,
  groupOrder: raidGroupOrder
  splitData: iSplitSignup | null
} => {
  
  // keep admin members
  const saveUserIDs = Object.keys(raid.admins)
  if(saveIDs) saveUserIDs.push(...saveIDs)
  if(isUserOwned(raid.owner)) saveUserIDs.push(raid.owner.userID)
  const ownerMember = Object.values(raid.members).find(
    (member) => member?.discordID === raid?.owner?.discordID
  )
  if(!!ownerMember && !saveUserIDs.includes(ownerMember.userID)) saveUserIDs.push(ownerMember.userID)

  const newMembers = copy(raid.members)
  
  for (const [userID, member] of Object.entries(newMembers)) {
    if(saveUserIDs.includes(userID)) {
      // is admin
      member.signupState = SignupStates.NONE
      member.isConfirmed = false
      member.onRoster = false
      member.altCharacters = []
      delete member.character
      delete member.lateMax
      continue;
    }
    delete newMembers[userID]
  }

  if(raid?.splitData){
    clearMembersFromRuns(raid?.splitData)
  }

  return { members: newMembers, groupOrder: raid.groupOrder.map(o => null), splitData: raid?.splitData || null }
}

export const getAlternateCharactersFrom = (members: iMember[]): iCharacter[] => {
  if(!members) return []
  const alternates = members.reduce((acc, item) => {
    if(!item.altCharacters) return acc
    return [...acc, ...item.altCharacters]
  }, [] as iCharacter[])
  return alternates
}

export const spreadCharsByGameRole = (chars: iCharacter[]) => {
  const obj: { [gameRole in gameRoleType]?: iCharacter[] } = {}
  for (const char of chars) {
    const arr = obj[char.gameRole] || []
    obj[char.gameRole] = [...arr, char]
  }
  return obj
}

export const getSignupPingCategories = (signup: iRaid, selectedTimeWindow?: string) => {
  const members = signup.members
  const categories: Record<string, iMember[]> = {
    "all": [],
    "roster": [],
    "bench": [],
    "unconfirmed": [],
    "standby": [],
  }

  const addToArraysNormalSignup = () => {
    for (const member of Object.values(members)) {
      try {
        if(!member?.discordID || member.userID?.startsWith("placeholder-member-") === true) continue;
        if(!member?.character?.charName) continue;
        
        categories.all.push(member)
    
        if(member.onRoster) {
          categories.roster.push(member)
          if(!member.isConfirmed) categories.unconfirmed.push(member)
          continue;
        }
    
        if(isSignedMember(member)) categories.bench.push(member)
    
        if(member.isStandby) {
          categories.standby.push(member)
          if(!member.isConfirmed) categories.unconfirmed.push(member)
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const addToArraysSplitSignup = () => {
    if(!signup?.splitData || !selectedTimeWindow) return
    const runs = findRunsIn(signup.splitData, selectedTimeWindow)
    const runCharIDs = runs.reduce((acc, run) => [...acc, ...run.run.members], [] as charID[])

    for (const member of Object.values(members)) {
      try {
        if(!member?.discordID) continue;
        if(!member?.character?.charName) continue;
        
        categories.all.push(member)

        // roster
        if(!!runCharIDs.find(charID => charID.memberID === member.userID)){
          categories.roster.push(member)
          // unconfirmed in run
          if(member?.eligibility?.[selectedTimeWindow]?.isConfirmed !== true){
            categories.unconfirmed.push(member)
          }
          continue;
        }

        // bench
        categories.bench.push(member)

        // standby
        if(member?.eligibility?.[selectedTimeWindow]?.isStandby === true){
          categories.standby.push(member)
          // unconfirmed standby
          if(member?.eligibility?.[selectedTimeWindow]?.isConfirmed !== true){
            categories.unconfirmed.push(member)
          }
        }
    
      } catch (error) {
        console.error(error);
      }
    }
  }

  if(!!signup?.splitData) {
    addToArraysSplitSignup()
  } else {
    addToArraysNormalSignup()
  }
  
  return categories
}

export const membersToPingString = (members: iMember[]) => {
  const memberDiscordIDs = members
        .map((m) => m.discordID)
        .filter((m) => !!m) as string[]

  return `||${memberDiscordIDs.map(memberID => `<@${memberID}>`)}||`
}

export const getCharInMember = (member: iMember, charName: string): iCharacter | null => {
  const chars = getMemberChars(member)
  const char = chars.find(char => char.charName === charName)
  if(!char) return null

  return char
}

export const findCharInMembers = (charID: charID, members: members): iCharacter | null => {

  const member = members?.[charID.memberID]
  if(!member) return null
  
  return getCharInMember(member, charID.charName)
}

export const memberToChars = (member: iMember, includeMain = true, inludeAlts = true) => {
  const arr: iCharacter[] = []
  if(includeMain && member?.character) arr.push(member?.character)
  if(inludeAlts && member?.altCharacters) arr.push(...member?.altCharacters)
  return arr
}

export const memberToCharIDs = (member: iMember, includeMain = true, includeAlts = true) => {
  const arr: charID[] = memberToChars(member, includeMain, includeAlts).map((char) => ({
    memberID: member.userID,
    charName: char.charName
  }))
  return arr
}

export const membersToCharIDs = (memberArr: iMember[], filter?: (member: iMember) => boolean): charID[] => {
  const charIDs: charID[] = []

  for (const member of Object.values(memberArr)) {
    if(!!filter && typeof filter === "function" && filter(member) === false) {
      continue;
    }
    charIDs.push(...memberToCharIDs(member))
  }

  return charIDs
}

export const getUpdatedMembers = (members: members, lastMemberCheck: number): iMember[] => {
  return Object.values(members).filter((member) => member.lastUpdate > lastMemberCheck)
}

export const charIDsToNewTargets = (charIDs: charID[]) => {
  const newTargets: iTarget[] = []

  for (const charID of charIDs) {
    if(!charID.charName || !charID.memberID) continue;
    const memberTargetIDstring = `${Areas.ROSTER}-${charID.memberID}-${charID.charName}`
    const target = Target().create({
      area: Areas.ROSTER, 
      containType: eDragType.MEMBER, 
      containID: charIDToString(charID), 
      targetIDstr: memberTargetIDstring
    })
    newTargets.push(target)
  }

  return newTargets
}

export const addLogItem = (signup: iRaid, logItem: iSignupLogItem) => {
  signup.log = {
    ...signup?.log,
    [Date.now().toString()]: logItem
  }
}

export const getMemberGameRoleAlternatives = (members: members, location: iLocationClassSpec, gameRole: string, ignoreAlts = false) => {
  const memberToVariants: {
    [memberID: string]: iMemberCharAlternatives
  } = {}
  for (const member of Object.values(members)) {
    const memberAlternatives = getAltSpecVariants(member, location, gameRole, ignoreAlts)
    if(memberAlternatives === null) continue;
    memberToVariants[member.userID] = memberAlternatives
  }
  return memberToVariants
}

export const applyDefaultsToSignup = (s: iRaid, defaults: Partial<iRaidCreation>, location: iLocation): iRaid => {
  const signup: iRaid = Object.assign({}, s, defaults)
  // @ts-ignore
  if(signup.keep) delete signup.keep
  // @ts-ignore
  if(signup.template) delete signup.template
  if(location.signupRules.server === eRequired.NEVER){
    delete signup.server
  }
  return signup
}

export const filterSignupsOnOwner = (signups: iRaid[], ownerType: "user" | "team", ownerID: string) => {
  return signups.filter(signup => {
    if(signup.owner.type !== ownerType) return false
    if(isTeamOwned(signup.owner) && signup.owner.teamID === ownerID) return true
    if(isUserOwned(signup.owner) && signup.owner.userID === ownerID) return true
    return false
  })
}

export const getMemberType = (member: iMember) => {
  if(member?.type) return member.type
  if(member?.userID?.startsWith("placeholder")) return eMemberType.PLACEHOLDER
  return eMemberType.MEMBER
}

export const checkIsSigned = (signup: iRaid, userID: string) => {
  return (
    -1 !==
    Object.values(signup?.members)
      .filter((member) => memberPossiblyPresent(member.signupState))
      .findIndex((member) => member.userID === userID)
  )
}

export const serverText = (serverString?: string, showRegion = true) => {
  if(!serverString) return ""
  const [server, region] = serverString.split("::");
  if(!!region){
    return showRegion ? `${server} ${region.toUpperCase()}` : server
  }
  return server
}

export const charIDToString = (charID: charID): string => {
  if(!charID?.charName) return charID.memberID
  return `${charID.memberID}_${charID.charName}`
}

export const stringToCharID = (str: string | null): charID => {
  const [ memberID = '', charName = '' ] = str?.split('_') ?? [];
  return { memberID, charName };
};

export const transferSignupOwnership = (raid: iRaid, user: iUser): iRaid => {

  const oldOwner = copy(raid.owner)

  // if(isTeamOwned(raid.owner) && !isPublicTemplate) {
  //   throw new Error("Cannot transfer ownership of a team owned document.")
  // }

  const newOwner: RaidAdmin = {
    type: "user",
    userID: user.userID,
    discordID: user.discordID,
    displayName: user.displayName
  }
  
  raid.owner = newOwner
  if(raid.admins[user.userID]) {
    delete raid.admins[user.userID]
  }
  if(isUserOwned(oldOwner)) raid.admins[oldOwner.userID] = oldOwner
  raid.admins = {}
  
  return raid
}

export const removeAllMembersFromRoster = (members: members) => {
  for (const member of Object.values(members)) {
    member.onRoster = false
    member.isConfirmed = false
  }
}

export const filterRaidDataNosync = (rd: iRaidData): iRaidData => {
  for (const target of Object.values(rd.targets)) {
    if(target?.noSync) {
      delete rd.targets[target.id]
    }
  }
  for (const list of Object.values(rd.lists)) {
    if(list?.noSync) {
      delete rd.lists[list.id]
    }
  }
  return rd
}