import { eFeature, iTeam } from "typings"

export const findHighestCurrentBoostTier = (hasBoosts: number): iTeamBoostTier => {
  const nonLegacyTiers = Object.values(prepiTeamBoostTiers)
    .filter(tier => {
      if(tier.legacy === true) return false
      return tier.boostsRequired <= hasBoosts
    })
    .sort((a, b) => a.boostsRequired < b.boostsRequired ? 1 : -1)
  if(nonLegacyTiers?.length < 1) throw new Error(`Couldn't find any boost tiers. User has ${hasBoosts} boosts.`)
  return nonLegacyTiers[0]
}

export const boostTierToAccess = (boostTierID: string): iFeatureAccess => {
  const boostTier = prepiTeamBoostTiers?.[boostTierID]
  if(!boostTier) return coreFeatures
  return boostTier.features
}

export const getFreeTierAccess = (): iFeatureAccess => {
  return prepiTeamBoostTiers?.["CORE"]?.features
}

export const findLowestTierThatHasAccessTo = (feature: eFeature): iTeamBoostTier => {
  const tiers = Object.values(prepiTeamBoostTiers)
    .filter(tier => tier.legacy === false && !!tier.features?.[feature])
    .sort((a, b) => a.boostsRequired > b.boostsRequired ? 1 : -1)
  if(tiers?.length < 1) throw new Error(`No tier have access to feature: ${feature}.`)
  return tiers[0]
}

export enum eBoostTierID {
  CORE = 'CORE',
  FOUNDER = 'FOUNDER',
}

export type iFeatureAccessLevel = boolean | number

export type iFeatureAccess = {
  [permission in eFeature]?: iFeatureAccessLevel
}

export type iTeamBoostTier = {
  id: string
  label: string
  labelShort: string
  boostsRequired: number
  legacy: boolean
  showInPricingPage: boolean
  description: string
  features: iFeatureAccess
}

const coreFeatures: iFeatureAccess = {
  [eFeature.VOID]: true,
  
  [eFeature.DISCORD_EMBEDS_INSTANT_SYNC]: 1,

  [eFeature.SIGNUPS]: true,
  [eFeature.ROSTER_CATEGORIES]: true,
  [eFeature.MULTI_CHAR_SIGNUPS]: true,
  [eFeature.MULTI_SPEC_SIGNUPS]: true,
  [eFeature.LIVE_UPDATES]: true,
  [eFeature.PRE_MADE_TEMPLATES]: true,
  [eFeature.PLACEHOLDERS]: true,
  [eFeature.ROLES]: true,
  [eFeature.ONLY_MY_ASSIGNMENTS]: true,
  [eFeature.REPLACE_WITH]: true,
  [eFeature.SMART_LISTS]: true,

  [eFeature.DISCORD_BOT]: true,
  [eFeature.DISCORD_SIGNUPS]: true,
  [eFeature.DISCORD_LINK_SIGNUP]: true,

  [eFeature.PROMOTE_ADMINS]: true,
  [eFeature.COLLABORATIVE_EDITING]: true,
}

const lvlOneFeatures: iFeatureAccess = {
  [eFeature.AUTOMATIONS]: true,
  [eFeature.DISCORD_EMBEDS_INSTANT_SYNC]: 3,
  [eFeature.SPLIT_SIGNUPS]: true,
  [eFeature.CUSTOM_SMART_LISTS]: true,
  [eFeature.CUSTOM_FIELDS]: true,
}

export type iTeamTierChange = {
  direction: "down" | "up" | "no-change"
  totalBoosts: number
  team: iTeam
  from: iTeamBoostTier | undefined
  to: iTeamBoostTier
}

export const prepiTeamBoostTiers: Record<string, iTeamBoostTier> = {
  "CORE": {
    id: "CORE",
    label: "Level 0",
    labelShort: "L0",
    boostsRequired: 0,
    legacy: false,
    showInPricingPage: true,
    description: "Everything you need to get started.",
    features: {...coreFeatures}
  },
  "FOUNDER": {
    id: "FOUNDER",
    label: "founder",
    labelShort: "Founder",
    boostsRequired: 100,
    legacy: false,
    showInPricingPage: false,
    description: "Includes everything we offer.",
    features: {...coreFeatures, ...lvlOneFeatures}
  },
  "DEC22-LVL1": {
    id: "DEC22-LVL1",
    label: "Level 1",
    labelShort: "L1",
    boostsRequired: 3,
    legacy: false,
    showInPricingPage: true,
    description: "Extra powerful features to speed up your team.",
    features: {...coreFeatures, ...lvlOneFeatures}
  },
  // "DEC22-LVL2": {
  //   id: "DEC22-LVL2",
  //   label: "Level 2",
  //   labelShort: "L2",
  //   boostsRequired: 6,
  //   legacy: false,
  //   showInPricingPage: false,
  //   description: "Extra powerful features to speed up your team.",
  //   features: {...coreFeatures, ...lvlOneFeatures}
  // },
}
Object.freeze(prepiTeamBoostTiers);