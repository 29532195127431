import { CSSProperties } from "react";

const AccessTag = ({ label = "L1", style }: { label?: string, style?: CSSProperties }) => {
  return (
    <div className={`accessTag ml0`} style={{...style}}>
      <span className="noselect">{label}</span>
    </div>
  );
}

export default AccessTag;