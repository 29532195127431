import { NodeViewProps, NodeViewWrapper, useCurrentEditor } from "@tiptap/react"
import Styles from "./cellRef.module.scss"
import { jotaiStore } from "../../../../pages/_app"
import { getCellRefContentAtom } from "../../../../global/state/raid"
import { CellType } from "typings"
import { capitalize, classNames } from "functions"


interface CellRefComponentProps extends NodeViewProps {
  test: string
}

const CellRefComponent = (props: CellRefComponentProps) => {
  const cellContent = jotaiStore.set(getCellRefContentAtom, props.node.attrs.id)
  const { editor } = useCurrentEditor()

  const getText = () => {
    if(!cellContent) return ""
    if(cellContent.cellType === CellType.TEXT) return cellContent.text
    if(cellContent.cellType === CellType.MEMBER) {
      return capitalize(cellContent?.data?.member?.character?.charName)
    }
    if(props?.node?.attrs?.label) return props.node.attrs.label + ":" + props.node.attrs.id.split(":")[1]
    return props.node.attrs.id.replace("assignment-", "")
  }

  return (
    <NodeViewWrapper className={Styles.wrapper}>
      <div
        className={Styles.container}
        onClick={() => {
          editor?.commands.deleteCurrentNode()
          editor?.commands.insertContent("=")
        }}
      >
        <span
          className={classNames(
            "use-class-container use-inherit-color",
            cellContent?.data?.member?.character?.charClass?.replace(" ", "")
          )}
        >
          {getText()}
        </span>
      </div>
    </NodeViewWrapper>
  )
}

export default CellRefComponent
