import { SignupStates, members, iRaid, eFSColl, PathValue, Path } from 'typings';
import { firebase } from '../client'
import copy from 'fast-copy';
import documentAPI, { standardCRUD } from '../documentAPI';
import { clearSignupsKeepAdmins, getCollectionData } from 'functions';

const raidAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const collectionName = eFSColl.SIGNUPS
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iRaid>(collectionName, db, deleteField)

  const getArchive = async (userID: string): Promise<iRaid[]> => {
    const archivedRaidsRef = db
      .collection(eFSColl.SIGNUPS)
      .where(`members.${userID}.userID`, "==", userID)
      .where(`isArchived`, "==", true);

    const raids = await archivedRaidsRef
      .get()
      .then((response) => {
        return getCollectionData(response) as iRaid[]
      })
      .catch(error => {
        console.error(error)
        return false
      });
    
    if(typeof raids === "boolean") return []
    return raids;
  };
  
  const clearSignups = async (raidID: string, raid: iRaid, saveIDs: string[]) => {
    const { members, groupOrder, splitData } = clearSignupsKeepAdmins(raid, saveIDs)
    const update: Record<string, unknown> = {
      members,
      groupOrder,
      membersCanConfirm: false,
    }
    if(splitData) update.splitData = splitData
    
    await updateMultiPath(raidID, update);
  }

  const ghostifySignups = async (raidID: string, raidMembers: members) => {
    const newMembers = copy(raidMembers)
    
    for (const [userID, member] of Object.entries(newMembers)) {

      member.signupState = member.signupState === SignupStates.NONE ? SignupStates.NONE : SignupStates.GHOST
      member.isConfirmed = false
      member.onRoster = false
      if(member?.lateMax) delete member?.lateMax
    }
    
    await updateMultiPath(raidID, {
      members: newMembers,
      membersCanConfirm: false,
    });
  }

  const deleteAll = async (documentIDs: string[]) => {

    const deleteBoth = async (documentID: string) => {
      const promises: Promise<unknown>[] = [
        documentAPI().raidDataAPI.remove(documentID),
        remove(documentID)
      ]

      await Promise.all(promises)
      return 
    };

    const promises: Promise<unknown>[] = documentIDs.map(docID => deleteBoth(docID))
    await Promise.all(promises)
  }

  return ({
    get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath,
    clearSignups,
    ghostifySignups,
    deleteAll,
    
    getArchive,
  })
}

export default raidAPI;