import { iClass } from "typings";
import { wowSpecs } from "./specs";

const baseWarrior: iClass = {
  gameClass: "warrior",
  iconImgPath: "/img/icon/wowClass/warrior.png",
  colorDark: "#bd8a54",
  emoji: "<:warrior:902216247209496628>",

  specs: {
    arms: wowSpecs.armsWarriorBase,
    fury: wowSpecs.furyWarriorBase,
    protection: wowSpecs.protectionWarriorBase,
  }
}

const baseWarlock: iClass = {
  gameClass: "warlock",
  iconImgPath: "/img/icon/wowClass/warlock.png",
  colorDark: "#957FEF",
  emoji: "<:warlock:902216247607980052>",

  specs: {
    affliction: wowSpecs.afflictionWarlockBase,
    demonology: wowSpecs.demonologyWarlockBase,
    destruction: wowSpecs.destructionWarlockBase,
  }
}

const baseMage: iClass = {
  gameClass: "mage",
  iconImgPath: "/img/icon/wowClass/mage.png",
  colorDark: "#39A9DB",
  emoji: "<:mage:902216247389876234>",

  specs: {
    arcane: wowSpecs.arcaneMageBase,
    fire: wowSpecs.fireMageBase,
    frost: wowSpecs.frostMageBase,
  }
}

const baseRogue: iClass = {
  gameClass: "rogue",
  iconImgPath: "/img/icon/wowClass/rogue.png",
  colorDark: "#efbf16",
  emoji: "<:rogue:902216247243079691>",

  specs: {
    assassination: wowSpecs.assassinationRogueBase,
    combat: wowSpecs.combatRogueBase,
    subtlety: wowSpecs.subtletyRogueBase,
  }
}

const baseDruid: iClass = {
  gameClass: "druid",
  iconImgPath: "/img/icon/wowClass/druid.png",
  colorDark: "#F39237",
  emoji: "<:druid:902216247087890463>",

  specs: {
    balance: wowSpecs.balanceDruidBase,
    feral: wowSpecs.feralDruidBase,
    guardian: wowSpecs.guardianDruidBase,
    restoration: wowSpecs.restorationDruidBase,
  }
}

const baseHunter: iClass = {
  gameClass: "hunter",
  iconImgPath: "/img/icon/wowClass/hunter.png",
  colorDark: "#6BBF59",
  emoji: "<:hunter:902216247104663643>",

  specs: {
    "beast mastery": wowSpecs.beastMasteryHunterBase,
    marksmanship: wowSpecs.marksmanshipHunterBase,
    survival: wowSpecs.survivalHunterBase,
  }
}

const baseShaman: iClass = {
  gameClass: "shaman",
  iconImgPath: "/img/icon/wowClass/shaman.png",
  colorDark: "#2589BD",
  emoji: "<:shaman:902216247100465223>",

  specs: {
    elemental: wowSpecs.elementalShamanBase,
    enhancement: wowSpecs.enhancementShamanBase,
    restoration: wowSpecs.restorationShamanBase,
  }
}

const basePaladin: iClass = {
  gameClass: "paladin",
  iconImgPath: "/img/icon/wowClass/paladin.png",
  colorDark: "#EF709D",
  emoji: "<:paladin:902216247301771334>",

  specs: {
    holy: wowSpecs.holyPaladinBase,
    protection: wowSpecs.protectionPaladinBase,
    retribution: wowSpecs.retributionPaladinBase,
  }
}

const basePriest: iClass = {
  gameClass: "priest",
  iconImgPath: "/img/icon/wowClass/priest.png",
  colorDark: "#666666",
  colorLight: "#ffffff",
  emoji: "<:priest:902216247284989973>",

  specs: {
    discipline: wowSpecs.disciplinePriestBase,
    holy: wowSpecs.holyPriestBase,
    shadow: wowSpecs.shadowPriestBase,
  }
}

// wotlk
const baseDeathKnight: iClass = {
  gameClass: "death knight",
  iconImgPath: "/img/icon/wowClass/death_knight.webp",
  colorDark: "#AF1B3F",
  emoji: "<:death_knight:1075090990639100054>",

  specs: {
    blood: wowSpecs.bloodDeathKnightBase,
    "blood dps": wowSpecs.bloodDpsDeathKnightBase,
    frost: wowSpecs.frostDeathKnightBase,
    unholy: wowSpecs.unholyDeathKnightBase,
  }
}

// cata
const deathKnightPostCata: iClass = {
  ...baseDeathKnight,

  specs: {
    blood: wowSpecs.bloodDeathKnightBase,
    frost: wowSpecs.frostDeathKnightBase,
    unholy: wowSpecs.unholyDeathKnightBase,
  }
}

const hunterPostCata: iClass = {
  ...baseHunter,
  specs: {
    "beast mastery": wowSpecs.beastMasteryHunterPostCata,
    marksmanship: wowSpecs.marksmanshipHunterPostCata,
    survival: wowSpecs.survivalHunterPostCata,
  }
}

const priestPostCata: iClass = {
  ...basePriest,
  specs: {
    discipline: wowSpecs.disciplinePriestPostCata,
    holy: wowSpecs.holyPriestBase,
    shadow: wowSpecs.shadowPriestBase,
  }
}

const paladinPostCata: iClass = {
  ...basePaladin,
  specs: {
    holy: wowSpecs.holyPaladinBase,
    protection: wowSpecs.protectionPaladinPostCata,
    retribution: wowSpecs.retributionPaladinBase,
  }
}


// mop
const baseMonk: iClass = {
  gameClass: "monk",
  iconImgPath: "/img/icon/wowClass/monk.webp",
  colorDark: "#06D6A0",
  emoji: "<:monk:1177608017265573988>",

  specs: {
    brewmaster: wowSpecs.brewmasterMonkBase,
    mistweaver: wowSpecs.mistweaverMonkBase,
    windwalker: wowSpecs.windwalkerMonkBase,
  }
}

const shamanPostMop: iClass = {
  ...baseShaman,
  specs: {
    elemental: wowSpecs.elementalShamanBase,
    enhancement: wowSpecs.enhancementShamanPostMop,
    restoration: wowSpecs.restorationShamanBase,
  }
}

const roguePostMop: iClass = {
  ...baseRogue,
  specs: {
    assassination: wowSpecs.assassinationRoguePostMop,
    combat: wowSpecs.combatRogueBase,
    subtlety: wowSpecs.subtletyRogueBase,
  }
}


// legion
const baseDemonHunter: iClass = {
  gameClass: "demon hunter",
  iconImgPath: "/img/icon/wowClass/demon_hunter.webp",
  colorDark: "#744FC6",
  emoji: "<:demon_hunter:1177607740504424448>",

  specs: {
    havoc: wowSpecs.havocDemonHunterBase,
    vengeance: wowSpecs.vengeanceDemonHunterBase,
  }
}

const roguePostLegion: iClass = {
  ...roguePostMop,
  specs: {
    assassination: wowSpecs.assassinationRoguePostMop,
    outlaw: wowSpecs.outlawRogueBase,
    subtlety: wowSpecs.subtletyRogueBase,
  }
}

// dragonflight
const baseEvoker: iClass = {
  gameClass: "evoker",
  iconImgPath: "/img/icon/wowClass/evoker.jpg",
  colorDark: "#33937F",
  emoji: "<:evoker:1177607495280250981>",
  
  specs: {
    augmentation: wowSpecs.augmentationEvokerBase,
    devastation: wowSpecs.devastationEvokerBase,
    preservation: wowSpecs.preservationEvokerBase,
  }
}


// season of discovery
const sodMage: iClass = {
  ...baseMage,
  specs: {
    arcane: wowSpecs.arcaneMageBase,
    fire: wowSpecs.fireMageBase,
    frost: wowSpecs.frostMageBase,
    "regeneration": wowSpecs.sodMageHealer
  }
}

const sodRogue: iClass = {
  ...baseRogue,
  specs: {
    assassination: wowSpecs.assassinationRogueBase,
    combat: wowSpecs.combatRogueBase,
    subtlety: wowSpecs.subtletyRogueBase,
    "flesh wound": wowSpecs.sodRogueTank
  }
}
const sodWarlock: iClass = {
  ...baseWarlock,
  specs: {
    affliction: wowSpecs.afflictionWarlockBase,
    demonology: wowSpecs.demonologyWarlockBase,
    destruction: wowSpecs.destructionWarlockBase,
    "metamorphosis": wowSpecs.sodWarlockTank
  }
}

const sodShaman: iClass = {
  ...baseShaman,
  specs: {
    elemental: wowSpecs.elementalShamanBase,
    enhancement: wowSpecs.enhancementShamanBase,
    restoration: wowSpecs.restorationShamanBase,
    "way of earth": wowSpecs.sodShamanTank
  }
}

const wowClasses = {
  baseWarrior,
  baseWarlock,
  baseMage,
  baseRogue,
  baseDruid,
  baseHunter,
  baseShaman,
  basePaladin,
  basePriest,
  baseDeathKnight,
  deathKnightPostCata,
  hunterPostCata,
  priestPostCata,
  paladinPostCata,
  baseMonk,
  shamanPostMop,
  roguePostMop,
  baseDemonHunter,
  roguePostLegion,
  baseEvoker,
  // sod
  sodMage,
  sodRogue,
  sodWarlock,
  sodShaman,
}

const vanillaClasses = {
  warrior: wowClasses.baseWarrior,
  warlock: wowClasses.baseWarlock,
  mage: wowClasses.baseMage,
  rogue: wowClasses.baseRogue,
  druid: wowClasses.baseDruid,
  hunter: wowClasses.baseHunter,
  shaman: wowClasses.baseShaman,
  paladin: wowClasses.basePaladin,
  priest: wowClasses.basePriest,
}

const wotlkClasses = {
  ...vanillaClasses,
  "death knight": wowClasses.baseDeathKnight,
}

const cataClasses = {
  ...wotlkClasses,
  "death knight": wowClasses.deathKnightPostCata,
  priest: wowClasses.priestPostCata,
  hunter: wowClasses.hunterPostCata,
  paladin: wowClasses.paladinPostCata
}

const mopClasses = {
  ...cataClasses,
  shaman: wowClasses.shamanPostMop,
  rogue: wowClasses.roguePostMop,
  monk: wowClasses.baseMonk
}

const legionClasses = {
  ...mopClasses,
  rogue: wowClasses.roguePostLegion,
  "demon hunter": wowClasses.baseDemonHunter
}

const dragonflightClasses = {
  ...legionClasses,
  evoker: wowClasses.baseEvoker,
}

const sodClasses = {
  ...vanillaClasses,
  warlock: wowClasses.sodWarlock,
  mage: wowClasses.sodMage,
  rogue: wowClasses.sodRogue,
  shaman: wowClasses.sodShaman
}


export const wowClassesByExpansion = {
  "vanilla": vanillaClasses,
  "sod": sodClasses,
  "tbcc": vanillaClasses,
  "wotlk": wotlkClasses,
  "cata": cataClasses,
  "mop": mopClasses,
  "wod": mopClasses,
  "legion": legionClasses,
  "bfa": legionClasses,
  "df": dragonflightClasses,
  "tww": dragonflightClasses
}