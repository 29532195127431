import copy from "fast-copy"
import { iPanel, iPanelGrid, CellType, iPanelSpecSwap, AssignmentType, eDragType, targets, roles, iColumnCell } from "typings"
import { getY } from "../global"
import { atom } from "jotai"
import { jotaiStore } from "../../../pages/_app"
import { cellOverridesAtom } from "../smartListState"
import { getCoordsFromXYString, stringToCharID } from "functions"
import { iCellOverride } from "../../../context/assignmentPanelContext"

type userLocation = {col: number, row: number}

export const filterByUserAtom = atom(false)

const filterColumns = (gridPanel: iPanelGrid, unfilteredColumns: number[]) => {
  return gridPanel?.gridData?.columns.filter((column, index) => unfilteredColumns.includes(index) || index === 0) 
}

const filterRows = (gridPanel: iPanelGrid, unfilteredRows: number[]) => {
  return gridPanel?.gridData?.columns.map(col => {
    col.cells = col.cells.filter((cell, index) => {
      return unfilteredRows.includes(index)
    })
    return col
  })
}

const targetIDContainsUser = (targetID: string, userID: string): boolean => {
  const y = getY()
  const target = y.targets.get(targetID)
  if(!target?.containID) return false

  // member directly in target
  const { memberID } = stringToCharID(target?.containID)
  if (memberID === userID) return true;

  // member in role
  if (target?.containType === eDragType.ROLE) {
    const role = y.roles.get(target?.containID)
    if(!role) return false
    const roleMemberTarget = y.targets.get(role?.memberTargetID)
    const { memberID: roleMemberID } = stringToCharID(roleMemberTarget?.containID || "")
    if(roleMemberID === userID) return true
  }
  
  // todo: member in magic list
  return false;
}

export const assignmentHasTargetOfMe = (assignment: iPanel, userID: string) => {
  const targetIDs: string[] = []

  if(assignment?.type === AssignmentType.IMAGE || assignment?.type === AssignmentType.TEXT) return true

  // find all targets in targetAssignment
  if(assignment?.type === AssignmentType.TARGET){
    const arr: string[] = [...assignment.targetData.addonTargetIDs, assignment.targetData.mainTargetID]
    targetIDs.push(...arr)
  }
  
  // find all targets in gridAssignment
  if(assignment?.type === AssignmentType.GRID) {
    for (const col of assignment?.gridData?.columns) {
      const memberCells = col?.cells?.filter(cell => cell?.type === CellType.MEMBER) as iColumnCell[]
      targetIDs.push(...memberCells.map(cell => cell.data))
    }
  }

  // find all targets in spec swap
  if(assignment?.type === AssignmentType.SPEC_SWAP) {
    for (const swap of assignment.specSwapData.swaps) {
      if(swap.memberID === userID) return true
    }
  }
  
  // check if any targets pointing to "me" / logged in user
  for (const targetID of targetIDs) {
    const foundUser = targetIDContainsUser(targetID, userID)
    if(foundUser) return true
  }

  const overrides = jotaiStore.get(cellOverridesAtom)
  for (const [xy, cellOverride] of Object.entries(overrides?.[assignment.id] || {})) {
    if(cellOverride.charID?.memberID !== userID) continue;
    return true
  }

  return false
}

const findUserLocation = (panel: iPanelGrid, userID: string): userLocation[] => {
  const arr: userLocation[] = []
  const overrides = jotaiStore.get(cellOverridesAtom)

  for (let i = 0; i < panel.gridData?.columns.length; i++) {
    const col = panel.gridData?.columns[i];
    
    for (let o = 0; o < col.cells.length; o++) {
      const cell = col.cells[o];
      if(cell?.type === CellType.MEMBER){
        if(targetIDContainsUser(cell?.data, userID)) arr.push({col: i, row: o})
      }
    }
  }

  for (const [xy, cellOverride] of Object.entries(overrides?.[panel.id] || {})) {
    if(cellOverride.charID?.memberID !== userID) continue;
    const { col, row } = getCoordsFromXYString(xy)
    arr.push({col, row})
  }

  return arr
}

const filteredGridAssignment = (gridPanel: iPanelGrid, userID: string) => {
  const filteredAssignment = copy(gridPanel)
  const userLoc = findUserLocation(filteredAssignment, userID);
  let unfilteredRows: number[] = []
  let unfilteredColumns: number[] = []

  switch (filteredAssignment?.gridData?.gridDirection) {
    case 0: // horisontal
      unfilteredRows = userLoc.map((xy) => xy.row)
      filteredAssignment.gridData.columns = filterRows(filteredAssignment, unfilteredRows)
      break;

    case 1: // vertical
      unfilteredColumns = userLoc.map((xy) => xy.col)
      filteredAssignment.gridData.columns = filterColumns(filteredAssignment, unfilteredColumns)
      break;

    case 2: // single cells
      unfilteredColumns = userLoc.map((xy) => xy.col)
      unfilteredRows = userLoc.map((xy) => xy.row)
      filteredAssignment.gridData.columns = filterColumns(filteredAssignment, unfilteredColumns)
      filteredAssignment.gridData.columns = filterRows(filteredAssignment, unfilteredRows)
      break;

    default: break;
  }
  
  filteredAssignment.colSpan = Math.ceil(filteredAssignment.gridData.columns.length / 2)
  return { panel: gridPanel, unfilteredColumns, unfilteredRows }
}

const filteredSpecSwapAssignment = (specSwapPanel: iPanelSpecSwap, userID: string) => {
  const filteredAssignment = copy(specSwapPanel)
  try {
    filteredAssignment.specSwapData.swaps =
      filteredAssignment.specSwapData.swaps.filter((swap) => swap.memberID === userID)
    return filteredAssignment
  } catch (error) {
    console.error(error)
    return filteredAssignment
  }
}

export const filterPanelByUser = (
  panel: iPanel,
  userID: string
): { panel: iPanel; unfilteredRows: number[]; unfilteredColumns: number[] } => {
  if (assignmentHasTargetOfMe(panel, userID) === false){
    return { panel, unfilteredRows: [], unfilteredColumns: [] }
  }
  if (panel.type === AssignmentType.GRID) return filteredGridAssignment(panel, userID)
  if (panel.type === AssignmentType.SPEC_SWAP){
    return {
      panel: filteredSpecSwapAssignment(panel, userID),
      unfilteredRows: [],
      unfilteredColumns: []
    }
  }
  return { panel, unfilteredRows: [], unfilteredColumns: [] }
}

export const removeLastUnusedRows = (
  _panel: iPanelGrid,
  cellOverrides: Record<string, { [xy: string]: iCellOverride }>,
  targets: targets,
) => {
  const panel = copy(_panel)
  const panelOverrides = cellOverrides?.[panel.id]
  const skipRows: number[] = []
  const columns = panel.gridData.columns

  const getUnusedRows = () => {
    for (let rowIndex = columns[0].cells.length - 1; rowIndex >= 0; rowIndex--) {
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const cell = columns?.at(colIndex)?.cells?.at(rowIndex)
        if(!cell?.data) continue;
        if(cell.type === CellType.ICON) return
        if(cell.type === CellType.TEXT && (!!cell?.data || !!cell?.iconLeft)) return

        const target = targets?.[cell.data]
        if(!!target?.containID) return

        const cellOverride = panelOverrides?.[`${colIndex}.${rowIndex}`]
        if(!!cellOverride) return
      }
      skipRows.push(rowIndex)
    }
  }

  getUnusedRows()
  if(skipRows.length < 1) return panel


  // remove unused rows
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    column.cells = column.cells.filter((cell, index) => skipRows.includes(index) === false)
  }

  return panel
}