import { MakeKeysOptional } from "../../types";
import { SignupStates, GameRole, eMemberType } from "../../types/enum"
import { removeUndefinedProperties } from "../../types/factory";
import { iCharacter } from "../user/user"

export type placeholderSpec = { wantedNumber: number; role: gameRoleType }[]
export type gameRoleType = GameRole
// export type gameRoleType = GameRole.TANK | GameRole.MELEE | GameRole.RANGED | GameRole.HEALER
export type signupState = SignupStates.SIGNED | SignupStates.AVAILABLE | SignupStates.ABSENT | SignupStates.LATE | SignupStates.TENTATIVE | SignupStates.GHOST | SignupStates.NONE
export type members = {
  [key: string]: iMember
}
export type charID = {
  memberID: string
  charName: string
}
export type charIDString = string // "memberID_charName"

export type iMemberTimeWindow = {
  isConfirmed: boolean
  lateMax?: number
  isStandby?: boolean
}

export type iMemberCharAlternatives = {
  respec: iCharacter[]
  alts: iCharacter[]
  altRespecs: iCharacter[]
}

export type iMemberTimeWindows = Record<string, iMemberTimeWindow>
export type iMemberCustomFieldReplies = Record<string, string> // would have to be updated if add non-string fields

export interface iMember {
  type: eMemberType
  userID: string
  discordID: string
  signupState: signupState
  displayName: string
  signedUpAt: number
  lastUpdate: number
  onRoster: boolean
  isConfirmed: boolean
  
  isStandby?: boolean
  character?: iCharacter
  altCharacters?: iCharacter[]
  lateMax?: number
  eligibility?: iMemberTimeWindows
  customFieldsReplies?: iMemberCustomFieldReplies
  rosterCategory?: string // category ID
}

type optionaliMemberCreationKeys = "signedUpAt" | "lastUpdate" | "onRoster" | "isConfirmed"
type iMemberCreation = MakeKeysOptional<iMember, optionaliMemberCreationKeys>
export interface iMemberWithLabel extends iMember {
  label?: string
}

export const Member = () => {

  
  const create = (params: iMemberCreation) => {
    const defaultValues: Pick<iMember, optionaliMemberCreationKeys> = {
      signedUpAt: Date.now(),
      lastUpdate: Date.now(),
      isConfirmed: false,
      onRoster: false,
    }

    const member: iMember = {
      ...defaultValues,
      ...params
    }

    for (const key of Object.keys(member)) {
      if (member?.[key as keyof iMember] === undefined) {
        delete member[key as keyof iMember];
      }
    }

    removeUndefinedProperties(member)

    return member
  }


  return ({
    create,
  })
}