import { newID } from "../../types/typeUtils";
import { dragType, raidAreaType } from "../raid/raid";

export type targetAccepts = dragType | dragType[]

export interface iTarget {
  id: string              // i.e target-12
  containID: string | null       // roleID or memberID or charIDString
  containType: dragType | null  
  area: raidAreaType
  noSync?: true
  inListID?: string | null
}

export const Target = () => {
  
  const createID = (targetIDstr?: string) => {
    if(targetIDstr?.startsWith("target-")) return targetIDstr
    if(!!targetIDstr) return `target-${targetIDstr}`
    return "target-" + newID(5)
  }

  const create = (params: {
    area: raidAreaType
    containType?: dragType | null
    containID?: string | null
    inListID?: string
    targetIDstr?: string
    noSync?: true
  }) => {

    const {
      area,
      containID = null,
      containType = null,
      inListID = null,
      noSync,
      targetIDstr
    } = params

    const targetID = createID(targetIDstr)

    const targetData: iTarget = {
      id: targetID,
      containType,
      containID,
      area,
      inListID
    }
    if (noSync) targetData.noSync = noSync
    return targetData
  }

  return {
    // no protection
    create,
    createID
  }
}