import { HocuspocusProvider } from "@hocuspocus/provider";
import * as Sentry from "@sentry/nextjs";
import { documentAPI, firebase } from 'api';
import { dequal } from "dequal";
import copy from "fast-copy";
import { getAdminStatus } from "functions";
import { atom } from "jotai";
import { RESET, atomWithReset, atomWithStorage, selectAtom } from 'jotai/utils';
import { Areas, PanelCopyMode, iAdminStatus, iAssignmentPositions, iDragData, iList, iLocationMap, iModal, iPanel, iPopOver, iRaid, iRaidArea, iRaidData, iRole, iSmartList, iSplitSignup, iTarget, iTeam, iTeamPermissionType, iUser, iUserClaims, iUserPreferences, iUserSettings } from "typings";
import * as Yjs from 'yjs';
import { jotaiStore } from "../../pages/_app";
import { demoModal } from "../constants/modals";
import { onAwarenessUpdateType } from "../hooks";
import { importRaidDataAtom, importSignupAtom } from "./importSignup";
import { raidDataFSState, raidFSState, raidStateCore, raidSyncOverrideState, signupGroupingAtom, signupLocationAtom } from "./raid";
import { areasState, assignmentPosState, assignmentsState, listsState, rolesState, targetsState } from "./raidData";
import { filterByUserAtom } from "./raidData/onlyMyAssignments";
import { basicSmartListsAtom, smartListsCoreAtom } from "./smartListState";
import { resetSplitStateAtom, splitListsInitialisedState } from "./split";

export interface iGridSelected {
  rows: number[];
  cols: number[];
}

// user
export const FBUserState = atomWithStorage<firebase.User | null>("user", null)
export const UserDataState = atomWithStorage<iUser | null>("userData", null)
export const userIDOverrideAtom = atomWithReset<string | null>(null)
export const claimsState = atomWithStorage<iUserClaims>("claims", { teamPermissions: {} })
export const userSettingsState = selectAtom<iUser | null, iUserSettings>(UserDataState, (user) => {
  return user?.settings || {}
}, dequal)

// teams
export const teamsState = atom<iTeam[]>([])

// general
export const undoManagerState = atomWithReset<Yjs.UndoManager | null>(null);
export const loginRedirectURIState = atom<string>("")
export const modalState = atom<iModal>(demoModal)
export const lastRouterPathState = atomWithStorage('lastRouterPath', "")
export const lastUsedLocationState = atomWithStorage<iLocationMap | null>('lastUsedLocation', null)
export const defaultServerState = atomWithStorage<string | null>('defaultServer', null)
export const showCharacterIconsState = atomWithStorage<boolean | null>('showCharacterIcons', null)
export const undoManagerEnabledState = atomWithStorage<boolean | null>('undoManagerEnabled', null)
export const bannersShownState = atomWithStorage<Record<string, number>>('bannersShown', {})
export const userPreferencesState = atomWithStorage<iUserPreferences>('userPreferences', {
  runLayout: "auto",
  showBench: true,
  hideGeneralAssignments: false,
  calendarView: "month",
  
  // split view distribution
  expandSplitMatches: true,
  showSplitAllMatches: false,
  showSplitConflicts: false,
  useNegativeAppealChars: true,
  useLegacyDistAlgo: true,

  preferredRosterView: null
})

export const setUserPreferenceState = atom(
  null,
  (get, set, update: Partial<iUserPreferences>) => {
    const prevUserPref = copy(get(userPreferencesState))
    const userPref = Object.assign(prevUserPref, { ...update })

    set(userPreferencesState, userPref)
  }
)

// raid
export const editModeOnCoreAtom = atom(false)
export const editModeOnState = atom(
  (get) => get(editModeOnCoreAtom),
  (get, set, isEnabled: boolean) => {
    if(isEnabled === true) set(filterByUserAtom, false)
    set(editModeOnCoreAtom, isEnabled)
  }
)
export const showAlternativesState = atom<boolean>(false)
export const createPlaceholdersState = atom<boolean>(false)
export const moveObjectState = atomWithReset<iDragData | null>(null)
export const hoveringCharIDAtom = atomWithReset<{ memberID: string, charName?: string }[]>([])
export const panelCopyState = atomWithReset<{
  mode: PanelCopyMode
  data: iPanel
  selection: string[]
  isGeneral: boolean
} | null>(null)

export const adminStatusState = atom<iAdminStatus>("none")
export const setAdminStatusState = atom(
  null,
  (
    get,
    set,
    update: {
      permission: iTeamPermissionType
      adminPermission: iTeamPermissionType
    }
  ) => {
    const fbUser = get(FBUserState)
    const claims = get(claimsState)
    const raid = get(raidFSState)
    let adminState: iAdminStatus 
    if (typeof raid === "boolean") {
      adminState = "none"
    } else {
      adminState = getAdminStatus(
        fbUser?.uid || "",
        claims,
        raid.owner,
        raid.admins,
        update.permission,
        update.adminPermission
      )
    }

    Sentry.setTag("isSignupAdmin", adminState !== "none")
    set(adminStatusState, adminState)
  }
)

export const isRaidAdminState = atomWithReset<boolean | undefined>(undefined)
export const selectedAssignmentSectionState = atom<string[]>([])
export const savedSelectedAssignmentSectionState = atomWithStorage<string[]>("selectedSpecificAssignments", [])
export const editingGridsState = atomWithReset<{
  [panelID: string]: iGridSelected;
}>({});
export const showRaidAreasState = atomWithStorage<{[key: string]: boolean}>("showSignupAreas", {
  [Areas.ROSTER]: true,
  [Areas.ROLES]: true,
  [Areas.GROUPS]: true,
  [Areas.ASSIGNMENTS]: true,
  [Areas.SMARTLISTS]: true
})

// Modal & popover
export const popOverState = atomWithReset<iPopOver | null>(null);
export const showModalState = atom<boolean>(false)
type dismissMethod = "all" | "save" | "close" | "cancel" | "clickoutside"
export const preventDismissModalAtom = atomWithReset<dismissMethod[]>([]) // save, close, cancel, clickoutside
export const checkCanDismissModalAtom = atom(
  null,
  (get, set, method: dismissMethod) => {
    const arr = copy(get(preventDismissModalAtom))
    if(arr.includes("all")) return false
    return arr.includes(method) === false
  }
)

// Yjs
const entryYdocMap = <T extends unknown>(ydoc: Yjs.Doc, mapKey: string) => {
  return {
    get: () => ydoc.getMap(mapKey).get("entry") as T,
    set: (value: T) => ydoc.getMap(mapKey).set("entry", value),
    getMap: () => ydoc.getMap<unknown>(mapKey),
    delete: () => ydoc.getMap<unknown>(mapKey).delete("entry"),
  }
}
export const ydocState = atom<Yjs.Doc>(new Yjs.Doc())
export const ydocAtom = atom(
  (get) => {
    const ydoc = get(ydocState)

    return {
      // ydoc
      doc: ydoc,
      transactUser: (callback: () => void) => ydoc.transact(callback, "user"),
      transactSystem: (callback: () => void) => ydoc.transact(callback, "system"),

      // general
      extra: ydoc.getMap<any>("extra"), // used for instance for memberLastCheck: unix timestamp
      raidSync: ydoc.getMap<any>("raidSync"), // [iRaid.nested.key.to.value]: overrideValue
      splits: entryYdocMap<iSplitSignup>(ydoc, "splits"),

      // raidData
      targets: ydoc.getMap<iTarget>("targets"),
      roles: ydoc.getMap<iRole>("roles"),
      lists: ydoc.getMap<iList>("lists"),
      smartLists: ydoc.getMap<iSmartList>("smartLists"),
      assignments: ydoc.getMap<iPanel>("assignments"),
      assignmentPos: entryYdocMap<iAssignmentPositions>(ydoc, "aPos"),
      areas: ydoc.getMap<iRaidArea>("areas")
    }
  }
)
export const getY = () => {
  return jotaiStore.get(ydocAtom)
}

// Hocus
export const hocusProviderState = atom<HocuspocusProvider | boolean>(false)
export const hocusBroadcastingState = atomWithReset<boolean | undefined>(undefined)
export const hocusRoomState = atomWithReset<string>("")
export const hocusStatusState = atomWithReset<string>("n/a")
export const hocusAwarenessState = atomWithReset<onAwarenessUpdateType>([])
export const setHocusUserState = atom(
  null,
  (get, set, update: {name: string, color: string}) => {
    if(!(update?.name && update?.color)) {
      console.warn("Need to pass name and color!");
      return
    }
    if(!(typeof update?.name === "string" && typeof update?.color === "string")) {
      console.warn(`setHocusUser user fields not strings`, update);
      return
    }

    const hocusProvider = get(hocusProviderState)
    if(typeof hocusProvider === "boolean") {
      console.warn("setHocusUser no hocusProvider");
      return
    }

    hocusProvider.setAwarenessField('user', update)
  }
)

// utility
export const resetRaidJotaiState = atom(
  null,
  (get, set) => {
    // console.log("Jotai - resetting raid state")
    // raid reset
    set(targetsState, RESET)
    set(listsState, RESET)
    set(assignmentsState, RESET)
    set(assignmentPosState, RESET)
    set(rolesState, RESET)
    set(areasState, RESET)
    set(smartListsCoreAtom, RESET)
    set(basicSmartListsAtom, RESET)
    set(raidStateCore, RESET)
    set(raidFSState, RESET)
    set(signupLocationAtom, RESET)
    set(signupGroupingAtom, RESET)
    set(raidDataFSState, RESET)
    set(raidSyncOverrideState, RESET)
    set(popOverState, RESET)
    set(moveObjectState, RESET)
    set(panelCopyState, RESET)
    set(isRaidAdminState, RESET)
    set(hocusBroadcastingState, RESET)
    set(undoManagerState, RESET)
    set(userIDOverrideAtom, RESET)
    
    // hocus reset
    // set(hocusProviderState, RESET)
    // set(ydocState, RESET)
    set(hocusRoomState, RESET)
    set(hocusStatusState, RESET)
    set(hocusAwarenessState, RESET)

    // raid
    set(adminStatusState, "none")
    Sentry.setTag("isSignupAdmin", false)
    set(editingGridsState, RESET)
    set(editModeOnState, false)
    set(showAlternativesState, false)
    set(createPlaceholdersState, false)
    set(showModalState, false)
    set(selectedAssignmentSectionState, [])
    
    set(resetSplitStateAtom)
  }
)


type deleteUnsavedChangesProps = { forceLoadRaidData?: boolean; inputRaidData?: iRaidData, inputRaid?: iRaid }
export const deleteUnsavedChangesState = atom(
  null,
  async (get, set, update?: deleteUnsavedChangesProps) => {
    const y = get(ydocAtom)
    const FSRaid = update?.inputRaid || get(raidFSState)
    if(typeof FSRaid === "boolean") return
    const getFSRaidData = async () => {
      if(FSRaid?.isSignupOnly === true) return false
      if(!!update?.inputRaidData) return update?.inputRaidData
      if(update?.forceLoadRaidData !== true) return get(raidDataFSState)
      const rd = await documentAPI().raidDataAPI.get(FSRaid.raidID)
      set(raidDataFSState, rd)
      return rd
    }
    const FSRaidData = await getFSRaidData()

    y.doc.transact(() => {
      y.raidSync.clear()
      y.targets.clear()
      y.lists.clear()
      y.roles.clear()
      y.assignments.clear()
      y.assignmentPos.getMap().clear()
      y.areas.clear()
      y.smartLists.clear()
  
      // fix split data
      if(FSRaid?.splitData) {
        set(splitListsInitialisedState, false) // split init false
        y.splits.set(FSRaid?.splitData)
      }
      
      set(importSignupAtom, FSRaid)
      set(selectedAssignmentSectionState, [])
      set(savedSelectedAssignmentSectionState, [])
      if(typeof FSRaidData !== "boolean") set(importRaidDataAtom, FSRaidData)
    }, "system")
  }
)

export const resetYDocState = atom(
  null,
  (get, set) => {
    const ydoc = get(ydocState)
    ydoc.destroy()
    set(ydocState, new Yjs.Doc())
  }
)

export const resetHocusProviderAndYdoc = atom(
  null,
  (get, set) => {
    const provider = get(hocusProviderState)
    const ydoc = get(ydocState)
    if(typeof provider === "boolean") return
    console.log("Hocus - closing provider")
    ydoc.destroy()
    provider.disconnect()
    provider.destroy()
    set(ydocState, new Yjs.Doc())
    set(hocusProviderState, false)
  }
)

export const deleteRaidSyncKeysState = atom(null,
  (get, set, checkKey: string, checkValueArray?: any[

  ]) => {
    const y = get(ydocAtom)
    const deleteRaidSyncKeys: string[] = Array.from(y.raidSync?.entries())
      .filter(([key, value]) => {
        const isStringMatch = key.startsWith(checkKey)
        const isValueMatch = checkValueArray?.some(val => dequal(val, value)) 
        const shouldDeleteKey = isStringMatch || isValueMatch
        return shouldDeleteKey
      })
      .map(([key, value]) => key)

    for (const key of deleteRaidSyncKeys) {
      y.raidSync.delete(key)
    }
  }
)

export const setBannersShownAtom = atom(
  null,
  (get, set, bannerID: string) => {
    const banners = copy(get(bannersShownState))
    banners[bannerID] = Date.now()
    set(bannersShownState, banners)
  }
)