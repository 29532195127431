import { iSplitRun, iSplitSession, iSplitTimeWindow } from "../../iSplitSignup"

export const CreateSplitTimeWindow = ({
  start,
  end,
}: {
  start: number
  end: number
}) => {
  const timeWindow: iSplitTimeWindow = {
    start,
    end,
    sessions: {}
  }
  return timeWindow
}

export const CreateSplitSession = ({ start, end }: { start: number; end: number }) => {
  const session: iSplitSession = {
    start,
    end,
    splits: {}
  }
  return session
}

export const CreateSplitRun = (): iSplitRun => {
  const run: iSplitRun = {
    members: []
  }
  return run
}