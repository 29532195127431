import { classNames, debounce } from "functions";
import { CSSProperties, ChangeEvent, ChangeEventHandler, DetailedHTMLProps, FC, FocusEventHandler, InputHTMLAttributes, KeyboardEvent, KeyboardEventHandler, useEffect, useRef } from "react";

export interface TextInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  callback?: (event: KeyboardEvent<HTMLInputElement>) => void
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  placeholder?: string
  defaultValue?: string
  dismiss?: () => void
  style?: CSSProperties
  autoSelect?: boolean
  autoFocus?: boolean
  className?: string
  allowPropUpdate?: boolean
  onChanceDebounceMS?: number
}

const TextInput: FC<TextInputProps> = ({ callback, dismiss, onChange, onBlur, style, className, placeholder = "", defaultValue, autoFocus = false, autoSelect = false, allowPropUpdate = false, onChanceDebounceMS = 350, ...props }) => {
  
  const inputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    if(!inputRef.current) return
    if(autoFocus) {
      inputRef.current.focus({
        preventScroll: true
      })
    }
    if(autoSelect === true) inputRef?.current?.select()
  }, [])

  useEffect(() => {
    if(!inputRef.current) return
    if(allowPropUpdate && defaultValue) inputRef.current.value = defaultValue
  }, [defaultValue, allowPropUpdate])

  return (
    <input
      ref={inputRef}
      {...props}
      defaultValue={defaultValue || ""}
      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !!callback) {
          callback(event)
          if (dismiss && typeof dismiss === "function") dismiss()
        }
      }}
      onBlur={
        !onBlur
          ? undefined
          : (event) => {
              setTimeout(() => {
                onBlur(event)
              }, 50)
            }
      }
      onChange={
        !onChange
          ? undefined
          : debounce((event: ChangeEvent<HTMLInputElement>) => {
              onChange(event)
              event.preventDefault()
            }, onChanceDebounceMS)
      }
      placeholder={placeholder || ""}
      autoComplete="off"
      style={{ width: "100%", padding: 4, boxSizing: "border-box", ...style }}
      className={classNames(
        "default-input",
        className && className
      )}
    />
  )
};

export default TextInput;