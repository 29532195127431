import copy from "fast-copy"
import { iMember, charID } from "typings"
import { memberToCharIDs, getCharInMember } from "./raidUtilities"

export const getMemberCharVariants = (member: iMember, includeMain = true, includeAlts = true): iMember[] => {
  const charIDs: charID[] = memberToCharIDs(member, includeMain, includeAlts)
  const memberVariants: iMember[] = []

  for (const charID of charIDs) {
    const _member = copy(member)
    const char = getCharInMember(member, charID.charName)
    if(!char) throw new Error("Member variants - could not find characer in member.")
    _member.character = char
    if(!!_member?.altCharacters) delete _member.altCharacters
    memberVariants.push(_member)
  }

  return memberVariants
}

export const getMemberNoVariants = (member: iMember, includeMain = true, includeAlts = true): iMember[] => {
  const memberVariants: iMember[] = []
  if(includeMain) memberVariants.push(member)
  return memberVariants
}