import * as Sentry from "@sentry/nextjs";
import { Extensions } from "@tiptap/react";
import { documentAPI } from "api";
import { findLocation, getGrouping, iFeatureAccess } from "data";
import { dequal } from "dequal";
import { dset } from 'dset';
import copy from "fast-copy";
import { arrayEntriesToObject, capitalize, charIDToString, filterRaidDataNosync, getAdminStatus, getCellFromPanel, getErrorMessage, memberPossiblyPresent, minMax, stringToCharID } from "functions";
import { Getter, atom } from "jotai";
import { atomWithReset, selectAtom } from "jotai/utils";
import toast from "react-hot-toast";
import { Areas, Assignment, AssignmentType, CellType, GridColumn, GridData, List, PanelCopyMode, RaidAdmin, Role, SignupStates, Target, TargetAssignment, TeamOwned, assertIsDragDataMember, assignmentType, eDragType, eReplaceWithType, eTeamPermission, iAssignmentPositions, iColumnCell, iDragData, iGrouping, iList, iLocation, iLocationMap, iMember, iPanel, iPanelText, iRaid, iRaidData, iReplaceWithOption, iReplaceWithRoleOption, iReplaceWithSmartListOption, iRole, iSection, iTarget, isBoolean, isDefined, isGridPanel, isNormalLoc, isTextPanel, isUserOwned, members } from "typings";
import { getContentExtensionArray } from "../../components/2_molecule/textEditor/textEditorContent";
import { generateText } from "../../components/2_molecule/textEditor/utilities/textEditorUtilities";
import { getTextAssignmentData } from "../util";
import { copyToClipboard } from "../utilities";
import { UserDataState, claimsState, selectedAssignmentSectionState, userSettingsState, ydocAtom } from "./global";
import { areasState, assignmentPosState, assignmentsState, listsState, rolesState, targetsState } from "./raidData";
import { calculatePanelOverrides, cellOverridesAtom, iPanelIDToOverrides, smartListsAtom } from "./smartListState";
import { splitListsInitialisedState } from "./split";
import { updateMemberState } from "./updateMemberState";

// workaround null initialisation
// https://github.com/pmndrs/jotai/issues/550#issuecomment-877777563
// const myAtomConfig = atom<boolean | null>(null) as PrimitiveAtom<
//   boolean | null
// >;

export const raidStateCore = atomWithReset<iRaid | boolean>(false)
export const raidFSState = atomWithReset<iRaid | boolean>(false)

export const raidDataFSState = atomWithReset<iRaidData | boolean>(false)
export const lastRaidImportState = atomWithReset<number | null>(null)

export const raidState = atom(
  (get) => {
    const r = get(raidStateCore)
    const modify = get(raidSyncOverrideState)
  
    if(typeof r === "boolean") return r
    let raid: iRaid = copy(r)
  
    // modify with raidSync
    for (const [modifyPath, value] of Object.values(modify)) {
      // console.log(`modifying ${modifyPath} to ${value}`)
      dset(raid, modifyPath, value)
    }

    // console.log("raidModified to", raid)
    return raid
  },
  (get, set, raid: iRaid) => {
    set(raidStateCore, raid)
  }
)

export const signupLocationAtom = atomWithReset<iLocation | null>(null)
export const signupGroupingAtom = atomWithReset<iGrouping | null>(null)

export const setGroupingAtom = atom(
  null,
  (get, set, { signupInput, forceRefresh }: { signupInput?: iRaid, forceRefresh?: boolean }) => {
    
    const groupingPrev = get(signupGroupingAtom)
    if(groupingPrev && forceRefresh !== true) return groupingPrev

    const signup = signupInput || get(raidState)
    if (isBoolean(signup)) return

    const location = findLocation(signup.location)
    const rosterLayout = location.ui.layoutData.defaultLayout.web.roster
    const grouping = getGrouping(rosterLayout.groupingFunction, signup, location)
    set(signupGroupingAtom, grouping)

    return grouping
  }
)

export const isSignupOnlyState = selectAtom(raidState, (signup) => {
  return isBoolean(signup) ? true : signup.isSignupOnly
}, dequal)

export const isPublicTemplateState = selectAtom(raidState, (signup) => {
  return isBoolean(signup) ? false : signup?.templateData?.isPublic === true
}, dequal)

export const raidIDState = selectAtom(raidState, (s) => {
  if(typeof s === "boolean") return
  return s.raidID
}, dequal)

export const raidOwnerState = selectAtom<iRaid | boolean, TeamOwned | RaidAdmin | boolean>(raidState, (signup) => {
  if(isBoolean(signup)) return signup
  return signup?.owner
}, dequal)

export const groupOrderState = selectAtom(raidState, (s) => {
  if(typeof s === "boolean") return []
  return s.groupOrder
}, dequal)

export const rosterSizeState = selectAtom(raidState, (raid) => {
  if(typeof raid === "boolean") return 0
  const rosterMembers = Object.values(raid.members)
        .filter(member => member.onRoster)
  return rosterMembers.length;
}, dequal)

export const rosterFullState = atom(
  (get) => {
    const raid = get(raidState)
    const raidSize = get(rosterSizeState)
    if(isBoolean(raid)) return false
    
    return raidSize >= raid.maxSize
  }
)
export const signupMaxSizeState = atom(
  (get) => {
    const raid = get(raidState)
    if(isBoolean(raid)) return 0
    return raid.maxSize
  }
)
export const roleIDToMemberAtom = atom(
  (get) => {
    const members = get(raidMembersState)
    const roles = get(rolesState)
    const targets = get(targetsState)

    const roleIDToMember = new Map<string, {role: iRole, member: iMember}>()

    for (const role of Object.values(roles)) {
      const targetID = role.memberTargetID
      const { memberID } = stringToCharID(targets?.[targetID].containID)
      const member = members?.[memberID]
      roleIDToMember.set(role.id, { member, role })
    }

    return roleIDToMember
  }
)

export type raidSync = [string, any][]
export const raidSyncOverrideState = atomWithReset<raidSync>([])

export const raidMembersState = selectAtom<iRaid | boolean, members>(raidState, (s) => {
  if(typeof s === "boolean") return {}
  return s.members
}, dequal)

export const signupLocationMapState = selectAtom<iRaid | boolean, iLocationMap | null>(raidState, (s) => {
  if(typeof s === "boolean") return null
  return s.location
}, dequal)

export const raidDataDerivedState = atom<iRaidData | boolean>((get) => {
  const fireStore = get(raidDataFSState)
  const targets = get(targetsState)
  const lists = get(listsState)
  const assignments = get(assignmentsState)
  const smartLists = get(smartListsAtom)
  const aPos = get(assignmentPosState)
  const areas = get(areasState)
  const roles = get(rolesState)

  if(typeof fireStore === "boolean") return false
  const final: iRaidData = copy(fireStore)
  final.targets = targets
  final.lists = lists
  final.assignments = assignments
  final.assignmentPos = aPos
  final.areas = areas
  final.roles = roles
  final.smartLists = smartLists

  return final
})

export const raidChangesActive = atom<boolean>((get) => {
  const raid = get(raidState)
  const FSraid = get(raidFSState)
  if(typeof raid === "boolean") return false
  if(typeof FSraid === "boolean") return false

  const raidSync = get(raidSyncOverrideState)
  const raidChangesActive = !dequal(raid, FSraid)
  const syncOverridesFound = raidSync.length > 0
  if(raidChangesActive) return true
  if(syncOverridesFound) return true

  let raidData = get(raidDataDerivedState)
  const FSRaidData = get(raidDataFSState)
  
  if(typeof raidData === "boolean") return false
  if(typeof FSRaidData === "boolean") return false

  raidData = filterRaidDataNosync(copy(raidData))
  const RaidDatachangesActive = !dequal(raidData, FSRaidData)
  
  return RaidDatachangesActive
})

export const hasSignupAccessState = atom<boolean>((get) => {
  const signup = get(raidState)
  const claims = get(claimsState)
  const userData = get(UserDataState)
  if(!userData) return false
  if(typeof signup === "boolean") return false
  if(signup.visibility === "open") return true

  const adminStatus = getAdminStatus(
    userData?.userID || "",
    claims,
    signup.owner,
    signup.admins,
    eTeamPermission.MANAGE_SIGNUPS,
    eTeamPermission.SIGNUPS_ASSISTANT
  )
  if(adminStatus !== "none") return true
  
  if(signup.visibility === "roster") {
    if(!signup.members[userData?.userID]) return false
    if(signup.members[userData.userID]?.onRoster !== true) return false
    return true
  }
  
  return false
})


type memberAndRoleDerivedType = {
  roles: iReplaceWithOption[],
  smartLists: iReplaceWithOption[],
  members: iReplaceWithOption[],
  chars: iReplaceWithOption[],
  gameRole: {
    [key: string]: iReplaceWithOption[]
  },
  gameClass: {
    [key: string]: iReplaceWithOption[]
  },
}
export const memberAndRoleDerivedAtom = atom(
  null,
  (get, set) => {

    const outputRoles: iReplaceWithOption[] = []
    const outputSmartLists: iReplaceWithOption[] = []
    const outputMembers: iReplaceWithOption[] = []
    const outputChars: iReplaceWithOption[] = []
    const outputGameRole: {
      [key: string]: iReplaceWithOption[]
    } = {}
    const outputGameClass: {
      [key: string]: iReplaceWithOption[]
    } = {}

    const raid = get(raidState)
    const roles = get(rolesState)
    const smartLists = get(smartListsAtom) // TODO: make replaceWithOptions with smartLists
    const targets = get(targetsState)
    const isSplit = get(splitListsInitialisedState)
    const location = get(signupLocationAtom)
    const grouping = get(signupGroupingAtom)

    const emptyObj: memberAndRoleDerivedType = {
      members: [],
      chars: [],
      roles: [],
      smartLists: [],
      gameRole: {},
      gameClass: {},
    }
    if(!(typeof raid !== "boolean" && raid?.members && location && grouping)) return emptyObj

    try {
      const memberVariantArr = Object.values(raid.members)
        .reduce((acc, member) => {
          if (isSplit) return [...acc, ...grouping.getMemberVariants(member, true, true)]
          return [...acc, member]
        }, [] as iMember[])

      for (const member of memberVariantArr) {
        const onRosterCorrect = isSplit === true || member?.onRoster === true
        if (!onRosterCorrect && member.signupState !== SignupStates.GHOST) continue;
        if(isNormalLoc(location)){
          outputMembers.push({
            type: eReplaceWithType.MEMBER,
            memberID: member.userID,
            displayName: member.displayName,
            signupState: member.signupState,
          });
          continue
        }
        if(!member.character) continue;
        
        const groupOrderIndex = raid.groupOrder.findIndex(spot => spot === member.userID)
        const groupPos =
          groupOrderIndex !== -1 ? Math.floor(groupOrderIndex / raid.groupSize) + 1 : null

        try {
          outputChars.push({
            type: eReplaceWithType.CHAR,
            memberID: member.userID,
            charName: member.character.charName,
            charGameRole: member.character.gameRole,
            charGameClass: member.character.charClass,
            charGameSpec: member.character.charSpec,
            signupState: member.signupState,
          });
        } catch (error) {
          console.error("member on roster without character", member);
          Sentry.captureException(error, {extra: {member}});
          continue;
        }

        if (!Array.isArray(outputGameRole[member?.character?.gameRole])) {
          outputGameRole[member?.character?.gameRole] = [];
        }
        if (!Array.isArray(outputGameClass[member?.character?.charClass])) {
          outputGameClass[member?.character?.charClass] = [];
        }

        outputGameRole[member.character.gameRole].push({
          type: eReplaceWithType.GAMEROLE,
          memberID: member.userID,
          charName: member.character.charName,
          charGameRole: member.character.gameRole,
          charGameClass: member.character.charClass,
          charGameSpec: member.character.charSpec,
          signupState: member.signupState,
          groupPositions: {
            a: groupPos
          }
        });

        outputGameClass[member?.character?.charClass].push({
          type: eReplaceWithType.CLASS,
          memberID: member.userID,
          charName: member.character.charName,
          charGameRole: member.character.gameRole,
          charGameClass: member.character.charClass,
          charGameSpec: member.character.charSpec,
          signupState: member.signupState,
        });
      }

      for (const role of Object.values(roles)) {
        const option: iReplaceWithRoleOption = {
          type: eReplaceWithType.ROLE,
          roleID: role.id,
          roleName: role.roleName,
        };
        const memberID = targets[role.memberTargetID].containID || ""
        const member = raid?.members[memberID];

        if (
          memberID &&
          member?.userID &&
          (member?.onRoster || member.signupState === SignupStates.GHOST)
        ) {
          option.memberID = member.userID;
          option.charName = member?.character?.charName || member?.displayName;
          option.charGameRole = member?.character?.gameRole
          option.signupState = member.signupState;
        }

        if (member?.character?.charClass) option.charGameClass = member.character.charClass;

        outputRoles.push(option);
      }

      for (const smartList of Object.values(smartLists)) {
        const option: iReplaceWithSmartListOption = {
          type: eReplaceWithType.SMARTLIST,
          smartListID: smartList.id,
          smartListLabel: smartList.label
        };

        outputSmartLists.push(option);
      }

      const returnObj: memberAndRoleDerivedType = {
        roles: outputRoles,
        chars: outputChars,
        members: outputMembers,
        smartLists: outputSmartLists,
        gameRole: outputGameRole,
        gameClass: outputGameClass,
      }

      return returnObj
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    return emptyObj
  }
)

export const addPlaceholdersState = atom(
  null,
  async (get, set, { benchMembers, rosterMembers }: { rosterMembers: iMember[]; benchMembers: iMember[] }) => {
  const raid = get(raidState)
  const rosterSize = get(rosterSizeState)
  if (isBoolean(raid)) return

  const openSpots = raid.maxSize - rosterSize

  // check how many members fit on roster
  const ableToFitMembers = rosterMembers.slice(0, openSpots)
    .map((member) => ({ ...member, onRoster: true }))
  const notAbleToFitMembers = rosterMembers
    .slice(openSpots)
    .map((member) => ({ ...member, onRoster: false }))
  const dummyMembers = [...ableToFitMembers, ...notAbleToFitMembers, ...benchMembers]
  const memberIDs = ableToFitMembers.map((member) => member.userID)
  console.log(`Planning to add ${dummyMembers.length} placeholders`, dummyMembers)

  // update groupOrder for placeholders that fit on roster
  const groupOrder = copy(raid.groupOrder)
  for (const memberID of memberIDs) {
    const index = groupOrder.findIndex((o) => o === null)
    if (index === -1) break
    groupOrder[index] = memberID
  }

  const allMembers: [string, iMember][] = [...Object.values(raid.members), ...dummyMembers].map(
    (member) => [member.userID, member]
  )
  const signupMembers = arrayEntriesToObject(allMembers)
  const update = {
    members: signupMembers,
    groupOrder: groupOrder
  }
  console.log("getPlaceholders update", update)
  // return
  try {
    await documentAPI().raidAPI.updateMultiPath(raid.raidID, update)
  } catch (error) {
    console.error(error)
  }
})

// TARGETS
export const registerTargetsState = atom(null, (get, set, targets: iTarget[]) => {
  const y = get(ydocAtom)
  if(targets.length < 1) return
  y.doc.transact(() => {
    for (const target of targets) {
      y.targets.set(target.id, target)
    }
  })

  const data = Array.from(y.targets.entries()) as Array<[string, iTarget]>
  const targetsObj = arrayEntriesToObject(data);
  set(targetsState, targetsObj)
})

export const unregisterTargetsState = atom(null, (get, set, targetIDs: string[]) => {
  const y = get(ydocAtom)
  if(Array.isArray(targetIDs) === false || targetIDs?.length < 1) return
  y.doc.transact(() => {
    for (const targetID of targetIDs) {
      if(y.targets.has(targetID) === false) continue;
      y.targets.delete(targetID)
      set(removeTargetIDFromListsState, targetID)
    }
  })
})

export const resetTargetsPointingToState = atom(
  null,
  (get, set, { containID, exceptTargetIDs }: { containID: string; exceptTargetIDs: string[] }) => {
    const y = get(ydocAtom)

    const allTargets = Array.from<iTarget>(y.targets.values())
    const removeTargets = allTargets
      .filter((target) => target.containID === containID)
      .filter((target) => target.area !== Areas.ROSTER)

    y.doc.transact(() => {
      removeTargets.forEach((target) => {
        if (exceptTargetIDs.includes(target.id)) return
        if (!y.targets.has(target.id)) return

        target.containID = null
        if (target.area !== Areas.GROUPS) target.containType = null

        y.targets.set(target.id, target)
      })
    }, "system")
  }
)

// LISTS
export const registerListsState = atom(null, (get, set, updateLists: iList[]) => {
  const y = get(ydocAtom)

  if(updateLists.length < 1) return
  // const alreadyExists = findPreexistingLists(lists, "1")
  // if(alreadyExists) return false
  if(!y?.lists) {
    console.warn("trying to add list but lists not initialised");
    return false
  }
  
  y.doc.transact(() => {
    for (const list of updateLists) {
      if(y.lists.has(list.id)){
        // console.log(`registerLists - tried to register already registered list. ListID: ${list?.id}`);
        continue;
      }
      if(!list?.targetIDs || !Array.isArray(list?.targetIDs)){
        console.log(`registerLists - skipped ${list.listName}: no targetIDs`, list)
        continue;
      }
      y.lists.set(list.id, list)
    }
  }, "system") 

  const data = Array.from(y.lists.entries()) as Array<[string, iList]>
  const listsObj = arrayEntriesToObject(data);
  set(listsState, listsObj)
})

export const unregisterListsState = atom(null, (get, set, listIDs: string[], deleteTargets = true) => {
  const y = get(ydocAtom)
  if(Array.isArray(listIDs) === false || listIDs?.length < 1) return
  y.doc.transact(() => {
    const cleanupTargetIDs: string[] = []
    for (const listID of listIDs) {
      const list = y.lists.get(listID)
      if(!list?.targetIDs) continue
      cleanupTargetIDs.push(...list.targetIDs)
      y.lists.delete(listID)
    }
    if(deleteTargets) set(unregisterTargetsState, cleanupTargetIDs)
  })
})

type moveTargetToListParam = {
  targetID: string
  mode: "add" | "remove"
  fromListID: string
  toListID: string
}
export const moveTargetToListState = atom(null, (get, set, update: moveTargetToListParam) => {
  const y = get(ydocAtom)

  const { targetID, mode, fromListID, toListID } = update
 
  if(!y?.lists.has(fromListID)) return
  if(!y?.lists.has(toListID)) return
  
  const target = copy(y.targets.get(targetID))
  const listFrom = copy(y.lists.get(fromListID))
  const listTo = copy(y.lists.get(toListID))
  if(!target || !listTo || !listFrom) return
  
  const fromTargetIDs = listFrom.targetIDs
  const targetObjIndex = fromTargetIDs.findIndex(tarID => tarID === targetID)
  const targetObj = fromTargetIDs[targetObjIndex]
  
  try {
    // remove from old list
    fromTargetIDs.splice(targetObjIndex, 1)
    // add to new list
    listTo?.targetIDs.push(targetObj)
    // add new list reference to target - used for undo/redo
    target.inListID = listTo.id
    
    y.doc.transact(() => {
      y.targets.set(target.id, target)
      y.lists.set(toListID, listTo)
      y.lists.set(fromListID, listFrom)
    }, "system") 
  } catch (error) {
    console.warn(error)
  }
})

export const removeTargetIDFromListsState = atom(null, (get, set, targetID: string) => {
  const y = get(ydocAtom)
  const allLists: iList[] = Array.from(y.lists?.values())
  const listsWithTargetID: iList[] = allLists.filter(
    (list) => list.targetIDs.findIndex((tarID) => tarID === targetID) !== -1
  )
  
  y.doc.transact(() => {
    for (const list of listsWithTargetID) {
      const obj = copy(list)
      obj.targetIDs.splice(list.targetIDs.findIndex(tarID => tarID === targetID), 1)
  
      y.lists.set(list.id, obj)
    }
  }) 
})

// ROLES
export const registerRolesAtom = atom(null, (get, set, roles: iRole[]) => {
  const y = get(ydocAtom)

  if(roles.length < 1) return
  if(!y?.roles) {
    console.warn("trying to add list but lists not initialised");
    return false
  }
  
  y.transactSystem(() => {
    for (const role of roles) {
      y.roles.set(role.id, role)
    }
  })

  const data = Array.from(y.roles.entries()) as Array<[string, iRole]>
  const rolesObj = arrayEntriesToObject(data);
  set(rolesState, rolesObj)
})

export const createNewRoleAtom = atom(null, (get, set, roleName: string, listID: string) => {
  const memberTarget = Target().create({ area: Areas.ROLES, containType: eDragType.MEMBER })

  const role = Role().create(roleName, memberTarget.id)
  const roleTarget = Target().create({
    area: Areas.ROLES,
    containType: eDragType.ROLE,
    containID: role.id,
    inListID: listID
  })

  set(addTargetToListAtom, roleTarget.id, listID)
  set(registerRolesAtom, [role])
  set(registerTargetsState, [memberTarget, roleTarget])
})

export const addTargetToListAtom = atom(null, (get, set, targetID: string, listID: string) => {
  const y = get(ydocAtom)

  const list = copy(y.lists.get(listID))
  if(!list) return
  list.targetIDs.push(targetID)
  y.lists.set(list.id, list)
})

export const teamAccessState = atomWithReset<{ [teamID: string]: iFeatureAccess }>({})
export const signupTeamAccessState = atom<iFeatureAccess>(
  (get) => {
    const signupOwner = get(raidOwnerState)
    const teamAccess = get(teamAccessState)

    if(isBoolean(signupOwner)) return {}
    if(isUserOwned(signupOwner)) return {}

    const access = teamAccess?.[signupOwner.teamID]
    if(!access) return {}
    
    return access
  }
)

// Assignments / Panels 
export const registerAssignmentsState = atom(null, (get, set, panels: iPanel[]) => {
  const y = get(ydocAtom)
  if(panels.length < 1) return
  
  y.transactSystem(() => {
    for (const panel of panels) {
      if(!panel?.id) continue;
      y.assignments.set(panel.id, panel)
    }
  })

  const data = Array.from(y.assignments.entries()) as Array<[string, iPanel]>
  const panelsObj = arrayEntriesToObject(data);
  set(assignmentsState, panelsObj)
})

export const unregisterAssignmentsState = atom(null, (get, set, assignmentIDs: string[]) => {
  const y = get(ydocAtom)
  if(assignmentIDs.length < 1) return

  y.doc.transact(() => {
    for (const assignmentID of assignmentIDs) {
      if(!y.assignments.has(assignmentID)) {
        continue;
      }
      y.assignments.delete(assignmentID)
    }
  }) 
})


export const updateAssignmentState = atom(
  null,
  (get, set, { callback, assignmentID }: { assignmentID: string, callback: (panel: iPanel) => void}) => {
    const y = get(ydocAtom)

    try {
      const assignment = copy(y.assignments.get(assignmentID))
      if(!assignment) return
      callback(assignment)
      console.log("assignment after update", assignment)
      
      y.transactUser(() => {
        y.assignments.set(assignmentID, assignment)
      })
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)

export const runYDocTransactionAtom = atom(
  null,
  (get, set, callbacks: ((...args: any[]) => any)[]) => {
    const y = get(ydocAtom)

    try {
      
      y.doc.transact(() => {
        for (const callback of callbacks) {
          callback()
        }
      }, "user")
    } catch (error) {
      console.error(error);
    }
  }
)

export const sectionToTextAtom = atom(
  null,
  (get, set, sectionID: string, formatCellText?: (cellType: CellType, text: string) => string) => {
    const y = get(ydocAtom)

    try {

      const section = y.assignmentPos.get().sections?.[sectionID]
      if(!section) throw new Error("Section to text - that section was not found.")
      let returnString = ""

      for (const panelID of section.panels || []) {
        const panelString = set(panelToTextAtom, panelID, formatCellText)
        if(!panelString) continue;
        returnString += "\n\n"
        returnString += panelString
      }

      copyToClipboard(returnString)
      toast.success(`Copied ${section.sectionName} as text to clipboard.`)
      
    } catch (error) {
      console.error(error);
      toast.error("Failed to copy section as text.")
    }
  }
)

export const targetIDToMemberAtom = atom(
  null,
  (get, set, targetID: string) => {
    return getMemberFromTargetID(targetID, get)
  }
)

export const getMemberFromTargetID = (targetID: string, get: Getter) => {
  const y = get(ydocAtom)
  const members = get(raidMembersState)

  const target = copy(y.targets.get(targetID))
  if(!target) {
    // console.warn("No target found", targetID)
    return
  }

  const getReturnMember = (target: iTarget) => {
    if(!target?.containID) return
    const { memberID, charName } = stringToCharID(target.containID)
    const member = members[memberID]
    
    // if alt - return none so that cell references for inactive alts don't show anything
    const memberCharName = member?.character?.charName
    const isAlt = !!memberCharName && !!charName && memberCharName !== charName
    if(isAlt) return
    return member
  }

  if(target.containType === eDragType.ROLE && target?.containID){
    const role = copy(y.roles.get(target?.containID))

    if(!!role && Object.keys(role).length > 0) {
      const memberTarget = y.targets.get(role?.memberTargetID)
      if(!memberTarget?.containID){
        console.warn("Error: couldn't find memberTarget");
        return
      }
  
      return getReturnMember(memberTarget)
    }
  }

  return getReturnMember(target)
}


export const panelIDToMacroAtom = atom(null, (get, set, panelID: string, linePrefix = "/ra ") => {
  const y = get(ydocAtom)
  const overrides = get(cellOverridesAtom)
  const members = get(raidMembersState)
  const panelOverride = overrides?.[panelID]

  try {
    const assignment = copy(y.assignments.get(panelID))
    if (!assignment) return

    if (assignment.type === AssignmentType.GRID) {
      const cellToText = (cell: iColumnCell | null, colIndex: number, rowIndex: number): string => {
        if (!cell?.data) return ""
        if (cell?.type === CellType.TEXT) return cell?.data
        if (cell?.type === CellType.ICON) return `{${cell?.data}}`
        if (cell?.type === CellType.MEMBER) {
          const cellOverride = panelOverride?.[`${colIndex}.${rowIndex}`]
          const member = cellOverride?.charID?.memberID
            ? members?.[cellOverride?.charID?.memberID]
            : set(targetIDToMemberAtom, cell?.data)

          if (!member?.character?.charName) return "__"
          return capitalize(member.character.charName)
        }
        if (cell?.type === CellType.LINK) {
          try {
            const url = new URL(cell?.data)
            return url.hostname
          } catch (error) {
            console.error("invalid link")
            return "invalid link"
          }
        }
        return ""
      }

      const prevCellToSpacer = (cell: iColumnCell | null): string => {
        if (cell?.type === CellType.MEMBER && cell?.data && set(targetIDToMemberAtom, cell?.data)) return ", "
        if (cell?.type === CellType.TEXT) return " - "
        return " "
      }

      const columns = assignment.gridData.columns

      const lines: string[] = []
      for (let i = 0; i < columns.length; i++) {
        const col = columns[i]

        for (let u = 0; u < col.cells.length; u++) {
          let line = i === 0 ? `\n${linePrefix}` : lines[u] || ""
          const cell = col.cells[u]

          const spacing = i === 0 ? `` : prevCellToSpacer(columns[i - 1].cells[u])
          const cellString = cellToText(cell, i, u)

          lines[u] = line + spacing + cellString
        }
      }

      lines.splice(0, 0, `${linePrefix}${columns.map((col) => col.header).join(" - ")}`)

      return lines.join("")
    }

    if (assignment.type === AssignmentType.TARGET) {
      const { mainTargetID, addonTargetIDs } = assignment.targetData

      const addonTargetString = addonTargetIDs
        .map((targetID) => {
          const member = set(targetIDToMemberAtom, targetID)
          if (!member?.character?.charName) return "__"
          return capitalize(member?.character?.charName)
        })
        .join(", ")

      const mainTargetString = capitalize(
        set(targetIDToMemberAtom, mainTargetID)?.character?.charName || "__"
      )
      console.log({ mainTargetID, addonTargetIDs, addonTargetString, mainTargetString })
      return `${linePrefix}${assignment.assignmentTitle}\n${linePrefix}${
        assignment.targetData?.icon ? `{${assignment.targetData?.icon}} ` : ""
      }${mainTargetString}: ${addonTargetString}`
    }
  } catch (error) {
    console.error(error);
    toast.error("Failed to export panel as text.")
  }
})

export const panelToTextAtom = atom(null, (get, set, panelID: string, formatCellText?: (cellType: CellType, text: string) => string) => {
  const y = get(ydocAtom)
  const userSettings = get(userSettingsState)
  const overrides = get(cellOverridesAtom)
  const members = get(raidMembersState)
  const panelOverride = overrides?.[panelID]

  function generateTableText(
    gridData: GridData,
    headline?: string
  ): string {
    const { columns } = gridData

    const maxCellLength = userSettings?.panelTextExport?.maxColumnWidth || 12

    const memberToText = (member?: iMember) => {
      if(!member) return ""
      if(memberPossiblyPresent(member?.signupState) !== true) return ""
      if(member.onRoster !== true) return ""
      return capitalize(member?.character?.charName) || ""
    }

    const processCellText = (cell: iColumnCell): string => {
      if(cell?.data && cell?.type === CellType.MEMBER){
        const member = set(targetIDToMemberAtom, cell.data)
        if(member) return memberToText(member)
      }
      return cell?.data || ""
    }

    const getOverridenMemberID = (colIndex: number, rowIndex: number) => {
      const cellOverride = panelOverride?.[`${colIndex}.${rowIndex}`]
      if(!cellOverride) return null
      const member = members?.[cellOverride.charID?.memberID]
      return memberToText(member)
    }

    const getCellText = (cell: iColumnCell | null): string => {
      if(!cell) return ""
      const processed = processCellText(cell).slice(0, maxCellLength)
      if(!formatCellText) return processed || ""
      const formatted = formatCellText(cell.type, processed)
      return formatted
    }


    // Determine column widths
    const columnWidths: number[] = columns.map((column, colIndex) => {
      const headerWidth = column.header.length
      const cellWidths = column.cells
        .filter((cell) => cell !== null)
        .map((cell, rowIndex) => getOverridenMemberID(colIndex, rowIndex)?.length || getCellText(cell)?.length || 0)
        
      const maxLength = Math.max(headerWidth, ...cellWidths)
      return minMax(maxLength, undefined, maxCellLength)
    })

    // Generate header line
    const headerLine = columns
      .map((column, index) => {
        const headerText = column.header.slice(0, maxCellLength)
        const paddedHeader = headerText.padEnd(columnWidths[index])
        return ` ${paddedHeader} `
      })
      .join(" | ")

    // Generate separator line
    const separatorLine = columnWidths.map((width) => "-".repeat(width + 2)).join("---")

    // Generate data rows
    const dataLines: string[] = []
    for (let rowIndex = 0; rowIndex < columns[0].cells.length; rowIndex++) {
      const rowData = columns
        .map((column, colIndex) => {
          const overridenText = getOverridenMemberID(colIndex, rowIndex)
          if(!!overridenText){
            const paddedText = overridenText.padEnd(columnWidths[colIndex])
            return ` ${paddedText} `
          }

          const cell = column.cells[rowIndex]
          if (cell === null) {
            return " ".repeat(columnWidths[colIndex] + 2)
          }
          switch (cell.type) {
            case CellType.TEXT:
              const textText = getCellText(cell)
              const paddedText = textText.padEnd(columnWidths[colIndex])
              return ` ${paddedText} `
            case CellType.MEMBER:
              const memberText = getCellText(cell)
              const paddedMember = memberText.padEnd(columnWidths[colIndex])
              return ` ${paddedMember} `
            case CellType.ICON:
              const iconText = getCellText(cell)
              const paddedIcon = iconText.padEnd(columnWidths[colIndex])
              return ` ${paddedIcon} `
            case CellType.LINK:
              const linkText = getCellText(cell)
              const paddedLink = linkText.padEnd(columnWidths[colIndex])
              return ` ${paddedLink} `
            default:
              return " ".repeat(columnWidths[colIndex] + 2)
          }
        })
        .join(" | ")
      dataLines.push(rowData)
    }

    // Combine lines into table text
    const textRows = [headerLine, separatorLine, ...dataLines]
    if(!!headline) textRows.splice(0,0, ` ${headline} `)

    return textRows.join("\n")
  }

  try {
    const panel = copy(y.assignments.get(panelID))
    if (!panel) return ""
    if(isGridPanel(panel)){
      return generateTableText(panel.gridData, panel?.headline?.text)
    }
    if(isTextPanel(panel)){
      return set(getTextFromTextPanelAtom, { panel })
    }

    return ""
  } catch (error) {
    console.error(error)
    toast.error("Failed to export panel as text.")
  }
})

export const groupsToCSVAtom = atom(null, (get, set) => {
  const userSettings = get(userSettingsState)
  const y = get(ydocAtom)

  const allLists = Array.from(y.lists?.values()) as iList[]
  const groupLists = Object.values(allLists)
    .filter((list) => list.area === Areas.GROUPS)
    .sort((a, b) => {
      const groupNumberA = a.listName.substring("Group ".length)
      const groupNumberB = b.listName.substring("Group ".length)
      const aNumber: number = !isNaN(parseInt(groupNumberA)) ? parseInt(groupNumberA) : 0
      const bNumber: number = !isNaN(parseInt(groupNumberB)) ? parseInt(groupNumberB) : 0
      return aNumber > bNumber ? 1 : -1
    })

  if(groupLists.length < 1) return
  let returnString = ""

  for (let i = 0; i < groupLists.length; i++) {
    const list = groupLists[i];
    const groupMembers: string[] = []
    if(i !== 0) returnString += "\n"

    for (const targetID of list?.targetIDs) {
      if(!targetID) continue
      const member = set(targetIDToMemberAtom, targetID)
      groupMembers.push(capitalize(member?.character?.charName || ""))
    }

    if(userSettings?.groupsFormat === "mrt vertical"){
      returnString += groupMembers.join("\n")
      continue;
    } 
    returnString += groupMembers.join(",")
  }

  return returnString
})


export const copyOrLinkPanelAtom = atom(null, (get, set, selection: string[], mode: PanelCopyMode, assignment: iPanel, isGeneral: boolean = false) => {
  const y = get(ydocAtom)

  const aPos = copy(y.assignmentPos.get())
  const newTargets: iTarget[] = []
  const newAssignment = copy(assignment)
  if(mode === PanelCopyMode.COPY) newAssignment.id = Assignment().createID()
  
  const currentSection = isGeneral ? aPos?.sections["section-1"] : set(getCurrentSectionFromSelectionAtom, aPos, selection)
  if(!currentSection) return
  if(!currentSection?.panels) currentSection.panels = []
  
  if(mode === PanelCopyMode.LINK){
    currentSection.panels.push(newAssignment.id)
    if(!aPos.links.includes(newAssignment.id)) aPos.links.push(newAssignment.id)
    y.assignmentPos.set(aPos)
    return
  }
  
  if(newAssignment.type === AssignmentType.IMAGE || newAssignment.type === AssignmentType.TEXT){
    
  }
  
  // if grid or target -> replace all targets
  if(newAssignment.type === AssignmentType.TARGET){

    // replace main
    const mainTarget = Target().create({ area: Areas.ASSIGNMENTS})
    newTargets.push(mainTarget)
    newAssignment.targetData.mainTargetID = mainTarget.id

    // replace addons
    for (let i = 0; i < newAssignment?.targetData?.addonTargetIDs.length; i++) {
      const oldTargetID = newAssignment?.targetData?.addonTargetIDs[i];
      const oldTarget = y.targets.get(oldTargetID)
      
      const addonTarget = Target().create({
        area: oldTarget?.area || Areas.ASSIGNMENTS,
        containType: oldTarget?.containType || null,
        containID: oldTarget?.containID || null
      });
      newTargets.push(addonTarget)
      newAssignment.targetData.addonTargetIDs[i] = addonTarget.id
    }
  }
  
  if(newAssignment.type === AssignmentType.GRID){
    for (const col of newAssignment.gridData.columns) {
      for (const cell of col.cells) {
        const oldTargetID = cell?.data
        if(cell?.type !== CellType.MEMBER || !oldTargetID) continue;
        const oldTarget = y.targets.get(oldTargetID)
        
        const cellTarget = Target().create({
          area: oldTarget?.area || Areas.ASSIGNMENTS,
          containType: oldTarget?.containType || null,
          containID: oldTarget?.containID || null
        });
        newTargets.push(cellTarget)
        cell.data = cellTarget.id
      }
    }
  }

  set(registerAssignmentsState, [newAssignment])
  currentSection.panels.push(newAssignment.id)
  set(registerTargetsState, newTargets)
  y.assignmentPos.set(aPos)
})

export const getCurrentSectionFromSelectionAtom = atom(null, (get, set, aPos: iAssignmentPositions, selection: string[], depth: number = 0, currentSection?: iSection): iSection | null => {

  const getCurrentSectionFromSelection = (aPos: iAssignmentPositions, selection: string[], depth: number = 0, currentSection?: iSection): iSection | null => {
    if(depth === 0) return getCurrentSectionFromSelection(aPos, selection, 1, aPos?.sections[selection[0]])
    if(currentSection?.sectionOrder) return getCurrentSectionFromSelection(aPos, selection, depth + 1, aPos?.sections[selection[depth]])
    if(currentSection) return currentSection
    console.error("Found no panels in", currentSection, {selection, depth}, aPos);
    return null
  }

  return getCurrentSectionFromSelection(aPos, selection, depth, currentSection)
})

export const createPanelAtom = atom(null, (get, set, selection: string[], assignmentType: assignmentType, isGeneral: boolean = false, data?: any, title: string = "New Assignment") => {
  const y = get(ydocAtom)
  const aPos = copy(y.assignmentPos.get())
  if(selection?.length < 1 && isGeneral === false){
    toast("Please add a section before adding an assignment panel.")
    return
  }
  const currentSection = isGeneral ? aPos?.sections["section-1"] : set(getCurrentSectionFromSelectionAtom, aPos, selection)
  if(!currentSection) return
  if(!currentSection?.panels) currentSection.panels = []

  if(assignmentType === AssignmentType.GRID){
    const targets: iTarget[] = [...Array(6)].map((v) =>
      Target().create({ area: Areas.ASSIGNMENTS})
    )
    
    const aType: assignmentType = AssignmentType.GRID
    
    const columns: GridColumn[] = [
      {
        header: "Column 1",
        cells: [
          { type: CellType.MEMBER, data: targets[0].id },
          { type: CellType.MEMBER, data: targets[1].id },
          { type: CellType.MEMBER, data: targets[2].id }
        ]
      },
      {
        header: "Column 2",
        cells: [
          { type: CellType.MEMBER, data: targets[3].id },
          { type: CellType.MEMBER, data: targets[4].id },
          { type: CellType.MEMBER, data: targets[5].id }
        ]
      }
    ]
    
    set(registerTargetsState, targets)
    const assignment = Assignment().create(aType, title, {gridData: {columns: columns, gridDirection: 0}});
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.IMAGE){
    const assignment = Assignment().create(AssignmentType.IMAGE, title, {imgData: data});
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.VIDEO){
    const assignment = Assignment().create(AssignmentType.VIDEO, title, { videoURL: data }, 3);
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.TEXT){
    const assignment = Assignment().create(AssignmentType.TEXT, title, { textData: { content: [] }});
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.SPACER){
    const assignment = Assignment().create(AssignmentType.SPACER, "spacer", {});
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.SPEC_SWAP){
    console.log("creating spec swap")
    const assignment = Assignment().create(AssignmentType.SPEC_SWAP, "spec-swap", {
      specSwapData: { swaps: [] }
    })
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  if(assignmentType === AssignmentType.TARGET){
    const targets: iTarget[] = []
    for (let i = 0; i < 3; i++) {
      const target = Target().create({ area: Areas.ASSIGNMENTS})
      targets.push(target)
    }

    const targetData: TargetAssignment = {
      mainTargetID: targets[0].id,
      addonTargetIDs: [targets[1].id, targets[2].id]
    }

    const assignment = Assignment().create(AssignmentType.TARGET, title, {targetData: targetData});
    set(registerTargetsState, targets)
    set(registerAssignmentsState, [assignment])
    currentSection.panels.push(assignment.id)
  }

  y.assignmentPos.set(aPos)
})

export const movePanelAtom = atom(
  null,
  (get, set, panelID: string, indexDiff: number, selection: string[], isGeneral: boolean = false) => {
    // move panel by indexDiff amount
    const y = get(ydocAtom)
    const aPos = copy(y.assignmentPos.get())
    const currentSection = isGeneral
      ? aPos.sections["section-1"]
      : set(getCurrentSectionFromSelectionAtom, aPos, selection)
    if (!currentSection) return
    if (!currentSection?.panels) currentSection.panels = []
    const startIndex = currentSection?.panels?.findIndex(_panelID => _panelID === panelID)
    if(startIndex === -1) return

    const fromIndex = startIndex
    const toIndex = startIndex + indexDiff

    const from = currentSection.panels.splice(fromIndex, 1)[0]
    currentSection.panels.splice(toIndex, 0, from)

    y.transactUser(() => {
      y.assignmentPos.set(aPos)
    })
  }
)

function parsePanelId(panelIdString: string) {
  const regex = /^([^:]+):([A-Z]+)(\d+)$/;

  const match = panelIdString.match(regex);

  if (match) {
    const panelID = match[1];
    const columnLetter = match[2];
    const row = parseInt(match[3]);

    return { panelID, columnLetter, row };
  }

  return null; // Return null or handle invalid input as needed
}

function getGridColumnNumber(columnLetter: string): number {
  if (!/^[A-Z]+$/.test(columnLetter)) {
    throw new Error("Invalid input: Please provide only capital letters.");
  }

  let columnNumber = 0;

  for (let i = 0; i < columnLetter.length; i++) {
    const charCode = columnLetter.charCodeAt(i) - 65 + 1; // A=65, so subtract 65 and add 1
    columnNumber = columnNumber * 26 + charCode;
  }

  return columnNumber - 1; // Adjust to start from 0
}

export function getGridColumnLetter(columnNumber: number): string {
  if (columnNumber < 0) {
    throw new Error("Invalid input: Please provide a non-negative column number.");
  }

  let columnName = '';

  while (columnNumber >= 0) {
    const remainder = columnNumber % 26; // 0 to 25 for A to Z
    columnName = String.fromCharCode(65 + remainder) + columnName;
    columnNumber = Math.floor(columnNumber / 26) - 1;
  }

  return columnName;
}



type iCellRefContent = {
  cellType: CellType
  text?: string
  data?: { member: iMember }
}
export const getCellRefContentAtom = atom(
  null,
  (get, set, cellRef: string, panelOverrides?: iPanelIDToOverrides): iCellRefContent | null => {
    const y = get(ydocAtom)
    const members = get(raidMembersState)

    try {
      const panelResults = parsePanelId(cellRef)
      if(!panelResults) return null
      const { columnLetter, panelID, row } = panelResults
      const panel = y.assignments.get(panelID)
      const colIndex = getGridColumnNumber(columnLetter)
      if (!panel) return null
      if (!isGridPanel(panel)) throw new Error("Panel is not of type grid.")
      
      const cell = getCellFromPanel(panel, colIndex, row)
      if (cell?.type === CellType.TEXT) return { cellType: CellType.TEXT, text: cell.data }
      if (cell?.type === CellType.ICON) return { cellType: CellType.ICON, text: "" } // maybe fix?
      
      if (cell?.type === CellType.MEMBER && cell?.data) {
        const targetID = cell.data
        const member = getMemberFromTargetID(targetID, get)
        if (!!member) {
          return {
            cellType: CellType.MEMBER,
            text: member?.character?.charName || "member char n/a",
            data: {
              member
            }
          }
        }

        // cell override
        const overrides = panelOverrides || get(cellOverridesAtom)
        const cellOverride = overrides?.[panelID]?.[`${colIndex}.${row}`]
        const overrideMember = members?.[cellOverride?.charID?.memberID]
        if (!!overrideMember) {
          return {
            cellType: CellType.MEMBER,
            text: overrideMember?.character?.charName || "member char n/a",
            data: {
              member: overrideMember
            }
          }
        }
      }

      return null
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }

    return null
  }
)

interface getTextFromTextPanelProps {
  panel: iPanelText
  extensionsInput?: Extensions
}
export const getTextFromTextPanelAtom = atom(
  null,
  (get, set, props: getTextFromTextPanelProps): string => {
    const { panel, extensionsInput } = props
    try {
      if (!panel?.textData) throw new Error("No text data found to copy.")
      const extensionArray = extensionsInput || getContentExtensionArray(get(roleIDToMemberAtom))

      const doc = getTextAssignmentData(panel?.textData)
      const text = generateText(doc, extensionArray)
      return text
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
      return ""
    }
  }
)

export const copyAllTextPanelsAtom = atom(
  null,
  (get, set) => {
    try {
      const roleIDToMemberMap = get(roleIDToMemberAtom)
      const allOverrides = calculatePanelOverrides({
        aPos: get(assignmentPosState),
        get,
        panels: get(assignmentsState),
        selectedSections: get(selectedAssignmentSectionState),
        calculateAllPanels: true
      })
      const extensionArray = getContentExtensionArray(roleIDToMemberMap, allOverrides)
      const panelsObject = Object.values(get(assignmentsState))
        .filter(panel => panel.type === AssignmentType.TEXT && !!panel?.noteID)
        .reduce((acc, panel) => {
          if(!isTextPanel(panel) || !panel.noteID) return acc
          const panelString = set(getTextFromTextPanelAtom, {
            panel,
            extensionsInput: extensionArray,
          })
          acc[panel.noteID] = panelString
          return acc
        }, {} as Record<string, string>)

      const copyString = JSON.stringify(panelsObject, null, 2)
      copyToClipboard(copyString)
      toast.success(`Copied to clipboard! Length: ${copyString?.length} characters.`)
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)


export const lockMemberCellsAtom = atom(
  null,
  (get, set, panelID: string) => {
    try {
      const cellSmartListDistribution = get(cellOverridesAtom)
      const panelOverrides = cellSmartListDistribution?.[panelID]
      
      set(updateGridPanelCellsAtom, {
        panelID,
        callback(cell, col, row, target) {
          if(cell.type !== CellType.MEMBER) return
          if(!!target?.containID || !isDefined(target)) return
          const cellOverride = panelOverrides?.[`${col}.${row}`]
          if(!cellOverride) return
          target.containID = charIDToString(cellOverride.charID)
          target.containType = eDragType.MEMBER
        },
      })
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)

export const updateGridPanelCellsAtom = atom(
  null,
  (get, set, { panelID, callback }: { panelID: string, callback: (cell: iColumnCell, col: number, row: number, target?: iTarget) => void } ) => {
    try {
      const y = get(ydocAtom)
      y.transactUser(() => {
        set(updateAssignmentState, {
          assignmentID: panelID,
          callback(panel) {
            if (!isGridPanel(panel)) {
              throw new Error("Move smart list instance - not in grid panel")
            }

            for (let col = 0; col < panel.gridData.columns.length; col++) {
              const column = panel.gridData.columns[col];

              for (let row = 0; row < column.cells.length; row++) {
                const cell = column.cells[row];
                if(!cell?.data) continue;
                const target = copy(y.targets.get(cell?.data))
                if(!target) continue;

                callback(cell, col, row, target)
                if(!!target) y.targets.set(target.id, target)
              }
            }

          }
        })
      })
    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)


export const replaceMemberAtom = atom(
  null,
  (get, set, { direction, from, toTargetID }: { direction: "roster" | "bench", from: iDragData, toTargetID: string }) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    const targets = get(targetsState)

    assertIsDragDataMember(from)
    if(isBoolean(raid) || !from.targetID) return

    const oldContainID = targets[toTargetID].containID
    const newContainID = targets[from.targetID].containID
    const { memberID: toMemberID } = stringToCharID(oldContainID)
    const { memberID: fromMemberID } = stringToCharID(newContainID)
    const replacedMember = raid?.members[direction === "roster" ? toMemberID : fromMemberID]
    const newMember = raid?.members[direction === "roster" ? fromMemberID : toMemberID]

    if (!(replacedMember && newMember)) {
      console.error("ReplaceMember - Couldn't find old or new member", {
        oldMember: replacedMember,
        newMember
      })
      return
    }

    y.transactUser(() => {

      // change roster status
      set(updateMemberState, { memberID: replacedMember.userID, callback: (member) => {
        member.onRoster = false
      }})
      set(updateMemberState, { memberID: newMember.userID, callback: (member) => {
        member.onRoster = true
      }})

      // update groupOrder spot
      const replacedMemberIDIndex = raid.groupOrder.findIndex(
        (spot) => spot === replacedMember.userID
      )
      if (replacedMemberIDIndex === -1) {
        return toast.error("Error: Couldn't remove that member from groups.")
      }
      y.raidSync.set(`groupOrder.${replacedMemberIDIndex}`, newMember.userID)

      // replace all member usage - need both to support old target data linking just to member
      const replaceContainIDs = [replacedMember.userID]
      if(oldContainID) replaceContainIDs.push(oldContainID)
      set(replaceTargetContainAtom, {
        replaceContainIDs,
        newContainID
      })
    })
  }
)

export const replaceTargetContainAtom = atom(
  null,
  (get, set, { replaceContainIDs, newContainID }: { replaceContainIDs: string[], newContainID: string | null }) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    if(isBoolean(raid)) return

    y.transactUser(() => {
      // replace all targets that has .containID matching
      const targetArray: iTarget[] = copy(Array.from(y.targets.values()))
      const targetsWithReplacedUserID = targetArray.filter((target) => {
        return (
          target.area !== Areas.ROSTER &&
          target?.containID &&
          replaceContainIDs.includes(target?.containID)
        )
      })

      for (const target of targetsWithReplacedUserID) {
        target.containID = newContainID
        y.targets.set(target.id, target)
      }
    })
    
  }
)

export const createNewRoleCategoryAtom = atom(
  null,
  (get, set, { categoryLabel }: { categoryLabel: string }) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    if (isBoolean(raid)) return

    const list = List().create(categoryLabel, Areas.ROLES, eDragType.ROLE, [])

    y.transactUser(() => {
      const area = copy(y.areas.get(Areas.ROLES))
      if(!area?.areaLists) return
      area.areaLists.push(list.id)
      set(registerListsState, [list])
      y.areas.set(Areas.ROLES, area)
    })
  }
)

export const deleteRoleCategoryAtom = atom(
  null,
  (get, set, { listID }: { listID: string }) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    const list = copy(y.lists.get(listID))
    if (isBoolean(raid) || !list) return

    y.transactUser(() => {
      // delete contained roles
      for (const targetID of list.targetIDs) {
        const target = y.targets.get(targetID)
        if(!target?.containID) continue
        const role = y.roles.get(target?.containID)
        if(!role) continue;
        set(deleteRoleAtom, { roleID: role.id, inListID: list.id })
      }
      
      // remove list from area
      const roleArea = copy(y.areas.get(Areas.ROLES))
      if(!roleArea?.areaLists) return
      roleArea.areaLists = roleArea.areaLists.filter(_listID => _listID !== listID)
      y.areas.set(Areas.ROLES, roleArea)
      // delete list
      set(unregisterListsState, [list.id])
    })
  }
)

export const deleteRoleAtom = atom(
  null,
  (get, set, { roleID, inListID }: { roleID: string, inListID: string }) => {
    const y = get(ydocAtom)
    const raid = get(raidState)
    if (isBoolean(raid)) return

    y.transactUser(() => {
      if(!y.roles.has(roleID)) {
        console.error(`role doesn't exist`);
        return
      }
      const list = copy(y.lists.get(inListID))
      if(!list) throw new Error("List could not be found")
      
      const index = list.targetIDs.findIndex((targetID) => y.targets.get(targetID)?.containID === roleID)
      const targetsToRemove: string[] = Array.from(y.targets.values())
        .filter(target => target.containID === roleID)
        .map(target => target.id)

      const roleTargetID = list.targetIDs?.[index]
      list.targetIDs.splice(index, 1);
      
  
      for (const targetID of targetsToRemove) {
        const target = copy(y.targets.get(targetID))
        if(!target) continue
        y.targets.set(targetID, {...target, containID: null, containType: null })
      }

      y.lists.set(list.id, list)
      y.roles.delete(roleID)
      set(unregisterTargetsState, [roleTargetID])
    })
  }
)

export const removeTargetContentAtom = atom(
  null,
  (get, set, { targetID }: { targetID: string }) => {
    const y = get(ydocAtom)

    if(!(targetID && typeof targetID === "string")) {
      console.error(`targetID not defined or not typeof string`);
      return
    }
    if(!y.targets.has(targetID)) {
      console.error(`target doesn't exist`);
      return
    }
  
    const target = copy(y.targets.get(targetID))
    if(!target) return
  
    target.containID = null
    target.containType = null
  
    y.transactUser(() => {
      y.targets.set(target.id, target)
    })
  }
)