import { Areas, eLocationType } from "../enum"


export type iLayout = {
  web: {
    roster: WebRosterLayout
  },
  discord: {
    embeds: {
      signup: SignupEmbedLayout,
      confirm: ConfirmEmbedLayout
    }
  }
}

export type iLayout_ClassSpec = {
  web: {
    roster: WebRosterLayout_Class_spec
  },
  discord: {
    embeds: {
      signup: SignupEmbedLayout_Class_spec,
      confirm: ConfirmEmbedLayout_Class_spec
    }
  }
}

export type iLayout_Normal = {
  web: {
    roster: WebRosterLayout_Normal
  },
  discord: {
    embeds: {
      signup: SignupEmbedLayout_Normal,
      confirm: ConfirmEmbedLayout_Normal
    }
  }
}

// the types should decide what all locations can look like
// then we have the per location rules object, which decides what alternatives are available
// then we have the current layout UI setup
const classSpecWhatUICanBe = {
  web: {
    areas: {
      [Areas.ROSTER]: ["table", "class-spec"],
      [Areas.GROUPS]: true,
      [Areas.SMARTLISTS]: true,
      [Areas.ROLES]: true,
      [Areas.ASSIGNMENTS]: true,
    }
  },
  discord: {
    embeds: {
      signup: {},
      confirm: {}
    }
  }
}

export enum eSignupEmbed {
  NORMAL = "normal",
  CLASS_SPEC = "class-spec",
}

export enum eSignupMembersToFields {
  NORMAL = "normal",
  CLASS_SPEC = "class-spec",
}

export enum eMemberGroupToField {
  EXTRA_STRING = "extra-string",
}

export enum eMembersToString {
  JOIN = "join",
  JOIN_SIMILAR = "join-similar",
  NEW_LINE = "new-line"
}

export enum eMemberJoinIdentifier {
  CHAR_CLASS_SPEC = "char_class-spec"
}

export enum eMemberToString {
  DISPLAY_NAME = "display-name",
  USER_MENTION = "user-mention",
  
  // class spec
  CHARACTER_NAME = "character-name",
}

export enum eMemberToStringEmoji {
  NONE = "none",
  CLASS_SPEC = "class-spec",
}


export enum eMemberGroupingFunction {
  SIGNUP_STATE = "signup-state",
  ROSTER_CATEGORIES = "roster-categories",
  
  // class spec
  CHARACTER_CLASS = "character-class",
  CHARACTER_GAMEROLE = "character-gamerole",
}

export enum eMemberConfirmLayout {
  GROUPS = "groups",
}




// web roster
export type WebRosterLayout = WebRosterLayout_Normal | WebRosterLayout_Class_spec
export type WebRosterLayout_Normal = {
  type: eLocationType.NORMAL
  groupingFunction: eMemberGroupingFunction.ROSTER_CATEGORIES | eMemberGroupingFunction.SIGNUP_STATE
}

export type WebRosterLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC
  groupingFunction: eMemberGroupingFunction.CHARACTER_CLASS | eMemberGroupingFunction.CHARACTER_GAMEROLE
}




// Confirm embed
export type ConfirmEmbedLayout = ConfirmEmbedLayout_Normal | ConfirmEmbedLayout_Class_spec
export type ConfirmEmbedLayout_Normal = {
  type: eLocationType.NORMAL
  fieldLayout: eMemberConfirmLayout.GROUPS
  memberFields: ConfirmMembersToFields_Normal
  standbyMemberToString: MemberToString_Normal
}

export type ConfirmEmbedLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC
  fieldLayout: eMemberConfirmLayout.GROUPS
  memberFields: ConfirmMembersToFields_Class_Spec
  standbyMemberToString: MemberToString_Class_Spec
}

export type ConfirmMembersToFields = ConfirmMembersToFields_Normal | ConfirmMembersToFields_Class_Spec
export type ConfirmMembersToFields_Normal = {
  membersToString: MembersToString_Normal
}

export type ConfirmMembersToFields_Class_Spec = {
  membersToString: MembersToString_Class_Spec
}

// Signup embed
export type SignupEmbedLayout = SignupEmbedLayout_Class_spec | SignupEmbedLayout_Normal
export type SignupEmbedLayout_Class_spec = {
  type: eLocationType.CLASS_SPEC
  columns: 1 | 2 | 3
  memberFields: SignupMembersToFields_Class_Spec
}

export type SignupEmbedLayout_Normal = {
  type: eLocationType.NORMAL
  columns: 1 | 2 | 3
  memberFields: SignupMembersToFields_Normal
}


// Signup members to fields
export type SignupMembersToFields = SignupMembersToFields_Normal | SignupMembersToFields_Class_Spec
export type SignupMembersToFields_Normal = {
  groupingFunction: eMemberGroupingFunction.ROSTER_CATEGORIES | eMemberGroupingFunction.SIGNUP_STATE
  memberGroupToField: MemberGroupToField_Normal
}

export type SignupMembersToFields_Class_Spec = {
  groupingFunction: eMemberGroupingFunction.CHARACTER_CLASS | eMemberGroupingFunction.CHARACTER_GAMEROLE
  memberGroupToField: MemberGroupToField_Class_Spec
}

// Member group to field
export type MemberGroupToField = MemberGroupToField_Normal | MemberGroupToField_Class_Spec
export type MemberGroupToField_Normal = {
  type: eMemberGroupToField
  membersToString: MembersToString_Normal
}

export type MemberGroupToField_Class_Spec = {
  type: eMemberGroupToField
  membersToString: MembersToString_Class_Spec
}

// Members to string
export type MembersToString = MembersToString_Normal | MembersToString_Class_Spec
export type MembersToString_Normal =
  | {
      type: eMembersToString.JOIN
      memberToString: MemberToString_Normal
    }
  | {
      type: eMembersToString.NEW_LINE
      memberToString: MemberToString_Normal
    }

export type MembersToString_Class_Spec =
  | {
      type: eMembersToString.JOIN_SIMILAR
      joinIdentifier: eMemberJoinIdentifier.CHAR_CLASS_SPEC
      memberToString: MemberToString_Class_Spec
    }
  | {
      type: eMembersToString.JOIN
      memberToString: MemberToString_Class_Spec
    }
  | {
      type: eMembersToString.NEW_LINE
      memberToString: MemberToString_Class_Spec
    } 

// Member to string
export type MemberToString = MemberToString_Class_Spec | MemberToString_Normal
export type MemberToString_Class_Spec = MemberToString_CHARNAME | MemberToString_DISPLAYNAME | MemberToString_USER_MENTION
export type MemberToString_Normal = MemberToString_DISPLAYNAME | MemberToString_USER_MENTION
type MemberToStringCore = {
  showLink?: "always" | "isConfirmed" | "never"
}
export type MemberToString_CHARNAME = MemberToStringCore & {
  type: eMemberToString.CHARACTER_NAME
  showEmoji: eMemberToStringEmoji.CLASS_SPEC | eMemberToStringEmoji.NONE
}
export type MemberToString_DISPLAYNAME = MemberToStringCore & {
  type: eMemberToString.DISPLAY_NAME
  showEmoji: eMemberToStringEmoji.NONE
}
export type MemberToString_USER_MENTION = MemberToStringCore & {
  type: eMemberToString.USER_MENTION
  showEmoji: eMemberToStringEmoji.CLASS_SPEC | eMemberToStringEmoji.NONE
}
