import { dequal } from "dequal"
import React, { CSSProperties, FC, useRef } from "react"
import { iMenuItem } from "typings"
import { DropdownItem, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../dropdown/dropdown"
import { useResetAtom } from "jotai/utils"
import { popOverState } from "../../../global/state/global"

interface PopOverMenuProps {
  menuItems: iMenuItem[]
  modal?: boolean
  style?: CSSProperties
}

export const PopOverMenu: FC<PopOverMenuProps> = ({ menuItems, modal = false, style }) => {
  const ref = useRef<HTMLDivElement>(null)
  const dismissPopOver = useResetAtom(popOverState)
  const items = menuItems?.length > 0 ? menuItems : [{ desc: "No options" }]

  return (
    <div className="pos-rel disable-popper">
      <DropdownMenu open modal={modal}>
        <DropdownMenuTrigger asChild>
          <div style={{ display: "none" }}></div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          avoidCollisions={false}
          sideOffset={-8}
          side="top"
          style={{ ...style }}
          onInteractOutside={dismissPopOver}
        >
          <div ref={ref}>
            {items.map((item) => (
              <React.Fragment key={item.desc}>
                <DropdownItem item={item} dismiss={dismissPopOver} />
              </React.Fragment>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}


const areEqual = (prev: unknown, next: unknown) => {
  const sameProps = dequal(prev, next);
  // const diff1 = diff(prev, next)
  // console.log("PopOverMenu - sameProps", sameProps, diff1)
  return sameProps;
}

export default React.memo(PopOverMenu, areEqual);