import copy from "fast-copy"
import { addCallbacks, getErrorMessage } from "functions"
import { PrimitiveAtom, atom } from "jotai"
import { toast } from "react-hot-toast"
import { ButtonProps } from "../../components/1_atom/buttons/button"
import { anyFunction, isDefined } from "typings"
import { isFunction } from "lodash"


export type iCustomMode = {
  mode: "select-cell" | "preview-restore",
  modeLabel: string,
  text?: string
  button?: ButtonProps
  selectWillClose?: boolean
  fadeBackground?: boolean
  onOpen?: () => void
  onSelect?: (data: any) => void // runs when clicking specific UI elements
  onCancel?: () => void // runs when clicking mode cancel button
  beforeClose?: () => void // runs before onCancel, onSelect and button.click
  onClose?: () => void // runs on onCancel, onSelect and button.click
  close?: () => void // closes the custom mode
}

export const customModeAtom = atom<iCustomMode | null>(null) as PrimitiveAtom<iCustomMode | null>

export const setCustomMode = atom(
  null,
  (get, set, customMode: iCustomMode) => {
    const mode = copy(customMode)

    try {
      // before close callbacks
      const beforeCloseCallback = !!mode?.beforeClose ? mode?.beforeClose : () => {}
      const beforeCloseCallbacks: anyFunction[] = [beforeCloseCallback]
      
      // after close callbacks
      const afterCloseCallback = () => set(closeCustomModeAtom)
      mode.close = afterCloseCallback
      const afterCloseCallbacks: anyFunction[] = [afterCloseCallback]

      if(!!mode?.onOpen) mode.onOpen()

      if(!!mode?.onCancel){
        mode.onCancel = addCallbacks(mode.onCancel, afterCloseCallbacks, beforeCloseCallbacks)
      }

      if(!!mode?.button?.click && isFunction(mode?.button?.click) && mode?.selectWillClose) {
        mode.button.click = addCallbacks(mode.button.click, afterCloseCallbacks)
      }

      // TODO: test that this works
      if(mode?.selectWillClose === true && !!mode?.onSelect){
        mode.onSelect = addCallbacks(mode.onSelect, afterCloseCallbacks, beforeCloseCallbacks)
      }

      // if(mode?.selectWillClose === true && !!mode?.onSelect){
      //   mode.onSelect = (data: unknown) => {
      //     beforeCloseCallback()
      //     customMode.onSelect(data)
      //     set(closeCustomModeAtom)
      //   }
      // }

      set(customModeAtom, mode)

    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)

export const closeCustomModeAtom = atom(
  null,
  (get, set) => {

    try {
      const mode = get(customModeAtom)
      if(!!mode?.onClose) mode.onClose()
      set(customModeAtom, null)

    } catch (error) {
      console.error(error)
      toast.error(getErrorMessage(error))
    }
  }
)


export const toggleHideModalAtom = atom(
  null,
  (get, set, hide?: boolean) => {

    try {
      const modalContainer = document.getElementById("modal-container")
      if(!modalContainer) return
      if(!isDefined(hide)) {
        modalContainer.classList.toggle("hidden")
        return
      }
      if(hide === true){
        modalContainer.classList.add("hidden")
        return
      }
      if(hide === false){
        modalContainer.classList.remove("hidden")
        return
      }
      // const updateFunction = !isDefined(hide) ? modalContainer.classList.toggle
      //   : hide === true ? modalContainer.classList.remove
      //   : modalContainer.classList.add

      // updateFunction("hidden")
    } catch (error) {
      console.error(error)
    }
  }
)