import React, { FC, ReactNode } from 'react';
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { styled } from '@stitches/react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import DotFilledIcon from '../../../public/img/icon/indexFeatures/dotFilled.svg'
import { IconProps, eFeature, iMenuItem } from 'typings';
import SvgIcon from '../buttons/svgIcon';
import AccessTag from '../tag/accessTag';
import { usePremiumFeatureInSignup } from '../../../global/hooks';
import { capitalize, sleep } from 'functions';

// const slideUpAndFade = keyframes({
//   '0%': { opacity: 0, transform: 'translateY(2px)' },
//   '100%': { opacity: 1, transform: 'translateY(0)' },
// });

// const slideRightAndFade = keyframes({
//   '0%': { opacity: 0, transform: 'translateX(-2px)' },
//   '100%': { opacity: 1, transform: 'translateX(0)' },
// });

// const slideDownAndFade = keyframes({
//   '0%': { opacity: 0, transform: 'translateY(-2px)' },
//   '100%': { opacity: 1, transform: 'translateY(0)' },
// });

// const slideLeftAndFade = keyframes({
//   '0%': { opacity: 0, transform: 'translateX(2px)' },
//   '100%': { opacity: 1, transform: 'translateX(0)' },
// });

const contentStyles = {
  minWidth: 150,
  backgroundColor: 'var(--pop-over-bg)',
  borderRadius: 6,
  padding: 8,
  boxShadow: 'var(--pop-over-shadow)',
  zIndex: 10,
  // '@media (prefers-reduced-motion: no-preference)': {
  //   animationDuration: '400ms',
  //   animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  //   animationFillMode: 'forwards',
  //   willChange: 'transform, opacity',
  //   '&[data-state="open"]': {
  //     '&[data-side="top"]': { animationName: slideDownAndFade },
  //     '&[data-side="right"]': { animationName: slideLeftAndFade },
  //     '&[data-side="bottom"]': { animationName: slideUpAndFade },
  //     '&[data-side="left"]': { animationName: slideRightAndFade },
  //   },
  // },
}

const StyledContent = styled(DropdownMenuPrimitive.Content, { ...contentStyles })

const itemStyles = {
  all: 'unset',
  cursor: 'pointer',
  fontSize: 13,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  height: 32,
  padding: '0 6px',
  position: 'relative',
  paddingLeft: 32,
  userSelect: 'none',
  color: 'var(--white-on-dark)',

  '&:focus': {
    backgroundColor: 'rgba(var(--white-on-dark-rgb), 0.12)',
    outline: 'none'
  },
  '&[data-state="open"]': {
    backgroundColor: 'rgba(var(--white-on-dark-rgb), 0.12)',
  },

  '&[data-disabled]': {
    color: 'var(--inactive)',
    pointerEvents: 'none',
  },
  
  variants: {
    color: {
      normal: {},
      danger: {
        color: 'var(--tertiary)',
        '&:focus': {
          backgroundColor: 'rgba(var(--tertiary-rgb), 0.12)',
          outline: 'none',
        },
      },
      inactive: {
        color: 'var(--inactive)',
        '&:focus': {
          backgroundColor: 'rgba(var(--inactive-rgb), 0.12)',
          outline: 'none',
        },
      },
    },
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  // base styles
  ...itemStyles,
});

const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, { ...itemStyles });
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles });
const StyledSubTriggerItem = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 32,
  fontSize: 12,
  lineHeight: '24px',
  color: 'var(--inactive)',
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: 'var(--inactive)',
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 32,
  height: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  color: 'var(--white-on-dark)'
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'var(--pop-over-bg)',
});
const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, { ...contentStyles, zIndex: 100 });

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;

const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: 'var(--inactive)',
  ':focus > &': { color: 'var(--white-on-dark)' },
  '[data-disabled] &': { color: 'var(--inactive)' },
});

interface DropdownProps {
  children: React.ReactNode
  menuItems: iMenuItem[]
  disabled?: boolean
}

export const DropdownItem = ({ item, dismiss }: { item: iMenuItem, dismiss?: (...args: any[]) => any }) => {
  const { hasAccess, checkHasAccess, lowestTier } = usePremiumFeatureInSignup(item?.requiredFeatureAccess || eFeature.VOID)

  if(item?.component) return item.component
  
  const Core = ({ overrideIcon }:{ overrideIcon?: IconProps }) => {
    const icon = overrideIcon || item?.icon

    const Left = () => {
      if (!!item?.left) return item.left
      if (icon) {
        return (
          <SvgIcon
            icon={icon?.icon}
            fill={icon?.fill}
            strokeWidth={icon?.strokeWidth}
            title={icon?.title}
            addClass={`${icon?.addClass}`}
            height={14}
            width={14}
            color="currentColor"
          />
        )
      }
      return <></>
    }

    return (
      <>
        <div
          style={{
            height: 32,
            width: 32,
            padding: !!item?.left ? 0 : 9,
            position: "absolute",
            left: 0,
            color: item?.disabled
              ? "var(--inactive)"
              : item?.color === "danger"
              ? "var(--tertiary)"
              : "var(--white-on-dark)"
          }}
        >
          <Left />
        </div>
        <span style={{ color: "inherit" }}>{capitalize(item.desc)}</span>
        {(!!item?.requiredFeatureAccess && !hasAccess && lowestTier) && (
          <AccessTag label={lowestTier.labelShort} />
        )}
        {/* <RightSlot>⌘+T</RightSlot> */}
      </>
    )
  }

  const Item = () => {
    return (
      <DropdownMenuItem
        textValue={item.desc}
        disabled={item?.disabled}
        color={item?.disabled ? "inactive" : item?.color === "danger" ? "danger" : "normal"}
        onClick={(e) =>
          checkHasAccess(async () => {
            if (item?.link) return
            if (item?.click) {
              if(item?.dismissPopOverOnClick !== false && dismiss) dismiss()
              await sleep(0) // helps with unwanted scroll nudges
              item?.click(e)
            }
          })}
      >
        <Core />
      </DropdownMenuItem>
    )
  }

  if (item?.nested) {
    return (
      <DropdownMenuPrimitive.Sub>
        <StyledSubTriggerItem
          textValue={item.desc}
          color={item?.color === "danger" ? "danger" : "normal"}
        >
          <Core />
          <RightSlot>
            <ChevronRightIcon height={16} width={16} />
          </RightSlot>
        </StyledSubTriggerItem>
        <DropdownMenuPrimitive.Portal>
          <StyledSubContent sideOffset={2} alignOffset={-5} >
            {item?.nested.map((item) => (
              <React.Fragment key={item.desc}>
                <DropdownItem item={item} dismiss={dismiss} />
              </React.Fragment>
            ))}
          </StyledSubContent>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Sub>
    )
  }

  if(item?.link) {
    return (
      <a href={item.link} target={item?.linkDisableTargetBlank ? "_self" : "_blank"}>
        <Item />
      </a>
    )
  }

  if(item?.radio){
    return (
      <>
      {item.radio?.radioLabel && <DropdownMenuLabel>{item.radio?.radioLabel}</DropdownMenuLabel>}
      <DropdownMenuRadioGroup value={item?.radio.defaultValue} onValueChange={(val) => {
        item.radio?.onChange(val)
        dismiss?.()
      }}>
        {item.radio.options.map(option => (
          <DropdownMenuRadioItem key={option.value} value={option.value}>
            <DropdownMenuItemIndicator>
              <DotFilledIcon style={{height: '100%'}}/>
            </DropdownMenuItemIndicator>
            {option.label}
          </DropdownMenuRadioItem>
        ))}
      </DropdownMenuRadioGroup>
      </>
    )
  }

  if (item?.checkbox) {
    return (
      <DropdownMenuCheckboxItem
        checked={item?.checkbox.defaultValue}
        onCheckedChange={(checked) => {
          item?.checkbox?.onChange(checked)
          dismiss?.()
        }}
      >
        <Core overrideIcon={item?.checkbox.defaultValue ? { icon: "checkmark", fill: true } : undefined} />
      </DropdownMenuCheckboxItem>
    )
  }

  if(item?.separator) return <DropdownMenuSeparator />

  return <Item />
}

export const Dropdown: FC<DropdownProps> = ({ children, menuItems, disabled = false }) => {
  
  return (
    <DropdownMenu open={true}>
      <DropdownMenuTrigger asChild disabled={disabled}>
        {children}
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={5} side="top" style={{boxShadow: 'none'}}>
        {menuItems.map((item) => (
          <React.Fragment key={item.desc}>
            <DropdownItem item={item} />
          </React.Fragment>
        ))}
        <div className="tooltip-arrow"></div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default Dropdown;


export const DropdownExample = ({ children }: { children: ReactNode }) => {
  const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
  const [urlsChecked, setUrlsChecked] = React.useState(false);
  const [person, setPerson] = React.useState('pedro');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {children}
      </DropdownMenuTrigger>

      <DropdownMenuContent sideOffset={5}>
        <DropdownMenuItem>
          <div style={{height: 24, width: 24, padding: 4, position: 'absolute', left: 0}}>
            <CheckIcon />
          </div>
          New Tabb <RightSlot>⌘+T</RightSlot>
        </DropdownMenuItem>
        <DropdownMenuItem>
          New Tab <RightSlot>⌘+T</RightSlot>
        </DropdownMenuItem>
        <DropdownMenuItem>
          New Window <RightSlot>⌘+N</RightSlot>
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          New Private Window <RightSlot>⇧+⌘+N</RightSlot>
        </DropdownMenuItem>
        <DropdownMenu>
          <DropdownMenuContent sideOffset={2} alignOffset={-5}>
            <DropdownMenuItem>
              Save Page As… <RightSlot>⌘+S</RightSlot>
            </DropdownMenuItem>
            <DropdownMenuItem>Create Shortcut…</DropdownMenuItem>
            <DropdownMenuItem>Name Window…</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Developer Tools</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={bookmarksChecked}
          onCheckedChange={setBookmarksChecked}
        >
          <DropdownMenuItemIndicator>
            <CheckIcon />
          </DropdownMenuItemIndicator>
          Show Bookmarks <RightSlot>⌘+B</RightSlot>
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked={urlsChecked} onCheckedChange={setUrlsChecked}>
          <DropdownMenuItemIndicator>
            <CheckIcon />
          </DropdownMenuItemIndicator>
          Show Full URLs
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>People</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={person} onValueChange={setPerson}>
          <DropdownMenuRadioItem value="pedro">
            <DropdownMenuItemIndicator>
              <DotFilledIcon />
            </DropdownMenuItemIndicator>
            Pedro Duarte
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="colm">
            <DropdownMenuItemIndicator>
              <DotFilledIcon />
            </DropdownMenuItemIndicator>
            Colm Tuite
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuArrow />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};