import React, { FC, useState } from "react";
import { createContext } from 'use-context-selector';

type FSContextType = {
  setNoSignupFeedback: React.Dispatch<React.SetStateAction<string>>
  noSignupFeedback: string
}
export const FsContext = createContext<FSContextType>(null!);

interface LocationMapProps {
  children: React.ReactNode
}

export const FsProvider: FC<LocationMapProps> = ({ children }) => {

  const [noSignupFeedback, setNoSignupFeedback] = useState<string>("")

  return (
    <FsContext.Provider
      value={{
        noSignupFeedback, setNoSignupFeedback
      }}
    >
      {children}
    </FsContext.Provider>
  );
};

export default FsContext;
