import { GameRole, iSpec } from "typings";

// death knight
const bloodDeathKnightBase: iSpec = {
  gameSpec: "blood",
  iconImgPath: "/img/icon/wowClass/blood_death_knight_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "death knight",
  emoji: "<:blood_death_knight_v1:1175798070512992266>"
}
const bloodDpsDeathKnightBase: iSpec = {
  gameSpec: "blood dps",
  iconImgPath: "/img/icon/wowClass/blood_death_knight_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "death knight",
  emoji: "<:blood_death_knight_v1:1175798070512992266>"
}
const frostDeathKnightBase: iSpec = {
  gameSpec: "frost",
  iconImgPath: "/img/icon/wowClass/frost_death_knight_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "death knight",
  emoji: "<:frost_death_knight_v1:1175798153778307122>"
}
const unholyDeathKnightBase: iSpec = {
  gameSpec: "unholy",
  iconImgPath: "/img/icon/wowClass/unholy_death_knight_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "death knight",
  emoji: "<:unholy_death_knight_v1:1175798267997605950>"
}

// demon hunter
const havocDemonHunterBase: iSpec = {
  gameSpec: "havoc",
  iconImgPath: "/img/icon/wowClass/havoc_demon_hunter_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "demon hunter",
  emoji: "<:havoc_demon_hunter_v1:1175798162309533847>"
}
const vengeanceDemonHunterBase: iSpec = {
  gameSpec: "vengeance",
  iconImgPath: "/img/icon/wowClass/vengeance_demon_hunter_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "demon hunter",
  emoji: "<:vengeance_demon_hunter_v1:1175798269327192135>"
}

// evoker
const augmentationEvokerBase: iSpec = {
  gameSpec: "augmentation",
  iconImgPath: "/img/icon/wowClass/augmentation_evoker_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "evoker",
  emoji: "<:augmentation_evoker_v1:1175798057275760710>"
}
const devastationEvokerBase: iSpec = {
  gameSpec: "devastation",
  iconImgPath: "/img/icon/wowClass/devastation_evoker_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "evoker",
  emoji: "<:devastation_evoker_v1:1175798105837420655>"
}
const preservationEvokerBase: iSpec = {
  gameSpec: "preservation",
  iconImgPath: "/img/icon/wowClass/preservation_evoker_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "evoker",
  emoji: "<:preservation_evoker_v1:1175798220748755064>"
}



// druid
const balanceDruidBase: iSpec = {
  gameSpec: "balance",
  iconImgPath: "/img/icon/wowClass/balance_druid_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "druid",
  emoji: "<:balance_druid_v1:1175798058601156790>"
}

const guardianDruidBase: iSpec = {
  gameSpec: "guardian",
  iconImgPath: "/img/icon/wowClass/guardian_druid_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "druid",
  emoji: "<:guardian_druid_v1:1175798160858288129>"
}

const feralDruidBase: iSpec = {
  gameSpec: "feral",
  iconImgPath: "/img/icon/wowClass/feral_druid_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "druid",
  emoji: "<:feral_druid_v1:1175798149055524984>"
}

const restorationDruidBase: iSpec = {
  gameSpec: "restoration",
  iconImgPath: "/img/icon/wowClass/restoration_druid_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "druid",
  emoji: "<:restoration_druid_v1:1175798228663402677>"
}



// hunter
const beastMasteryHunterBase: iSpec = {
  gameSpec: "beast mastery",
  iconImgPath: "/img/icon/wowClass/beast_mastery_hunter_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "hunter",
  emoji: "<:beast_mastery_hunter_v1:1175798066092195840>"
}

const marksmanshipHunterBase: iSpec = {
  gameSpec: "marksmanship",
  iconImgPath: "/img/icon/wowClass/marksmanship_hunter_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "hunter",
  emoji: "<:marksmanship_hunter_v1:1175798170719109211>"
}

const survivalHunterBase: iSpec = {
  gameSpec: "survival",
  iconImgPath: "/img/icon/wowClass/survival_hunter_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "hunter",
  emoji: "<:survival_hunter_v1:1175798264394686525>"
}

// mage
const arcaneMageBase: iSpec = {
  gameSpec: "arcane",
  iconImgPath: "/img/icon/wowClass/arcane_mage_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "mage",
  emoji: "<:arcane_mage_v1:1175798048530628760>"
}

const fireMageBase: iSpec = {
  gameSpec: "fire",
  iconImgPath: "/img/icon/wowClass/fire_mage_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "mage",
  emoji: "<:fire_mage_v1:1175798152293515274>"
}

const frostMageBase: iSpec = {
  gameSpec: "frost",
  iconImgPath: "/img/icon/wowClass/frost_mage_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "mage",
  emoji: "<:frost_mage_v1:1175798156408147978>"
}

// monk
const brewmasterMonkBase: iSpec = {
  gameSpec: "brewmaster",
  iconImgPath: "/img/icon/wowClass/brewmaster_monk_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "monk",
  emoji: "<:brewmaster_monk_v1:1175798095615885393>"
}

const mistweaverMonkBase: iSpec = {
  gameSpec: "mistweaver",
  iconImgPath: "/img/icon/wowClass/mistweaver_monk_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "monk",
  emoji: "<:mistweaver_monk_v1:1175798216546066444>"
}

const windwalkerMonkBase: iSpec = {
  gameSpec: "windwalker",
  iconImgPath: "/img/icon/wowClass/windwalker_monk_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "monk",
  emoji: "<:windwalker_monk_v1:1175798270648389754>"
}


// paladin
const holyPaladinBase: iSpec = {
  gameSpec: "holy",
  iconImgPath: "/img/icon/wowClass/holy_paladin_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "paladin",
  emoji: "<:holy_paladin_v1:1175798164490555412>"
}

const protectionPaladinBase: iSpec = {
  gameSpec: "protection",
  iconImgPath: "/img/icon/wowClass/protection_paladin_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "paladin",
  emoji: "<:protection_paladin_v1:1175798222296457266>"
}

const retributionPaladinBase: iSpec = {
  gameSpec: "retribution",
  iconImgPath: "/img/icon/wowClass/retribution_paladin_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "paladin",
  emoji: "<:retribution_paladin_v1:1175798234392829952>"
}

// priest
const disciplinePriestBase: iSpec = {
  gameSpec: "discipline",
  iconImgPath: "/img/icon/wowClass/discipline_priest_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "priest",
  emoji: "<:discipline_priest_v1:1175798109369020468>"
}

const holyPriestBase: iSpec = {
  gameSpec: "holy",
  iconImgPath: "/img/icon/wowClass/holy_priest_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "priest",
  emoji: "<:holy_priest_v1:1175798168353513522>"
}

const shadowPriestBase: iSpec = {
  gameSpec: "shadow",
  iconImgPath: "/img/icon/wowClass/shadow_priest_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "priest",
  emoji: "<:shadow_priest_v1:1175798260745642014>"
}

// rogue
const assassinationRogueBase: iSpec = {
  gameSpec: "assassination",
  iconImgPath: "/img/icon/wowClass/assassination_rogue_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "rogue",
  emoji: "<:assassination_rogue_v1:1175798051605069864>"
}

const combatRogueBase: iSpec = {
  gameSpec: "combat",
  iconImgPath: "/img/icon/wowClass/combat_rogue_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "rogue",
  emoji: "<:combat_rogue_v1:1175798099143295016>"
}

const subtletyRogueBase: iSpec = {
  gameSpec: "subtlety",
  iconImgPath: "/img/icon/wowClass/subtlety_rogue_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "rogue",
  emoji: "<:subtlety_rogue_v1:1175798261735497799>"
}

// shaman
const elementalShamanBase: iSpec = {
  gameSpec: "elemental",
  iconImgPath: "/img/icon/wowClass/elemental_shaman_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "shaman",
  emoji: "<:elemental_shaman_v1:1175798112309223485>"
}

const enhancementShamanBase: iSpec = {
  gameSpec: "enhancement",
  iconImgPath: "/img/icon/wowClass/enhancement_shaman_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "shaman",
  emoji: "<:enhancement_shaman_v1:1175798114628669581>"
}
const restorationShamanBase: iSpec = {
  gameSpec: "restoration",
  iconImgPath: "/img/icon/wowClass/restoration_shaman_v1.jpg",
  gameRole: GameRole.HEALER,
  gameClass: "shaman",
  emoji: "<:restoration_shaman_v1:1175798231737839738>"
}


// warlock
const afflictionWarlockBase: iSpec = {
  gameSpec: "affliction",
  iconImgPath: "/img/icon/wowClass/affliction_warlock_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "warlock",
  emoji: "<:affliction_warlock_v1:1175798046148284527>"
}

const demonologyWarlockBase: iSpec = {
  gameSpec: "demonology",
  iconImgPath: "/img/icon/wowClass/demonology_warlock_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "warlock",
  emoji: "<:demonology_warlock_v1:1175798101299187832>"
}

const destructionWarlockBase: iSpec = {
  gameSpec: "destruction",
  iconImgPath: "/img/icon/wowClass/destruction_warlock_v1.jpg",
  gameRole: GameRole.RANGED,
  gameClass: "warlock",
  emoji: "<:destruction_warlock_v1:1175798103174037515>"
}

// warrior
const armsWarriorBase: iSpec = {
  gameSpec: "arms",
  iconImgPath: "/img/icon/wowClass/arms_warrior_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "warrior",
  emoji: "<:arms_warrior_v1:1175798049923145740>"
}

const furyWarriorBase: iSpec = {
  gameSpec: "fury",
  iconImgPath: "/img/icon/wowClass/fury_warrior_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "warrior",
  emoji: "<:fury_warrior_v1:1175798158354305024>"
}

const protectionWarriorBase: iSpec = {
  gameSpec: "protection",
  iconImgPath: "/img/icon/wowClass/protection_warrior_v1.jpg",
  gameRole: GameRole.TANK,
  gameClass: "warrior",
  emoji: "<:protection_warrior_v1:1175798226528518294>"
}



// cata changes
const disciplinePriestPostCata: iSpec = {
  ...disciplinePriestBase,
  iconImgPath: "/img/icon/wowClass/discipline_priest_v2.jpg",
  emoji: "<:discipline_priest_v2:1175798110891560981>"
}

const beastMasteryHunterPostCata: iSpec = {
  ...beastMasteryHunterBase,
  iconImgPath: "/img/icon/wowClass/beast_mastery_hunter_v2.jpg",
  emoji: "<:beast_mastery_hunter_v2:1175798068059328552>"
}

const marksmanshipHunterPostCata: iSpec = {
  ...marksmanshipHunterBase,
  iconImgPath: "/img/icon/wowClass/marksmanship_hunter_v2.jpg",
  emoji: "<:marksmanship_hunter_v2:1175798213798809741>"
}

const survivalHunterPostCata: iSpec = {
  ...survivalHunterBase,
  iconImgPath: "/img/icon/wowClass/survival_hunter_v2.jpg",
  emoji: "<:survival_hunter_v2:1175798265904644106>"
}

const protectionPaladinPostCata: iSpec = {
  ...protectionPaladinBase,
  iconImgPath: "/img/icon/wowClass/protection_paladin_v2.jpg",
  emoji: "<:protection_paladin_v2:1175798223642841099>"
}

// mop changes
const assassinationRoguePostMop: iSpec = {
  ...assassinationRogueBase,
  iconImgPath: "/img/icon/wowClass/assassination_rogue_v2.jpg",
  emoji: "<:assassination_rogue_v2:1175798054645936259>"
}

const enhancementShamanPostMop: iSpec = {
  ...enhancementShamanBase,
  iconImgPath: "/img/icon/wowClass/enhancement_shaman_v2.jpg",
  emoji: "<:enhancement_shaman_v2:1175798115874394142>"
}

// legion changes
const outlawRogueBase: iSpec = {
  gameSpec: "outlaw",
  iconImgPath: "/img/icon/wowClass/outlaw_rogue_v1.jpg",
  gameRole: GameRole.MELEE,
  gameClass: "rogue",
  emoji: "<:outlaw_rogue_v1:1175798218202808421>"
}


// season of discovery
const sodWarlockTank: iSpec = {
  ...demonologyWarlockBase,
  gameSpec: "metamorphosis",
  gameRole: GameRole.TANK,
}

const sodRogueTank: iSpec = {
  ...combatRogueBase,
  gameSpec: "flesh wound",
  gameRole: GameRole.TANK,
}

const sodShamanTank: iSpec = {
  ...enhancementShamanBase,
  gameSpec: "way of earth",
  gameRole: GameRole.TANK,
}

const sodMageHealer: iSpec = {
  ...arcaneMageBase,
  gameSpec: "regeneration",
  gameRole: GameRole.RANGED,
}

export const wowSpecs = {
  beastMasteryHunterBase,
  survivalHunterBase,
  marksmanshipHunterBase,
  armsWarriorBase,
  furyWarriorBase,
  protectionWarriorBase,
  afflictionWarlockBase,
  demonologyWarlockBase,
  destructionWarlockBase,
  arcaneMageBase,
  fireMageBase,
  frostMageBase,
  assassinationRogueBase,
  combatRogueBase,
  outlawRogueBase,
  subtletyRogueBase,
  balanceDruidBase,
  guardianDruidBase,
  feralDruidBase,
  restorationDruidBase,
  elementalShamanBase,
  enhancementShamanBase,
  restorationShamanBase,
  holyPaladinBase,
  protectionPaladinBase,
  retributionPaladinBase,
  disciplinePriestBase,
  holyPriestBase,
  shadowPriestBase,
  bloodDeathKnightBase,
  bloodDpsDeathKnightBase,
  frostDeathKnightBase,
  unholyDeathKnightBase,
  brewmasterMonkBase,
  mistweaverMonkBase,
  windwalkerMonkBase,
  havocDemonHunterBase,
  vengeanceDemonHunterBase,
  devastationEvokerBase,
  augmentationEvokerBase,
  preservationEvokerBase,
  survivalHunterPostCata,
  disciplinePriestPostCata,
  beastMasteryHunterPostCata,
  protectionPaladinPostCata,
  marksmanshipHunterPostCata,
  assassinationRoguePostMop,
  enhancementShamanPostMop,
  sodWarlockTank,
  sodRogueTank,
  sodShamanTank,
  sodMageHealer,
} as const