import { eFSColl, iAutomation } from 'typings';
import { firebase } from '../client'
import { standardCRUD } from '../documentAPI';

const automationsAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iAutomation>(eFSColl.AUTOMATIONS, db, deleteField)

  return { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath };
}

export default automationsAPI;