import { dequal } from "dequal";
import copy from "fast-copy";
import { findCharInMembers, memberToCharIDs, isInArray, membersToCharIDs, isSignedMember, createRosterTarget, charIDToRosterTargetID, getRosterListID } from "functions";
import { atom } from "jotai";
import { charID, iMember, iTarget, isBoolean, eKeyOrderLayout } from "typings";
import { raidState, registerTargetsState, signupGroupingAtom, unregisterTargetsState } from "./raid";
import { ydocAtom } from "./global";
import { splitListsInitialisedState, splitDataState } from "./split";
import { atomWithReset } from "jotai/utils";

export const initialisedCharIDsInSplitAtom = atomWithReset<charID[]>([])
export const updateSplitMembersState = atom(
  null,
  (get, set, { updatedMember }: { updatedMember?: iMember; }) => {
    const hasInitialised = get(splitListsInitialisedState);
    if (hasInitialised === false) return;

    try {
      const raid = copy(get(raidState));
      if (!raid || isBoolean(raid)) return console.log("updateSplitMembersState stop");
      if (!!updatedMember) {
        raid.members[updatedMember.userID] = updatedMember;
      }
      const y = get(ydocAtom);
      const splitData = copy(get(splitDataState));

      const grouping = get(signupGroupingAtom);
      if (!grouping || !splitData) return;
      const { getGroupingKeyOrder } = grouping;
      const categoriesVertical = getGroupingKeyOrder({ mode: "specific", orderLayout: eKeyOrderLayout.VERTICAL });

      const oldCharIDs = get(initialisedCharIDsInSplitAtom);
      const newerCharIDs = membersToCharIDs(Object.values(raid.members), isSignedMember);
      const newCharIDs = newerCharIDs.filter((obj) => isInArray(obj, oldCharIDs) === false);
      const deletedCharIDs = oldCharIDs.filter((obj) => isInArray(obj, newerCharIDs) === false);
      const absentCharIDs = Object.values(raid.members)
        .filter((member) => isSignedMember(member) === false)
        .reduce((acc, member) => [...acc, ...memberToCharIDs(member)], [] as charID[]);
      deletedCharIDs.push(...absentCharIDs);

      // remove from runs
      for (const [twIndex, timeWindow] of Object.entries(splitData.timeWindows)) {
        for (const [sessionIndex, session] of Object.entries(timeWindow.sessions)) {
          for (const [runIndex, run] of Object.entries(session.splits)) {

            // filter out charIDs that are deleted
            const filteredMembers = run.members.filter(
              (charID) => !deletedCharIDs.find((deletedCharID) => dequal(deletedCharID, charID))
            );

            // update if array size diff
            if (run.members.length !== filteredMembers.length) {
              run.members = filteredMembers;
            }
          }
        }
      }

      y.doc.transact(() => {
        // remove from bench
        const removeTargetIDs = deletedCharIDs.map((charID) => charIDToRosterTargetID(charID));

        for (const category of categoriesVertical) {
          const benchListID = getRosterListID("bench", category);
          const list = y.lists.get(benchListID);
          if (!list) continue;
          y.lists.set(benchListID, {
            ...list,
            targetIDs: list.targetIDs.filter((tarID) => isInArray(tarID, removeTargetIDs) === false)
          });
        }

        // add new targets in lists
        const newTargets: iTarget[] = [];
        for (const newCharID of newCharIDs) {
          const char = findCharInMembers(newCharID, raid.members);
          if (!char) continue; // FIXME: GS


          // check if already in list
          const benchListID = getRosterListID("bench", char.gameRole);
          const list = y.lists.get(benchListID);
          if (!list) continue;
          const targetID = charIDToRosterTargetID(newCharID);
          if (list.targetIDs.includes(targetID)) continue;

          // create new target if doesnt exist
          const target = y.targets.get(targetID);
          if (!target) newTargets.push(createRosterTarget(newCharID));

          // add to list
          y.lists.set(benchListID, { ...list, targetIDs: [...list.targetIDs, targetID] });
        }

        set(unregisterTargetsState, removeTargetIDs);
        set(registerTargetsState, newTargets);
        y.splits.set(splitData);
      }, "system");

      set(initialisedCharIDsInSplitAtom, newerCharIDs);
    } catch (error) {
      console.error(error);
    }
  }
);
