import { iRaid } from "../documents/iRaid"
import { iRaidData } from "../documents/iRaidData"
import { gameRoleType, iMember } from "../documents/raid/iMember"
import { iLocationMap } from "../documents/raid/raid"
import { iSmartList } from "../documents/raidData/iSmartList"
import { iCharacter } from "../documents/user/user"
import { GroupedArray } from "../types"
import { Areas, GameRole, eKeyOrderLayout, eLocationType, eRequired } from "./enum"
import { WOW_API_GetCharacter, WOW_API_GetFactionAuctions } from "./gameAPI/wow/wowGameAPITypes"
import { options } from "./ui/autoComplete"
import { eMemberGroupingFunction, iLayout } from "./ui/iLayout"

export const CURRENT_DEFAULT_MAP: iLocationMap = {
  tier1: "online",
}

export type serverRegion = "eu" | "us" | "kr" | "tw" | "br"
export type locationListNameType = {
  [gameRole in gameRoleType]: string
}
export interface iLocationIcon {
  iconLabel: string
  iconImgPath: string
}
export interface iGameServerZone {
  timeZone: string // AINA - Europe/paris https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  timeZoneLabel: string // CET
  region: serverRegion
  servers: string[] // https://worldofwarcraft.blizzard.com/en-us/game/status/us
  gameLabel: string
  locationLabel: string
}

export interface iSpec {
  gameSpec: string
  iconImgPath: string
  gameRole: gameRoleType
  gameClass: string
  emoji: string
}

export interface iClass {
  gameClass: string
  iconImgPath: string
  colorDark: string
  colorLight?: string
  emoji: string
  specs: {
    [specName: string]: iSpec
  }
}

export type iRosterView = "table" | "category"
export interface iLocationWebUIAreas {
  [Areas.ROSTER]: {
    default: iRosterView
    available: iRosterView[]
  }
  [Areas.GROUPS]: boolean
  [Areas.SMARTLISTS]: boolean
  [Areas.ROLES]: boolean
  [Areas.ASSIGNMENTS]: boolean
}


export interface iLocationUI {
  layoutData: {
    defaultLayout: iLayout
  }
  web: {
    warcraftLogsPrefix?: string
    areas: iLocationWebUIAreas
  },
  // discord: {}
}

export type iGameAPI = {
  getCharacter: WOW_API_GetCharacter
  getFactionAuctions: WOW_API_GetFactionAuctions

  // verifyCharacterOwnership - verifies that user owns that character
  // getCharacterSpec: (charName: string, server: string, namespace: string) => any
  // getCharacterGear: (charName: string, server: string, namespace: string) => any
  // getGuildRoster: (guildName: string, server: string, namespace: string) => any
}

export interface iLocationGame {
  factionOptions: string[]
  classes: { [className: string]: iClass }
  gameServerZones?: iGameServerZone[]
  filterCharacters: {
    filterByServer: boolean
    filterByFaction: boolean
  }
  gameRoleLayouts: {
    [key in eKeyOrderLayout]: GameRole[]
  }
  gameRoleMeta: iCategoryMeta
  gameAPI?: iGameAPI
  getFullSignupPlaceholders: (location: iLocationClassSpec) => { char: iCharacter }[][]
}

export type iLocationCore = {
  locationLabel: string
  locationLabelExtended: string
  locMap: iLocationMap
  ui: iLocationUI
  defaultMaxSize: number
  iconLookupURL: string
  locationIcons: iLocationIcon[]
  templates: options<string>
  getIconURL: (fileName: string) => string
  groupCompTools?: {
    label: string
    data: { type: "link", url: (signup: iRaid, raidData: iRaidData) => string, img: string }
    // | { type: "function", callback: (...args: any) => any }
  }[]
  examples?: {
    splitDistribution?: iSmartList[]
    smartLists?: iSmartList[]
  },
  signupRules: {
    server: eRequired.ALWAYS | eRequired.NEVER
    character: eRequired.ALWAYS | eRequired.NEVER
  }
}

export type iLocationNormal = iLocationCore & {
  type: eLocationType.NORMAL
}

export type iLocationClassSpec = iLocationCore & {
  type: eLocationType.CLASS_SPEC
  game: iLocationGame
}

export type iLocation = iLocationNormal | iLocationClassSpec

type getGroupingKeyOrderParams =
  | {
      mode: "column-number"
      numberOfCols: number
    }
  | {
      mode: "specific"
      orderLayout: eKeyOrderLayout
    }

export type iCategoryMetaDetails = {
  label: string
  labelPlural: string
  emoji: string
  colorDark?: string
  colorLight?: string
  iconImgPath?: string
  hideInSignupEmbed?: boolean
  mergeMembersIntoCategoryID?: string // must point to earlier category
  columns?: "normal" | "max-columns"
}
export type iCategoryMeta<T extends string = string> = Record<T, iCategoryMetaDetails>

export type iGrouping = {
  id: eMemberGroupingFunction
  getGroupingKeyOrder: (params: getGroupingKeyOrderParams) => string[]
  getGroupedMembers: (memberArray: iMember[]) => GroupedArray<iMember>
  getMemberVariants: (member: iMember, includeMain?: boolean, includeAlts?: boolean) => iMember[]
  groupingLabel: string
  groupingLabelPlural: string
  categoryMeta: iCategoryMeta
}

export type iMemberGroup = {
  id: string
  label: string
  members: iMember[]
}