import {
  iRaidData, iRaid,
  Areas, ListNamesReversed,
  actionUseTemplate,
  isUseTemplateAction,
  iAutomation, iTextData,
  iDragData,
  assertIsDragDataMember,
  CellType,
  MakeKeysOptional
} from "typings";
import { addDays, addHours, addMinutes } from 'date-fns';
import { dequal } from 'dequal';
import copy from 'fast-copy';
import { omit } from 'lodash';
import { JSONContent } from '@tiptap/react';
import { iDropData } from "../components/dnd_kit/droppable/iDropData";
import { checkMemberCanDrop, getErrorMessage, handleAsyncError, listIDToGameRole } from 'functions';
import toast from 'react-hot-toast';


// AUTHOR: Muthukrishnan
// LINK: https://stackoverflow.com/a/53739792/8512499
export const flattenObject = (obj: Record<string, any>) => {
  var toReturn = {};

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue

    if (typeof obj[i] == "object" && obj[i] !== null) {
      var flatObject = flattenObject(obj[i])
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        // @ts-ignore
        toReturn[i + "." + x] = flatObject[x]
      }
    } else {
      // @ts-ignore
      toReturn[i] = obj[i]
    }
  }
  return toReturn;
}


export const scrubRaidData = (rd: iRaidData) => {
  const usedTargetIDs: string[] = []
  const usedRoleIDs: string[] = []
  let listsFixed = 0
  let listsRemoved = 0
  let targetsRemoved = 0

  const raidData = copy(rd)

  const removeListNames = [...Object.keys(ListNamesReversed)]

  // scrub lists with old listObj
  for (const list of Object.values(raidData.lists)) {

    // remove old roster & group lists
    if(removeListNames.includes(list.listName) || list.listName.startsWith("Group ")) {
      listsRemoved += 1
      delete raidData.lists[list.id]
      continue;
    }

    // @ts-ignore
    const listObjects: {targetID: string, roleID: string}[] = list?.listObjectIDs
    if(listObjects){
      listsFixed += 1
      list.targetIDs = listObjects.map(listObj => listObj.targetID)
      // @ts-ignore
      delete list?.listObjectIDs
    }
  }

  // remove targets from old roster & groups

  for (const target of Object.values(raidData.targets)) {
    if(target.area === Areas.ROSTER || target.area === Areas.GROUPS) {
      targetsRemoved += 1
      delete raidData.targets[target.id]
    }
  }

  type raidDataWithoutTargetsType = MakeKeysOptional<iRaidData, "targets">
  const raidDataWithoutTargets: raidDataWithoutTargetsType = copy(rd)
  delete raidDataWithoutTargets.targets

  const targetIDsBefore = Object.values(raidData.targets).length
  const roleIDsBefore = Object.values(raidData.roles).length
  const flatRaidDataWithoutTargets = flattenObject(raidDataWithoutTargets)

  // check targets
  for (const val of Object.values(flatRaidDataWithoutTargets)) {
    if(typeof val !== "string") continue;
    if(val.startsWith("target-") && true) {
      usedTargetIDs.push(val)
      continue;
    }
  }

  // check roles
  const roleListIDs =
    raidData?.areas[Areas.ROLES]?.areaLists &&
    Array.isArray(raidData?.areas[Areas.ROLES]?.areaLists)
      ? raidData?.areas[Areas.ROLES]?.areaLists
      : [];
  
  for (const listID of roleListIDs) {
    const list = raidData?.lists[listID]
    if(!list) continue;

    for (const targetID of Object.values(list.targetIDs)) {
      const roleID = raidData?.targets[targetID]?.containID
      if(!roleID) continue;
      const role = raidData.roles[roleID]
      if(!role) continue;
      usedRoleIDs.push(role.id)
    }
  }

  const unUsedTargetIDs = Object.values(raidData.targets)
    .filter((target) => {
      if(usedTargetIDs.includes(target.id)) return false;
      return true;
    })
    .map((target) => target.id);
  
  const unUsedRoleIDs = Object.values(raidData.roles)
    .filter((role) => {
      if(usedRoleIDs.includes(role.id)) return false;
      return true;
    })
    .map((role) => role.id);
  
  if(unUsedTargetIDs.length > 0){
    for (const target of Object.values(raidData.targets)) {
      if(unUsedTargetIDs.includes(target.id)) delete raidData.targets[target.id]
    }
  }

  if(unUsedRoleIDs.length > 0){
    for (const role of Object.values(raidData.roles)) {
      if(unUsedRoleIDs.includes(role.id)) delete raidData.roles[role.id]
    }
  }

  const scrubbedTargets = targetIDsBefore - Object.values(raidData.targets).length
  const scrubbedRoles = roleIDsBefore - Object.values(raidData.roles).length
  const totalScrubbed = scrubbedTargets + scrubbedRoles + listsFixed + listsRemoved + targetsRemoved
  if(totalScrubbed > 0) {
    console.log(`Scrubbed ${scrubbedTargets} targets & ${scrubbedRoles} roles, removed ${listsRemoved} lists, ${targetsRemoved} targets and fixed ${listsFixed} lists from raidData.`)
  }

  return raidData
}

export const dateAndDelayByStringToUnix = (
  startDate: number | Date,
  delayBy: string
) => {
  if(!startDate || !delayBy) return 0
  const delayByArrSplit = delayBy.split(".").map(str => parseInt(str))
  if(!(delayByArrSplit && delayByArrSplit?.length === 3)) throw new Error("DelayBy string invalid")
  return dateAndDelayByToUnix(startDate, delayByArrSplit[0], delayByArrSplit[1], delayByArrSplit[2])
};

export const dateAndDelayByToUnix = (
  startDate: number | Date,
  daysAfter: number = 0,
  hoursAfter: number = 0,
  minutesAfter: number = 0
) => {
  let date = new Date(startDate);
  date = addDays(date, daysAfter);
  date = addHours(date, hoursAfter);
  date = addMinutes(date, minutesAfter);
  return date.getTime();
};

export const automationToUsedRaidTemplates = (automation: iAutomation, templateRaids: iRaid[]) => {
  const arr: {action: actionUseTemplate, raid: iRaid}[] = []
  const sequences = Object.keys(automation.sequences).sort().map(key => automation.sequences[key])
  
  // find template IDs
  for (const sequence of sequences) {
    for (const step of sequence.steps) {
      if(!isUseTemplateAction(step.action)) continue;
      if(!step.action.templateID) throw new Error("Use template without signupID")
      
      // @ts-ignore
      const templateRaid = templateRaids?.find(raid => raid.raidID === step.action?.templateID)
      if(!templateRaid) continue;
      arr.push({action: step.action, raid: templateRaid})
    }
  }

  return arr
}

export const roundNearQtr = function(number: number): number {
  return parseFloat((Math.round(number * 4) / 4).toFixed(2));
};

export const stringToRoundedFloat = (str: string, min?: number, max?: number): number => {
  const strFiltered = str.replace(",", ".")
  const float = roundNearQtr(parseFloat(strFiltered))
  if (isNaN(parseFloat(strFiltered))) return min || 0
  if(!!min && float < min) return min
  if (!!max && float > max) return max
  return float
}

export const scrollToTargetAdjusted = (id: string, offset = 60) => {
  var element = document.getElementById(id)
  if(!element) return
  var elementPosition = element.getBoundingClientRect().top
  var offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}

export const stringArrayToObjectPropertiesWithValue = <T extends unknown>(
  arr: string[],
  value: T
): Record<string, T> => {
  return arr.reduce((ac, a) => ({ ...ac, [a]: value }), {})
}

export const validateRaidID = (str: string): true => {
  if(typeof str !== "string") {
    throw new Error("Cannot load signup. Error: That signup ID is not a string. Make sure your URL is correct.")
  }
  const validCharacters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-"
  const characters = Array.from(str)
  if(characters.some(c => validCharacters.includes(c) === false)) {
    throw new Error("Cannot load signup. Error: That signup ID contains invalid characters. Make sure your URL is correct.")
  }
  return true
}

export const dequalExceptKeysEqualInKey = (
  a: Record<string, unknown>,
  b: Record<string, unknown>,
  compareKeysOnly: string
) => {
  const typeEqual = typeof a?.[compareKeysOnly] === typeof b?.[compareKeysOnly]
  if(typeEqual === false) return false
  
  const omitEqual = dequal(omit(a, compareKeysOnly), omit(b, compareKeysOnly))
  if(typeof a?.[compareKeysOnly] !== "object") return omitEqual

  // @ts-ignore
  const keysEqual = dequal(Object.keys(a?.[compareKeysOnly]), Object.keys(b?.[compareKeysOnly]))
  return omitEqual && keysEqual
}

export const getTextAssignmentData = (textData: iTextData): JSONContent => {
  const doc: JSONContent = typeof textData === "string" ? JSON.parse(textData) as JSONContent : textData
  return doc
}

export const checkValidSplitDrop = (dragData: iDragData, dropData: iDropData) => {
  try {
    const dropListID = dropData?.list?.id
    const dragInListID = dragData.inList
    if(!dropListID || !dragInListID || !dropData?.runID) {
      throw new Error("Couldn't verify valid drop")
    }
    assertIsDragDataMember(dragData)
    const validGameRole = listIDToGameRole(dropListID) === listIDToGameRole(dragInListID)
    const validTWEligibility = checkMemberCanDrop(dragData.member, dropData?.runID)
    if(dragData?.runID === dropData?.runID) return false // prevents swap in same runID
    return validGameRole && validTWEligibility
  } catch (error) {
    console.error(error);
    return false
  }
}

export const getEventDurationString = (start: number, end: number) => {
  const durationMS = end - start
  const ONE_HOUR_MS = 3600000
  if(durationMS <= (ONE_HOUR_MS / 2)) return "half-hour"
  if(durationMS <= (ONE_HOUR_MS)) return "one-hour"
  return ""
}


export const toastPromise = async (
  promiseCallback: (...args: any[]) => Promise<void>,
  {
    loadingText = "Loading...",
    successText = "Success!",
    errorText = (err) => `${getErrorMessage(err)}`
  }: { loadingText?: string; successText?: string; errorText?: (err: Error) => string }
) => {
  const toastId = toast.loading(loadingText)
  const modifiedCallback = async () => {
    await promiseCallback()
    toast.success(successText, { id: toastId })
  }

  await handleAsyncError(modifiedCallback, (err) =>
    toast.error(errorText(err), { id: toastId })
  )
}

export function formatCellText(cellType: CellType, text: string) {
  switch (cellType) {
    case CellType.ICON:
      if(text.startsWith("::")) return text.slice(2)
      return text
    default:
      return text || ""
  }
}