import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { iLocationMap, iSpec, isClassSpecLoc } from "typings";
import { useContextSelector } from "use-context-selector";
import LocationContext from "../context/locationContext";
import { signupGroupingAtom, signupLocationAtom } from "./state/raid";

export const useLocation = (useMap?: iLocationMap) => {
  const location = useContextSelector(LocationContext, state => state.location);
  const map = useContextSelector(LocationContext, state => state.map);
  const setMap = useContextSelector(LocationContext, state => state.setMap);

  useEffect(() => {
    if(!!useMap) setMap(useMap)
  }, [useMap])

  const getLocationSpec = (charClass: string, charSpec: string): iSpec | null => {
    if(isClassSpecLoc(location) === false) return null
    return location?.game?.classes[charClass]?.specs[charSpec] || null
  }

  const getFullSignupPlaceholders = () => {
    if(isClassSpecLoc(location) === false) return []
    return location?.game?.getFullSignupPlaceholders(location)
  }

  return {
    location,
    map, setMap,
    getLocationSpec,
    getFullSignupPlaceholders,
  };
};

export const useSignupLocation = () => {
  const location = useAtomValue(signupLocationAtom)
  const grouping = useAtomValue(signupGroupingAtom)
  
  if(!location || !grouping) throw new Error("Signup location or grouping is not initialised")
  return { location, grouping }
}