import { eActionRequired } from "typings"
import { iErrorProps } from "./customError"
import { ER400_BAD_REQUEST, ER401_UNAUTHORIZED, ER404_NOT_FOUND, ER408_REQUEST_TIMEOUT, ER409_CONFLICT, ER413_PAYLOAD_TOO_LARGE, ER500_SERVER_ERROR } from "./standardErrors"

// 400
export const ER_REQUIRED_FIELD_MISSING_400: iErrorProps = {
  ...ER400_BAD_REQUEST,
  title: {
    ...ER400_BAD_REQUEST.title,
    en: "Missing Field",
    fr: "Champ Manquant",
    es: "Campo Faltante"
  },
  message: {
    ...ER400_BAD_REQUEST.message,
    en: "A required field is missing.",
    fr: "Un champ obligatoire est manquant.",
    es: "Falta un campo obligatorio."
  }
}

export const ER_CHARNAME_INVALID_400: iErrorProps = {
  ...ER400_BAD_REQUEST,
  title: {
    ...ER400_BAD_REQUEST.title,
    en: "Character name invalid",
  },
  message: {
    ...ER400_BAD_REQUEST.message,
    en: `Character name is not valid.\nRules: The name must be between 2-12 characters long.\nAccented characters are supported.\nNumbers and symbols are not supported.\nMixed capitals and spaces are not supported.`,
  }
}

// 401
export const ER_USER_NOT_LOGGED_IN_401: iErrorProps = {
  ...ER401_UNAUTHORIZED,
  title: { ...ER401_UNAUTHORIZED.title, en: "User not logged in" },
  message: { ...ER401_UNAUTHORIZED.message, en: "User not logged in. Please login and try again." },
  // You must be logged in to make this request. i.e Authroization: Bearer <token>
  actionRequired: eActionRequired.RENEW_FIREBASE_AUTH_TOKEN
}

// 404
export const ER_USER_NOT_FOUND_404: iErrorProps = {
  ...ER404_NOT_FOUND,
  title: { ...ER404_NOT_FOUND.title, en: "User not found" },
  message: { ...ER404_NOT_FOUND.message, en: "That user could not be found. If you have only ever used Prepi throught he Discord bot, make sure to login once through the website." }
}

export const ER_USER_OR_GUEST_NOT_FOUND_404: iErrorProps = {
  ...ER404_NOT_FOUND,
  title: { ...ER404_NOT_FOUND.title, en: "User not found" },
  message: { ...ER404_NOT_FOUND.message, en: "That user could not be found, this is likely a bug. If you encounter this, please report it to us on our Discord server." }
}

export const ER_CHAR_NOT_FOUND_404: iErrorProps = {
  ...ER404_NOT_FOUND,
  title: { ...ER404_NOT_FOUND.title, en: "Character not found", },
  message: { ...ER404_NOT_FOUND.message, en: "That character could not be found.", },
}

export const ER_NO_VALID_CHARS_FOUND_404: iErrorProps = {
  ...ER404_NOT_FOUND,
  title: { ...ER404_NOT_FOUND.title, en: "No valid characters found", },
  message: { ...ER404_NOT_FOUND.message, en: "No valid characters found on that server.", },
  actionRequired: eActionRequired.CREATE_CHAR
}

export const ER_NO_TIMEZONE_FOUND_404: iErrorProps = {
  ...ER404_NOT_FOUND,
  title: { ...ER404_NOT_FOUND.title, en: "User time zone not defined", },
  message: { ...ER404_NOT_FOUND.message, en: "You need to set your default time zone first. You can do so at https://prepi.io/settings?show=general or by using discord bot command /settings time zone.", },
}


// 408
export const ER_INTERACTION_TIMED_OUT_408: iErrorProps = {
  ...ER408_REQUEST_TIMEOUT,
  title: { ...ER408_REQUEST_TIMEOUT.title, en: "Interaction timed out", },
  message: { ...ER408_REQUEST_TIMEOUT.message, en: "Interaction timed out.", },
}

// 409
export const ER_ALREADY_EXISTS_409: iErrorProps = {
  ...ER409_CONFLICT,
  title: { ...ER409_CONFLICT.title, en: "Already exists", },
  message: { ...ER409_CONFLICT.message, en: "That resource already exists.", },
}

// 413
export const ER_INTERACTION_TIMED_OUT_413: iErrorProps = {
  ...ER413_PAYLOAD_TOO_LARGE,
  title: { ...ER413_PAYLOAD_TOO_LARGE.title, en: "Interaction timed out", },
  message: { ...ER413_PAYLOAD_TOO_LARGE.message, en: "Interaction timed out.", },
}


// 500
export const ER_DOCUMENT_NOT_CREATED_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Failed to create document", },
  message: { ...ER500_SERVER_ERROR.message, en: "Failed to create new document. This is likely an issue between the server and the database. Please try again, if the problem persists, feel free to reach out to us on our Discord server.", },
}

export const ER_GAME_SERVER_INVALID_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Game server time zone invalid", },
  message: { ...ER500_SERVER_ERROR.message, en: "Couldn't verify game server time zone, this is likely a bug. If you encounter this, please report it to us on our Discord server.", },
}

export const ER_INVALID_INTERACTION_ID_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Invalid interaction ID", },
  message: { ...ER500_SERVER_ERROR.message, en: "Invalid interaction ID. This is a server error. If you encounter this, please report it to us on our Discord server.", },
}

export const ER_INVALID_INTERACTION_TYPE_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Invalid interaction type", },
  message: { ...ER500_SERVER_ERROR.message, en: "Invalid interaction type. This is a server error. If you encounter this, please report it to us on our Discord server.", },
}

export const ER_LOCATION_DATA_NOT_FOUND_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Location data not found", },
  message: { ...ER500_SERVER_ERROR.message, en: "Location data could not be found. If you encounter this, please report it to us on our Discord server.", },
}

export const ER_LOCATION_INVALID_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Location invalid", },
  message: { ...ER500_SERVER_ERROR.message, en: "Location invalid. If you encounter this, please report it to us on our Discord server.", },
}

export const ER_SPLITDATA_NOT_FOUND_500: iErrorProps = {
  ...ER500_SERVER_ERROR,
  title: { ...ER500_SERVER_ERROR.title, en: "Signup split data not found", },
  message: { ...ER500_SERVER_ERROR.message, en: "Signup does not have split signup data. If you encounter this, please report it to us on our Discord server.", },
}