import { eFeature, isNever } from "typings"
import { prepiTeamBoostTiers } from "./boostTiers"

export const checkHasPermission = (
  tierID: string,
  permission: eFeature
): {
  hasPermission: boolean
  multiple: number
} => {
  const noPermissions = { hasPermission: false, multiple: 0 }

  const perm = prepiTeamBoostTiers?.[tierID]?.features?.[permission]
  if (!perm) return noPermissions

  if (typeof perm === "number") {
    return { hasPermission: true, multiple: perm }
  }

  if (perm === true) return { hasPermission: true, multiple: 0 }
  isNever(perm)
  return noPermissions
}

