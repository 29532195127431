
// May change if firestore data needs to change collection
export enum eFSColl {
  SIGNUPS = "signups",
  USERS = "users_new",
  RAIDDATA = "raidData",
  AUTOMATIONS = "automations",
  TEAMS = "teams",
  ANALYTICS = "analytics",
  GUESTS = "guests",
}

export enum eDocType {
  SIGNUP = "signup",
  USER = "user",
  RAIDDATA = "raidData",
  AUTOMATION = "automation",
  TEAM = "team",
  GUEST = "guest",
}

export enum UserFlags {
  ALPHA_TESTER = "alpha_tester",
}

export enum eDragType {
  NONE = "none",
  MEMBER = "member",
  ROLE = "role",
  CONTAINER = "container",
  SMARTLISTITEM = "sl-item",
  SMARTLIST = "smart-list",
  SMARTLIST_INSTANCE = "smart-list-instance",
  ITEM = "item"
}

export enum PanelCopyMode {
  COPY = "copy",
  LINK = "link"
}

export enum Areas {
  ROSTER = "roster",
  GROUPS = "groups",
  ROLES = "roles",
  SMARTLISTS = "smart-lists",
  ASSIGNMENTS = "assignments",
  DRAGLAYER = "drag-layer",
  VOID = "void" // sometimes used as default area
}

export enum signupModes {
  ROSTERFIRST = "roster-first",
  BENCHFIRST = "bench-first"
}

export enum SignupStates {
  SIGNED = "signed",
  AVAILABLE = "available",
  TENTATIVE = "tentative",
  LATE = "late",
  ABSENT = "absent",
  GHOST = "ghost",
  NONE = "none"
}

export enum GameRole {
  TANK = "tank",
  MELEE = "melee",
  RANGED = "ranged",
  HEALER = "healer"
}

export enum eReplaceWithSearchHitType {
  CLASS = "class",
  SPEC = "spec",
  GAMEROLE = "game role",
  ROLE = "role",
  GROUP_POS = "group pos",
  HIDELABEL = "hide label"
}

export const ListNames = {
  ROSTER: {
    ROSTER: {
      "tank": "Tanks",
      "melee": "Melee DPS",
      "ranged": "Ranged DPS",
      "healer": "Healers"
    },
    BENCH: {
      "tank": "Tanks bench",
      "melee": "Melee DPS bench",
      "ranged": "Ranged DPS bench",
      "healer": "Healers bench"
    }
  }
};
Object.freeze(ListNames);

export const ListNamesReversed: {
  [key: string]: { onRoster: boolean; role: GameRole; }
} = {
  "Tanks": { onRoster: true, role: GameRole.TANK },
  "Tanks bench": { onRoster: false, role: GameRole.TANK },
  "Melee DPS": { onRoster: true, role: GameRole.MELEE },
  "Melee DPS bench": { onRoster: false, role: GameRole.MELEE },
  "Ranged DPS": { onRoster: true, role: GameRole.RANGED },
  "Ranged DPS bench": { onRoster: false, role: GameRole.RANGED },
  "Healers": { onRoster: true, role: GameRole.HEALER },
  "Healers bench": { onRoster: false, role: GameRole.HEALER },
};
Object.freeze(ListNamesReversed);

export enum RaidFlags {
  PREVENT_AVAILABLE = "prevent_available",
  PREVENT_TENTATIVE = "prevent_tentative",
  PREVENT_ALTERNATE_CHARS = "prevent_alternate_chars",
  NOTIFY_OWNER_SIGNOFF = "notify-owner-signoff",
  DISCORD_EMBEDS_MEMBER_PROFILENAME = "discord-embeds-member-profile-name"
}


export enum eDuplicateKeep {
  SIGNUPS = "signups",
  ROLESANDASSIGNMENTS = "roles-and-assignments"
}

export enum eSignupVisibility {
  OPEN = "open",
  ROSTER = "roster",
  ADMIN = "admin"
}

export enum eAdminStatus {
  OWNER = "owner",
  MANAGER = "manager",
  ADMIN = "admin",
  NONE = "none"
}

export enum eSignupLogType {
  SIGNUP = "signup",
  SIGNOFF = "signoff", // set absent after previously signed up
  DELETE_MEMBER = "delete-member",
  PUBLISH = "publish",
  ARCHIVE = "archive",
  EDIT_SIGNUP = "edit-signup",
  DUPLICATE_SIGNUP = "duplicate-signup",
  SIGNUP_LINK = "signup-link",
  RESTORE = "restore",
  REQUEST_CONFIRMATION = "request-confirmation",
}

export enum eGroupsFormat {
  CGA = "clean group assigns",
  MRT_VERTICAL = "mrt vertical", // set absent after previously signed up
}

export enum eSideEffects {
  MOVE_TO_LIST = "move-to-list",
  UPDATE_SPLITS = "update-splits",
}

export enum eSignupDiscoverability {
  LINK = "link",
  PUBLIC = "public",
}

export enum eSettingsTabName {
  CHARACTERS = 'characters',
  GENERAL = 'general',
  APPEARANCE = 'appearance',
  SIGNUPS = 'signups',
  PREMIUM = "premium",
  EXPERIMENTAL = 'experimental'
}

export enum eMemberType {
  MEMBER = 'member',
  GUEST = 'guest',
  PLACEHOLDER = 'placeholder',
}

export enum eSmartListItemType {
  SMARTLIST = "smart-list",
  SUBLIST = "sub-list",
  CHARACTER = "character"
}

export enum eSmartListSortType {
  USERID = "user-id",
  MANUAL = "manual",
  ALPHABETICAL = 'alphabetical',
  PERFORMANCE = 'performance', // wclogs parses
}

export enum eSortDirection {
  ASC = "asc",
  DESC = "desc",
}

export enum eTimestampLength {
  FULL = "full",
  SHORT = "short",
  TIME = "time",
  DATE = "date",
}

export enum eLocationType {
  NORMAL = "normal",
  CLASS_SPEC = "class-spec",
}

export enum eCustomFieldType {
  STRING_SHORT = "string_short",
  STRING_LONG = "string_long",
}

export enum eUserCacheType {
  CUSTOM_FIELD_REPLY = "custom-field-reply",
  ROSTER_CATEGORY = "roster-category",
}

export enum eKeyOrderLayout {
  HORISONTAL = "horisontal",
  VERTICAL = "vertical",
  TWOCOL = "twoCol"
}

export enum eRequired {
  ALWAYS = "always",
  OPTIONAL = "optional",
  NEVER = "never"
}