import copy from "fast-copy"
import { createRaidID, eDuplicateKeep, eSignupLogType, iRaid, iRaidData, iSignupLogItem, iUser, isTeamOwned, isUserOwned } from "typings"
import { cleanupSplitDataOnDuplication } from "../split/splitUtilities"
import { addLogItem, clearSignupsKeepAdmins, filterRaidDataNosync, removeAllMembersFromRoster, transferSignupOwnership } from "./raidUtilities"

type duplicateSignupParams = {
  signupInput: iRaid,
  raidSizeDecreased: boolean

  keep?: eDuplicateKeep[],
  user?: iUser,
  useRaidID?: string,
}

export const duplicateSignup = (params: duplicateSignupParams) => {
  const { keep = [eDuplicateKeep.ROLESANDASSIGNMENTS], signupInput, user, useRaidID, raidSizeDecreased } = params
  
  // update inputRaid raidID, isTemplate = false and createdAt
  const raidID = useRaidID || createRaidID()
  const newSignup: iRaid = copy(signupInput)
  const isPublicTemplate = newSignup?.templateData?.isPublic
  newSignup.raidID = raidID
  newSignup.isTemplate = false
  newSignup.createdAt = Date.now()
  if(signupInput.isTemplate) newSignup.templateIDUsed = signupInput.raidID

  // clear log and add "duplicate signup" event to log
  newSignup.log = {}
  if(user){
    const newLogItem: iSignupLogItem = {
      memberID: user.userID,
      type: eSignupLogType.DUPLICATE_SIGNUP,
      displayName: user.displayName,
      data: {
        fromSignupID: signupInput.raidID
      }
    }
    addLogItem(newSignup, newLogItem)
  }

  // transfer ownership
  const shouldTransferOwnership =
    isUserOwned(newSignup.owner) && user?.userID && newSignup.owner.userID !== user?.userID
  if (user && (shouldTransferOwnership || isPublicTemplate)) {
    transferSignupOwnership(newSignup, user)
  } else if (isTeamOwned(newSignup.owner)) {
    // do nothing?
  }

  // clean up splitData
  if(newSignup?.splitData){
    cleanupSplitDataOnDuplication(newSignup?.splitData, raidSizeDecreased, true)
  }

  // keep or clear signups?
  const keepSignups = keep.includes(eDuplicateKeep.SIGNUPS)
  if (keepSignups) {
    newSignup.members = copy(newSignup.members)
    newSignup.groupOrder = copy(newSignup.groupOrder)
    if(raidSizeDecreased) removeAllMembersFromRoster(newSignup.members)
  } else {
    const { members, groupOrder } = clearSignupsKeepAdmins(newSignup, user ? [user.userID] : [])
    newSignup.members = members
    newSignup.groupOrder = groupOrder
  }

  // fix split data
  if(newSignup?.splitData){
    newSignup.splitData = newSignup?.splitData
    cleanupSplitDataOnDuplication(newSignup?.splitData, raidSizeDecreased, keepSignups)
  }

  return newSignup
}


export const duplicateRaidData = (raidDataInput: iRaidData, newSignupID: string) => {
  const raidData = copy(raidDataInput)
  raidData.raidID = newSignupID
  filterRaidDataNosync(raidData)
  return raidData
}