import axios from 'axios'
import { processAxiosResponse } from './utilities';


type useAxiosParams = {
  bearerToken?: string
  baseURL: string
  getBearerToken?: () => Promise<string | undefined>
}
export const useAxios = (params?: useAxiosParams) => {
  
  const instance = axios.create();
  instance.defaults.validateStatus = () => true;
  if(!!params?.bearerToken){
    instance.defaults.headers.common = { 'Authorization': `Bearer ${params?.bearerToken}` }
  }
  if(!!params?.baseURL){
    instance.defaults.baseURL = params.baseURL
  }

  instance.interceptors.request.use(async (request) => {
    if(!params?.getBearerToken) return request
    const token = await params?.getBearerToken()
    // if(!request?.headers) {
    //   if (request.headers === undefined) {
    //     request.headers = {};
    //   }
    // }
    if(token) request.headers.Authorization = `Bearer ${token}`;
    return request;
  });

  instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("processing")
    try {
      processAxiosResponse(response, "Unknown error") 
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }, function (err) {
    // console.log("erroooor")
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(err);
  });

  return instance
};