import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export type FirestoreDB = firebase.firestore.Firestore

var firebaseConfig = {
  apiKey: "AIzaSyBZbwaOgc5ucYyjkB1LNrPNBCs2xacCWo4",
  authDomain: "assignment-manager-50a04.firebaseapp.com",
  projectId: "assignment-manager-50a04",
  storageBucket: "assignment-manager-50a04.appspot.com",
  messagingSenderId: "606801786370",
  appId: "1:606801786370:web:c3663857938024a8df191b",
  measurementId: "G-1VW3TKRR09",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({
    experimentalAutoDetectLongPolling: true,
    merge: true
  })
}else {
  firebase.app(); // if already initialized, use that one
}

// const db = firebase.firestore();
// const auth = firebase.auth();

export { firebase }