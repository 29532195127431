import { documentAPI } from "api";
import { dequal } from "dequal";
import copy from "fast-copy";
import { addLogItem, filterRaidDataNosync, fixSplitRunGroupOrder, fixSplitRunMembers, getErrorMessage, getSplitRunSignupID, runIDAndSplitDataToRunSections, updateDiscordSyncTo } from "functions";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import toast from "react-hot-toast";
import { eSignupLogType, iRaid, iRaidData, iSignupLogItem, iSplitSignup, isBoolean } from "typings";
import { UserDataState } from "../../../global/state/global";
import { raidChangesActive, raidDataFSState, raidFSState, raidIDState, raidState, raidSyncOverrideState } from "../../../global/state/raid";
import { splitDataState } from "../../../global/state/split";
import { jotaiStore } from "../../../pages/_app";
import Button from "./button";



export const executePublishAtom = atom(
  null,
  async (get, set, getLocalRaidData: (raidID: string) => iRaidData) => {
    const raidDataAPI = documentAPI().raidDataAPI
    const raidAPI = documentAPI().raidAPI
    const userData = get(UserDataState)
    const raidID = get(raidIDState)
    
    try {
      if(!raidID) throw new Error("Signup ID not defined")
      if(!userData) throw new Error("User data not found")

      const getRD = () => {
        const localRD = copy(getLocalRaidData(raidID))
        const raidData = filterRaidDataNosync(localRD)
        return raidData
      }
    
      const updateSplit = async (parentSignup: iRaid, runID: string) => {
        const splitData = parentSignup.splitData
        if(!splitData) return
        const isRunAlreadyCreated = splitData.createdRunIDs.includes(runID) === true
        const runRaidID = getSplitRunSignupID(parentSignup.raidID, runID)
    
        const { run, session, timeWindow } = runIDAndSplitDataToRunSections(splitData, runID)
        if(!(run && session && timeWindow)) throw new Error(`Update splits - failed to find run, session or time window for ${runID}`)
    
        const splitRun = isRunAlreadyCreated ? await raidAPI.get(runRaidID) : copy(parentSignup)
        if(!splitRun) {
          console.log(`Cannot update splitRun ${runRaidID} because it has been deleted`)
          return // splitRun document deleted in the meantime
        }
    
        const splitRunInitialCopy = () => {
          splitRun.title = `${parentSignup.title} run: ${runID}`
          splitRun.createdAt = Date.now()
          
          // fix split signup data
          splitRun.raidID = runRaidID
          splitRun.splitRef = parentSignup.raidID
          if(splitRun?.splitData) delete splitRun.splitData
          if(splitRun?.syncTo) delete splitRun.syncTo
        }
    
        const splitRunIncrementalUpdate = () => {
          splitRun.start = session.start
          splitRun.end = session.end
          splitRun.maxSize = run?.overrides?.maxSize || parentSignup.maxSize
        }
        
        if(isRunAlreadyCreated === false) splitRunInitialCopy()
        splitRunIncrementalUpdate()
    
        // fix members
        fixSplitRunMembers(splitRun, parentSignup, splitData, runID)
    
        // fix groupOrder
        fixSplitRunGroupOrder(splitRun, isRunAlreadyCreated)
    
        const createSplitRunDocuments = async () => {
          console.log(`__ SPLITRUN: ${runID} __`, splitRun)
          await raidAPI.set(splitRun.raidID, splitRun)
    
          if(isRunAlreadyCreated === true) return
          splitData.createdRunIDs.push(runID)
      
          if(splitRun.isSignupOnly === false){
            const raidData = getRD()
            raidData.raidID = splitRun.raidID
            await raidDataAPI.set(splitRun.raidID, raidData)
          }
        }
    
        await createSplitRunDocuments()
      }
    
      const deleteSplit = async (splitRunID: string) => {
        await raidDataAPI.remove(splitRunID)
        await raidAPI.remove(splitRunID)
      }
    
      const updateSplits = (parentSignup: iRaid) => {
        const FSSignup = copy(get(raidFSState))
        const splitData = parentSignup.splitData
        if(typeof FSSignup === "boolean" || !splitData) return []
        
        // skip if no changed data
        if(dequal(FSSignup.splitData, splitData) && dequal(splitData.runIDs, splitData.createdRunIDs)) {
          return []
        }
    
        const promises: Promise<unknown>[] = []
    
        const runDataSame = (runID: string, newSplitData: iSplitSignup, oldSplitData: iSplitSignup) => {
          const { run: oldRun, session: oldSession, timeWindow: oldTimeWindow } = runIDAndSplitDataToRunSections(oldSplitData, runID)
          const { run: newRun, session: newSession, timeWindow: newTimeWindow } = runIDAndSplitDataToRunSections(newSplitData, runID)
          if(newSplitData.createdRunIDs.includes(runID) === false) return false
          if(dequal(oldTimeWindow, newTimeWindow) === false) return false
          if(dequal(oldSession, newSession) === false) return false
          return dequal(oldRun, newRun)
        }
    
        // delete removed runs
        const removedRunIDs = splitData.createdRunIDs.filter(runID => splitData.runIDs.includes(runID) === false)
        const remainingRunIDs = splitData.createdRunIDs.filter(runID => splitData.runIDs.includes(runID))
        for (const runID of removedRunIDs) {
          const splitRunID = getSplitRunSignupID(parentSignup.raidID, runID)
          console.log("DELETING RUNID", splitRunID)
          promises.push(deleteSplit(splitRunID))
        }
        splitData.createdRunIDs = remainingRunIDs
        
        // update current runs
        for (const runID of splitData.runIDs) {
          if(FSSignup.splitData && runDataSame(runID, splitData, FSSignup.splitData)) continue;
          promises.push(updateSplit(parentSignup, runID))
        }
    
        return promises
      }
    
      const executePublish = async () => {
        const raid = copy(jotaiStore.get(raidState))
        if(isBoolean(raid)) throw new Error("Error: Signup not initialised yet.") 
        if (!raidID) throw new Error("Save changes - No RaidID")
    
        const promises: Promise<unknown>[] = []
        
        // getFSRaidData
        if(raid?.isSignupOnly !== true) {
          const raidData = getRD()
          const FSRaidData = jotaiStore.get(raidDataFSState)
          if (!raidData) throw new Error("Save changes - raidData not found!")
          
          // only update raidData if different
          if (isBoolean(FSRaidData) === true || dequal(raidData, FSRaidData) === false) {
            promises.push(raidDataAPI.set(raidID, raidData))
          }
        }
        
        // update splitData
        raid.splitData = jotaiStore.get(splitDataState)
        if(raid?.splitData && raid.splitData?.runIDs?.length > 0) {
          const splitPromises = updateSplits(raid)
          promises.push(...splitPromises)
        }
    
        // update log
        const newLogItem: iSignupLogItem = {
          memberID: userData.userID,
          type: eSignupLogType.PUBLISH,
          displayName: userData.displayName
        }
        addLogItem(raid, newLogItem)
        
        await Promise.all(promises)
        await raidAPI.set(raidID, raid)
    
        // resetRaidSync()
        set(raidSyncOverrideState, RESET)
        updateDiscordSyncTo(raid)
      }
      
      toast.promise(executePublish(), {
        loading: 'Saving changes...',
        success: 'Changes saved and published!',
        error: (err) => {
          console.error(err);
          return `Failed to save changes.\n${getErrorMessage(err)}`
        },
      });

    } catch (error) {
      console.error(error);
    }
  }
)


const PublishChangesButton = ({ getLocalRaidData }: { getLocalRaidData: (raidID: string) => iRaidData }) => {

  const changesActive = useAtomValue(raidChangesActive)
  const executePublish = useSetAtom(executePublishAtom)

  const publishChanges = async () => {
    
    try {
      executePublish(getLocalRaidData)
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  };

  return (
    <Button
      text="Save and publish"
      click={publishChanges}
      size="mini"
      color={changesActive ? "secondary" : "inactive"}
      addClass="preventHideChildOnMobile"
    />
  );
}

export default PublishChangesButton;