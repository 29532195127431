import { admins, eTeamPermission, iAdminStatus, isTeamOwned, iTeam, iTeamMember, iTeamPermissionType, iUser, iUserClaims, permissionsInCustomClaims, RaidAdmin, TeamMember, TeamOwned, teamPermissionsClaims } from "typings"
import { enumKeys, uniqueFilter } from "../utilities/utilities"

export const tagIDsToPermissions = (team: iTeam, tagIDs: string[]): iTeamPermissionType[] => {
  const tags = tagIDs
    .filter(tagID => !!team.tags?.[tagID])
    .map(tagID => team.tags[tagID])

  const permissions = tags
    .reduce(
      (acc, item) => [...acc, ...Object.keys(item.permissions)] as iTeamPermissionType[],
      [] as iTeamPermissionType[]
    )
    .filter(uniqueFilter)
  return permissions
}

export const checkUserTeamPermission = (claims: iUserClaims | undefined, teamID: string, permission: iTeamPermissionType): boolean => {
  if(!claims) return false
  if(!claims?.teamPermissions?.[permission]) return false
  if(claims?.teamPermissions?.[permission]?.includes(teamID)) return true
  return false
}

export const getAdminStatus = (
  userID: string,
  userClaims: iUserClaims | undefined,
  docOwner: RaidAdmin | TeamOwned,
  admins: admins,
  permission: iTeamPermissionType,
  adminPermission: iTeamPermissionType
): iAdminStatus => {
  if(!userID) return "none"
  if (isTeamOwned(docOwner)) {
    if(!userClaims) return "none"
    if (checkUserTeamPermission(userClaims, docOwner.teamID, permission)) return "manager"
    if (checkUserTeamPermission(userClaims, docOwner.teamID, adminPermission)) return "admin"
    if (Object.keys(admins).includes(userID)) return "admin"
    return "none"
  }
  if (docOwner?.userID === userID) return "owner"
  if (Object.keys(admins).includes(userID)) return "admin"
  return "none"
}

export const userToTeamMember = (user: iUser): iTeamMember => {

  const teamMember = TeamMember().create({
    userID: user.userID,
    displayName: user.displayName,
    discordID: user.discordID,
  })
  return teamMember
}

export const setTeamPermissions = (
  teamID: string,
  existingClaims: iUserClaims,
  shouldHavePermissions: iTeamPermissionType[]
): iUserClaims => {
  const claims = existingClaims || { teamPermissions: {} }
  if(!claims?.teamPermissions) claims.teamPermissions = {}

  for (const value of enumKeys(eTeamPermission)) {
    const per = eTeamPermission[value]

    if(!permissionsInCustomClaims.includes(per)) continue;
    if(shouldHavePermissions.includes(per) && claims.teamPermissions?.[per]?.includes(teamID)) continue;

    // give if should have and don't have
    if(shouldHavePermissions.includes(per) && !claims.teamPermissions?.[per]?.includes(teamID)){
      giveTeamPermissionClaims(teamID, claims.teamPermissions, [per])
      continue;
    }

    // remove if shouldn't have and has
    if(claims.teamPermissions?.[per]?.includes(teamID)){
      removePermissionClaims(teamID, claims.teamPermissions, [per])
    }
  }

  return claims
}

export const giveTeamPermissionClaims = (
  teamID: string,
  claims: teamPermissionsClaims = {},
  givePermissions: iTeamPermissionType[],
): teamPermissionsClaims => {
  
  for (const permission of givePermissions) {
    if(claims?.[permission]?.includes(teamID)) continue;
    if(!claims?.[permission]) {
      claims[permission] = [teamID]
      continue;
    }
    const res = claims?.[permission]
    if(!!res) res.push(teamID)
  }
  return claims
}

export const removePermissionClaims = (
  teamID: string,
  claims: teamPermissionsClaims = {},
  removePermissions: iTeamPermissionType[],
): Record<string, any> => {
  
  for (const permission of removePermissions) {
    const arr = claims?.[permission]
    if(!arr) continue;

    if(!Array.isArray(arr)) throw new Error("team permission wrong data format")
    const index = arr.findIndex(ID => ID === teamID)
    if(index < 0) continue;

    arr.splice(index, 1)
    if(arr.length > 0) continue;
    delete claims[permission]
  }
  return claims
}

export const filterTeamIDsInClaims = (claims: iUserClaims, anyPermissions: eTeamPermission[]) => {
  const teamIDs: string[] = []

  for (const permission of anyPermissions) {
    const foundTeamIDs = claims.teamPermissions?.[permission]
    if(!!foundTeamIDs && foundTeamIDs?.length > 0) teamIDs.push(...foundTeamIDs)
  }
  
  return teamIDs
}