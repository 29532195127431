import { gameRoleType, signupState } from "../../documents/raid/iMember"

export enum eReplaceWithType {
  MEMBER = "member",
  CHAR = "char",
  ROLE = "role",
  CLASS = "class",
  GAMEROLE = "gamerole",
  SMARTLIST = "smart-list",
}

type iReplaceWithMemberOption = {
  type: eReplaceWithType.MEMBER
  memberID: string
  signupState: signupState
  displayName: string
}

type iReplaceWithCharOption = {
  type: eReplaceWithType.CHAR
  memberID: string
  signupState: signupState
  charName: string
  charGameRole: gameRoleType
  charGameClass: string,
  charGameSpec: string,
}

type iReplaceWithGameRoleOption = {
  type: eReplaceWithType.GAMEROLE
  memberID: string
  signupState: signupState
  charName: string
  charGameRole: gameRoleType
  charGameClass: string,
  charGameSpec: string,

  groupPositions: {
    [groupCompLetter: string]: number | null
  }
}

type iReplaceWithClassOption = {
  type: eReplaceWithType.CLASS
  memberID: string
  signupState: signupState
  charName: string
  charGameRole: gameRoleType
  charGameClass: string,
  charGameSpec: string,
}

export type iReplaceWithRoleOption = {
  type: eReplaceWithType.ROLE
  roleName: string
  roleID: string

  memberID?: string
  signupState?: signupState
  charName?: string
  charGameRole?: gameRoleType
  charGameClass?: string,
  charGameSpec?: string,
}

export type iReplaceWithSmartListOption = {
  type: eReplaceWithType.SMARTLIST
  smartListLabel: string
  smartListID: string
}

export type iReplaceWithOption =
  | iReplaceWithMemberOption
  | iReplaceWithCharOption
  | iReplaceWithGameRoleOption
  | iReplaceWithClassOption
  | iReplaceWithRoleOption
  | iReplaceWithSmartListOption