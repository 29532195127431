import { dequal } from "dequal";
import copy from "fast-copy";
import { Areas, charID, CreateSplitRun, CreateSplitSession, CreateSplitTimeWindow, eDragType, eSmartListItemType, gameRoleType, iCharacter, iMember, iMemberTimeWindows, iRaid, isDefined, iSmartList, iSmartListItem, iSplitRun, iSplitSession, iSplitSignup, iSplitTimeWindow, List, members } from "typings";
import { asciiNumberToString, getNextFullHour, getWithDefault, nextCharFromKeysIn, useArrayIndex } from "../../utilities/utilities";
import { format, isSameDay } from "date-fns";
import { iAllocation } from "./distributeMembers";
import { charIDsToNewTargets, isSignedMember, memberToChars } from "../raid/raidUtilities";

// signupIDs
export const getParentIDFromSplitRunID = (splitRunID: string) => splitRunID.substring(0, splitRunID.length - 4);
export const getSplitRunSignupID = (signupID: string, runID: string) => `${signupID}-${runID}`

// listIDs
export const runIDToListID = (runID: string, category: string) => `list-run-${runID}-${category}`

export const runIDToIndexes = (runID: string) => {
  if(runID?.length !== 3) throw new Error(`runID: ${runID} invalid length`)
  return {
    twIndex: runID[0],
    sessionIndex: runID[1],
    runIndex: runID[2],
  }
}

export const addTimeWindowTo = (split: iSplitSignup) => {
  try {
    const newTimeWindowID = nextCharFromKeysIn(split.usedTWIndexes)
    split.usedTWIndexes.push(newTimeWindowID)
    split.timeWindows[newTimeWindowID] = CreateSplitTimeWindow({
      start: getNextFullHour().getTime(),
      end: getNextFullHour().getTime() + 3600000, // plus one hour in ms
    })
    addSessionToTimeWindow(split.timeWindows[newTimeWindowID])
    return newTimeWindowID
  } catch (error) {
    console.error(error);
  }
}
  
export const addSessionToTimeWindow = (timeWindow: iSplitTimeWindow) => {
  const newSessionID = nextCharFromKeysIn(Object.keys(timeWindow.sessions))
  try {

    timeWindow.sessions[newSessionID] = CreateSplitSession({
      start: timeWindow.start,
      end: timeWindow.end
    })
    // addRunToSession(timeWindow.sessions[newSessionID])
    return newSessionID
  } catch (error) {
    console.error(error);
  }
}

export const addRunToSession = (session: iSplitSession) => {
  try {
    const newRunID = nextCharFromKeysIn(Object.keys(session.splits))

    session.splits[newRunID] = CreateSplitRun()
    return newRunID
    // create 4 new lists
    // register new lists
  } catch (error) {
    console.error(error);
  }
}

export const splitDataAndRunIDToRun = (splitData: iSplitSignup, runID: string) => {
  if(runID === "bench") throw new Error("Bench is not valid run ID")
  const [twIndex, sessionIndex, runIndex] = runID.split("")
  if(!(twIndex && sessionIndex && runIndex)) throw new Error("runID invalid")
  const run = splitData.timeWindows?.[twIndex].sessions?.[sessionIndex].splits?.[runIndex]
  if(!run) throw new Error("couldn't validate split run")
  return run
}

const removeCharIDFromRun = (run: iSplitRun, memberID: string, charName: string) => {
  const charIDIndex = run.members.findIndex((charID) => dequal(charID, { memberID, charName }))
  if (charIDIndex === -1) throw new Error("Couldn't find that member character combo")
  run.members.splice(charIDIndex, 1)
}

export const moveCharIDToRunID = ({
  splitData, fromRunID, toRunID, memberID, charName
}: {
  splitData: iSplitSignup,
  fromRunID: string,
  toRunID: string,
  memberID: string,
  charName: string
}) => {
  if(!(fromRunID && toRunID)) throw new Error("from or to run ID missing")

  // add to new
  if(toRunID !== "bench"){
    const toRun = splitDataAndRunIDToRun(splitData, toRunID)
    const alreadyInRun = isInRun(toRun, memberID, charName)
    if(toRun && !alreadyInRun){
      toRun.members.push({ memberID, charName })
    }
  }
  
  // remove old
  if(fromRunID === "bench") return
  const fromRun = splitDataAndRunIDToRun(splitData, fromRunID)
  removeCharIDFromRun(fromRun, memberID, charName)
}

export const findRunsIn = (splitData: iSplitSignup, filterTwIndex?: string, filterSessionIndex?: string) => {
  if(!splitData?.timeWindows) return []
  const runs: {runID: string, run: iSplitRun}[] = []
  for (const [twIndex, timeWindow] of Object.entries(splitData.timeWindows).sort()) {
    if(!!filterTwIndex && twIndex !== filterTwIndex) continue;
    for (const [sessionIndex, session] of Object.entries(timeWindow.sessions).sort()) {
      if(filterSessionIndex && sessionIndex !== filterSessionIndex) continue;
      for (const [runIndex, run] of Object.entries(session.splits).sort()) {
        runs.push({ run, runID: twIndex + sessionIndex + runIndex })
      }
    }
  }
  return runs
}

export const runIDAndSplitDataToRunSections = (
  splitData: iSplitSignup,
  runID: string
): {
  timeWindow: iSplitTimeWindow
  session: iSplitSession
  run: iSplitRun
} => {
  const chars = runID.split("")
  const timeWindow = splitData?.timeWindows?.[chars[0]]
  const session = timeWindow?.sessions?.[chars[1]]
  const run = session?.splits?.[chars[2]]
  return { timeWindow, session, run }
}

export const charIDsToMembers = (charIDs: charID[], members: members, timeWindow: iSplitTimeWindow): members => {
  const membersObject: members = {}

  for (const charID of charIDs) {
    const member = copy(members[charID.memberID])
    if(!member) continue;
    const memberChars: iCharacter[] = []
    if(member?.character?.charName) memberChars.push(member.character)
    if(member?.altCharacters) memberChars.push(...member.altCharacters)
    
    const correctChar = memberChars.find(char => char?.charName === charID?.charName)
    if(!correctChar) continue;

    delete member.character
    delete member.altCharacters
    member.character = correctChar
    member.onRoster = true
    // TODO: fix confirmations from timeWindow
    // if(typeof timeWindow.members[member.userID].isConfirmed === "boolean")

    membersObject[member.userID] = member
  }

  return membersObject
}

export const fixSplitRunMembers = (splitRun: iRaid, parentSignup: iRaid, splitData: iSplitSignup, runID: string) => {
  const { run, session, timeWindow } = runIDAndSplitDataToRunSections(splitData, runID)
  
  // insert members from run.members using parent.members
    // only the character that is in that run, no .altCharacters
  splitRun.members = charIDsToMembers(run.members, parentSignup.members, timeWindow)

  // update member confirmed ++
  const twIndex = runID.substring(0, 1)
  for (const member of Object.values(splitRun.members)) {
    member.isStandby = false
    member.isConfirmed = !!member?.eligibility?.[twIndex]?.isConfirmed
    member.altCharacters = []
  }
}

export const fixSplitRunGroupOrder = (signup: iRaid, isRunAlreadyCreated: boolean) => {
  const memberIDs = Object.values(signup.members)
    .filter(isSignedMember)
    .map((member) => member.userID)
  
  // reset groupSpots that are not in memberIDs
  signup.groupOrder = signup.groupOrder.slice(0, signup.maxSize).map((spot) => {
    if(isRunAlreadyCreated === false) return null
    
    const shouldRemainIndex = memberIDs.findIndex(memberID => memberID === spot)
    if(shouldRemainIndex === -1) return null

    memberIDs.splice(shouldRemainIndex, 1)
    return spot
  })
  
  // fill open groupSpots with remaining memberIDs
  for (let i = 0; i < signup.groupOrder.length; i++) {
    
    if(signup.groupOrder[i] !== null) continue;
    if(memberIDs?.length < 1) break;

    const newID = memberIDs.splice(0, 1)[0]
    signup.groupOrder[i] = newID
  }
}

export const findMemberInSession = (
  memberID: string,
  splitData: iSplitSignup,
  twIndex: string,
  sessionIndex: string
) => {
  const session = splitData?.timeWindows?.[twIndex].sessions?.[sessionIndex]
  if (!session) {
    return null
  }

  for (const [runIndex, run] of Object.entries(session.splits)) {
    const charID = isInRun(run, memberID)
    if (charID) {
      return { charID, runID: twIndex + sessionIndex + runIndex }
    }
  }

  return null
}

export const signupToNewSplitData = (signup: iRaid): iSplitSignup => {
  const newSplitData: iSplitSignup = { runIDs: ["AAA", "AAB"], timeWindows: {}, createdRunIDs: [], usedTWIndexes: [], memberMaxPerSplit: 0 }
  newSplitData.timeWindows["A"] = {
    start: signup.start,
    end: signup.end,
    sessions: {
      ["A"]: {
        start: signup.start,
        end: signup.end,
        splits: {
          ["A"]: { members: [] },
          ["B"]: { members: [] },
        }
      }
    }
  }
  newSplitData.usedTWIndexes = ["A"]

  return newSplitData
}

export const cleanupSplitDataOnDuplication = (split: iSplitSignup, raidSizeDecreased: boolean, keepSignups: boolean) => {
  // reset createRunIDs & usedTWIndexes
  split.usedTWIndexes = []
  split.createdRunIDs = []

  // find changes
  if(!split?.usedTWIndexes) throw new Error("Couldn't validate duplication split data.")
  const newTWIndexMap: Record<string, {newKey: string, twData: iSplitTimeWindow}> = {} // { [oldKey: string]: newData }
  const twKeys = Object.keys(split.timeWindows).sort()
  
  for (let i = 0; i < twKeys.length; i++) {
    const oldTWIndex = twKeys[i];
    const newTWIndex = asciiNumberToString(i)
    newTWIndexMap[oldTWIndex] = {
      newKey: newTWIndex,
      twData: copy(split.timeWindows[oldTWIndex])
    }
  }

  // clean up runIDs
  split.runIDs = split.runIDs.map((runID) => {
    const oldTWIndex = runID.substring(0, 1)
    const sessionAndRunIndexes = runID.substring(1)
    if (!newTWIndexMap?.[oldTWIndex]) throw new Error("Couldn't find old TW index")
    return newTWIndexMap?.[oldTWIndex].newKey + sessionAndRunIndexes
  })

  // clean up time window keys & used TW Indexes
  split.timeWindows = {}
  for (const { newKey, twData } of Object.values(newTWIndexMap)) {
    split.timeWindows[newKey] = twData
    split.usedTWIndexes.push(newKey)
  }

  if(raidSizeDecreased) clearMembersFromRuns(split)
  if(keepSignups === false) clearMembersFromRuns(split)
}

export const clearMembersFromRuns = (split: iSplitSignup, placeholdersOnly = false) => {
  for (const timeWindow of Object.values(split.timeWindows)) {
    for (const session of Object.values(timeWindow.sessions)) {
      for (const run of Object.values(session.splits)) {
        if(placeholdersOnly){
          run.members = run.members.filter(
            (charID) => charID.memberID.startsWith("placeholder-member") === false
          )
        } else {
          run.members = []
        }
      }
    }
  }
}

export const getTWColor = (index: number) => {
  const colors = [
    { color: "#0C77EB", darkText: false },
    { color: "#AE4FEF", darkText: false },
    { color: "#FF308C", darkText: false },
    { color: "#ef233c", darkText: false },
    { color: "#fb8500", darkText: false },
    { color: "#ffd400", darkText: true },
    { color: "#8DBF03", darkText: true },
    { color: "#00C24E", darkText: true },
    { color: "#00C49A", darkText: true },
    { color: "#0C9DEB", darkText: false },
  ]
  
  return {
    color: useArrayIndex(colors, index).color,
    useDarkText: useArrayIndex(colors, index).darkText
  }
}

export const checkMemberCanDrop = (member: iMember, runID: string) => {
  if(!member?.eligibility || Object.keys(member?.eligibility)?.length < 1) return true
  if(!!member?.eligibility?.["ALL"]) return true
  return !!member?.eligibility?.[runID.substring(0, 1)]
}

export const mapCharIDsToTWAvailability = (split: iSplitSignup, signup: iRaid) => {
  const twKeys = Object.keys(split.timeWindows).sort()
  const allTimeWindows: { charID: charID; category: string }[] = []
  const notAllTimeWindows: { charID: charID; category: string; timeWindows: iMemberTimeWindows }[] = []

  const addToAll = (chars: iCharacter[], member: iMember) => {
    allTimeWindows.push(
      ...chars.map((char) => ({
        charID: { charName: char.charName, memberID: member.userID },
        category: char.gameRole
      }))
    )
  }

  for (const member of Object.values(signup.members)) {
    const chars = memberToChars(member)
    const memberEligibility = member?.eligibility || {}
    const memberKeys = Object.keys(memberEligibility).sort()
    const isEqual = dequal(memberKeys, twKeys)

    if(!!member?.eligibility?.["ALL"]){
      addToAll(chars, member)
    }

    // if member has .timeWindows and is not equal to twKeys -> notAll
    if(memberKeys.length > 0 && isEqual === false) {
      notAllTimeWindows.push(
        ...chars.map((char) => ({
          charID: { charName: char.charName, memberID: member.userID },
          category: char.gameRole,
          timeWindows: memberEligibility
        }))
      )
      continue;
    }

    // if member do not have .timeWindows -> assume all / probably placeholder
    // if member has .timeWindows and is equal to twKeys -> all
    addToAll(chars, member)
  }

  return {
    allTimeWindows,
    notAllTimeWindows
  }
}

export const getTWStrings = (split: iSplitSignup) => {
  if (!split) return []
  const strings: string[] = []

  for (const [twIndex, timeWindow] of Object.entries(split.timeWindows).sort()) {
    const sameDay = isSameDay(new Date(timeWindow?.start), new Date(timeWindow?.end))
    strings.push(
      `${twIndex}: ${format(timeWindow.start, "EEEE dd. LLLL")}: ${format(
        timeWindow.start,
        "HH.mm"
      )} - ${format(timeWindow.end, sameDay ? "HH.mm" : "EEEE HH.mm")}`
    )
  }

  return strings
}

export const isMemberAvailableInTimeWindow = (member: iMember, twIndex: string): boolean => {
  if(!member?.eligibility) return true
  return !!member?.eligibility?.[twIndex]
}

export const twKeysToMemberEligibility = (twKeys: string[], signup?: iRaid, memberID?: string) => {
  const map: iMemberTimeWindows = {}
  for (const twKey of (twKeys || []).sort()) {
    map[twKey] = {
      isConfirmed: !!signup?.members?.[memberID || ""]?.eligibility?.[twKey]?.isConfirmed || false
    }
  }
  return map
}

export const removeCharsFromRuns = (
  split: iSplitSignup,
  memberID: string,
  keepCharNames?: string[]
) => {
  if (!split) return
  const runs = findRunsIn(split)
  for (const { runID, run } of runs) {
    if (!!keepCharNames) {
      run.members = run.members.filter((charID) => {
        const otherMemberChar = charID.memberID !== memberID
        const keepMemberChar = (charID.memberID === memberID && keepCharNames.includes(charID.charName) === true)
        return otherMemberChar || keepMemberChar
      })
      continue
    }
    run.members = run.members.filter((charID) => charID.memberID !== memberID)
  }
}

export const createNewSplitList = (listID: string, listName: string, charIDs: charID[]) => {
  const targets = charIDsToNewTargets(charIDs)
  const targetIDs = targets.map(t => t.id)
  const list = List().create(listName, Areas.ROSTER, eDragType.MEMBER, targetIDs, listID, true)
  return { targets, list }
}

export const countMemberInSplit = (split: iSplitSignup, memberID: string) => {
  const runs = findRunsIn(split)
  let memberFound = 0

  for (const { run } of runs) {
    const alreadyInRun = isInRun(run, memberID)
    if(alreadyInRun) memberFound += 1
  }

  return memberFound
}

export const allocationsToRun = (allocations: iAllocation[]): iSplitRun => {
  const splitRun: iSplitRun = {
    members: allocations.map(allo => allo.charID)
  }
  return splitRun
}

export const isInRun = (run: iSplitRun, memberID: string, charName?: string): charID | undefined => {
  if(!charName) return run.members.find(charID => charID.memberID === memberID)
  return run.members.find(charID => dequal(charID, { memberID, charName }))
}

export const getItemTarget = (smartListItem: iSmartListItem, runSize: string) => {
  if(smartListItem.type === eSmartListItemType.CHARACTER) return 1
  return getWithDefault(smartListItem?.target, runSize) || 0
}

export const getItemTargetMax = (smartListItem: iSmartListItem) => {
  if (smartListItem.type === eSmartListItemType.CHARACTER) return 1
  if (!isDefined(smartListItem?.target)) return 0
  const allItemTargets = Object.values(smartListItem?.target).filter(
    (val) => typeof val === "number"
  )
  return Math.max(...allItemTargets)
}

export const getSmartListPriority = (smartList: iSmartList): number => {
  if(isDefined(smartList?.priority)) return smartList?.priority
  return 50
}

export const getRunMaxSize = (split: iSplitSignup, signupMaxSize: number, runID: string) => {
  if(!split) return signupMaxSize
  const { run } = runIDAndSplitDataToRunSections(split, runID)
  return run?.overrides?.maxSize || signupMaxSize
}