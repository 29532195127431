import { Areas, charID, eDragType, iTarget, Target } from "typings";
import { charIDToString } from "../raid/raidUtilities";

export const rosterTargetIDToCharID = (targetID: string): charID => {
  if(targetID.startsWith("target-roster-") === false) {
    console.error("targetID invalid", targetID);
    throw new Error("failed to validate roster targetID.")
  }
  const charIDString = targetID.substring("target-roster-".length)
  const memberID = charIDString.substring(0, charIDString.lastIndexOf("-"))
  const charName = targetID.slice(targetID.lastIndexOf("-") + 1)
  return { charName, memberID }
}

type charIDCharNameOptional = Omit<charID, "charName"> & { charName?: string }
export const charIDToRosterTargetID = (charID: charIDCharNameOptional) => {
  if(!charID?.charName) return `target-roster-${charID.memberID}`
  return `target-roster-${charID.memberID}-${charID.charName}`
}

export const createRosterTarget = (charID: charID): iTarget => {
  return Target().create({
    area: Areas.ROSTER,
    containType: eDragType.MEMBER,
    containID: charIDToString(charID),
    targetIDstr: charIDToRosterTargetID(charID),
    noSync: true
  })
}