import { atom } from "jotai";
import { iRaid, iList, iTarget, Areas, Target, List, eDragType, eKeyOrderLayout } from "typings";
import copy from "fast-copy";
import { ydocAtom } from "./global";
import { capitalize, charIDToRosterTargetID, charIDToString, getMemberChars, getRosterListID, getUpdatedMembers, isSignedMember, stringToCharID } from "functions";
import toast from "react-hot-toast";
import { resetMembersCharRaidSyncState } from "./updateMemberState";
import { moveTargetToListState, registerListsState, registerTargetsState, signupGroupingAtom, unregisterTargetsState } from "./raid";

export const updateRosterState = atom(null, (get, set, update: { raid: iRaid }) => {
  const y = get(ydocAtom);
  const raid = copy(update.raid);
  
  const lists: iList[] = Array.from(y.lists?.values());
  const newLists: iList[] = [];
  const newTargets: { category: string, target:iTarget }[] = [];
  const lastMemberCheck = y.extra.get("memberLastCheck") as number || 0;
  const signedMembers = Object.values(raid.members).filter(isSignedMember)

  // find updated members && remove raidSync
  const newlyUpdatedMemberIDs: string[] = getUpdatedMembers(raid.members, lastMemberCheck).map(
    (m) => m.userID
  );

  const grouping = get(signupGroupingAtom)
  if(!grouping) return
  const { getGroupingKeyOrder, getGroupedMembers } = grouping
  const groupedMembers = getGroupedMembers(signedMembers)
  const categories = getGroupingKeyOrder({ mode: "specific", orderLayout: eKeyOrderLayout.HORISONTAL })

  set(resetMembersCharRaidSyncState, newlyUpdatedMemberIDs);

  const categoryToRosterTargetIDs: { [category: string]: string[] } = categories.reduce(
    (acc, item) => ({ ...acc, [item]: [] }),
    {}
  )
  const categoryToBenchTargetIDs: { [category: string]: string[] } = categories.reduce(
    (acc, item) => ({ ...acc, [item]: [] }),
    {}
  )

  // create targets for members and add to categoryToRoster/Bench targetIDs
  for (const [category, members] of Object.entries(groupedMembers)) {
    for (const member of members) {

      // get member target
      const char = member?.character
      const memberTargetID = charIDToRosterTargetID({ memberID: member?.userID, charName: char?.charName })
      const foundTarget = y.targets.get(memberTargetID);

      if(foundTarget){
        // if exists, check if in right list
        const listID = getRosterListID(member.onRoster ? "roster" : "bench", category)
        const shouldBeInList = y.lists.get(listID);
        if (!shouldBeInList) {
          console.warn("updateRoster - couldn't find shouldBeInList", listID);
          continue;
        }
        const isInRightList = shouldBeInList.targetIDs.includes(foundTarget.id);
        if (isInRightList) continue;
        
        // not in correct list -> move
        const fromList = lists.find((list) => list.targetIDs.includes(foundTarget.id));
        if (!fromList) {
          console.error(
            `Couldn't find fromList for foundTarget: ${foundTarget?.id}. Should be in list: ${shouldBeInList?.listName}`
          );
          continue;
        }
        // console.log(`Moving member ${char?.charName} from ${fromList.listName} to ${shouldBeInList.listName}`)
        set(moveTargetToListState, {
          targetID: foundTarget.id,
          mode: member?.onRoster === false ? "remove" : "add",
          fromListID: fromList.id,
          toListID: shouldBeInList.id
        });
        continue;
      }

      // create new target
      const target = Target().create({
        area: Areas.ROSTER,
        containType: eDragType.MEMBER,
        containID: !!char ? charIDToString({ memberID: member.userID, charName: char.charName }) : member.userID,
        targetIDstr: memberTargetID,
        noSync: true
      });
      newTargets.push({ target, category });
      if (member?.onRoster === true) {
        categoryToRosterTargetIDs[category].push(target.id);
      } else {
        categoryToBenchTargetIDs[category].push(target.id);
      }
    }
  }

  // init roster lists
  for (const rosterORBench of ["roster", "bench"] as const) {
    const isRoster = rosterORBench === "roster";

    for (const category of categories) {
      const listIDstring = getRosterListID(rosterORBench, category);
      const foundList = y.lists.get(listIDstring);
      if (foundList) continue;
      const categoryMeta = grouping.categoryMeta[category]

      const targetIDs = isRoster
        ? categoryToRosterTargetIDs[category]
        : categoryToBenchTargetIDs[category];

      // init new roster list
      const list = List().create(
        isRoster ? `${capitalize(categoryMeta.label)}` : `${capitalize(categoryMeta.label)} Bench`,
        Areas.ROSTER,
        eDragType.MEMBER,
        targetIDs,
        listIDstring,
        true,
        false
      );
      newLists.push(list);
    }
  }

  // cleanup old targets
  const targets = Array.from<iTarget>(y.targets?.values());
  const invalidTargetIDs = targets
    .filter((t) => t.area === Areas.ROSTER)
    .filter((t) => {
      if (t.id.startsWith("target-roster") === false) return true;
      const { charName } = stringToCharID(t.containID);
      if(!charName) return false
      const parts = t.id.split("-");
      parts.pop();
      parts.splice(0, 2);
      const userID = parts.join("-");
      const member = raid.members[userID];
      const charNames = getMemberChars(member).map(_char => _char.charName)
      return charNames.includes(charName) === false
    })
    .map((t) => t.id);

  y.doc.transact(() => {
    // add new targets to existing lists
    if (newTargets.length > 0 && newLists.length === 0) {
      for (const { target, category } of newTargets) {
        const { memberID } = stringToCharID(target.containID);
        const member = raid.members[memberID];
        const listID = getRosterListID(member.onRoster ? "roster" : "bench", category);

        const foundList = y.lists.get(listID)
        if (!foundList) {
          toast.error(`error: failed to find existing list for ${member?.character?.charName}`);
          continue;
        }

        if (foundList.targetIDs.includes(target.id)) continue;
        y.lists.set(foundList.id, {
          ...foundList,
          targetIDs: [...foundList.targetIDs, target.id]
        });
      }
    }

    // if((newTargets.length + newLists.length) > 0) {
    //   console.log(`UpdateRoster newTargets: ${newTargets.length} - newLists: ${newLists.length}`, {newTargets, newLists})
    // } else {
    //   console.log("updateRoster: nothing new")
    // }
    set(registerListsState, newLists);
    set(registerTargetsState, newTargets.map(_ => _.target));
    set(unregisterTargetsState, invalidTargetIDs);
    y.extra.set("memberLastCheck", Date.now());
  }, "system");
});
