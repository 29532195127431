// Grid panel
export type iTextCell = { type: CellType.TEXT; iconLeft?: string; data: string }
export type iMemberCell = { type: CellType.MEMBER; data: string }
export type iIconCell = { type: CellType.ICON; data: string }
export type iLinkCell = { type: CellType.LINK; data: string }
export type iRefCell = { type: CellType.REFERENCE; data: string }
export type iColumnCell = iTextCell | iMemberCell | iIconCell | iLinkCell | iRefCell

export interface GridColumn {
  header: string
  iconLeft?: string
  cells: (iColumnCell | null)[]
}

export type iSmartListInstance = {
  id: string
  xyTopLeft: string
  direction: number // 0 = horisontally right, 1 = vertically down
  smartListID: string
  xSpan: number
  ySpan: number
  reversed?: boolean
  reversedPos?: boolean
  offset: number // skips x amount from start of list
}

export interface GridData {
  columns: GridColumn[]
  gridDirection: number // 0 = horisontal, 1 = vertical, 2 = single cells
  smartListDisplay?: {
    priority: string[] // format: "column.row" -> "0.0" for top left - used for: which smartList to spread first
    instances: {
      [id: string]: iSmartListInstance
    }
  }
}

export enum CellType {
  TEXT = "text",
  MEMBER = "member",
  ICON = "icon",
  LINK = "link",
  REFERENCE = "ref", // implement later?
}

export type iCellType = CellType