import { eSmartListItemType, iSmartList, iMember, isNever, members, smartLists, iSmartListLinkItem, iSmartListSubList, isDefined, iSmartListCharacterItem, eSmartListSortType, charID, iGrouping } from "typings";
import { filterObjects } from "./smartListFiltering";
import { getNestedSubscriptionOrDependantTo } from "./smartListSubscriptions";
import { getSmartListSubscriberPairs } from "./smartListSubscriptions";
import { dequal } from "dequal";

export type itemIDCharIDs = { itemID: string; charIDs: charID[] }
export type smartListIDToCharIDs = Record<string, charID[]>

export const getMembersForSmartList = (
  smartListID: string,
  smartLists: smartLists,
  members: members,
  smartListIDToCharIDs: smartListIDToCharIDs,
  isSplit: boolean,
  grouping: iGrouping
) => {
  const smartList = smartLists?.[smartListID]
  const itemIDMembersTuple: itemIDCharIDs[] = []
  if (!smartList) return itemIDMembersTuple

  const memberVariantArr = Object.values(members)
    .reduce((acc, member) => {
      if (isSplit) {
        acc.push(...grouping.getMemberVariants(member))
        return acc
      }
      acc.push(member)
      return acc
    }, [] as iMember[])
    .filter((member) => {
      if(!member?.character?.charName) return false
      if (isSplit) return true
      return member.onRoster === true
    })

  let smartListSpots = smartList.limit === 0 ? null : smartList.limit
  let validSmartListMembers = filterObjects(
    memberVariantArr,
    smartList.conditions,
    "userID",
    undefined,
    0,
    smartList.conditionMode
  ) // filter smart list conditions on all members

  for (const itemID of smartList.itemOrder) {
    if(isDefined(smartListSpots) && smartListSpots < 1) {
      itemIDMembersTuple.push({ itemID, charIDs: [] })
      continue;
    }

    // get item members
    let itemCharIDs: charID[] = getCharIDsForListItem(
      smartList,
      itemID,
      validSmartListMembers,
      smartListIDToCharIDs
    )
    
    // if not enough spots left, add slice
    if(isDefined(smartListSpots) && itemCharIDs.length > smartListSpots) {
      itemCharIDs = itemCharIDs.slice(0, smartListSpots)
    }
    itemIDMembersTuple.push({ itemID, charIDs: itemCharIDs })
    if(isDefined(smartListSpots)) smartListSpots -= itemCharIDs.length

    // remove found item members from smart list members
    validSmartListMembers = validSmartListMembers.filter(
      (member) =>
      itemCharIDs.findIndex((itemCharID) =>
        // TODO:DI might have to change this to remove members that match userID only
        // not sure if it makes sense to have a member more than once inside as a smart list result
        // itemCharID.memberID === member.userID
          dequal(itemCharID, { memberID: member.userID, charName: member!.character!.charName })
        ) === -1
    )
  }

  return itemIDMembersTuple
}

export const flattenItemIDMembersTuple = (itemIDMembersTuple: itemIDCharIDs[]) => {
  
  const flattenedArray = itemIDMembersTuple.reduce((acc, { itemID, charIDs }) => {
    if (!charIDs || charIDs?.length > 0 === false) return acc
    return [...acc, ...charIDs]
  }, [] as charID[])
  
  return flattenedArray
}

export const getCharIDsForListItem = (smartList: iSmartList, itemID: string, validMembers: iMember[], smartListIDToCharIDs: smartListIDToCharIDs): charID[] => {
  const item = smartList?.items?.[itemID]
  if(!item) throw new Error("Get members for smart list item - that item does not exist")

  // item link
  if(item.type === eSmartListItemType.SMARTLIST){
    return getCharIDsForLinkItem(item, smartListIDToCharIDs, validMembers)
  }

  // sub list
  if(item.type === eSmartListItemType.SUBLIST){
    const subList = smartList.subLists?.[item.subListID]
    return getCharIDsForSubList(subList, validMembers)
  }

  // char item
  if(item.type === eSmartListItemType.CHARACTER){
    return getCharIDsForCharItem(item, validMembers)
  }

  isNever(item)
  return []
}

const getCharIDsForCharItem = (
  item: iSmartListCharacterItem,
  validMembers: iMember[]
): charID[] => {
  const member = validMembers.find((member) => {
    return dequal(item.charID, { memberID: member.userID, charName: member?.character?.charName })
  })
  if (!member) return []
  if (member?.character?.charName === item.charID.charName){
    return [{
      memberID: member.userID,
      charName: member!.character!.charName
    }]
  }
  return []
}

export const getCharIDsForSubList = (
  subList: iSmartListSubList,
  validMembers: iMember[]
): charID[] => {
  if(subList.conditions?.length < 1) return []
  const sortByKey = subList.sortBy.type === eSmartListSortType.USERID ? "userID" : "userID" // TODO: needs to be improved when more sorting functions are added
  const charIDs = filterObjects(
    validMembers,
    subList.conditions,
    sortByKey,
    undefined,
    subList.limit,
    subList.conditionMode
  ).map((member) => ({ memberID: member.userID, charName: member!.character!.charName }))
  if(subList.limit > 0) return charIDs.slice(0, subList.limit)
  return charIDs
}

export const getCharIDsForLinkItem = (
  linkItem: iSmartListLinkItem,
  smartListIDToMembers: smartListIDToCharIDs,
  validMembers: iMember[]
): charID[] => {
  if(!smartListIDToMembers?.[linkItem.smartListID]) {
    throw new Error("Get members for link item - members not provided.")
  }
  const charIDs = smartListIDToMembers?.[linkItem.smartListID]
  const validCharIDs = charIDs.filter((charID) => {
    return !!validMembers.find((validMember) => {
      if(!validMember?.character?.charName) return false
      const validCharID: charID = { memberID: validMember.userID, charName: validMember.character.charName }
      return dequal(charID, validCharID)
    })
  })
  if(linkItem.limit > 0) return validCharIDs.slice(0, linkItem.limit)
  return validCharIDs
}


export const getSmartListCalculationOrder = (smartLists: smartLists) => {
  const dependencyArr = getSmartListDependencies(smartLists)
  const sortedSmartListIDs = dependencySort(dependencyArr)
  return { sortedSmartListIDs, dependencyArr }
}

export const getSmartListDependencies = (smartLists: smartLists) => {
  const smartListAndDependenciesArr: ObjectDependency[] = []
  const subscriberPairs = getSmartListSubscriberPairs(smartLists)
  for (const smartList of Object.values(smartLists)) {
    const dependencies = getNestedSubscriptionOrDependantTo(smartList.id, subscriberPairs, false)
    smartListAndDependenciesArr.push( { objectID: smartList.id, dependencies: new Set(dependencies) })
  }
  return smartListAndDependenciesArr
}

type ObjectDependency = {
  objectID: string;
  dependencies: Set<string>;
};

const dependencySort = (objects: ObjectDependency[]): string[] => {

  let notSortedYetIDs = objects.map(obj => obj.objectID)

  // Create a set of all objects that have no dependencies
  const sortedIDs = objects
    .filter(({ dependencies }) => dependencies.size === 0)
    .map(({ objectID }) => objectID)
  
  // remove roots
  notSortedYetIDs = notSortedYetIDs.filter(ID => sortedIDs.includes(ID) === false)

  // add objectIDs that have dependencies
  let maxAttempts = 100
  while (maxAttempts > 0) {
    maxAttempts -= 1

    for (const { dependencies, objectID } of objects) {
      if(notSortedYetIDs.includes(objectID) === false) continue;
      const depArray = Array.from(dependencies)
      if(depArray.every(dep => sortedIDs.includes(dep))){
        sortedIDs.push(objectID)
        notSortedYetIDs = notSortedYetIDs.filter(ID => ID !== objectID)
      }
    }
  }

  // Return the sorted objects
  return sortedIDs
}