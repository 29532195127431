import { JSONContent } from '@tiptap/react';

import { GridData } from './panelGridTypes';
import { TargetAssignment, iSpecSwapData, imgData } from './panelSubTypes';

// Assignments / panels
export enum AssignmentType {
  GRID = "grid",
  TARGET = "target",
  SPEC_SWAP = "spec-swap",
  IMAGE = "image",
  TEXT = "text",
  VIDEO = "video",
  SPACER = "spacer",
}
export type assignmentType = AssignmentType

export type iHorizontalAlignOptions = 'left' | 'center' | 'right'
export type iPanelHeadline = {
  text: string
  align: iHorizontalAlignOptions
}
export type iTextData = JSONContent | string


// iPanel
export interface iPanelCore {
  id: string
  colSpan: number
  adminVisibleOnly?: boolean
  assignmentTitle: string // remove?
  headline?: iPanelHeadline
}
export interface iPanelGrid extends iPanelCore {
  type: AssignmentType.GRID
  gridData: GridData
  uniqueMembers?: boolean
}
export interface iPanelTarget extends iPanelCore {
  type: AssignmentType.TARGET
  targetData: TargetAssignment
  uniqueMembers?: boolean
}
export interface iPanelSpecSwap extends iPanelCore {
  type: AssignmentType.SPEC_SWAP
  specSwapData: iSpecSwapData
}

export interface iPanelText extends iPanelCore {
  type: AssignmentType.TEXT
  textData: iTextData
  noteID?: string
}
export interface iPanelImage extends iPanelCore {
  type: AssignmentType.IMAGE
  imgData: imgData
}
export interface iPanelVideo extends iPanelCore {
  type: AssignmentType.VIDEO
  videoURL: string
}
export interface iPanelSpacer extends iPanelCore {
  type: AssignmentType.SPACER
}

export type iPanel =
  | iPanelGrid
  | iPanelTarget
  | iPanelSpecSwap
  | iPanelText
  | iPanelImage
  | iPanelVideo
  | iPanelSpacer


