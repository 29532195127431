import { getUserPremiumField, iTeamTierChange } from "data"
import { getErrorMessage, useAxios } from "functions"
import { useMemo, useState } from "react"
import toast from "react-hot-toast"
import { isDefined, iTeam } from "typings"
import { useAuth } from "../../../context/authContext"
import { useModal, useTeams } from "../../../global/hooks"
import Button from "../../1_atom/buttons/button"
import Divider from "../../1_atom/divider/divider"
import { LoadingSpinner } from "../../1_atom/empty/notFound"

export const ManageBoostsModal = () => {

  const axios = useAxios()
  const { userData } = useAuth()
  const { teams, loadTeamsState } = useTeams()

  const getInitialTotalBoosts = () => {
    const obj: Record<string, number> = {}
    if(!userData?.[getUserPremiumField()]?.boostDistribution) return obj
    for (const [teamID, { boostsSent }] of Object.entries(userData?.[getUserPremiumField()]?.boostDistribution || [])) {
      obj[teamID] = boostsSent
    }
    return obj
  }
  
  const [_, closeModal] = useModal()
  const [totalBoosts, setTotalBoosts] = useState<Record<string, number>>(getInitialTotalBoosts())
  const usedBoosts = Object.values(totalBoosts).reduce((acc, boosts) => acc + boosts, 0)
  const availableBoosts = userData?.[getUserPremiumField()]?.availableBoosts || 0
  const moreBoostsLeft = availableBoosts > usedBoosts

  const updateTotalBoosts = (teamID: string, boosts: number) => {
    setTotalBoosts(prev => ({...prev, [teamID]: boosts}))
  }

  const saveChanges = () => {

    const saveTeamBoostsReceived = async () => {
      const res = await axios.post(
        `/api/updateUserBoosts`,
        { userID: userData.userID, distribution: totalBoosts },
        { validateStatus: () => true }
      )
      if (res.status >= 300) {
        throw new Error(res?.data?.feedback || "Failed to update boost distribution.")
      }
      closeModal()

      const teamChangedArray = res?.data?.results as iTeamTierChange[]
      if(!!teamChangedArray && Array.isArray(teamChangedArray) && teamChangedArray?.length > 0){
        const changedTeamsFeedback = teamChangedArray.map(change => {
          if(change.direction === "up") return `${change.team.teamName} was upgraded to ${change.to.label}!`
          if(change.direction === "down") return `${change.team.teamName} was downgraded to ${change.to.label}!`
          return `${change.team.teamName} now has ${change.totalBoosts} boosts!`
        })
        return changedTeamsFeedback.join("\n")
      }
      return null
    }

    try {
      toast.promise(saveTeamBoostsReceived(), {
        loading: 'Saving changes...',
        success: (teamsUpgradedString) => {
          return teamsUpgradedString || 'Changes saved!'
        },
        error: (err) => {
          console.error(err);
          return `Failed to save changes.\n${getErrorMessage(err)}`
        },
      });

    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }

  const Team = ({ team, overrideBoosts, updateTotalBoosts }: { team: iTeam, updateTotalBoosts: (teamID: string, boosts: number) => void, overrideBoosts?: number }) => {

    const teamBoosts = useMemo(() => {
      if(isDefined(overrideBoosts)) return overrideBoosts
      if(!team) return 0
      const boostsReceivedByUser = team?.premium?.boostsReceived?.[userData.userID]
      if(!boostsReceivedByUser) return 0
      return boostsReceivedByUser
    }, [overrideBoosts, team?.premium?.boostsReceived?.[userData.userID]])

    return (
      <div className="left-right-container align-center">
        <span>{team.teamName}</span>
        <div className="flex-row gap1 align-center">
          <span>{teamBoosts} boosts</span>
          <Button
            iconLeft={{ icon: "plus", fill: true }}
            size="small-sq"
            variant="outline"
            click={() => {
              if(moreBoostsLeft === false) {
                toast("You've already distributed all your boosts.")
                return
              }
              updateTotalBoosts(team.id, teamBoosts + 1)
            }}
          />
          <Button
            iconLeft={{ icon: "minus", fill: true }}
            size="small-sq"
            variant="outline"
            click={() => {
              if(teamBoosts < 1) return
              updateTotalBoosts(team.id, teamBoosts - 1)
            }}
          />
        </div>
      </div>
    )
  }

  if(loadTeamsState !== "loaded") return (
    <div className="flex-row p2 bg3 rounded center-center">
      <LoadingSpinner />
    </div>
  )

  return (
    <div className="flex-col gap1">
      <span className=" align-self-end">{usedBoosts}/{availableBoosts} boosts used</span>
      {teams.map((team) => {
        return (
          <div key={team.id}>
            <Team
              team={team}
              updateTotalBoosts={updateTotalBoosts}
              overrideBoosts={totalBoosts?.[team.id]}
            />
            <Divider halfSize />
          </div>
        )
      })}
      <Button
        text="Save changes"
        size="small"
        addClass="align-self-end"
        click={saveChanges}
      />
    </div>
  )
}