import { iSplitSignup, iRaid, iSmartListCacheType, charID, iMemberTimeWindows, iSplitRun, members, iSmartList, eSmartListItemType, iSmartListItem, isNever, isDefined, charIDString, smartListCache, iUserPreferences } from "typings"
import { findRunsIn, splitDataAndRunIDToRun, findMemberInSession, mapCharIDsToTWAvailability, getItemTarget, getSmartListPriority, getRunMaxSize, getItemTargetMax } from "./splitUtilities"
import { arrayFilterIndex, capitalize, findArrayOverlap, getWithDefault, isInArray } from "../../utilities/utilities"
import copy from "fast-copy"
import { charIDToString, findCharInMembers, sortCharRoleClassSpecName } from "../raid/raidUtilities"
import { subListToLabel } from "../raidData/smartListUtilities"
import { getCharFromMember } from "../user/userUtilities"
import { dequal } from "dequal"
import { minMax } from "../../utilities/mathUtilities"


export type iAllocation = {
  charID: charID
  category: string
}
export type iRunAllocationConflict = {}
export type iRunAllocationFoundPerItem = {}
export type iRunAllocationSmartListMatchCore = {
  label: string // smart list label
  id: string // smart list / item ID
  target: number
  found: number
  charIDs: charID[]
}
export type iRunAllocationSmartListMatch = iRunAllocationSmartListMatchCore & {
  priority: number
  foundPerItem: iRunAllocationSmartListMatchCore[]
}
export type iRunAllocation = {
  runSize: number
  allocations: iAllocation[]
  matches: iRunAllocationSmartListMatch[]
  conflicts: iRunAllocationConflict[]
}
export type iRunAllocations = {
  [runID: string]: iRunAllocation
}
type charIDStringToAppeal = Record<
  charIDString,
  {
    appeal: number
    wantedFor: {
      smartListID: string
      smartListLabel: string
    }[]
  }
>

type notAllObj = {
  charID: charID
  category: string
  timeWindows: iMemberTimeWindows
}
type allObj = {
  charID: charID
  category: string
}
type basicDistributeProps = {
  runIDToSpotsRemaining: Record<string, number>
  categoryOrder: string[]
  notFullRunIDs: string[]
  split: iSplitSignup
  signup: iRaid
  smartListCache: iSmartListCacheType
  smartLists: Record<string, iSmartList>,
  notAll: notAllObj[]
  all: allObj[]
  userPreferences: iUserPreferences
  distributionLog: iDistributeLog
}
type smartListProgress = {
  remainingWantedCharIDs: charID[]
}
type runProgress = {
  remainingWantedInRun: number
}
export type iDistributeLogItem =
  | {
      type: "header"
      title: string
    }
  | {
      type: "allocate-char"
      desc: string
    }
export type iDistributeLog = iDistributeLogItem[]

export const getDistributeAllocation = (
  splitData: iSplitSignup,
  signup: iRaid,
  categoryOrder: string[],
  smartListCache: iSmartListCacheType,
  smartLists: Record<string, iSmartList>,
  userPreferences: iUserPreferences,
  distributionLog: iDistributeLog
) => {
  const split = copy(splitData)
  const runAndRunIDs = findRunsIn(split)
  const runIDs = runAndRunIDs.map((o) => o.runID)

  const { all, notAll } = getDistributeMembers(split, signup, runAndRunIDs)

  const notFullRunIDs = runIDs.sort().filter((runID) => {
    const run = splitDataAndRunIDToRun(split, runID)
    const maxSize = run?.overrides?.maxSize || signup.maxSize
    return run.members?.length < maxSize
  })
  const runIDToSpotsRemaining: Record<string, number> = notFullRunIDs.reduce((acc, runID) => {
    const run = splitDataAndRunIDToRun(split, runID)
    const maxSize = run?.overrides?.maxSize || signup.maxSize
    return { ...acc, [runID]: maxSize - splitDataAndRunIDToRun(split, runID).members?.length }
  }, {})

  return smartAllocation({
    all,
    categoryOrder,
    notAll,
    notFullRunIDs,
    runIDToSpotsRemaining,
    signup,
    smartListCache,
    smartLists,
    split,
    userPreferences,
    distributionLog
  })
}


const basicAllocation = ({
  all,
  categoryOrder,
  notAll,
  notFullRunIDs,
  signup,
  runIDToSpotsRemaining,
  smartListCache,
  smartLists,
  allocationInput,
  split,
  userPreferences,
  distributionLog
}: basicDistributeProps & { allocationInput?: iRunAllocations }): iRunAllocations => {
  const allocation = allocationInput || splitDataToAllocation(split, signup, smartListCache, smartLists)
  const usedCharIDs = allocationToCharIDs(allocation)

  let maxIterations = 20
  while (
    Object.values(runIDToSpotsRemaining).reduce((acc, number) => acc + number, 0) > 0 &&
    maxIterations > 0
  ) {
    maxIterations -= 1

    for (const category of categoryOrder) {
      for (const runID of notFullRunIDs) {
        const twIndex = runID.substring(0, 1)
        if (!runIDToSpotsRemaining?.[runID] || runIDToSpotsRemaining[runID] <= 0) continue

        const filter = (
          obj: {
            charID: charID
            category: string
            timeWindows?: iMemberTimeWindows
          },
        ) => {
          if (!!obj?.timeWindows && !obj.timeWindows?.[twIndex]) return false
          const isInSession = findMemberInSession(obj.charID.memberID, split, runID[0], runID[1])
          if (isInSession !== null) return false
          return obj.category === category
        }

        const useIfExists = (
          indexArray: number[],
          useArray: {
            charID: charID
            category: string
            timeWindows?: iMemberTimeWindows
          }[]
        ): boolean => {
          if (indexArray?.length < 1) return false
          const spliceIndex = indexArray[0]
          const { charID } = useArray.splice(spliceIndex, 1)[0]
          allocateSpot({
            allocation,
            charID,
            category,
            runID,
            usedCharIDs,
            runIDToSpotsRemaining,
            split,
            runProgress: { remainingWantedInRun: 0 }, // maybe remove/ make optional?
            smartListProgress: { remainingWantedCharIDs: [] }, // maybe remove/ make optional?
            filledInRuns: {},
            desirableCharacters: [],
            distributionLog
          })
          return true
        }

        // try to find a category match that didn't sign up to all time windows - sort: the fewer time windows the better
        const notAllIndexes = arrayFilterIndex(notAll, filter, (a, b) =>
          a?.timeWindows?.length < b?.timeWindows?.length ? 1 : -1
        )
        const usedNotAll = useIfExists(notAllIndexes, notAll)
        if(usedNotAll) continue;

        // try to find a category match that signed up to all time windows
        const allIndexes = arrayFilterIndex(all, filter)
        const usedAll = useIfExists(allIndexes, all)
        if(usedAll) continue;
      }
    }
  }

  sortAllocations(allocation, signup)

  return allocation
}

const smartAllocation = ({
  all,
  notAll,
  categoryOrder,
  notFullRunIDs,
  runIDToSpotsRemaining,
  signup,
  smartListCache,
  smartLists,
  split,
  userPreferences,
  distributionLog
}: basicDistributeProps): iRunAllocations => {
  const allocation = splitDataToAllocation(split, signup, smartListCache, smartLists)
  const appeal = calculateCharAppeal(smartListCache, smartLists, "default")
  const usedCharIDs = allocationToCharIDs(allocation)

  const smartListsWithTarget = Object.values(smartLists)
    .filter(smartList => smartListHasAnyTargets(smartList))
    .sort((a, b) => {
      const aPriority = getSmartListPriority(a)
      const bPriority = getSmartListPriority(b)
      if(aPriority > bPriority) return -1
      if(aPriority < bPriority) return 1
      return a.id > b.id ? -1 : 1
    })
  
  // per smart list
  for (const smartList of smartListsWithTarget) {
    const cache = smartListCache.get(smartList.id)
    if (!cache?.charIDsPerItemID_NOLIMIT) continue
    distributionLog.push({
      type: "header",
      title: smartList.label,
    })

    fillSmartList({
      smartList,
      cache,
      all,
      allocation,
      appeal,
      notAll,
      notFullRunIDs,
      runIDToSpotsRemaining,
      usedCharIDs,
      split,
      signup,
      userPreferences,
      distributionLog
    })
  }

  if(userPreferences?.useLegacyDistAlgo === true){
    basicAllocation({
      all,
      categoryOrder,
      notAll,
      notFullRunIDs,
      runIDToSpotsRemaining,
      signup,
      smartListCache,
      smartLists,
      split,
      allocationInput: allocation,
      userPreferences,
      distributionLog
    })
  }


  sortAllocations(allocation, signup)
  return allocation
}

type maybeFillProps = {
  desirableCharacters: charID[]
  runID: string
  smartListProgress: smartListProgress
  runProgress: runProgress
  runIDToSpotsRemaining: Record<string, number>
  allocation: iRunAllocations
  split: iSplitSignup
  notAll: notAllObj[]
  all: allObj[]
  usedCharIDs: charID[]
  appeal: charIDStringToAppeal
  filledInRuns: Record<string, number>
  userPreferences: iUserPreferences
  distributionLog: iDistributeLog
}
const maybeFill = ({
  allocation,
  desirableCharacters,
  runID,
  runIDToSpotsRemaining,
  runProgress,
  filledInRuns,
  smartListProgress,
  split,
  notAll,
  all,
  usedCharIDs,
  appeal,
  userPreferences,
  distributionLog
}: maybeFillProps): boolean => {
  const twIndex = runID.substring(0, 1)
  if (runProgress.remainingWantedInRun < 1) return false
  if (!runIDToSpotsRemaining?.[runID] || runIDToSpotsRemaining[runID] <= 0) return false

  const filter = (obj: {
    charID: charID
    category: string
    timeWindows?: iMemberTimeWindows
  }) => {
    if (!!obj?.timeWindows && !obj.timeWindows?.[twIndex]) return false
    const isInSession = findMemberInSession(obj.charID.memberID, split, runID[0], runID[1])
    if (!!isInSession) return false
    if (isInArray(obj.charID, desirableCharacters) === false) return false // TODO: check if there are any desirable options left
    if(isDefined(userPreferences?.useNegativeAppealChars) && userPreferences?.useNegativeAppealChars === false){
      const charAppeal = lookupAppeal(obj.charID, appeal)?.appeal
      if(isDefined(charAppeal) && charAppeal < 0) return false
    }
    return true
  }

  const useIfExists = (
    indexArray: number[],
    useArray: {
      charID: charID
      category: string
      timeWindows?: iMemberTimeWindows
    }[]
  ): boolean => {
    if (indexArray?.length < 1) return false
    const spliceIndex = indexArray[0]
    const { charID, category } = useArray.splice(spliceIndex, 1)[0]
    allocateSpot({
      allocation,
      charID,
      category,
      runID,
      runIDToSpotsRemaining,
      split,
      usedCharIDs,
      smartListProgress,
      filledInRuns,
      runProgress,
      desirableCharacters,
      distributionLog
    })
    return true
  }

  // try to find a category match that didn't sign up to all time windows - sort: the fewer time windows the better
  const notAllIndexes = arrayFilterIndex(notAll, filter, (a, b) => {
    if (a?.timeWindows?.length < b?.timeWindows?.length) return 1
    if (a?.timeWindows?.length > b?.timeWindows?.length) return -1
    const appealA = lookupAppeal(a.charID, appeal)?.appeal || 0
    const appealB = lookupAppeal(b.charID, appeal)?.appeal || 0
    return appealA > appealB ? -1 : 1
  })
  const usedNotAll = useIfExists(notAllIndexes, notAll)
  if (usedNotAll === true) return true

  // try to find a category match that signed up to all time windows
  const allIndexes = arrayFilterIndex(all, filter, (a, b) => {
    const appealA = lookupAppeal(a.charID, appeal)?.appeal || 0
    const appealB = lookupAppeal(b.charID, appeal)?.appeal || 0
    return appealA > appealB ? -1 : 1
  })
  const usedAll = useIfExists(allIndexes, all)
  if (usedAll === true) return true

  return false
}

type fillSmartListProps = {
  cache: smartListCache,
  smartList: iSmartList
  notFullRunIDs: string[]
  split: iSplitSignup
  notAll: notAllObj[]
  all: allObj[]
  allocation: iRunAllocations
  appeal: charIDStringToAppeal
  runIDToSpotsRemaining: Record<string, number>
  usedCharIDs: charID[]
  signup: iRaid
  userPreferences: iUserPreferences
  distributionLog: iDistributeLog
}
const fillSmartList = (fillSmartListProps: fillSmartListProps) => {
  const {
    smartList,
    cache,
    notFullRunIDs,
    all,
    notAll,
    split,
    allocation,
    appeal,
    usedCharIDs,
    runIDToSpotsRemaining,
    signup,
    userPreferences,
    distributionLog
  } = fillSmartListProps
  const filledInRuns: Record<string, number> = findRunsIn(split).reduce((acc, { run, runID }) => {
    return { ...acc, [runID]: 0 }
  }, {})

  const isAlreadyUsed = (_charID: charID): boolean => {
    if(isInArray(_charID, usedCharIDs)) return false
    return true
  }

  const smartListProgress: smartListProgress = {
    remainingWantedCharIDs: cache.charIDs_NOLIMIT.filter(isAlreadyUsed), // used for fillRest
  }

  const getRemainingRunTarget = (runID: string, target: number, charIDs: charID[], runSize: string) => {
    // reduce by overlap between allocated and potential charIDs
    const existingCharIDs = (allocation?.[runID]?.allocations || []).map((allo) => allo.charID)

    // check if smart list target is met
    if(isDefined(smartList?.target)){
      const smartListTarget = getWithDefault(smartList?.target, runSize) || 0
      const smartListOverLap = findArrayOverlap(existingCharIDs, cache.charIDs_NOLIMIT)
      if(smartListOverLap.length >= smartListTarget) return 0
    }

    // return run target
    const runOverlap = findArrayOverlap(charIDs, existingCharIDs)
    const modifiedTarget = minMax(target - runOverlap.length, 0)
    return modifiedTarget
  }

  itemLoop: for (const itemCache of cache?.charIDsPerItemID_NOLIMIT) {
    const desirableCharacters = copy(itemCache.charIDs)
    const item = smartList.items?.[itemCache.itemID]
    const itemTargetMax = getItemTargetMax(item)
    if(itemTargetMax < 1) continue;
    
    // add chars based on item targets
    itemTargetLoop: for (let i = 0; i < itemTargetMax; i++) {
      let progressMade = false
      
      runLoop: for (const runID of notFullRunIDs) {
        if (desirableCharacters.length < 1) {
          // console.log("no more desirable chars - breaking")
          break itemTargetLoop
        }
        const runIsFull = !runIDToSpotsRemaining?.[runID] || runIDToSpotsRemaining[runID] <= 0
        const runSize = getRunMaxSize(split, signup.maxSize, runID).toString()
        const itemTarget_ = getItemTarget(item, runSize)
        const runItemTarget = getRemainingRunTarget(runID, itemTarget_, itemCache.charIDs, runSize)
        if(runIsFull || runItemTarget < 1) continue;

        if (!filledInRuns?.[runID]) filledInRuns[runID] = 0
        const runProgress: runProgress = {
          remainingWantedInRun: runItemTarget,
        }

        const fillSuccess = maybeFill({
          desirableCharacters,
          runID,
          filledInRuns,
          smartListProgress,
          runProgress,
          all,
          allocation,
          usedCharIDs,
          appeal,
          notAll,
          runIDToSpotsRemaining,
          split,
          userPreferences,
          distributionLog
        })
        if(fillSuccess) progressMade = true
      }

      if(progressMade === false) {
        // console.log("no progress made - breaking itemTargetLoop")
        break itemTargetLoop
      }
    }
  } // item loop end


  // Fill rest: make sure smart list target is met, not just items inside
  outerLoop: for (let i = 0; i < 20; i++) {

    const charactersLeft = smartListProgress.remainingWantedCharIDs.length > 0;
    const runIDToRunRemainingTargets = Object.entries(filledInRuns).reduce((acc, [runID, filledIn]) => {
      const runSize = getRunMaxSize(split, signup.maxSize, runID).toString()
      const smartListTarget = getWithDefault(smartList?.target, runSize)
      if(!isDefined(smartListTarget)) return acc
      const runTarget = getRemainingRunTarget(runID, smartListTarget, cache.charIDs_NOLIMIT, runSize)
      return {...acc, [runID]: runTarget }
    }, {} as Record<string, number>)
    const allRunsMetTarget = Object.values(runIDToRunRemainingTargets).every(target => target < 1);


    // break if no characters left or all runs already done
    if (!charactersLeft || allRunsMetTarget) {
      break outerLoop;
    }

    let progressMade = false
    
    runLoop: for (const [runID, runTarget] of Object.entries(runIDToRunRemainingTargets)) {
      const runIsFull = !runIDToSpotsRemaining?.[runID] || runIDToSpotsRemaining[runID] <= 0
      if(runIsFull || runTarget === 0) continue;
  
      const runProgress: runProgress = {
        remainingWantedInRun: runTarget,
      }
      
      const fillSuccess = maybeFill({
        all,
        allocation,
        appeal,
        filledInRuns,
        desirableCharacters: smartListProgress.remainingWantedCharIDs,
        notAll,
        runID,
        runIDToSpotsRemaining,
        runProgress,
        smartListProgress,
        split,
        usedCharIDs,
        userPreferences,
        distributionLog
      })
      if(fillSuccess) progressMade = true
    }

    if(progressMade === false) {
      // console.log("no progress made - breaking outerloop")
      break outerLoop
    }
  }
}

const allocateSpot = (props: {
  allocation: iRunAllocations
  charID: charID
  runID: string
  category: string
  split: iSplitSignup
  runIDToSpotsRemaining: Record<string, number>
  smartListProgress: smartListProgress,
  runProgress: runProgress
  usedCharIDs: charID[]
  filledInRuns: Record<string, number>
  desirableCharacters: charID[]
  distributionLog: iDistributeLog
}) => {
  const {
    allocation,
    charID,
    category,
    runID,
    runIDToSpotsRemaining,
    split,
    runProgress,
    smartListProgress,
    usedCharIDs,
    filledInRuns,
    desirableCharacters,
    distributionLog
  } = props
  if (!allocation?.[runID]) allocation[runID] = {
    allocations: [],
    conflicts: [],
    matches: [],
    runSize: 0
  }
  if (!runIDToSpotsRemaining?.[runID] || runIDToSpotsRemaining[runID] <= 0) {
    throw new Error("Couldn't fill that spot")
  }
  runIDToSpotsRemaining[runID] -= 1
  allocation[runID].allocations.push({
    charID,
    category
  })
  const run = splitDataAndRunIDToRun(split, runID)
  run.members.push(charID)
  usedCharIDs.push(charID)
  runProgress.remainingWantedInRun -= 1
  filledInRuns[runID] += 1
  smartListProgress.remainingWantedCharIDs = smartListProgress.remainingWantedCharIDs.filter(_charID => !dequal(_charID, charID))
  const desiredCharIndex = desirableCharacters.findIndex(_charID => dequal(_charID, charID))
  if(desiredCharIndex !== -1) desirableCharacters.splice(desiredCharIndex, 1)
  distributionLog.push({
    type: "allocate-char",
    desc: `Added ${capitalize(charID.charName)} to ${runID}`
  })
}

const getDistributeMembers = (
  split: iSplitSignup,
  signup: iRaid,
  runAndRunIDs: { runID: string; run: iSplitRun }[]
) => {
  const runs = runAndRunIDs.map((o) => o.run)
  const runCharIDs = runs.reduce((acc, item) => [...acc, ...item.members], [] as charID[])
  const memberIDsInRuns = runCharIDs.map((c) => c.memberID)

  const checkIsBenched = (charID: charID) => {
    return (
      runCharIDs.findIndex(
        (o) => o.charName === charID.charName && o.memberID === charID.memberID
      ) === -1
    )
  }

  const { allTimeWindows, notAllTimeWindows } = mapCharIDsToTWAvailability(split, signup)
  const all: { charID: charID; category: string }[] = []
  const notAll: { charID: charID; category: string; timeWindows: iMemberTimeWindows }[] = []

  // those that can go to all time windows
  for (const { charID, category } of allTimeWindows) {
    if (checkIsBenched(charID) === false) continue // skip already in a run
    if (split.memberMaxPerSplit < 1) {
      all.push({ charID, category })
      continue
    }
    // member char limit
    const memberCount = memberIDsInRuns.filter((memberID) => memberID === charID.memberID).length
    if (memberCount >= split.memberMaxPerSplit) continue
    memberIDsInRuns.push(charID.memberID)
    all.push({ charID, category })
  }

  // those that can only go to some time windows
  for (const { charID, category, timeWindows } of notAllTimeWindows) {
    if (checkIsBenched(charID) === false) continue // skip already in a run
    if (split.memberMaxPerSplit < 1) {
      notAll.push({ charID, category, timeWindows })
      continue
    }
    // member char limit
    const memberCount = memberIDsInRuns.filter((memberID) => memberID === charID.memberID).length
    if (memberCount >= split.memberMaxPerSplit) continue
    memberIDsInRuns.push(charID.memberID)
    notAll.push({ charID, category, timeWindows })
  }
  return {
    all,
    notAll
  }
}


const itemToMatch = (
  item: iSmartListItem,
  smartList: iSmartList,
  smartLists: Record<string, iSmartList>,
  cacheItemObj: {
    itemID: string
    charIDs: charID[]
  },
  allocationCharIDs: charID[],
  runSize: string
): iRunAllocationSmartListMatchCore => {
  const found = allocationCharIDs.filter(
    (charID) => isInArray(charID, cacheItemObj.charIDs) === true
  ).length
  if (item.type === eSmartListItemType.CHARACTER) {
    return {
      id: item.itemID,
      charIDs: [item.charID],
      found,
      label: capitalize(item.charID.charName),
      target: 1
    }
  }
  if (item.type === eSmartListItemType.SUBLIST) {
    const subList = smartList.subLists[item.subListID]
    return {
      id: item.itemID,
      charIDs: cacheItemObj.charIDs,
      found,
      label: subListToLabel(subList),
      target: getWithDefault(item?.target, runSize) || 0
    }
  }
  if (item.type === eSmartListItemType.SMARTLIST) {
    const linkedSmartList = smartLists[item.smartListID]
    return {
      id: item.itemID,
      charIDs: cacheItemObj.charIDs,
      found,
      label: linkedSmartList.label,
      target: getWithDefault(item?.target, runSize) || 0
    }
  }
  isNever(item)
  throw new Error("Get allocation matches - item to match - invalid item type")
}

const getAllocationMatches = (
  allocations: iAllocation[],
  smartListCache: iSmartListCacheType,
  smartLists: Record<string, iSmartList>,
  runSize: string
): iRunAllocationSmartListMatch[] => {
  const matches: iRunAllocationSmartListMatch[] = []


  // so what we're looking for here is to supply an array of allocations
  // and see which smart lists meet their target
  for (const [smartListID, cache] of Array.from(smartListCache.entries())) {
    const smartList = smartLists?.[smartListID]
    if(!smartList) continue
    if(smartListHasTargets(smartList, runSize) === false) continue;
    const allocationCharIDs = allocations.map(allo => allo.charID)
    const foundCharacters = cache.charIDs_NOLIMIT.filter((charID) => isInArray(charID, allocationCharIDs) === true)
    const foundPerItem: iRunAllocationSmartListMatchCore[] = cache.charIDsPerItemID_NOLIMIT
      .filter((obj) => {
        // remove if item doesn't exist or item doesn't have target
        const item = smartList?.items?.[obj.itemID]
        if (!item) return false
        if (item.type === eSmartListItemType.CHARACTER) return false // decides if char items are displayed
        const target = getWithDefault(item?.target, runSize)
        return !!target && target > 0
      })
      .map((obj) => {
        const item = smartList?.items?.[obj.itemID]
        return itemToMatch(item, smartList, smartLists, obj, allocationCharIDs, runSize)
      })
    
    const match: iRunAllocationSmartListMatch = {
      charIDs: cache.charIDs_NOLIMIT,
      id: smartList.id,
      label: smartList.label,
      target: getWithDefault(smartList?.target, runSize) || 0,
      priority: getSmartListPriority(smartList),
      found: foundCharacters.length,
      foundPerItem
    }
    matches.push(match)
  }

  return matches
}

const getAllocationConflicts = (
  allocations: iAllocation[],
  smartListCache: iSmartListCacheType,
  runSize: string
): iRunAllocationSmartListMatch[] => {
  return [] // TODO:
}

const runToAllocations = (splitRun: iSplitRun, members: members) => {
  const allocations: iAllocation[] = []
  for (const charID of splitRun.members) {
    const char = findCharInMembers(charID, members)
    if(!char) continue;
    const allocation: iAllocation = {
      charID,
      category: char.gameRole
    }
    allocations.push(allocation)
  }
  return allocations
}

export const splitDataToAllocation = (
  split: iSplitSignup,
  signup: iRaid,
  smartListCache: iSmartListCacheType,
  smartLists: Record<string, iSmartList>,
): iRunAllocations => {
  const allocation: iRunAllocations = {}
  const runs = findRunsIn(split)

  for (const run of runs) {
    const runAllocations = runToAllocations(run.run, signup.members)
    const runSize = getRunMaxSize(split, signup.maxSize, run.runID)

    allocation[run.runID] = {
      allocations: runAllocations,
      conflicts: getAllocationConflicts(runAllocations, smartListCache, runSize.toString()),
      matches: getAllocationMatches(runAllocations, smartListCache, smartLists, runSize.toString()),
      runSize
    }
  }

  return allocation
}

export const calculateCharAppeal = (
  smartListCache: iSmartListCacheType,
  smartLists: Record<string, iSmartList>,
  runSize: string
) => {

  const appealObj: charIDStringToAppeal = {}

  for (const [smartListID, cache] of Array.from(smartListCache.entries())) {
    for (const charID of cache.charIDs_NOLIMIT) {
      const smartList = smartLists?.[smartListID]
      if(!smartList) {
        console.error("Calculate char appeal - smart list not found");
        continue;
      }
      const { target: _target, appeal: _appeal } = smartList
      const target = getWithDefault(_target, runSize)
      const appeal = getWithDefault(_appeal, runSize)
      const hasTarget = isDefined(target) && target > 0
      const hasAppeal = isDefined(appeal) && appeal !== 0
      if(!(hasTarget || hasAppeal)) continue;
      
      const charIDString = charIDToString(charID)
      if(!isDefined(appealObj?.[charIDString])){
        appealObj[charIDString] = {
          appeal: 0,
          wantedFor: []
        }
      }

      const appealPoints = getSmartListAppeal(smartList, runSize)
      appealObj[charIDString].appeal += appealPoints
      appealObj[charIDString].wantedFor.push({
        smartListID: smartList.id,
        smartListLabel: smartList.label
      })
    }
  }
  
  return appealObj
}

const getSmartListAppeal = (smartList: iSmartList, runSize: string) => {
  const target = getWithDefault(smartList?.target, runSize)
  const hasTarget = isDefined(target) && target > 0
  const priorityValue = hasTarget ? getSmartListPriority(smartList) : 0
  const appealValue = getWithDefault(smartList?.appeal, runSize) || 0
  return priorityValue + appealValue
}

const lookupAppeal = (charID: charID, appeal: charIDStringToAppeal) => {
  const charIDString = charIDToString(charID)
  if(appeal?.[charIDString]) return appeal[charIDString]
  return null
}

const sortAllocations = (allocation: iRunAllocations, signup: iRaid) => {
  for (let i = 0; i < Object.values(allocation).length; i++) {
    const runAllocation = Object.values(allocation)[i];
    runAllocation.allocations = runAllocation.allocations.sort((a,b) => {
      const memberA = signup.members[a.charID.memberID]
      const charA = getCharFromMember(memberA, a.charID.charName)
      const memberB = signup.members[b.charID.memberID]
      const charB = getCharFromMember(memberB, b.charID.charName)
      return sortCharRoleClassSpecName(charA, charB)
    })
  }
}

const allocationToCharIDs = (allocation: iRunAllocations) => {
  const charIDs = Object.values(allocation).reduce((acc, item) => {
    const _charIDs = item.allocations.map(allo => allo.charID)
    return [...acc, ..._charIDs]
  }, [] as charID[])
  return charIDs
}

export const smartListHasTargets = (smartList: iSmartList, runSize: string): boolean => {
  const smartListTarget = getWithDefault(smartList?.target, runSize)
  if(isDefined(smartListTarget) && smartListTarget > 0) return true
  const itemTotalTargets = Object.values(smartList.items).reduce(
    (acc, item) => acc + getItemTarget(item, runSize),
    0
  )
  if(itemTotalTargets > 0) return true
  return false
}

export const smartListHasAnyTargets = (smartList: iSmartList): boolean => {
  const smartListTarget = getWithDefault(smartList?.target)
  if(isDefined(smartListTarget) && smartListTarget > 0) return true
  const itemTotalTargets = Object.values(smartList.items).reduce(
    (acc, item) => {
      if(item.type === eSmartListItemType.CHARACTER) return acc + 1
      return getWithDefault(item?.target) || 0
    },
    0
  )
  if(itemTotalTargets > 0) return true
  return false
}