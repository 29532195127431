import { useAtom } from "jotai";
import { useRouter } from "next/router";
import React, { useEffect, useContext, createContext, ReactNode } from "react";
import toast from "react-hot-toast";
import { firebase } from 'api'
import { claimsState, FBUserState, UserDataState } from "../global/state/global";
import * as Sentry from "@sentry/nextjs";
import { eFSColl, iTeamPermissionType, iUser, iUserClaims } from "typings";

type AuthContextType = {
  userData: iUser
  user: firebase.User | null
  claims: iUserClaims
  getFirebaseAuthToken: () => Promise<string | undefined>
  hasTeamPermission: (teamID: string, permission: iTeamPermissionType) => boolean
  signout: () => Promise<void>
  deleteCurrentUser: () => Promise<void>
  confirmPasswordReset: (code: string, password: string) => Promise<boolean>
  refreshClaims: () => Promise<void>
  tokenSignin: (token: string, callback: (user: firebase.User) => any) => Promise<void>
}
const AuthContext = createContext<AuthContextType>(null!);


// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useAtom(FBUserState);
  const [userData, setUserData] = useAtom(UserDataState);
  const [claims, setClaims] = useAtom(claimsState)
  const router = useRouter()

  const db = firebase.firestore();

  // set sentry user
  useEffect(() => {
    if(!userData) return
    // since you cannot update user data when you're not logged in
    // this callback will trigger once you login
    if(userData?.userID) {
      Sentry.setUser({ id: userData?.userID, username: userData?.discordUID });
    }
  }, [userData])
  
  // set auth user
  useEffect(() => {
    if(!user) return

    const updateUserData = async () => {
      if(typeof user === "boolean") return
      const userDocRef = db.collection(eFSColl.USERS).doc(user.uid)

      userDocRef
        .onSnapshot((doc) => {
          if(doc.exists){
            const data = doc.data() as iUser
            if((data?.discordID === undefined || data?.discordID === null)) {
              return signout();
            }
            setUserData(data)
          } else {
            setUserData(null)
            console.warn("no user data found - completely normal if during a signup process");
          }
        });
    }

    updateUserData()
  }, [user])
  

  // OAuth login
  const tokenSignin = async (token: string, callback: (user: firebase.User) => any) => {
    if(!token) return
    console.log("logging in custom token", token)
    return firebase.auth().signInWithCustomToken(token)
      .then(async (userCredential) => {
        // Signed in
        var user = userCredential.user;
        if(user) await callback(user)
        setUser(user)
        refreshClaims()
        toast.success("Welcome!");
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error, { tags: { token } });
        toast.error(
          "Oops! Failed to login 😬 Please try again. If the problem persists, please check the FAQ, then make a post on the discord server!",
        );
      });
  }

  const signout = async () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setUserData(null);
      });
  };

  const confirmPasswordReset = async (code: string, password: string) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log("found user", user)
        setUser(user)
        refreshClaims()
      } else {
        console.log("found no user", user)
        setUser(null)
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const getFirebaseAuthToken = async () => {
    return await firebase.auth().currentUser?.getIdToken();
  }

  const deleteCurrentUser = async () => {
    if(!user) return
    const userIDToken = await firebase.auth().currentUser?.getIdToken();

    const uri = `/api/deleteUser`

    const userDeletionResult = await fetch(uri, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        userID: user.uid,
        idToken: userIDToken
      })
     })
      .then((result) => result.json())
      .catch(console.error);

    setUser(null)
    setUserData(null)
    router.replace("/")
    toast.success(`Account deleted!`)
  }

  const refreshClaims = async () => {
    const idTokenResult = await firebase.auth()?.currentUser?.getIdTokenResult(true)
    if(!idTokenResult?.claims) return
    setClaims(idTokenResult.claims as iUserClaims)
  }

  const hasTeamPermission = (teamID: string, permission: iTeamPermissionType) => {
    const teamIDs = claims?.teamPermissions?.[permission]
    return !!teamIDs?.includes(teamID)
  }
  
  
  return (
    <AuthContext.Provider value={{
      user,
      // FIXME: GS - might be a problem, 
      // atom can be null, but also don't want to handle it being null everytime it's used

      // maybe I should make it a hook - useUser and skip the context altogether
      // @ts-ignore
      userData,
      getFirebaseAuthToken,
      refreshClaims,
      claims,
      signout,
      confirmPasswordReset,
      tokenSignin,
      deleteCurrentUser,
      hasTeamPermission
    }}>
      {children}
    </AuthContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};