import { iCharacter, iLocationClassSpec } from "typings"
import { getRandomLocationChar } from "../games"
import { iWoWGameVersions } from "./expansions"

export const getFullRaidCharactersTBC = (location: iLocationClassSpec) => {
  const wowClasses = location.game.classes
  const raidSetup: {char: iCharacter}[][] = [
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warrior"].specs["protection"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["holy"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["holy"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["priest"].specs["holy"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["druid"].specs["restoration"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["retribution"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warrior"].specs["arms"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warrior"].specs["fury"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["rogue"].specs["combat"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["restoration"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["druid"].specs["guardian"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["hunter"].specs["beast mastery"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["hunter"].specs["beast mastery"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["hunter"].specs["survival"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["enhancement"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["destruction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["destruction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["destruction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["druid"].specs["balance"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["elemental"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["mage"].specs["arcane"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["mage"].specs["arcane"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["priest"].specs["shadow"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["protection"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["elemental"]})},
    ],
  ]
  return raidSetup
}

export const getFullRaidCharactersWotlk = (location: iLocationClassSpec) => {
  const wowClasses = location.game.classes
  const raidSetup: {char: iCharacter}[][] = [
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["protection"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["death knight"].specs["blood"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["retribution"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["rogue"].specs["assassination"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["rogue"].specs["assassination"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["death knight"].specs["unholy"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["death knight"].specs["unholy"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["death knight"].specs["frost"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["druid"].specs["feral"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warrior"].specs["fury"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["affliction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["affliction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["warlock"].specs["affliction"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["enhancement"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["elemental"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["mage"].specs["fire"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["mage"].specs["fire"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["priest"].specs["shadow"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["druid"].specs["balance"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["holy"]})},
    ],
    [
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["hunter"].specs["survival"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["hunter"].specs["survival"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["priest"].specs["discipline"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["shaman"].specs["restoration"]})},
      {char: getRandomLocationChar(location, { mode: "spec", spec: wowClasses["paladin"].specs["holy"]})},
    ],
  ]
  return raidSetup
}

const wowheadRaidCompToolMapWrath: Record<string, Record<string, string>> = {
  "death knight": { blood: "K", "blood dps": "K", frost: "L", unholy: "M" },
  priest: { discipline: "n", holy: "p", shadow: "q" },
  druid: { balance: "x", feral: "v", guardian: "v", restoration: "w" },
  rogue: { assassination: "k", combat: "j", subtlety: "m" },
  hunter: { "beast mastery": "C", marksmanship: "F", survival: "D" },
  shaman: { elemental: "r", enhancement: "t", restoration: "s" },
  mage: { arcane: "d", fire: "b", frost: "c" },
  warlock: { affliction: "z", demonology: "B", destruction: "y" },
  paladin: { holy: "H", protection: "J", retribution: "G" },
  warrior: { arms: "f", fury: "h", protection: "g" }
}

const wowheadRaidCompToolMapRetail: Record<string, Record<string, string>> = {
  "death knight": { blood: "s", frost: "t", unholy: "v" },
  priest: { discipline: "z", holy: "B", shadow: "C" },
  druid: { balance: "n", feral: "p", guardian: "q", restoration: "r" },
  rogue: { assassination: "D", combat: "F", subtlety: "G" },
  hunter: { "beast mastery": "w", marksmanship: "x", survival: "y" },
  shaman: { elemental: "H", enhancement: "J", restoration: "K" },
  mage: { arcane: "b", fire: "c", frost: "d" },
  warlock: { affliction: "L", demonology: "M", destruction: "N" },
  paladin: { holy: "f", protection: "g", retribution: "h" },
  warrior: { arms: "j", fury: "k", protection: "m" },
  evoker: { devastation: "V", preservation: "W", augmentation: "X" },
  monk: { brewmaster: "P", mistweaver: "R", windwalker: "Q" },
  "demon hunter": { havoc: "S", vengeance: "T"},
}

const getLookup = (gameVersion: iWoWGameVersions) => {
  if(gameVersion === "wotlk") return wowheadRaidCompToolMapWrath
  if(gameVersion === "retail") return wowheadRaidCompToolMapRetail
  return {}
}
export const classSpecToWowheadRaidCompToolLetter = (gameClass: string, gameSpec: string, gameVersion: iWoWGameVersions) => {
  return getLookup(gameVersion)?.[gameClass]?.[gameSpec] || "0"
}