import copy from "fast-copy";
import { Getter, PrimitiveAtom, atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { Areas, AssignmentType, CellType, assignments, charID, eDragType, eSmartListItemType, newID, iAssignmentPositions, iMember, iPanel, iPanelGrid, iPanelSpecSwap, iRaidArea, iSmartList, iSmartListCacheType, iSmartListInstance, iSmartListItem, iSmartListSubList, members, smartListCache, smartLists, iGrouping } from "typings";
import { selectedAssignmentSectionState, ydocAtom } from "./global";
import { getMemberFromTargetID, raidMembersState, signupGroupingAtom, signupLocationMapState, updateAssignmentState } from "./raid";
import { Active, Over } from "@dnd-kit/core";
import { getDragAndDropData } from "../../components/dnd_kit/utilities/createRange";
import { addListItemToSmartList, getDirectSubscribersTo, getListItemFromDragData, getSmartListSubscriberPairs, moveArrayItem, deleteItemFromSmartList, getSmartListCalculationOrder, getMembersForSmartList, flattenItemIDMembersTuple, getCellsForSmartListInstance, getGridPanelSize, minMax, addSmartListDisplayToGridData, deletePairsByCondition, getTWColor, getCellsFromPanel, modifyMembersWithSpecSwaps, getMemberItemFromDragData, getErrorMessage, validatePanelInstances, checkAllInstancesValid, membersToCharIDs, findFirstNonUsedCharID, createSmartListsWithoutLimits, smartListIDToCharIDs, memberPossiblyPresent } from "functions";
import { assignmentPosState, assignmentsState, targetsState } from "./raidData";
import { iCellOverride } from "../../context/assignmentPanelContext";
import { toast } from "react-hot-toast";
import { jotaiStore } from "../../pages/_app";
import { splitListsInitialisedState } from "./split";
import { findLocation } from "data";


export const smartListsCoreAtom = atomWithReset<Record<string, iSmartList>>({})
export const basicSmartListsAtom = atomWithReset<Record<string, iSmartList>>({})

export const smartListsAtom = atom(
  (get) => {
    const core = get(smartListsCoreAtom)
    const basicLists = get(basicSmartListsAtom)
    return {...core, ...basicLists }
  },
  (get, set, update: Record<string, iSmartList>) => {
    set(smartListsCoreAtom, update)
  }
)

export const previewingSmartListIDsAtom = atomWithReset<string[]>([])
export const isViewingSpreadPriorityAtom = atom<boolean>(false)
export const highlightedInstanceAtom = atom<string | null>(null) as PrimitiveAtom<string | null>
export const smartListTabAtom = atom<string | null>(null) as PrimitiveAtom<string | null>
export const gridCellSizeCache = atom<Record<string, { width: number; height: number }>>({})

// add or remove smart list
export const addSmartListAtom = atom(
  null,
  (get, set, listItem?: iSmartListItem, subList?: iSmartListSubList, smartListInput?: iSmartList) => {
    const y = get(ydocAtom)

    const id = newID(4)
    const smartList: iSmartList = smartListInput || {
      id,
      label: "New smart list",
      conditionMode: "any",
      lastUpdated: Date.now(),
      conditions: [],
      items: {},
      itemOrder: [],
      limit: 0,
      subLists: {}
    }
    
    try {
      if(y.smartLists.get(smartList.id)) throw new Error("That smart list already exists.")
      
      y.doc.transact(() => {

        if(!!listItem){
          addListItemToSmartList(smartList, listItem, subList)
        }
        set(setSmartListAtom, smartList)

        // update area lists
        const selectedTab = set(getSelectedTabAtom) || set(createNewSmartListTabsAtom, ["New smart lists"])
        if(!selectedTab) throw new Error("Add new smart list - no tab selected")
        const area: iRaidArea = copy(y.areas.get(Areas.SMARTLISTS)) || {
          tabbedAreaLists: {
            tabs: {},
            tabOrder: []
          }
        }

        area.tabbedAreaLists?.tabs[selectedTab].push(smartList.id)
        y.areas.set(Areas.SMARTLISTS, area)
      }, "user")
      
    } catch (error) {
      console.error(error);
    }
  }
)

export const deleteSmartListAtom = atom(
  null,
  (get, set, deleteSmartListID: string) => {
    const y = get(ydocAtom)

    try {
      if(!y.smartLists.get(deleteSmartListID)) throw new Error("That smart list does not exist.")

      y.doc.transact(() => {
        const smartLists = copy(get(smartListsAtom))

        const subscriberPairs = getSmartListSubscriberPairs(smartLists)
        const directSubscribers = getDirectSubscribersTo(deleteSmartListID, subscriberPairs)
        
        for (const subscriberID of directSubscribers) {
          const subscriberSmartList = copy(y.smartLists.get(subscriberID))
          if(!subscriberSmartList) continue

          // remove listItems that point to this smartList
          const removeItemIDs = subscriberSmartList.itemOrder.reduce((acc, itemID) => {
            const item = subscriberSmartList.items[itemID]
            if(item.type !== eSmartListItemType.SMARTLIST) return acc
            if(item.smartListID !== deleteSmartListID) return acc
            return [...acc, itemID]

          }, [] as string[])

          for (const itemID of removeItemIDs) {
            delete subscriberSmartList.items[itemID]
          }
          subscriberSmartList.itemOrder = subscriberSmartList.itemOrder.filter(
            (itemID) => !removeItemIDs.includes(itemID)
          )

          set(setSmartListAtom, subscriberSmartList)
        }

        
        y.smartLists.delete(deleteSmartListID)
        
        // update area lists
        const area = copy(y.areas.get(Areas.SMARTLISTS))
        const selectedTab = set(getSelectedTabAtom)
        if(!selectedTab || !area?.tabbedAreaLists) throw new Error("Add new smart list - no tab selected")
        area.tabbedAreaLists.tabs[selectedTab] = area.tabbedAreaLists.tabs[selectedTab].filter(
          (id) => id !== deleteSmartListID
        )
        y.areas.set(Areas.SMARTLISTS, area)

        // delete all instances
        const panels = copy(get(assignmentsState))

        for (const panel of Object.values(panels)) {
          if(panel.type !== AssignmentType.GRID) continue;
          const smartListDisplay = panel.gridData?.smartListDisplay
          const modifiedInstances = copy(smartListDisplay?.instances)
          if(!smartListDisplay || !modifiedInstances) continue;
          const hasLinkedInstance = Object.values(modifiedInstances).some(
            (instance) => instance.smartListID === deleteSmartListID
          )
          if(!hasLinkedInstance) continue;
          const deletedInstanceIDs = deletePairsByCondition(modifiedInstances, (instance) => {
            return instance.smartListID === deleteSmartListID
          })
          smartListDisplay.instances = modifiedInstances
          smartListDisplay.priority = smartListDisplay.priority.filter(
            (instanceID) => deletedInstanceIDs.includes(instanceID) === false
          )
          y.assignments.set(panel.id, panel)
        }
      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)


// update smart list
export const setSmartListAtom = atom(
  null,
  (get, set, smartListInput: iSmartList) => {
    const y = get(ydocAtom)

    try {
      const smartList = copy(smartListInput)
      smartList.lastUpdated = Date.now()
      
      y.doc.transact(() => {
        y.smartLists.set(smartList.id, smartList)
      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)

export const updateSmartListAtom = atom(
  null,
  (get, set, { smartListID, callback }: { smartListID: string, callback: (smartList: iSmartList) => void }) => {
    const y = get(ydocAtom)
    const smartLists = get(smartListsAtom)

    try {
      const smartList = copy(smartLists[smartListID])
      callback(smartList)
      smartList.lastUpdated = Date.now()
      
      y.doc.transact(() => {
        y.smartLists.set(smartList.id, smartList)
      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)

export const addItemToSmartListAtom = atom(
  null,
  (get, set, active: Active, over: Over) => {
    const y = get(ydocAtom)
    const { dragData, dropData } = getDragAndDropData(active, over)
    
    try {
      if (
        dragData.dragType !== eDragType.SMARTLISTITEM &&
        dragData.dragType !== eDragType.SMARTLIST
      ) {
        throw new Error("Invalid drop type in on new list drop")
      }

      const { listItem, subList } = getListItemFromDragData(dragData)
      const dropSmartList = dropData?.extraDropData?.smartListData?.smartList
      if(!dropSmartList) throw new Error("Add item to smart list - no smart list provided")

      y.doc.transact(() => {
        set(updateSmartListAtom, { smartListID: dropSmartList.id, callback: (sList) => {
          addListItemToSmartList(sList, listItem, subList, dragData?.listIndex)
        }})

      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)

export const deleteSmartListItemAtom = atom(
  null,
  (get, set, { itemID, smartListID }: { smartListID: string, itemID: string }) => {
    const y = get(ydocAtom)
    
    y.transactUser(() => {
      set(updateSmartListAtom, { smartListID, callback(smartList) {
        deleteItemFromSmartList(smartList, itemID)
      }})
    })
  }
)


// drag and drop handlers
export const onNewListDropAtom = atom(
  null,
  (get, set, active: Active, over: Over) => {
    const y = get(ydocAtom)
    
    try {
      const { dragData } = getDragAndDropData(active, over)
      const { listItem, subList } = getListItemFromDragData(dragData)
      
      y.doc.transact(() => {
        set(addSmartListAtom, listItem, subList)
      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)

export const defaultOnDropAtom = atom(
  null,
  (get, set, active: Active, over: Over) => {
    const y = get(ydocAtom)
    
    try {
      const { dragData, dropData } = getDragAndDropData(active, over)
      
      y.doc.transact(() => {
        
        // re-order smart lists
        if(dragData.dragType === eDragType.SMARTLIST){
          const area = copy(y.areas.get(Areas.SMARTLISTS))
          const selectedTab = set(getSelectedTabAtom)
          if(!selectedTab) throw new Error("Add new smart list - no tab selected")
          if(!area?.tabbedAreaLists?.tabs?.[selectedTab]) throw new Error("Re-order smart lists - no area lists found")
          if(!dragData.listIndex || !dropData.listIndex) throw new Error("Could not find index number")
          moveArrayItem(area.tabbedAreaLists.tabs[selectedTab], dragData.listIndex, dropData.listIndex)
          y.areas.set(Areas.SMARTLISTS, area)
        }

        // add member to smart list
        if(dragData.dragType === eDragType.MEMBER){
          const toSmartList = copy(y.smartLists.get(dropData?.extraDropData?.smartListData?.smartList?.id || ""))
          if(!toSmartList) throw new Error("Drag member into smart list - smart list not found")
          const charListItem = getMemberItemFromDragData(dragData)
          if(charListItem === null) return // FIXME: GS - should be able to drag regular members in as char items?
          
          addListItemToSmartList(toSmartList, charListItem)
          y.smartLists.set(toSmartList.id, toSmartList)
        }

      }, "user")

    } catch (error) {
      console.error(error);
    }
  }
)


// smart list display
export const smartListToMemberCacheAtom = atomWithReset<iSmartListCacheType>(new Map())

const recalcSmartsListMembers = (members: members, smartLists: smartLists, isSplit: boolean, grouping: iGrouping) => {
  const { sortedSmartListIDs } = getSmartListCalculationOrder(smartLists)
  const smartListIDToCharIDs: smartListIDToCharIDs = {}
  const smartListIDToCharIDs_NOLIMIT: smartListIDToCharIDs = {}
  const cacheMap = new Map<string, smartListCache>()

  for (const smartListID of sortedSmartListIDs) {
    // TODO: if list or lists dependencies hasn't updated
    // return cached results
    const smartList = smartLists[smartListID]

    // normal calculation
    const itemIDCharIDs = getMembersForSmartList(
      smartListID,
      smartLists,
      members,
      smartListIDToCharIDs,
      isSplit,
      grouping
    )
    const flattenedCharIDsArray = flattenItemIDMembersTuple(itemIDCharIDs)
    smartListIDToCharIDs[smartListID] = flattenedCharIDsArray

    if(isSplit === false) {
      // cache normal results
      cacheMap.set(smartListID, {
        lastUpdate: smartList.lastUpdated,
        charIDs: flattenedCharIDsArray,
        charIDsPerItemID: itemIDCharIDs,
        charIDs_NOLIMIT: [],
        charIDsPerItemID_NOLIMIT: []
      })
      continue
    }

    // disabled limits calculation
    // used for split signup distribution
    const smartLists_NOLIMIT = createSmartListsWithoutLimits(smartLists)
    const itemIDCharIDs_NOLIMIT = getMembersForSmartList(
      smartListID,
      smartLists_NOLIMIT,
      members,
      smartListIDToCharIDs_NOLIMIT,
      isSplit,
      grouping
    )
    const flattenedCharIDsArray_NOLIMIT = flattenItemIDMembersTuple(itemIDCharIDs_NOLIMIT)
    smartListIDToCharIDs_NOLIMIT[smartListID] = flattenedCharIDsArray_NOLIMIT

    // cache normal + no limit results
    cacheMap.set(smartListID, {
      lastUpdate: smartList.lastUpdated,
      charIDs: flattenedCharIDsArray,
      charIDsPerItemID: itemIDCharIDs,
      charIDs_NOLIMIT: flattenedCharIDsArray_NOLIMIT,
      charIDsPerItemID_NOLIMIT: itemIDCharIDs_NOLIMIT
    })
  }

  return cacheMap
}

export const recalcSmartsListMembersAtom = atom(null, (get, set, members?: members, updateCache = true) => {
  try {
    const smartLists = get(smartListsAtom)
    const signupMembers = members || get(raidMembersState)
    const grouping = get(signupGroupingAtom)
    if(!grouping) throw new Error("Cannot calculate smart lists - grouping is not initialised")

    const newCache = recalcSmartsListMembers(signupMembers, smartLists, get(splitListsInitialisedState), grouping)

    if(updateCache) set(smartListToMemberCacheAtom, newCache)
    return newCache
  } catch (error) {
    console.error(error)
  }
})


const getRepairedPanel = (gridPanel: iPanelGrid) => {
  try {
    // repair
    const repairedPanel = copy(gridPanel)
    validatePanelInstances(repairedPanel)
    
    // update state
    const y = jotaiStore.get(ydocAtom)
    y.transactSystem(() => {
      y.assignments.set(repairedPanel.id, repairedPanel)
    })
    
    return { repaired: true, panel: repairedPanel }
  } catch (error) {
    console.error(error)
    return { repaired: false, panel: gridPanel }
  }
}

const calculateCellOverrides = (panels: iPanel[], cache: Map<string, smartListCache>, get: Getter) => {
  const y = get(ydocAtom)
  let repairedAmount = 0

  const calculatePanel = (_panel: iPanel) => {
    if(_panel?.type !== AssignmentType.GRID) return {}
    if(!_panel?.gridData?.smartListDisplay) return {}
    
    const getValidPanel = (gridPanel: iPanelGrid) => {
      const instancesOK = checkAllInstancesValid(gridPanel)
      if(instancesOK) return gridPanel
      const { repaired, panel } = getRepairedPanel(gridPanel)
      if(repaired === false) {
        throw new Error("Update smart list display members - Unable to automatically repair panel")
      }
      repairedAmount += 1
      return panel
    }
    
    const panel = getValidPanel(_panel)
    if(!panel?.gridData?.smartListDisplay) return {}

    const placings: { [xy: string]: iCellOverride } = {}
    const { instances, priority } = panel.gridData?.smartListDisplay

    // normally filled members
    const memberArr: iMember[] = []
    const cells = getCellsFromPanel(panel)
    for (const cell of cells) {
      if(!cell?.data || cell.type !== CellType.MEMBER) continue
      const member = getMemberFromTargetID(cell?.data, get)
      if(member) memberArr.push(member)
    }
    const placedCharIDs: charID[] = membersToCharIDs(memberArr)
    const isUniqueMembers = panel?.uniqueMembers === true

    for (const instanceID of priority) {
      const instance = instances?.[instanceID]
      if(!instance) throw new Error("Update smart list display members - missing instance")
      const cellXYArray = getCellsForSmartListInstance(panel, instance)
      if(instance?.reversedPos) cellXYArray.reverse()
      const smartListCache = copy(cache.get(instance.smartListID))
      if(!smartListCache) break
      const charIDs = smartListCache?.charIDs
      if(instance?.reversed) charIDs.reverse()

      const getCharID = (): charID | null => {
        if(isUniqueMembers === true) {
          return findFirstNonUsedCharID(charIDs, placedCharIDs)
        }
        return charIDs.splice(0, 1)[0] || null
      }

      for (let i = 0; i < cellXYArray.length; i++) {
        const { cell, xy } = cellXYArray[i]

        // skip if cell is already filled with non smart list data
        if(cell?.type !== CellType.MEMBER) continue
        const target = y.targets.get(cell.data)
        if(!!target?.containID) {
          const member = getMemberFromTargetID(cell?.data, get)
          if(memberPossiblyPresent(member?.signupState) === true) {
            continue
          }
        }

        // skip previously filled cell
        if(!!placings[`${xy.col}.${xy.row}`]) continue; 
        const charID = getCharID()

        if (!charID) break

        const instanceIDs = Object.keys(panel.gridData?.smartListDisplay?.instances || {}).sort()
        const index = instanceIDs.findIndex((_instanceID) => _instanceID === instance.id)
        const { color } = getTWColor(index)

        placings[`${xy.col}.${xy.row}`] = { charID, color }
        placedCharIDs.push(charID)
      }
    }

    return placings
  }

  const panelOverrides: Record<string, { [xy: string]: iCellOverride }> = {}
  try {
    for (const panel of panels) {
      const over = calculatePanel(panel)
      if(Object.keys(over)?.length > 0){
        panelOverrides[panel.id] = over
      }
    }
  } catch (error) {
    console.error(error);
    toast.error("Could not calculate smart list distribution: " + getErrorMessage(error))
  }

  if(repairedAmount > 0){
    toast(
      `Automatically repaired ${repairedAmount} panel${
        repairedAmount > 1 ? "s" : ""
      } due to invalid smart list data.`
    )
  }

  return panelOverrides
}

export type iPanelIDToOverrides = Record<string, {
  [xy: string]: iCellOverride;
}>
export const cellOverridesAtom = atom((get) => {
  const panels = get(assignmentsState)
  const aPos = get(assignmentPosState)
  const selectedSections = get(selectedAssignmentSectionState)

  // TODO:SL
  // used to trigger updates when targets are filled
  // not ideal as it re-calculates everytime any target is updated
  // regardless if it's in a panel with smart list instances or not
  const targets = get(targetsState)

  return calculatePanelOverrides({ aPos, get, panels, selectedSections })
})

interface getAllOverridesProps {
  get: Getter
  panels: assignments
  aPos: iAssignmentPositions
  selectedSections: string[]
  calculateAllPanels?: boolean
}
export const calculatePanelOverrides = (update: getAllOverridesProps) => {

  const { aPos, panels, selectedSections, get, calculateAllPanels = false } = update
  let overrides: iPanelIDToOverrides = {}

  // calculate general panels
  const generalPanels = aPos.sections?.["section-1"]?.panels || []
  const normalCache = get(smartListToMemberCacheAtom)
  const generalOverrides = calculateCellOverrides(
    generalPanels.map((panelID) => panels[panelID]),
    normalCache,
    get
  )
  overrides = {...overrides, ...generalOverrides}


  // calculate specific panels
  const lastSelectedSection = selectedSections.at(-1)
  const sectionsWithPanels = Object.values(aPos.sections)
    .filter((section) => !!section?.panels && section?.panels.length > 0)
    .filter((section) => {
      if(section.sectionID === lastSelectedSection) return true // always allow selected section
      return calculateAllPanels
    })

  for (const section of sectionsWithPanels) {
    if(!section.panels) continue
    const specSwapPanelID = section.panels.find(
      (panelID) => panels?.[panelID]?.type === AssignmentType.SPEC_SWAP
    )
    const specSwapPanel = panels?.[specSwapPanelID || ""] as iPanelSpecSwap

    // if no spec swap
    if (!specSwapPanel) {
      const normalSpecificOverrides = calculateCellOverrides(
        section.panels.map((panelID) => panels[panelID]),
        normalCache,
        get
      )
      overrides = {...overrides, ...normalSpecificOverrides}
      continue;
    }

    // has spec swap
    const signupMembers = copy(get(raidMembersState))
    const grouping = get(signupGroupingAtom)
    if(!grouping) throw new Error("Cannot calculate smart lists - grouping is not initialised")
    modifyMembersWithSpecSwaps(signupMembers, specSwapPanel.specSwapData)
    const specSwappedCache = recalcSmartsListMembers(signupMembers, get(smartListsAtom), get(splitListsInitialisedState), grouping)
    const specSwappedSpecificOverrides = calculateCellOverrides(
      section.panels.map((panelID) => panels[panelID]),
      specSwappedCache,
      get
    )
    overrides = {...overrides, ...specSwappedSpecificOverrides}
  }

  return overrides
}

// smart list instances
export const moveSmartListInstanceAtom = atom(
  null,
  (get, set, active: Active, over: Over) => {
    const y = get(ydocAtom)
    
    try {
      const { dragData, dropData } = getDragAndDropData(active, over)
      if(dragData.dragType !== eDragType.SMARTLIST_INSTANCE){
        throw new Error("Move smart list instance - invalid drag type")
      }
      const fromPanelID = dragData.smartListData.instanceData.panelID
      const toPanel = dropData?.extraDropData?.panel
      const dragInstance = dragData.smartListData.instanceData.instance
      if(!toPanel || !dropData.extraDropData?.gridPanelData){
        throw new Error("Move smart list instance - panelIDs or drop data missing")
      }
      const { col: dropCol, row: dropRow } = dropData.extraDropData.gridPanelData
      const xyDrop = `${dropCol}.${dropRow}`

      const updateInstanceXY = (
        instance: iSmartListInstance,
      ) => {
        // update xySource
        instance.xyTopLeft = xyDrop

        // update xy span
        const size = getGridPanelSize(toPanel)
        instance.xSpan = minMax(size.columns - dropCol, 1, instance.xSpan)
        instance.ySpan = minMax(size.rows - dropRow, 1, instance.ySpan)
      }
      
      y.transactUser(() => {
        // if inside same panel
        // change instances xySource
        if(fromPanelID === toPanel.id){
          set(updateAssignmentState, { assignmentID: toPanel.id, callback(assignment) {
            if(assignment.type !== AssignmentType.GRID){
              throw new Error("Move smart list instance - not in grid panel")
            }
            if(!assignment.gridData.smartListDisplay){
              throw new Error("Move smart list instance - no smart list display")
            }
            
            const instance = assignment.gridData.smartListDisplay.instances[dragInstance.id]
            
            updateInstanceXY(instance)
          } })
          return
        }

        // if between panels
        // remove from original panel
        const removedInstance = !fromPanelID ? dragData.smartListData.instanceData.instance : set(
          deleteSmartListInstanceAtom,
          fromPanelID,
          dragData.smartListData.instanceData.instance.id
        )

        set(updateAssignmentState, { assignmentID: toPanel.id, callback(assignment) {
          if(assignment.type !== AssignmentType.GRID){
            throw new Error("Move smart list instance - not in grid panel")
          }
          
          // update xy + span and add to new panel
          if(removedInstance) updateInstanceXY(removedInstance)
          addSmartListDisplayToGridData(
            assignment.gridData,
            xyDrop,
            dragData.smartListData.smartList.id,
            removedInstance
          )
        } })
      })

    } catch (error) {
      console.error(error);
    }
  }
)

export const deleteSmartListInstanceAtom = atom(
  null,
  (get, set, panelID: string, instanceID: string) => {
    const y = get(ydocAtom)
    
    try {
      
      const panel = copy(y.assignments.get(panelID))
      if(!panel) return
      if(panel.type !== AssignmentType.GRID){
        throw new Error("Delete smart list instance - invalid panel type")
      }

      const instance = copy(panel.gridData?.smartListDisplay?.instances?.[instanceID])
      if(!instance || !panel?.gridData?.smartListDisplay){
        throw new Error("Delete smart list instance - instance not found")
      }

      delete panel.gridData?.smartListDisplay?.instances[instanceID]
      panel.gridData.smartListDisplay.priority = panel.gridData?.smartListDisplay.priority.filter(
        (_instanceID) => _instanceID !== instanceID
      )

      if(panel.gridData.smartListDisplay.priority?.length < 1){
        delete panel.gridData.smartListDisplay
      }

      y.transactUser(() => {
        y.assignments.set(panel.id, panel)
      })

      return instance

    } catch (error) {
      console.error(error);
    }
  }
)

export const createNewSmartListTabsAtom = atom(
  null,
  (get, set, newTabNames: string[], fromStart = false) => {
    const y = get(ydocAtom)
    
    try {
      if(newTabNames?.length < 1) throw new Error("You need to provide at least one new tab.")

      y.transactUser(() => {

        const area: iRaidArea = copy(y.areas.get(Areas.SMARTLISTS)) || {
          tabbedAreaLists: {
            tabs: {},
            tabOrder: []
          }
        }
        if(!area?.tabbedAreaLists) throw new Error("Create new smart lists - no tabbed area lists")
        const { tabOrder, tabs } = area.tabbedAreaLists

        let insertAt = fromStart ? 0 : tabOrder?.length
        for (const newTabName of newTabNames) {
          tabOrder.splice(insertAt, 0, newTabName)
          tabs[newTabName] = []
          insertAt += 1
        }
        
        set(smartListTabAtom, newTabNames[0])
        y.areas.set(Areas.SMARTLISTS, area)
      })

    } catch (error) {
      console.error(error);
    }
  }
)

export const addSmartListExamplesAtom = atom(
  null,
  (get, set, exampleMode: "normal" | "split-distribution" = "normal") => {
    const y = get(ydocAtom)
    const signupLocationMap = get(signupLocationMapState)
    if(!signupLocationMap) throw new Error("Add smart list examples - no signup location")
    const location = findLocation(signupLocationMap)

    const config = {
      "normal": {
        tab: "Examples",
        examples: location?.examples?.smartLists || []
      },
      "split-distribution": {
        tab: "Split Distribution Examples",
        examples: location?.examples?.splitDistribution || []
      }
    } as const
    
    try {

      const exampleConfig = config?.[exampleMode]
      if(!exampleConfig) throw new Error("Cannot add examples - mode not recognized.")
      if(exampleConfig?.examples?.length < 1) throw new Error("No examples found for this location.")

      const { examples, tab } = exampleConfig
      y.transactUser(() => {
        const examplesTabAlreadyExists = y.areas.get(Areas.SMARTLISTS)?.tabbedAreaLists?.tabs?.[tab]
        if(!examplesTabAlreadyExists) set(createNewSmartListTabsAtom, [tab])
        
        // add example smart lists to selectedTab
        for (const smartList of examples) {
          if(!!y.smartLists.get(smartList.id)) continue; // skip already existing smart lists
          set(addSmartListAtom, undefined, undefined, smartList)
        }
        set(smartListTabAtom, tab)
      })

    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error))
    }
  }
)


export const getSelectedTabAtom = atom(
  null,
  (get, set) => {
    const y = get(ydocAtom)
    const selectedTab = get(smartListTabAtom)
    
    try {

      const area = copy(y.areas.get(Areas.SMARTLISTS))

      if(area && selectedTab && area?.tabbedAreaLists?.tabOrder?.includes(selectedTab)){
        return selectedTab
      }

      if(area?.tabbedAreaLists?.tabOrder?.[0]){
        return area?.tabbedAreaLists?.tabOrder?.[0]
      }

      return null

    } catch (error) {
      console.error(error);
    }
  }
)

