import { capitalize, capitalizeEveryWord } from "functions"
import { iSmartListCondition, iSmartList, eSmartListItemType, eSmartListSortType, iLocation, GameRole, iRaid, eKeyOrderLayout, isClassSpecLoc, eLocationType, options, iLocationClassSpec, isNever, iLocationNormal, eMemberGroupingFunction, isArrayWithLength, option } from "typings"
import { groupMembersByCharGameRole, groupMembersByRosterCategories } from "../../dataUtilities/groupMemberFunctions"

type getBasicSmartListParams = {
  label: string,
  smartListID: string,
  conditions: iSmartListCondition[]
}
const getBasicSmartList = (params: getBasicSmartListParams) => {
  const { label, smartListID, conditions = [] } = params
  const itemID = `item:id`
  const subListID = `subList:id`

  const basicSmartList: iSmartList = {
    conditionMode: "all",
    conditions: [],
    id: smartListID,
    label: capitalizeEveryWord(label),
    itemOrder: [itemID],
    items: {
      [itemID]: {
        itemID: itemID,
        subListID: subListID,
        type: eSmartListItemType.SUBLIST
      }
    },
    lastUpdated: Date.now(),
    limit: 0,
    subLists: {
      [subListID]: {
        conditionMode: "any",
        conditions: conditions,
        id: subListID,
        limit: 0,
        sortBy: {
          type: eSmartListSortType.USERID
        }
      }
    }
  }
  return basicSmartList
}

// ⚠️ WARNING ⚠️ Do not change this function unless necessary
// panel smart list instances will depend on
// this function returning stable data
export const getBasicSmartLists = (location: iLocation, signup: iRaid) => {
  const smartLists: Record<string, iSmartList> = {}

  // "anyone"
  const anyoneSmartList = getBasicSmartList({
    label: "Anyone",
    smartListID: "basic:anyone",
    conditions: [
      {
        id: "onRoster:true",
        label: "On roster",
        criteria: [{ keyPath: `onRoster`, value: true }]
      },
    ]
  })
  smartLists[anyoneSmartList.id] = anyoneSmartList

  const addGroupingSmartLists = () => {
    const addedGroupingFunctions: eMemberGroupingFunction[] = []

    // ALWAYS: add roster categories
    let hasAddedRosterCategoryLists = false
    for (const rosterCategory of Object.values(signup?.rosterCategories?.categoryArray || [])) {
      const { uniqueID: categoryID, label } = rosterCategory
      const categorySmartList = getBasicSmartList({
        label: label,
        smartListID: `basic:category:${categoryID}`,
        conditions: [
          {
            id: `category:${categoryID}`,
            label: capitalize(categoryID),
            criteria: [{ keyPath: `rosterCategory`, value: categoryID }]
          },
        ]
      })
      smartLists[categorySmartList.id] = categorySmartList
      hasAddedRosterCategoryLists = true
    }
    if(hasAddedRosterCategoryLists){
      addedGroupingFunctions.push(eMemberGroupingFunction.ROSTER_CATEGORIES)
    }

    // add game roles
    if (
      isClassSpecLoc(location) &&
      addedGroupingFunctions.includes(eMemberGroupingFunction.CHARACTER_GAMEROLE) === false
    ) {
      addedGroupingFunctions.push(eMemberGroupingFunction.CHARACTER_GAMEROLE)
      const gameRoleGrouping = groupMembersByCharGameRole(signup, location)
      const categoriesVertical = gameRoleGrouping.getGroupingKeyOrder({
        mode: "specific",
        orderLayout: eKeyOrderLayout.VERTICAL
      })

      for (const category of categoriesVertical) {
        const addPlural = category === GameRole.TANK || category === GameRole.HEALER
        const gameRoleSmartList = getBasicSmartList({
          label: `${category}${addPlural ? "s" : ""}`,
          smartListID: `basic:${category}`,
          conditions: [
            {
              id: `gameRole:${category}`,
              label: capitalize(category),
              criteria: [{ keyPath: `character.gameRole`, value: category }]
            }
          ]
        })
        smartLists[gameRoleSmartList.id] = gameRoleSmartList
      }
    }


    // // add signup grouping
    // const signupGroupingFunction = location.ui.layoutData.defaultLayout.web.roster.groupingFunction
    // if(addedGroupingFunctions.includes(signupGroupingFunction)) return
    // const grouping = getGrouping(signupGroupingFunction, signup, location)
    // const categoriesVertical = grouping.getGroupingKeyOrder({
    //   mode: "specific",
    //   orderLayout: eKeyOrderLayout.VERTICAL
    // })

    // for (const category of categoriesVertical) {
    //   const meta = grouping.categoryMeta[category]
    //   const gameRoleSmartList = getBasicSmartList({
    //     label: meta.label,
    //     smartListID: `basic:${category}`,
    //     conditions: [
    //       {
    //         id: `gameRole:${category}`,
    //         label: capitalize(category),
    //         criteria: [{ keyPath: `character.gameRole`, value: category }]
    //       }
    //     ]
    //   })
    //   smartLists[gameRoleSmartList.id] = gameRoleSmartList
    // }

  }
  addGroupingSmartLists()

  if(isClassSpecLoc(location) === false) return smartLists

  // add classes
  for (const gameClass of Object.values(location.game.classes)) {
    const classSmartList = getBasicSmartList({
      label: gameClass.gameClass,
      smartListID: `basic:${gameClass.gameClass}`,
      conditions: [
        {
          id: `charClass:${gameClass.gameClass}`,
          label: capitalize(gameClass.gameClass),
          criteria: [{ keyPath: `character.charClass`, value: gameClass.gameClass }]
        },
      ]
    })
    smartLists[classSmartList.id] = classSmartList

    // add specs
    for (const gameSpec of Object.values(gameClass.specs)) {
      const specSmartList = getBasicSmartList(
        {
          label: capitalizeEveryWord(gameSpec.gameSpec) + " " + capitalizeEveryWord(gameSpec.gameClass),
          smartListID: `basic:${gameSpec.gameSpec} ${gameSpec.gameClass}`,
          conditions: [
            {
              id: `charSpec:${gameSpec.gameSpec}`,
              label: capitalize(gameSpec.gameSpec),
              criteria: [
                { keyPath: `character.charSpec`, value: gameSpec.gameSpec },
                { keyPath: `character.charClass`, value: gameSpec.gameClass }
              ]
            },
          ]
        }
      )
      smartLists[specSmartList.id] = specSmartList
    }
  }


  return smartLists
}

export const getSmartListConditions = (location: iLocation, signup: iRaid) => {
  if(location.type === eLocationType.CLASS_SPEC) return getSmartListConditions_ClassSpec(location, signup)
  if(location.type === eLocationType.NORMAL) return getSmartListConditions_Normal(location, signup)
  isNever(location)
}

const getSmartListConditions_Shared = (location: iLocation, signup: iRaid) => {
  const arr: options<iSmartListCondition> = []

  const rosterCategoryGrouping = groupMembersByRosterCategories(signup, location)
  for (const rosterCategory of Object.values(signup?.rosterCategories?.categoryArray || [])) {
    const { uniqueID, label } = rosterCategory
    const meta = rosterCategoryGrouping.categoryMeta[uniqueID]
    const categoryCondition: option<iSmartListCondition> = {
      label,
      value: {
        id: `rosterCategory:${rosterCategory.uniqueID}`,
        label,
        criteria: [{ keyPath: "rosterCategory", value: rosterCategory.uniqueID }]
      }
    }
    arr.push(categoryCondition)
  }

  return arr
}

const getSmartListConditions_Normal = (location: iLocationNormal, signup: iRaid) => {
  const arr: options<iSmartListCondition> = []
  arr.push(...getSmartListConditions_Shared(location, signup))

  return arr
}

const getSmartListConditions_ClassSpec = (location: iLocationClassSpec, signup: iRaid) => {
  const arr: options<iSmartListCondition> = []
  arr.push(...getSmartListConditions_Shared(location, signup))

  const gameRoleGrouping = groupMembersByCharGameRole(signup, location)
  const gameRoles = gameRoleGrouping.getGroupingKeyOrder({
    mode: "specific",
    orderLayout: eKeyOrderLayout.HORISONTAL
  })

  // Category:
  for (const gameRole of gameRoles) {
    const label = `${capitalize(gameRole)}`
    arr.push({
      label,
      value: {
        id: `gamerole:${gameRole}`,
        label,
        criteria: [{ keyPath: "character.gameRole", value: gameRole }]
      }
    })
  }

  const gameRoleToSpec: Record<string, string[]> = gameRoles.reduce(
    (acc, gameRole) => {
      return { ...acc, [gameRole]: [] }
    },
    {}
  )

  arr.push({
    label: "Is: main",
    value: {
      id: `is:main`,
      label: "Is: main",
      criteria: [{ keyPath: "character.isMain", value: true }]
    }
  })

  for (const gameClass of Object.values(location?.game?.classes || [])) {
    // Class:
    const label = `${capitalizeEveryWord(gameClass.gameClass)}`
    const classString = gameClass.gameClass.replace(" ", "-")
    const color = `var(--wow-${classString})`
    arr.push({
      label,
      value: {
        id: `class:${classString}`,
        label,
        criteria: [{ keyPath: "character.charClass", value: gameClass.gameClass }],
        styling: {
          color,
          icon: gameClass.iconImgPath
        }
      }
    })

    // Spec:
    for (const spec of Object.values(gameClass.specs)) {
      const label = `${capitalizeEveryWord(spec.gameSpec)} ${capitalizeEveryWord(
        gameClass.gameClass
      )}`
      arr.push({
        label,
        value: {
          id: `class:${classString}.spec:${spec.gameSpec}`,
          label,
          criteria: [
            { keyPath: "character.charSpec", value: spec.gameSpec },
            { keyPath: "character.charClass", value: gameClass.gameClass }
          ],
          styling: {
            color,
            icon: spec.iconImgPath
          }
        }
      })
    }


    // Can go: spec
    for (const spec of Object.values(gameClass.specs)) {
      const label = `Can go: ${capitalizeEveryWord(spec.gameSpec)} ${capitalizeEveryWord(
        gameClass.gameClass
      )}`
      gameRoleToSpec[spec.gameRole].push(spec.gameSpec)
      arr.push({
        label,
        value: {
          id: `can_go:class:${classString}.spec:${spec.gameSpec}`,
          label,
          criteria: [
            { keyPath: "character.altSpecs", value: spec.gameSpec },
            { keyPath: "character.charClass", value: gameClass.gameClass }
          ],
          styling: {
            color,
            icon: spec.iconImgPath,
            svgIcon: { icon: "swap-horizontal" }
          }
        }
      })
    }
  }
  
  // can go game role
  for (const [gameRole, specs] of Object.entries(gameRoleToSpec)) {
    const label = `Can go: ${capitalizeEveryWord(gameRole)}`
    arr.push({
      label,
      value: {
        id: `can_go:gamerole:${gameRole}`,
        label,
        criteria: [
          { keyPath: "character.altSpecs", value: specs },
        ],
      }
    })
  }

  return arr
}