import { AssignmentType, CellType, Target, iColumnCell, iPanel, iPanelGrid, iPanelSpecSwap, iSpecSwapData, iTarget, members } from "typings";

export const getGridPanelSize = (panel: iPanel) => {
  if(panel.type !== AssignmentType.GRID) return { columns: 0, rows: 0 }
  return {
    columns: panel.gridData.columns.length,
    rows: panel.gridData.columns[0].cells.length
  }
}

export const getCellFromPanel = (gridPanel: iPanelGrid, col: number, row: number) => {
  const cell = gridPanel.gridData.columns?.[col].cells?.[row]
  return cell || null
}

export const getCellsFromPanel = (gridPanel: iPanelGrid) => {
  const cells: (iColumnCell | null)[] = []

  for (const column of gridPanel.gridData.columns) {
    for (const cell of column.cells) {
      cells.push(cell)
    }
  }

  return cells
}

export const modifyMembersWithSpecSwaps = (members: members, specSwapData: iSpecSwapData) => {
  
  for (const member of Object.values(members)) {
    const swap = specSwapData.swaps.find(swap => swap.memberID === member.userID)
    if(!swap) continue;
    if(!member.character) continue;
    member.character.charSpec = swap.changeSpecTo
    member.character.gameRole = swap.gameRole
  }

  return members
}

export const getTargetIDsFrom = (a: iPanel): string[] => {
  if(a?.type === AssignmentType.TARGET){
    return [a.targetData.mainTargetID, ...a.targetData.addonTargetIDs.map(targetID => targetID)]
  }
  if(a?.type === AssignmentType.GRID){
    const arr: string[] = []
    for (const col of a.gridData.columns) {
      for (const cell of col.cells) {
        if(cell?.type !== CellType.MEMBER || !cell.data) continue;
        arr.push(cell.data)
      }
    }
    return arr.filter(val => !!val)
  }
  return []
}


const refreshTarget = (targetID: string, targets: iTarget[]): string => {
  const target = targets.find(tar => tar.id === targetID)
  if(!target) throw new Error("Refresh target - target not found")
  target.id = Target().createID()
  return target.id
}


export const refreshTargetIDsInPanel = (panel: iPanel, targets: iTarget[]) => {
  
  if(panel?.type === AssignmentType.TARGET){
    panel.targetData.mainTargetID = refreshTarget(panel.targetData.mainTargetID, targets)

    for (let i = 0; i < panel.targetData.addonTargetIDs.length; i++) {
      const addonTargetID = panel.targetData.addonTargetIDs[i];
      panel.targetData.addonTargetIDs[i] = refreshTarget(addonTargetID, targets)
    }
    return
  }

  if(panel?.type === AssignmentType.GRID){
    for (const col of panel.gridData.columns) {
      for (const cell of col.cells) {
        if(cell?.type !== CellType.MEMBER || !cell.data) continue;
        cell.data = refreshTarget(cell.data, targets)
      }
    }
    return
  }
}