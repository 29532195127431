import { CustomError, ER404_NOT_FOUND } from 'functions'
import { Path } from 'react-hook-form'
import { firebase } from './client'
import automationsAPI from './documents/automationsAPI'
import guestAPI from './documents/guestAPI'
import multidocAPI from './documents/multidocAPI'
import raidAPI from './documents/raidAPI'
import raidDataAPI from './documents/raidDataAPI'
import teamsAPI from './documents/teamsAPI'
import userAPI from './documents/userAPI'

export const standardCRUD = <T extends object>(
  collection: string,
  db: firebase.firestore.Firestore,
  deleteField: firebase.firestore.FieldValue
) => {

  const get = async (documentID: string): Promise<false | T> => {
    try {
      const docRef = db.collection(collection).doc(documentID)
      const doc = await docRef.get()
      if(doc?.exists === false) {
        throw new CustomError({
          ...ER404_NOT_FOUND,
          title: { en: "Document not found" },
          message: { en: `Document ${documentID} could not be found in ${collection}.` }
        })
      }
      const docData = doc.data() as T
      
      return docData
    } catch (error) {
      // console.error(error)
      return false
    }
  }
  
  const set = async (documentID: string, data: T) => {
    try {
      const docRef = db.collection(collection).doc(documentID)
      // console.log(`API - setting document ${documentID} in ${collection}`)

      await docRef.set(data)
      return true
    } catch (error) {
      console.error("Error updating document: ", error)
      return false
    }
  }

  // PathValue<T, Path<T>> - makes typescript very slow
  const updatePath = async (documentID: string, path: Path<T>, data: unknown) => {
    try {
      const docRef = db.collection(collection).doc(documentID)
      // console.log(`API - updating document ${documentID} in ${collection}`)

      await docRef.update({ [path]: data })
      return true
    } catch (error) {
      console.error("Error updating document: ", error)
      return false
    }
  }

  const updateMerge = async (documentID: string, data: Partial<T>) => {
    try {
      const docRef = db.collection(collection).doc(documentID)
      // console.log(`API - updating document ${documentID} in ${collection}`)

      await docRef.set(data, { merge: true })
      return true
    } catch (error) {
      console.error("Error updating document: ", error)
      return false
    }
  }

  const updateMultiPath = async (documentID: string, updates: Partial<T>, deletePaths?: Path<T>[]) => {
    if (!documentID) {
      console.error("API - update error: No documentID supplied")
      return false
    }
    const docRef = db.collection(collection).doc(documentID)
    // console.log(`API - updating document with multipath ${documentID}`)

    if (!updates || Object.values(updates).length < 1) return false
    const final: Record<string, unknown> = {}

    for (const [path, data] of Object.entries(updates)) {
      final[path] = data
    }

    for (const path of deletePaths || []) {
      final[path] = deleteField
    }
    try {
      await docRef.update(final)
      // console.log("API - updating document successful")
      return true
    } catch (error) {
      console.error("Error updating document: ", error)
      return false
    }
  }

  const deleteMultiPath = async (documentID: string, paths: Path<T>[]) => {
    if (!documentID) {
      console.error("API - update error: No documentID supplied")
      return false
    }
    const docRef = db.collection(collection).doc(documentID)
    // console.log(`API - removing fields in document with multipath ${documentID}`)
    
    if (!paths || Object.values(paths).length < 1) return false
    const final: Record<string, unknown> = {}

    for (const path of paths) {
      final[path] = deleteField
    }
    try {
      await docRef.update(final)
      // console.info("API - updating document successful")
      return true
    } catch (error) {
      console.error("Error updating document: ", error)
      return false
    }
  }

  const remove = async (documentID: string) => {
    try {
      await db.collection(collection).doc(documentID).delete()
      // console.log("Document successfully deleted!")
      return true
    } catch (error) {
      console.error("Error removing document: ", error)
      return false
    }
  }

  return {
    get,
    set,
    remove,
    updatePath,
    updateMerge,
    updateMultiPath,
    deleteMultiPath
  }
}

export const documentAPI = (
  db: firebase.firestore.Firestore = firebase.firestore(),
  deleteField: firebase.firestore.FieldValue = firebase.firestore.FieldValue.delete()
) => {

  return {
    raidAPI: raidAPI(db, deleteField),
    userAPI: userAPI(db, deleteField),
    raidDataAPI: raidDataAPI(db, deleteField),
    automationsAPI: automationsAPI(db, deleteField),
    teamsAPI: teamsAPI(db, deleteField),
    guestAPI: guestAPI(db, deleteField),
    multiDocAPI: multidocAPI(db, deleteField),
  }
}

export default documentAPI
