import { AutomationAction } from "./action";
import { iAutomation } from "../iAutomation";
import { newID } from "../../types/typeUtils";

export enum eDocOwnerType {
  USER = "user",
  TEAM = "team"
}
export type iOwnerType = `${eDocOwnerType}`

// automation
export type automationOwner = {
  id: string // can be userID or teamID
  ownerType: eDocOwnerType
  discordID: string
  displayName: string
}
export type AutomationSequences = {
  [key: string]: iAutomationSequence // numbered indexes: "0", "1", "2" etc
}

export type SequenceWithIndex = {
  index: number // numbered indexes: "0", "1", "2" etc
  sequence: iAutomationSequence 
}

// sequence
export enum eSequenceTimeSelectMode {
  IMMEDIATELY = "immediately",
  WHEN = "when",
  BEFORE = "before",
  AFTER = "after",
}

export interface iAutomationSequence {
  runsAt: {
    hours: number
    timeMode: eSequenceTimeSelectMode.IMMEDIATELY | eSequenceTimeSelectMode.WHEN | eSequenceTimeSelectMode.AFTER | eSequenceTimeSelectMode.BEFORE
    newSignupID: string | null
    usingStart: boolean
  }
  steps: iAutomationStep[]
}

export interface iRunAutomationSequence {
  automation: iAutomation
  runSequences: SequenceWithIndex[]
  testSequence?: boolean
  testAutomation?: boolean
}

// step
export interface iAutomationStep {
  action: AutomationAction
  status: "waiting" | "success" | "error"
  executedAt: number
  error?: string
}

export const Automation = () => {

  const create = (
    owner: automationOwner,
    title: string,
    startTime: number,
    repeatEvery: number,
    isActive: boolean = false,
    id?: string,
    description?: string,
    sequences?: AutomationSequences,
    runSequencesAt?: number[]
  ) => {
    const idFinal = id ?? newID(10)
    const noSequences = !sequences || Object.keys(sequences).length === 0
    const defaultSequence: iAutomationSequence = {
      runsAt: {
        hours: 1,
        newSignupID: null,
        timeMode: eSequenceTimeSelectMode.IMMEDIATELY,
        usingStart: true,
      },
      steps: [],
    }

    const automation: iAutomation = {
      id: idFinal,
      owner,
      title,
      description,
      startTime,
      repeatEvery,
      sequences: noSequences ? { "0": defaultSequence }
                             : sequences,
      runSequencesAt: !!runSequencesAt && runSequencesAt?.length > 0 ? runSequencesAt : [startTime],
      isActive,
    }

    return automation
  };


  return ({
    create,
  })
}