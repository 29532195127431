import React from 'react';
import App from 'next/app';
import { Provider } from '../context/MainContext'
import { AuthProvider } from '../context/authContext';
import '../global/styles/main.scss'
import Head from 'next/head';
import { Provider as JotaiProvider } from "jotai";
import * as Tooltip from '@radix-ui/react-tooltip';
import PopOverLocalized from '../components/1_atom/autoComplete/popOverLocalized'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from '@stripe/stripe-js'
import { createStore } from "jotai"

const stripePublishable =
  process.env.NODE_ENV === "development"
    ? "pk_test_51LZWuLEC5jM3mOW7SZ4yUIyDtnBHMbd1eD7hwAgCWHGnW50U57EukCVLOqbEDL8mjzmGefA8YUsUuEVBb6DEDuLQ00ulToyLqJ"
    : "pk_live_51LZWuLEC5jM3mOW791AOGgETOm4NwyEKYGtNWTSqDT6zHPo34QJe2mEP3zCC9BByuOxSluRM1NwipcbDuaefoDVO00gIq1h9QN"
export const stripePromise = loadStripe(stripePublishable)

export const jotaiStore = createStore()

class MyApp extends App {

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Elements stripe={stripePromise}>
        <JotaiProvider store={jotaiStore}>
          <Head>
            <title>Prepi | Signups + Assignments. Together at last.</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>

          <AuthProvider>
            <Provider>
              <Tooltip.Provider>
                {/* 
                  popOver localized here instead of in components 
                  to make sure it doesn't get re-rendered when the 
                  component tree is re-rendering 
                */}
                
                <PopOverLocalized />
                <Component {...pageProps} />
              </Tooltip.Provider>
            </Provider>
          </AuthProvider>
        </JotaiProvider>
      </Elements>
    );
  }
}


export default MyApp;