import { newID } from '../../types/typeUtils';
import { iPanel } from './iPanel';

export interface iSection {
  sectionID: string
  sectionName: string
  sectionOrder?: string[]
  panels?: string[] // assignmentID
}

export interface iAssignmentPositions {
  links: string[] // assignmentIDs
  sections: {
    [key: string]: iSection
  }
  rootSectionOrder: string[] // sectionID
}

export interface aPosAndAssignmentArray {
  assignmentPos: iAssignmentPositions,
  assignmentArray: iPanel[]
}

export const Section = () => {

  const create = (
    sectionName: string,
    sectionOrder?: string[],
    panels?: string[]
  ) => {
    const sectionID = "section-" + newID(process.env.NODE_ENV === "development" ? 3 : 10)

    const sectionObj: iSection = {
      sectionID,
      sectionName,
    }

    if(sectionOrder) sectionObj.sectionOrder = sectionOrder
    if(panels) sectionObj.panels = panels

    return sectionObj;
  };


  return ({
    // no protection
    create,
  })
}