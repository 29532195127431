import { atom } from "jotai";
import { isBoolean, iList, iTarget, Areas, Target, List, iMember, eDragType } from "typings";
import copy from "fast-copy";
import { ydocAtom } from "./global";
import { charIDToString, getUpdatedMembers, isSignedMember } from "functions";
import { warnRosterGroupDesyncState } from "./notifications";
import { raidState, registerListsState, registerTargetsState, unregisterListsState } from "./raid";


export const updateGroupsAtom = atom(null, (get, set) => {
  const y = get(ydocAtom);
  const raid = copy(get(raidState));
  if (isBoolean(raid) || !!raid?.splitData) return;

  const newLists: iList[] = [];
  const newTargets: iTarget[] = [];
  const updateTargets: iTarget[] = [];
  const lockGroupSpots: { [index: string]: string | null; } = {}; // locks groupSpot position for newly signed members
  const lastMemberCheck = y.extra.get("memberLastCheck") as number || 0;

  // find updated members
  const newlyUpdatedMembers: iMember[] = getUpdatedMembers(raid.members, lastMemberCheck);

  const removeMemberIDsArr: string[] = newlyUpdatedMembers
    .filter(member => !member?.onRoster || isSignedMember(member) === false)
    .map(m => m.userID);

  const assignMembersArr: iMember[] = newlyUpdatedMembers
    .filter(member => member?.onRoster === true && isSignedMember(member))
    .filter(member => raid.groupOrder.includes(member.userID) === false);


  for (let i = 0; i < raid.groupOrder?.length; i++) {
    const groupSpot = raid.groupOrder[i];
    const spotEmpty = groupSpot === null || removeMemberIDsArr.includes(groupSpot);
    const member = groupSpot ? raid.members?.[groupSpot] : undefined
    const isMemberSigned = !!member && member?.onRoster === true && isSignedMember(member);

    // assign new onRoster members
    if (
      spotEmpty &&
      assignMembersArr?.length > 0 &&
      !!member &&
      raid.groupOrder.includes(member?.userID) === false
    ) {
      const memberID = assignMembersArr.splice(0, 1)[0].userID
      lockGroupSpots[i.toString()] = memberID

      // check if can remove sync lock
    } else if (!!member && isMemberSigned === false) {
      lockGroupSpots[i.toString()] = null
    }
    const char = member?.character;
    const targetContainID = !!char
      ? charIDToString({ memberID: member.userID, charName: char.charName })
      : groupSpot;

    const groupTargetID = `target-${Areas.GROUPS}-${i}`;
    const foundTarget = y.targets?.get(groupTargetID);
    if (foundTarget) {
      if (foundTarget.containID === targetContainID) continue;
      updateTargets.push({ ...foundTarget, containID: targetContainID });
      continue;
    }


    // create new target with containID = groupSpot
    const target = Target().create({
      area: Areas.GROUPS,
      containType: eDragType.MEMBER,
      noSync: true,
      containID: targetContainID,
      targetIDstr: groupTargetID
    });
    newTargets.push(target);
  }

  // create lists with targets
  const allGroupTargets = [...newTargets];
  const numberOfGroups = Math.ceil(raid.groupOrder.length / raid.groupSize);
  if (allGroupTargets.length > 0) {

    for (let i = 0; i < numberOfGroups; i++) {
      const listIDstring = `${Areas.GROUPS}-${i + 1}`;
      const foundList = y.lists.get(`list-${listIDstring}`);
      if (foundList) continue;

      const groupTargets = allGroupTargets.splice(0, raid.groupSize);
      const targetIDs = groupTargets.map(target => target.id);

      // init new roster list
      const list = List().create(`Group ${i + 1}`, Areas.GROUPS, eDragType.MEMBER, targetIDs, listIDstring, true);
      newLists.push(list);

      // update the target.inListID
      for (const targetID of targetIDs) {
        const editTarget = newTargets.find(target => target.id === targetID);
        if (!editTarget) continue;
        editTarget.inListID = list.id;
      }
    }
  }

  // cleanup lists & their targets
  const cleanupListIDs: string[] = [];
  const allLists = Array.from(y.lists.values()) as Array<iList>;
  const groupsLists = allLists.filter(list => list.area === Areas.GROUPS);
  if (numberOfGroups < groupsLists.length) {
    for (let i = numberOfGroups; i < groupsLists.length; i++) {
      const list = groupsLists[i];
      cleanupListIDs.push(list.id);
    }
  }

  // if((newTargets.length + newLists.length) > 0) {
  //   console.log(`UpdateGroups newTargets: ${newTargets.length} - newLists: ${newLists.length}`, {newTargets, newLists})
  // } else {
  //   console.log(`updateGroups: ${updateTargets.length} updated`)
  // }
  // Notify user of unfixable desync
  if (assignMembersArr?.length > 0) set(warnRosterGroupDesyncState, assignMembersArr);

  y.doc.transact(() => {
    for (const target of updateTargets) {
      y.targets.set(target.id, target);
    }
    for (const [index, groupSpot] of Object.entries(lockGroupSpots)) {
      // console.log(`locking groupOrder.${index} to`, groupSpot)
      y.raidSync.set(`groupOrder.${index}`, groupSpot);
    }
    set(registerListsState, newLists);
    set(registerTargetsState, newTargets);
    set(unregisterListsState, cleanupListIDs);
    y.extra.set("memberLastCheck", Date.now());
  }, "system");
});
