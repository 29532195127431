import { iStatistics } from "typings"

export const minMax = (variableNumber: number, min?: number, max?: number) => {
  if(typeof min === "number" && variableNumber < min) return min
  if(typeof max === "number" && variableNumber > max) return max
  return variableNumber
}

export const convertMillisecondsToSecondsAndNanos = (
  milliseconds: number
): { seconds: number; nanoseconds: number } => {
  const seconds = Math.floor(milliseconds / 1000)
  const nanoseconds = (milliseconds % 1000) * 1e6
  return { seconds, nanoseconds }
}

export function isBetween(number: number, min: number, max: number): boolean {
  return number >= min && number <= max;
}

export const calculateStatistics = (data: number[]): iStatistics => {
  const sortedData = data.slice().sort((a, b) => a - b)
  const n = sortedData.length

  // Calculate sum
  const sum = sortedData.reduce((acc, val) => acc + val, 0)

  // Calculate average
  const average = sum / n

  // Calculate median
  const median =
    n % 2 === 0 ? (sortedData[n / 2 - 1] + sortedData[n / 2]) / 2 : sortedData[Math.floor(n / 2)]

  // Calculate min and max
  const min = sortedData[0]
  const max = sortedData[n - 1]

  // Calculate standard deviation
  const meanDiffSquared =
    sortedData.map((val) => Math.pow(val - average, 2)).reduce((acc, val) => acc + val, 0) / n
  const standardDeviation = Math.sqrt(meanDiffSquared)

  // Calculate variance
  const variance = meanDiffSquared

  // Calculate range
  const range = max - min

  // Calculate skewness
  const skewness = (3 * (average - median)) / standardDeviation

  // Calculate kurtosis
  const kurtosis =
    sortedData.map((val) => Math.pow(val - average, 4)).reduce((acc, val) => acc + val, 0) /
    n /
    Math.pow(variance, 2)

  return {
    average,
    median,
    min,
    max,
    standardDeviation,
    variance,
    range,
    skewness,
    kurtosis
  }
}