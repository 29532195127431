import { newID, iPanelGrid } from 'typings';
import { assignmentType, iTextData, AssignmentType, iPanelCore, iPanelTarget, iPanelImage, iPanelText, iPanelVideo, iPanelSpecSwap, iPanelSpacer } from './panelCore';
import { GridData } from './panelGridTypes';
import { TargetAssignment, imgData, iSpecSwapData } from './panelSubTypes';

export const Assignment = () => {

  const create = (
    type: assignmentType,
    title: string,
    data: {
      gridData?: GridData,
      targetData?: TargetAssignment,
      imgData?: imgData,
      textData?: iTextData,
      videoURL?: string
      specSwapData?: iSpecSwapData
    },
    colSpan?: number
  ) => {
    const assignmentID = "assignment-" + newID(process.env.NODE_ENV === "development" ? 3 : 10)
    if(type === AssignmentType.GRID && !data.gridData) console.error("Assignment.create() - Must define gridData", data);
    if(type === AssignmentType.TARGET && !data.targetData) console.error("Assignment.create() - Must define targetData", data);
    if(type === AssignmentType.IMAGE && !data.imgData) console.error("Assignment.create() - Must define imgData", data);

    const getColSpan = () => {
      if(type === AssignmentType.GRID && !!data?.gridData?.columns?.length) return 2
      return 1
    }

    const core: iPanelCore = {
      id: assignmentID,
      assignmentTitle: title,
      colSpan: colSpan || getColSpan(),
    } as const

    if(type === AssignmentType.GRID && data?.gridData){
      const panel: iPanelGrid = {
        ...core,
        type: AssignmentType.GRID,
        gridData: data?.gridData
      }
      return panel
    }

    if(type === AssignmentType.TARGET && data?.targetData){
      const panel: iPanelTarget = {
        ...core,
        type: AssignmentType.TARGET,
        targetData: data?.targetData
      }
      return panel
    }

    if(type === AssignmentType.IMAGE && data?.imgData){
      const panel: iPanelImage = {
        ...core,
        type: AssignmentType.IMAGE,
        imgData: data.imgData
      }
      return panel
    }
    
    if(type === AssignmentType.TEXT && data?.textData){
      const panel: iPanelText = {
        ...core,
        type: AssignmentType.TEXT,
        textData: data.textData
      }
      return panel
    }

    if(type === AssignmentType.VIDEO && data?.videoURL){
      const panel: iPanelVideo = {
        ...core,
        type: AssignmentType.VIDEO,
        videoURL: data.videoURL,
      }
      return panel
    }

    if(type === AssignmentType.SPEC_SWAP && data?.specSwapData){
      const panel: iPanelSpecSwap = {
        ...core,
        type: AssignmentType.SPEC_SWAP,
        specSwapData: data.specSwapData
      }
      return panel
    }

    if(type === AssignmentType.SPACER){
      const panel: iPanelSpacer = {
        ...core,
        type: AssignmentType.SPACER,
      }
      return panel
    }

    throw new Error("Create new assignment panel - type invalid")
  };

  const createID = () => {
    return `assignment-${newID(process.env.NODE_ENV === "development" ? 3 : 10)}`
  }


  return ({
    // no protection
    create,
    createID
  })
}