
import React, { FC } from 'react';

export interface IconProps {
  /**
   * String to choose which icon to display. There is a large amount of different icons to choose between.
   */
  icon: string,
  /**
   * The icon color.
   */
  color?: string,
  /**
   * The title in svg, shown when hovering over.
   */
  title?: string,
  /**
   * Height in px
   */
  height?: number,
  /**
   * Width in px
   */
  width?: number,
  /**
   * Fills the icon. False means outline. Not all icons have filled and outline versions.
   */
  fill?: boolean,
  /**
   * Width of stroke on icons.
   */
   strokeWidth?: number,
  /**
   * Forces height and width to 100%
   */
  fullSize?: boolean,
  /**
   * Add class string to svg container.
   */
  addClass?: string,
  style?: React.CSSProperties
}

export const SvgIcon: FC<IconProps> = ({  
    icon = 'dots',
    color = 'currentcolor',
    height = 32,
    width = 32,
    fill = false,
    fullSize = false,
    strokeWidth = 1,
    addClass,
    title,
    style
  }) => {

  const theColor = color === "primary" ? "var(--primary)"
              : color === "secondary" ? "var(--secondary)"
              : color === "tertiary" ? "var(--tertiary)"
              : color === "success" ? "var(--success)"
              : color === "info" ? "var(--info)"
              : color === "warning" ? "var(--warning)"
              : color === "inactive" ? "var(--inactive)"
              : color === "white" ? "#fff"
              : color === "black" ? "#000"
              : color === "weak" ? "var(--text-color-weak, grey)"
              : color ? color
              : "#333"

  const renderIcon = () => {
    switch (icon) {
      case "designer":
        return <Designer color={theColor} fillTrue={fill} />

      case "developer":
        return <Developer color={theColor} fillTrue={fill} />
    
      case "contentproducer":
        return <ContentProducer color={theColor} fillTrue={fill} />
    
      case "analytics":
        return <Analytics color={theColor} fillTrue={fill} />
    
      case "projectleader":
        return <ProjectLeader color={theColor} fillTrue={fill} />
        
      case "marketing":
        return <Marketing color={theColor} fillTrue={fill} />
        
      case "ongoing":
        return <Ongoing color={theColor} fillTrue={fill} />

      case "success":
        return <Success color={theColor} fillTrue={fill} />

      case "dots":
        return <Dots color={theColor} fillTrue={fill} />

      case "plus":
        return <Plus color={theColor} fillTrue={fill} />

      case "minus":
        return <Minus color={theColor} fillTrue={fill} />

      case "copy":
        return <Copy color={theColor} fillTrue={fill} />

      case "file":
        return <File color={theColor} fillTrue={fill} />

      case "circle-cross":
        return <CircleCross color={theColor} fillTrue={fill} />

      case "circle-check":
        return <CircleCheck color={theColor} fillTrue={fill} />
        
      case "hourglass":
        return <Hourglass color={theColor} fillTrue={fill} />

      case "thumbs-up":
        return <ThumbsUp color={theColor} fillTrue={fill} />

      case "thumbs-down":
        return <ThumbsDown color={theColor} fillTrue={fill} />

      case "messages":
        return <Messages color={theColor} fillTrue={fill} />

      case "projects":
        return <Projects color={theColor} fillTrue={fill} />

      case "help":
        return <Help color={theColor} fillTrue={fill} />

      case "search":
        return <Search color={theColor} fillTrue={fill} />

      case "mail":
        return <Mail color={theColor} fillTrue={fill} />

      case "star":
        return <Star color={theColor} fillTrue={fill} />

      case "star-rounded":
        return <StarRounded color={theColor} fillTrue={fill} />

      case "info":
        return <Info color={theColor} fillTrue={fill} />

      case "left-align":
        return <LeftAlign color={theColor} fillTrue={fill} />

      case "right-align":
        return <RightAlign color={theColor} fillTrue={fill} />

      case "center-align-horizontal":
        return <CenterHorizontalAlign color={theColor} fillTrue={fill} />
        
      case "refresh":
        return <Refresh color={theColor} fillTrue={fill} />

      case "sort":
        return <Sort color={theColor} fillTrue={fill} />

      case "eye":
        return <Eye color={theColor} fillTrue={fill} />

      case "banned":
        return <Banned color={theColor} fillTrue={fill} />

      case "filter":
        return <Filter color={theColor} fillTrue={fill} strokeWidth={strokeWidth} />

      case "half-star":
        return <HalfStar color={theColor} fillTrue={fill} />

      case "delete":
        return <Delete color={theColor} fillTrue={fill} title={title} />

      case "arrow-right":
        return <ArrowRight color={theColor} fillTrue={fill} title={title} />

      case "arrow-left":
        return <ArrowLeft color={theColor} fillTrue={fill} title={title} />

      case "arrow":
        return <Arrow color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "arrow-zigzag":
        return <ArrowZigZag color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "lock":
        return <Lock color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />
      
      case "reorder":
        return <Reorder color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "video-player":
        return <VideoPlayer color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />
        
      case "code":
        return <Code color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "italic":
        return <Italic color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "bold":
        return <Bold color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "font-size":
        return <FontSize color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />
        
      case "undo":
        return <Undo color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />
        
      case "redo":
        return <Redo color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "swap-horizontal":
        return <SwapHorizontal color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "revert":
        return <Revert color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "event-confirm":
        return <EventConfirm color={theColor} fillTrue={fill} title={title} />

      case "settings":
        return <Settings color={theColor} fillTrue={fill} title={title} />

      case "magnifying-glass":
        return <MagnifyingGlass color={theColor} fillTrue={fill} title={title} />
      
      case "cross":
        return <Cross color={theColor} fillTrue={fill} title={title} />

      case "cross-two":
        return <CrossTwo color={theColor} fillTrue={fill} title={title} />
      
      case "cross-small":
        return <CrossSmall color={theColor} fillTrue={fill} title={title} />

      case "login":
        return <Login color={theColor} fillTrue={fill} title={title} />

      case "logout":
        return <Logout color={theColor} fillTrue={fill} title={title} />

      case "crown":
        return <Crown color={theColor} fillTrue={fill} title={title} />

      case "chess-crown":
        return <ChessCrown color={theColor} fillTrue={fill} title={title} />

      case "move":
        return <Move color={theColor} fillTrue={fill} title={title} />

      case "checkmark":
        return <Checkmark color={theColor} fillTrue={fill} title={title} />

      case "pen":
        return <Pen color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />

      case "edit":
        return <Pen color={theColor} fillTrue={fill} title={title} strokeWidth={strokeWidth} />
      
      case "broadcast":
        return <Broadcast color={theColor} fillTrue={fill} title={title} />

      case "link":
        return <Link color={theColor} fillTrue={fill} title={title} />
        
      case "person":
        return <Person color={theColor} fillTrue={fill} title={title} />
        
      case "skull":
        return <Skull color={theColor} fillTrue={fill} title={title} />
        
      case "line-edit":
        return <LineEdit color={theColor} fillTrue={fill} title={title} />

      case "enlarge":
        return <Enlarge color={theColor} fillTrue={fill} title={title} />

      case "minimize":
        return <Minimize color={theColor} fillTrue={fill} title={title} />
        
      case "arrows-in":
        return <ArrowsIn color={theColor} fillTrue={fill} title={title} />
        
      case "arrows-out":
        return <ArrowsOut color={theColor} fillTrue={fill} title={title} />

      case "horisontal-lines":
        return <HorisontalLines color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "vertical-lines":
        return <VerticalLines color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "single-cells":
        return <SingleCells color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "save":
        return <Save color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "archive":
        return <Archive color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "ghost":
        return <Ghost color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "menu":
        return <Menu color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "discord":
        return <Discord color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "robot":
        return <Robot color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "clock":
        return <Clock color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "team":
        return <Team color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "warning":
        return <Warning color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "split-signup":
        return <SplitSignup color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "img":
        return <Img color={theColor} strokeWidth={strokeWidth} fillTrue={fill} title={title} />

      case "bullet-list":
        return <BulletList color={theColor} fillTrue={fill} title={title} />

      case "potion":
        return <Potion color={theColor} fillTrue={fill} title={title} />

      case "sync":
        return <Sync color={theColor} fillTrue={fill} title={title} />
      
      default:
        console.log("no icon found: ", icon)
        break;
    }
    return <></>
  }

  const finalWidth = fullSize ? "100%" : width + "px"
  const finalHeight = fullSize ? "100%" : height + "px"
  return (
    <div
      className={`svg-icon ${addClass ? addClass : ""}`}
      style={{ ...style, width: finalWidth, height: finalHeight }}
    >
      {renderIcon()}
    </div>
  )

};

export default SvgIcon;

interface ShapeProps {
  color: string,
  fillTrue: boolean,
  title?: string
  strokeWidth?: number
}

// Categories
const Designer = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}><path fill={color} d="M62.7,27.3c-0.9-5.5-3.7-9.6-8.1-11.8c-2.7-1.3-5.5-1.9-8.5-1.9c-4.1,0-7.9,1.2-11.3,2.3 c-2.6,0.8-4.8,1.5-6.6,1.5c-1.5,0-2.6-0.5-3.4-1.5c-0.7-0.9-0.2-2.3,0.3-4c0.6-2.1,1.4-4.8-0.3-7c-1-1.3-2.7-2-4.7-2 c-2,0-4.1,0.7-5.8,1.8C6,10.4,1,19.9,1,30.1c0,17.2,14,31.2,31.2,31.2c13.9,0,26.2-9.3,30-22.7C62.5,37.2,63.5,32.4,62.7,27.3z M10,28c0-2.2,1.8-4,4-4s4,1.8,4,4c0,2.2-1.8,4-4,4S10,30.2,10,28z M18,46c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4 C22,44.2,20.2,46,18,46z M32,52c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C36,50.2,34.2,52,32,52z M46,35c-3.9,0-7-3.1-7-7 s3.1-7,7-7s7,3.1,7,7S49.9,35,46,35z"/></g></svg>
  }
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><title>designer</title><g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={color} stroke={color}><circle data-color="color-2" fill="none" strokeMiterlimit="10" cx="46" cy="28" r="6"></circle> <circle data-color="color-2" fill="none" strokeMiterlimit="10" cx="14" cy="28" r="4"></circle> <circle data-color="color-2" fill="none" strokeMiterlimit="10" cx="18" cy="42" r="4"></circle> <circle data-color="color-2" fill="none" strokeMiterlimit="10" cx="32" cy="48" r="4"></circle> <path fill="none" stroke={color} strokeMiterlimit="10" d="M2,30.1 C2,19.9,7.1,10.9,14.8,5.4c2.8-1.9,7.3-2.4,9.1,0c2.6,3.4-2.1,8.2,0,11c5.4,6.6,16.8-6.4,30.2,0c9.6,4.8,8.2,17.4,7.1,21.9 C57.6,51,46,60.2,32.2,60.2C15.5,60.2,2,46.7,2,30.1z"></path></g></svg>
};
  
const Developer = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper fill-change" fill={color}><path fill={color} className="fill-change" d="M16,46.828L2.586,33.414c-0.781-0.781-0.781-2.047,0-2.828L16,17.172L18.829,20l-12,12l12,12L16,46.828z"/> <path className="fill-change" fill={color} d="M48,46.828L45.171,44l12-12l-12-12L48,17.172l13.414,13.414c0.781,0.781,0.781,2.047,0,2.828L48,46.828z"/> <rect data-color="color-2" x="6.59" y="30" transform="matrix(0.3418 -0.9398 0.9398 0.3418 -9.01 51.1359)" width="50.819" height="4"/></g></svg>
  }
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper fill-change" fill={color}><path fill={color} className="fill-change" d="M16,46.828L2.586,33.414c-0.781-0.781-0.781-2.047,0-2.828L16,17.172L18.829,20l-12,12l12,12L16,46.828z"/> <path className="fill-change" fill={color} d="M48,46.828L45.171,44l12-12l-12-12L48,17.172l13.414,13.414c0.781,0.781,0.781,2.047,0,2.828L48,46.828z"/> <rect data-color="color-2" x="6.59" y="30" transform="matrix(0.3418 -0.9398 0.9398 0.3418 -9.01 51.1359)" width="50.819" height="4"/></g></svg>
};

const ContentProducer = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}><path data-color="color-2" d="M57,57h-4V9c0-1.105-0.895-2-2-2H12V3c0-1.105,0.895-2,2-2h43c1.105,0,2,0.895,2,2v52 C59,56.105,58.105,57,57,57z"/> <path fill={color} d="M49,9H7c-1.105,0-2,0.895-2,2v50c0,1.105,0.895,2,2,2h42c1.105,0,2-0.895,2-2V11C51,9.895,50.105,9,49,9z M24,49h-8c-0.552,0-1-0.448-1-1s0.448-1,1-1h8c0.552,0,1,0.448,1,1S24.552,49,24,49z M40,37H16c-0.552,0-1-0.448-1-1s0.448-1,1-1 h24c0.552,0,1,0.448,1,1S40.552,37,40,37z M40,25H16c-0.552,0-1-0.448-1-1s0.448-1,1-1h24c0.552,0,1,0.448,1,1S40.552,25,40,25z"/></g></svg>
  }
  
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><title>content producer</title><g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={color} stroke={color}><rect x="6" y="10" fill="none" stroke={color} strokeMiterlimit="10" width="44" height="52"></rect> <line fill="none" stroke={color} strokeMiterlimit="10" x1="16" y1="24" x2="40" y2="24"></line> <line fill="none" stroke={color} strokeMiterlimit="10" x1="16" y1="36" x2="40" y2="36"></line> <line fill="none" stroke={color} strokeMiterlimit="10" x1="16" y1="48" x2="24" y2="48"></line> <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 12,2 58,2 58,56 "></polyline></g></svg>
};

const Analytics = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}><path fill={color} d="M54,35H29V10c0-0.552-0.447-1-1-1C13.112,9,1,21.112,1,36s12.112,27,27,27s27-12.112,27-27 C55,35.448,54.553,35,54,35z"/> <path data-color="color-2" d="M36,1c-0.553,0-1,0.448-1,1v26c0,0.552,0.447,1,1,1h26c0.553,0,1-0.448,1-1 C63,13.112,50.888,1,36,1z"/></g></svg>
  }

  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><title>analytics</title><g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={color} stroke={color}><path fill="none" stroke={color} strokeMiterlimit="10" d="M28,10 C13.641,10,2,21.641,2,36s11.641,26,26,26s26-11.641,26-26H28V10z"></path> <path data-color="color-2" fill="none" strokeMiterlimit="10" d="M36,2v26h26 C62,13.641,50.359,2,36,2z"></path></g></svg>
};

const ProjectLeader = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}> <rect y="14" width="10" height="4" rx="1" ry="1" fill={color}/> <rect x="6" y="25" width="32" height="4" rx="1" ry="1" data-color="color-2"/> <rect x="26" y="35" width="32" height="4" rx="1" ry="1" fill={color}/> <rect x="54" y="46" width="10" height="4" rx="1" ry="1" data-color="color-2"/> </g></svg>
  }

  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}> <rect y="14" width="10" height="4" rx="1" ry="1" fill={color}/> <rect x="6" y="25" width="32" height="4" rx="1" ry="1" data-color="color-2"/> <rect x="26" y="35" width="32" height="4" rx="1" ry="1" fill={color}/> <rect x="54" y="46" width="10" height="4" rx="1" ry="1" data-color="color-2"/> </g></svg>
};

const Marketing = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><g className="nc-icon-wrapper" fill={color}><path fill={color} d="M39,36H25c-0.552,0-1,0.448-1,1v24c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1V37 C40,36.448,39.552,36,39,36z"/> <path fill={color} d="M16,48H2c-0.552,0-1,0.448-1,1v12c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1V49C17,48.448,16.552,48,16,48 z"/> <path fill={color} d="M62,24H48c-0.552,0-1,0.448-1,1v36c0,0.552,0.448,1,1,1h14c0.552,0,1-0.448,1-1V25 C63,24.448,62.552,24,62,24z"/> <path data-color="color-2" d="M32.707,25.707L53,5.414V17c0,0.552,0.448,1,1,1s1-0.448,1-1V3c0-0.552-0.448-1-1-1H40 c-0.552,0-1,0.448-1,1s0.448,1,1,1h11.586L32,23.586l-9.293-9.293c-0.391-0.391-1.023-0.391-1.414,0l-12,12l1.414,1.414L22,16.414 l9.293,9.293C31.684,26.098,32.316,26.098,32.707,25.707z"/></g></svg>
  }

  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 64 64"><title>marketing</title><g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={color} stroke={color}><rect x="25" y="37" fill="none" stroke={color} strokeMiterlimit="10" width="14" height="24"></rect> <rect x="2" y="49" fill="none" stroke={color} strokeMiterlimit="10" width="14" height="12"></rect> <rect x="48" y="25" fill="none" stroke={color} strokeMiterlimit="10" width="14" height="36"></rect> <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points="10,27 22,15 32,25 54,3 " strokeLinecap="butt"></polyline> <polyline data-color="color-2" fill="none" strokeMiterlimit="10" points=" 40,3 54,3 54,17 "></polyline></g></svg>
};

// projects
const Ongoing = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>cycle</title><g fill={color}><path fill={color} d="M5,5h4L5,1v2C2.243,3,0,5.243,0,8s2.243,5,5,5v-2c-1.654,0-3-1.346-3-3S3.346,5,5,5z"></path> <path data-color="color-2" d="M11,3v2c1.654,0,3,1.346,3,3s-1.346,3-3,3H7l4,4v-2c2.757,0,5-2.243,5-5S13.757,3,11,3z"></path></g></svg>
  }

  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>cycle</title><g strokeWidth="1" fill={color} stroke={color}><path fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" d="M5.5,0.5l3,3H5C2.515,3.5,0.5,5.515,0.5,8v0 c0,2.485,2.015,4.5,4.5,4.5"></path> <path fill="none" strokeLinecap="round" strokeLinejoin="round" d="M10.5,15.5l-3-3H11 c2.485,0,4.5-2.015,4.5-4.5v0c0-2.485-2.015-4.5-4.5-4.5" data-color="color-2"></path></g></svg>
};

const Success = ({ color, fillTrue }: ShapeProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><g className="nc-icon-wrapper" fill={color}><polygon fill={color} points="5.6,9.6 2.4,6.4 0,8.8 5.6,14.4 16,4 13.6,1.6 "/></g></svg>
};

const Dots = ({ color, fillTrue }: ShapeProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><g className="nc-icon-wrapper" fill={color}><circle data-color="color-2" cx="8" cy="8" r="2"/> <circle fill={color} cx="2" cy="8" r="2"/> <circle fill={color} cx="14" cy="8" r="2"/></g></svg>
};

const Plus = ({ color, fillTrue, title }: ShapeProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>{title ? title : "add"}</title><g fill={color}><polygon fill={color} points="9,4 7,4 7,7 4,7 4,9 7,9 7,12 9,12 9,9 12,9 12,7 9,7 "></polygon></g></svg>
};

const Minus = ({ color, fillTrue, title }: ShapeProps) => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>{title ? title: "minus"}</title><g fill={color}><rect x="4" y="7" fill={color} width="8" height="2"></rect></g></svg>
};

const Copy = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>copy</title><g fill={color}><path fill={color} d="M11,12H1c-0.553,0-1-0.447-1-1V1c0-0.552,0.447-1,1-1h10c0.553,0,1,0.448,1,1v10C12,11.553,11.553,12,11,12z "></path> <path data-color="color-2" d="M15,16H4v-2h10V4h2v11C16,15.553,15.553,16,15,16z"></path></g></svg>
  }

  return <svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}} viewBox="0 0 16 16"><title>copy</title><g strokeWidth="1" fill={color} stroke={color}><rect x="0.5" y="0.5" width="11" height="11" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"></rect> <polyline points="13.5 4.5 15.5 4.5 15.5 15.5 4.5 15.5 4.5 13.5" fill="none" strokeLinecap="round" strokeLinejoin="round"></polyline></g></svg>
};

const File = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>file</title><g fill={color}><polygon points="11,0 11,4 15,4 "/><path d="M9,6h6v9c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h7V6z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>file</title><g fill={color} stroke={color} strokeWidth="1"><polyline fill="none" points="9.5,0.5 9.5,5.5 14.5,5.5 " strokeLinecap="round"/><polygon fill="none" points="9.5,0.5 1.5,0.5 1.5,15.5 14.5,15.5 14.5,5.5 " stroke={color} strokeLinecap="round"/></g></svg>
};

const CircleCross = ({ color, fillTrue }: ShapeProps) => {
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>circle cross</title><g fill={color}><polygon points="10.1,4.5 8,6.6 5.9,4.5 4.5,5.9 6.6,8 4.5,10.1 5.9,11.5 8,9.4 10.1,11.5 11.5,10.1 9.4,8 11.5,5.9 "/><path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z" fill={color}/></g></svg>
};

const CircleCheck = ({ color, fillTrue }: ShapeProps) => {
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>checkmark</title><g fill={color}><path d="M8,0C3.589,0,0,3.589,0,8s3.589,8,8,8s8-3.589,8-8S12.411,0,8,0z M8,14c-3.309,0-6-2.691-6-6s2.691-6,6-6 s6,2.691,6,6S11.309,14,8,14z" fill={color}/><polygon points="7,11.414 3.586,8 5,6.586 7,8.586 11,4.586 12.414,6 "/></g></svg>
};

const Hourglass = ({ color, fillTrue }: ShapeProps) => {
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>hour glass</title><g fill={color} stroke={color} strokeLinecap="square" strokeWidth="2"><line fill="none" stroke={color} x1="3" x2="21" y1="1" y2="1"/><line fill="none" stroke={color} x1="3" x2="21" y1="23" y2="23"/><path d="M8,20v-.789a1,1,0,0,1,.167-.553l3-4.5a1,1,0,0,1,1.664,0l3,4.5a1,1,0,0,1,.168.555V20Z" stroke="none"/><path d="M5,23V19.211a4,4,0,0,1,.672-2.219L9,12,5.672,7.008A4,4,0,0,1,5,4.789V1" fill="none" stroke={color} strokeLinecap="butt"/><path d="M19,1V4.789a4,4,0,0,1-.672,2.219L15,12l3.328,4.992A4,4,0,0,1,19,19.211V23" fill="none" stroke={color} strokeLinecap="butt"/></g></svg>
};

const ThumbsUp = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>like</title><g fill={color}><path d="M13.3,6H9V2c0-1.5-0.8-2-2-2C6.7,0,6.4,0.2,6.4,0.5C6.4,0.5,4.1,8,4,8v8h8.6c1.3,0,2.4-1,2.6-2.3L16,9.1 c0.1-0.8-0.1-1.6-0.6-2.1C14.9,6.3,14.1,6,13.3,6z" fill={color}/><rect height="8" width="2" y="8"/></g></svg>
  }
  
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>thumb up</title><g fill={color} stroke={color} strokeWidth="1"><rect height="8" width="3" fill="none" strokeLinecap="round" x="0.5" y="7.5"/><path d="M5.5,15.5h6.9a2,2,0,0,0,1.952-1.566l1.111-5A2,2,0,0,0,13.507,6.5H9.5v-4a2,2,0,0,0-2-2l-2,6" fill="none" stroke={color} strokeLinecap="round"/></g></svg>
};

const ThumbsDown = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>thumb down</title><g fill={color} stroke={color} strokeWidth="1"><rect height="8" width="3" fill="none" strokeLinecap="round" x="0.5" y="0.5"/><path d="M5.5.5h6.9a2,2,0,0,1,1.952,1.566l1.111,5A2,2,0,0,1,13.507,9.5H9.5v4a2,2,0,0,1-2,2l-2-6" fill="none" stroke={color} strokeLinecap="round"/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>thumb down</title><g fill={color}><path d="M14,10H8v4c0,1.2-0.8,2-2,2L3,9H1C0.4,9,0,8.6,0,8V1c0-0.6,0.4-1,1-1h11c1.4,0,2.7,1,2.9,2.4l1,5.2 C16.2,8.8,15.3,10,14,10z" fill={color}/></g></svg>
};

const Messages = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>messages</title><g fill={color}><path d="M15,4h-1v6c0,0.552-0.448,1-1,1H6.828L5,13h5l3,3v-3h2c0.552,0,1-0.448,1-1V5 C16,4.448,15.552,4,15,4z"/><path d="M1,0h10c0.552,0,1,0.448,1,1v7c0,0.552-0.448,1-1,1H6l-3,3V9H1C0.448,9,0,8.552,0,8V1C0,0.448,0.448,0,1,0z" fill={color}/></g></svg>
}

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>messages</title><g fill={color} stroke={color} strokeWidth="1"><path d="M10.5,0.5h-9 c-0.552,0-1,0.448-1,1v6c0,0.552,0.448,1,1,1h2v3l3-3h4c0.552,0,1-0.448,1-1v-6C11.5,0.948,11.052,0.5,10.5,0.5z" fill="none" stroke={color} strokeLinecap="round"/><path d=" M6.5,12.5h3l3,3v-3h2c0.552,0,1-0.448,1-1v-6c0-0.552-0.448-1-1-1h-1" fill="none" strokeLinecap="round"/></g></svg>
};

const Projects = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>projects</title><g fill={color}><path d="M11,16a1,1,0,0,1-.707-.293l-2-2,1.414-1.414L11,13.586l3.293-3.293,1.414,1.414-4,4A1,1,0,0,1,11,16Z"/><path d="M7,14H2V5H14V9h2V3a1,1,0,0,0-1-1H13V0H11V2H9V0H7V2H5V0H3V2H1A1,1,0,0,0,0,3V15a1,1,0,0,0,1,1H7Z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>projects</title><g fill={color} stroke={color} strokeWidth="1"><line fill="none" stroke={color} strokeLinecap="round" x1="0.5" x2="15.5" y1="5.5" y2="5.5"/><path d="M15.5,8.5v-5a1,1,0,0,0-1-1H1.5a1,1,0,0,0-1,1v11a1,1,0,0,0,1,1h5" fill="none" stroke={color} strokeLinecap="round"/><line fill="none" stroke={color} strokeLinecap="round" x1="4.5" x2="4.5" y1="0.5" y2="2.5"/><line fill="none" stroke={color} strokeLinecap="round" x1="11.5" x2="11.5" y1="0.5" y2="2.5"/><polyline fill="none" points="9.5 13.5 11.5 15.5 15.5 11.5" strokeLinecap="round"/></g></svg>
};

const Help = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>help</title><g fill={color}><circle cx="8" cy="13.5" r="1.5"/><path d="M8,0C6.309,0,4.792,1.072,4.229,2.667L3.895,3.609L5.78,4.276l0.333-0.943C6.396,2.536,7.154,2,8,2 c1.103,0,2,0.897,2,2c0,0.632-0.245,0.839-0.952,1.347C8.184,5.967,7,6.817,7,9v1h2V9c0-1.157,0.482-1.503,1.214-2.028 C10.968,6.431,12,5.69,12,4C12,1.794,10.206,0,8,0z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>help</title><g fill={color} stroke={color} strokeWidth="1"><circle cx="8" cy="15" r="1" stroke="none"/><path d="M5.5,2.397 c1.5-1.354,4.752-1.33,5.39,1C11.719,6.422,8,6.5,8,11" fill="none" stroke={color} strokeLinecap="round"/></g></svg>
};

const Search = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>search</title><g fill={color}><path d="M15.707,13.293L13,10.586c0.63-1.05,1-2.275,1-3.586c0-3.86-3.141-7-7-7S0,3.14,0,7s3.141,7,7,7 c1.312,0,2.536-0.369,3.586-1l2.707,2.707C13.488,15.902,13.744,16,14,16s0.512-0.098,0.707-0.293l1-1 C16.098,14.316,16.098,13.684,15.707,13.293z M7,12c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S9.761,12,7,12z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>search</title><g fill={color} stroke={color} strokeWidth="1"><polyline fill="none" points=" 9.624,11.624 13.5,15.5 15.5,13.5 11.624,9.624 " strokeLinecap="round"/><circle cx="6.5" cy="6.5" fill="none" r="6" stroke={color} strokeLinecap="round"/></g></svg>
};

const Mail = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>email</title><g fill={color}><path d="M14,6.883V13H2V6.82L0,5.695V14c0,0.553,0.448,1,1,1h14c0.552,0,1-0.447,1-1V5.783L14,6.883z"/><path d="M15,1H1C0.4,1,0,1.4,0,2v1.4l8,4.5l8-4.4V2C16,1.4,15.6,1,15,1z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>email</title><g fill={color} stroke={color} strokeWidth="1"><path d="M14.5,14.5h-13 c-0.552,0-1-0.448-1-1v-11c0-0.552,0.448-1,1-1h13c0.552,0,1,0.448,1,1v11C15.5,14.052,15.052,14.5,14.5,14.5z" fill="none" stroke={color} strokeLinecap="round"/><polyline fill="none" points=" 2.5,4.5 8,9 13.5,4.5 " strokeLinecap="round"/></g></svg>
};

const Star = ({ color, fillTrue }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>star</title><g fill={color}><path d="M15.144,5.438l-4.316-0.627L8.897,0.9c-0.338-0.683-1.456-0.683-1.794,0l-1.93,3.911L0.856,5.438 C0.039,5.557-0.292,6.565,0.302,7.145l3.124,3.044l-0.737,4.299c-0.139,0.814,0.717,1.439,1.451,1.054L8,13.513l3.861,2.03 c0.728,0.381,1.591-0.234,1.451-1.054l-0.737-4.299l3.124-3.044C16.292,6.565,15.961,5.557,15.144,5.438z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>star empty</title><g fill={color}><path d="M11.86,15.542L8,13.513l-3.86,2.029c-0.727,0.387-1.592-0.235-1.451-1.054l0.737-4.299L0.302,7.145 c-0.594-0.58-0.263-1.587,0.555-1.706l4.316-0.627L7.104,0.9c0.336-0.683,1.457-0.683,1.793,0l1.931,3.911l4.316,0.627 c0.818,0.119,1.148,1.126,0.555,1.706l-3.124,3.045l0.737,4.299C13.453,15.311,12.586,15.924,11.86,15.542z M8.466,11.498 l2.532,1.331l-0.483-2.82c-0.056-0.324,0.052-0.655,0.287-0.885l2.049-1.998L10.02,6.715C9.693,6.668,9.412,6.463,9.267,6.168 L8,3.602L6.733,6.168c-0.146,0.295-0.427,0.5-0.753,0.547L3.149,7.126l2.049,1.998c0.235,0.229,0.343,0.561,0.287,0.885 l-0.483,2.82l2.532-1.331C7.826,11.344,8.174,11.344,8.466,11.498z" fill={color}/></g></svg>
};

const HalfStar = ({ color, fillTrue }: ShapeProps) => {
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>half star rating</title><g fill={color}><g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1"><g id="r" fill={color} fillRule="nonzero" transform="translate(-441 -51)"><g id="star-empty" transform="translate(441 51)"><path id="Combined-Shape" d="M8 0v.023c.365 0 .73.17.898.512l1.93 3.911 4.317.627a1.001 1.001 0 0 1 .555 1.706l-3.124 3.045.737 4.299c.14.823-.726 1.436-1.452 1.054L8 13.148l-3.86 2.029c-.694.37-1.514-.18-1.465-.944v-.024l.014-.11.733-4.279-3.12-3.04A.982.982 0 0 1 0 6.106v-.024A1 1 0 0 1 .857 5.05l4.317-.627L7.104.512C7.273.171 7.637 0 8 0zm0 3.237v7.78c.16 0 .32.039.467.116l2.532 1.331-.483-2.82a1 1 0 0 1 .287-.885l2.049-1.998-2.831-.411a.996.996 0 0 1-.753-.547L8 3.237z"/></g></g></g></g></svg>
};

const Delete = ({ color, fillTrue, title }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title || "delete"}</title><g fill={color}><path d="M16,4V2H11V1a1,1,0,0,0-1-1H6A1,1,0,0,0,5,1V2H0V4Z"/><path d="M2,5v9a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V5Zm9.414,7.5L10,13.914l-2-2-2,2L4.586,12.5l2-2-2-2L6,7.086l2,2,2-2L11.414,8.5l-2,2Z" fill={color}/></g></svg>
  }

  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title || "delete"}</title><g fill={color} stroke={color} strokeWidth="1"><path d="M2.5,5.5v8a2,2,0,0,0,2,2h7a2,2,0,0,0,2-2v-8" fill="none" stroke={color} strokeLinecap="round"/><line fill="none" stroke={color} strokeLinecap="round" x1="1.5" x2="14.5" y1="3.5" y2="3.5"/><polyline fill="none" points="5.5 3.5 5.5 0.5 10.5 0.5 10.5 3.5" stroke={color} strokeLinecap="round"/><line fill="none" strokeLinecap="round" x1="5.5" x2="10.5" y1="12.5" y2="7.5"/><line fill="none" strokeLinecap="round" x1="10.5" x2="5.5" y1="12.5" y2="7.5"/></g></svg>
};

const ArrowRight = ({ color, fillTrue, title = "right arrow" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title}</title><g fill={color}><polygon fill={color} points="5.6,0 2.4,3.2 7.2,8 2.4,12.8 5.6,16 13.6,8 "/></g></svg>
  }
  
  return (
    <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title}</title><g fill={color}><polygon fill={color} points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8 "/></g></svg>
  );
};

const ArrowLeft = ({ color, fillTrue, title = "left arrow" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title}</title><g fill={color}><polygon fill={color} points="10.4,16 13.6,12.8 8.8,8 13.6,3.2 10.4,0 2.4,8 "/></g></svg>
  }
  
  return (
    <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title}</title><g fill={color}><polygon fill={color} points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12 "/></g></svg>
  );
};


const EventConfirm = ({ color, fillTrue, title = "Event Confirm" }: ShapeProps) => {
  if(fillTrue){
    return (
      <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>{title}</title> <g fill={color}> <path d="M11,16a1,1,0,0,1-.707-.293l-2-2,1.414-1.414L11,13.586l3.293-3.293,1.414,1.414-4,4A1,1,0,0,1,11,16Z"/> <path d="M7,14H2V5H14V9h2V3a1,1,0,0,0-1-1H13V0H11V2H9V0H7V2H5V0H3V2H1A1,1,0,0,0,0,3V15a1,1,0,0,0,1,1H7Z" fill={color}/> </g> </svg>
    )
  }
  
  return (
    <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16.5 16.5" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1.5"> <line fill="none" stroke={color} strokeLinecap="round" x1="0.5" x2="15.5" y1="5.5" y2="5.5"/> <path d="M15.5,8.5v-5a1,1,0,0,0-1-1H1.5a1,1,0,0,0-1,1v11a1,1,0,0,0,1,1h5" fill="none" stroke={color} strokeLinecap="round"/> <line fill="none" stroke={color} strokeLinecap="round" x1="4.5" x2="4.5" y1="0.5" y2="2.5"/> <line fill="none" stroke={color} strokeLinecap="round" x1="11.5" x2="11.5" y1="0.5" y2="2.5"/> <polyline fill="none" points="9.5 13.5 11.5 15.5 15.5 11.5" strokeLinecap="round"/> </g> </svg>
  );
};


const Settings = ({ color, fillTrue, title = "Settings" }: ShapeProps) => {
  if(fillTrue){
    return (
      <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M13.3,5.2l1.1-2.1l-1.4-1.4l-2.1,1.1c-0.3-0.2-0.7-0.3-1.1-0.4L9,0H7L6.2,2.3C5.9,2.4,5.5,2.5,5.2,2.7 L3.1,1.6L1.6,3.1l1.1,2.1C2.5,5.5,2.4,5.9,2.3,6.2L0,7v2l2.3,0.8c0.1,0.4,0.3,0.7,0.4,1.1l-1.1,2.1l1.4,1.4l2.1-1.1 c0.3,0.2,0.7,0.3,1.1,0.4L7,16h2l0.8-2.3c0.4-0.1,0.7-0.3,1.1-0.4l2.1,1.1l1.4-1.4l-1.1-2.1c0.2-0.3,0.3-0.7,0.4-1.1L16,9V7 l-2.3-0.8C13.6,5.9,13.5,5.5,13.3,5.2z M8,11c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S9.7,11,8,11z" fill={color}/> </g> </svg>
    )
  }
  
  return (
    <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <circle cx="8" cy="8" fill="none" r="2.5" strokeLinecap="round"/> <path d="M15.5,9V7 l-2.177-0.363c-0.131-0.512-0.332-0.994-0.595-1.437l1.283-1.796L12.596,1.99L10.8,3.272c-0.443-0.263-0.925-0.464-1.437-0.595 L9,0.5H7L6.637,2.677C6.125,2.808,5.643,3.009,5.2,3.272L3.404,1.99L1.99,3.404L3.272,5.2C3.009,5.643,2.808,6.125,2.677,6.637 L0.5,7v2l2.177,0.363c0.131,0.512,0.332,0.994,0.595,1.437L1.99,12.596l1.414,1.414L5.2,12.728c0.443,0.263,0.925,0.464,1.437,0.595 L7,15.5h2l0.363-2.177c0.512-0.131,0.994-0.332,1.437-0.595l1.796,1.283l1.414-1.414L12.728,10.8 c0.263-0.443,0.464-0.925,0.595-1.437L15.5,9z" fill="none" stroke={color} strokeLinecap="round"/> </g> </svg>
  );
};

const MagnifyingGlass = ({ color, fillTrue, title = "Magnifying Glass" }: ShapeProps) => {
  if(fillTrue){
    return (
      <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M7,14c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7S10.86,14,7,14z M7,2C4.243,2,2,4.243,2,7s2.243,5,5,5 s5-2.243,5-5S9.757,2,7,2z" fill={color}/> <path d="M15.707,14.293L13.314,11.9c-0.411,0.529-0.885,1.003-1.414,1.414l2.393,2.393 C14.488,15.902,14.744,16,15,16s0.512-0.098,0.707-0.293C16.098,15.316,16.098,14.684,15.707,14.293z"/> </g> </svg>
    )
  }
  
  return (
    <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <line fill="none" strokeLinecap="round" x1="15.5" x2="12.5" y1="15.5" y2="12.5"/> <circle cx="6.5" cy="6.5" fill="none" r="6" stroke={color} strokeLinecap="round"/> </g> </svg>
  );
};


const Cross = ({ color, fillTrue, title = "Cross" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon fill={color} points="12.2,0.9 8,5.2 3.8,0.9 0.9,3.8 5.2,8 0.9,12.2 3.8,15.1 8,10.8 12.2,15.1 15.1,12.2 10.8,8 15.1,3.8 "/> </g> </svg>
  }
  
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <polygon fill="none" points="14.5,3.5 12.5,1.5 8,6 3.5,1.5 1.5,3.5 6,8 1.5,12.5 3.5,14.5 8,10 12.5,14.5 14.5,12.5 10,8 " stroke={color} strokeLinecap="round"/> </g> </svg>
};

const CrossTwo = ({ color, fillTrue, title = "Cross" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} strokeWidth="3"> <line fill="none" stroke={color} strokeLinecap="round" x1="13.5" x2="2.5" y1="2.5" y2="13.5"/> <line fill="none" stroke={color} strokeLinecap="round" x1="2.5" x2="13.5" y1="2.5" y2="13.5"/> </g> </svg>
  }
  
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} strokeWidth="2"> <line fill="none" stroke={color} strokeLinecap="round" x1="13.5" x2="2.5" y1="2.5" y2="13.5"/> <line fill="none" stroke={color} strokeLinecap="round" x1="2.5" x2="13.5" y1="2.5" y2="13.5"/> </g> </svg>
};


const Login = ({ color, fillTrue, title = "Login" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon points="8,12 12,8 8,4 8,7 0,7 0,9 8,9 "/> <path d="M15,16H6c-0.6,0-1-0.4-1-1v-3h2v2h7V2H7v2H5V1c0-0.6,0.4-1,1-1h9c0.6,0,1,0.4,1,1v14C16,15.6,15.6,16,15,16z " fill={color}/> </g> </svg>
  }
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <path d="M5.5,5.5v-3 c0-0.552,0.448-1,1-1h8c0.552,0,1,0.448,1,1v12c0,0.552-0.448,1-1,1h-8c-0.552,0-1-0.448-1-1v-3" fill="none" stroke={color} strokeLinecap="round"/> <line fill="none" strokeLinecap="round" x1="0.5" x2="11.5" y1="8.5" y2="8.5"/> <polyline fill="none" points="8.5,5.5 11.5,8.5 8.5,11.5 " strokeLinecap="round"/> </g> </svg>
};

const Logout = ({ color, fillTrue, title = "Logout" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon points="4,4 0,8 4,12 4,9 10,9 10,7 4,7 "/> <path d="M15,16H6c-0.6,0-1-0.4-1-1v-3h2v2h7V2H7v2H5V1c0-0.6,0.4-1,1-1h9c0.6,0,1,0.4,1,1v14C16,15.6,15.6,16,15,16z " fill={color}/> </g> </svg>
  }
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <path d="M6.5,5.5v-3 c0-0.552,0.448-1,1-1h7c0.552,0,1,0.448,1,1v12c0,0.552-0.448,1-1,1h-7c-0.552,0-1-0.448-1-1v-3" fill="none" stroke={color} strokeLinecap="round"/> <line fill="none" strokeLinecap="round" x1="11.5" x2="0.5" y1="8.5" y2="8.5"/> <polyline fill="none" points="3.5,5.5 0.5,8.5 3.5,11.5 " strokeLinecap="round"/> </g> </svg>
};

const CrossSmall = ({ color, fillTrue, title = "Cross Small" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon fill={color} points="10.1,4.5 8,6.6 5.9,4.5 4.5,5.9 6.6,8 4.5,10.1 5.9,11.5 8,9.4 10.1,11.5 11.5,10.1 9.4,8 11.5,5.9 "/> </g> </svg>
  }
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <line fill="none" stroke={color} strokeLinecap="round" x1="11.5" x2="4.5" y1="4.5" y2="11.5"/> <line fill="none" stroke={color} strokeLinecap="round" x1="4.5" x2="11.5" y1="4.5" y2="11.5"/> </g> </svg>
};


const ChessCrown = ({ color, fillTrue, title = "Chess Crown" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <rect height="2" width="14" rx="1" x="1" y="14"/> <path d="M1.75,6.5a1.487,1.487,0,0,0,.165-.017L3.538,12h8.924l1.623-5.517a1.487,1.487,0,0,0,.165.017A1.75,1.75,0,1,0,12.5,4.75a1.726,1.726,0,0,0,.217.826L10.249,8.044,8.888,3.25a1.75,1.75,0,1,0-1.776,0L5.751,8.044,3.283,5.576A1.726,1.726,0,0,0,3.5,4.75,1.75,1.75,0,1,0,1.75,6.5Z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} strokeWidth="1"> <circle cx="8" cy="1.5" fill="none" r="1" stroke={color} strokeLinecap="round"/> <circle cx="1.5" cy="4.5" fill="none" r="1" stroke={color} strokeLinecap="round"/> <circle cx="14.5" cy="4.5" fill="none" r="1" stroke={color} strokeLinecap="round"/> <line fill="none" stroke={color} strokeLinecap="round" x1="1.717" x2="3.5" y1="5.476" y2="13.5"/> <polyline fill="none" points="7.702 2.454 5.5 9.5 2.124 5.28" stroke={color} strokeLinecap="round"/> <polyline fill="none" points="13.876 5.281 10.5 9.5 8.298 2.454" stroke={color} strokeLinecap="round"/> <line fill="none" stroke={color} strokeLinecap="round" x1="12.5" x2="14.283" y1="13.5" y2="5.476"/> <line fill="none" stroke={color} strokeLinecap="round" x1="1.5" x2="14.5" y1="15.5" y2="15.5"/> </g> </svg>
};

const Crown = ({ color, fillTrue, title = "Crown" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M16,2l-4,4L8,2L4,6L0,2v11c0,0.5522852,0.4477153,1,1,1h14c0.5522852,0,1-0.4477148,1-1V2z" fill={color}/> </g> </svg>
  }
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <line fill="none" strokeLinecap="round" x1="0.5" x2="15.5" y1="10.5" y2="10.5"/> <path d="M14.5,13.5H1.5a1,1,0,0,1-1-1V1.5l3,4L8,1.5l4.5,4,3-4v11A1,1,0,0,1,14.5,13.5Z" fill="none" stroke={color} strokeLinecap="round"/> </g> </svg>
};

const Move = ({ color, fillTrue, title = "Move" }: ShapeProps) => {
  if(fillTrue){
    return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon fill={color} points="13,7 9,7 9,3 11,3 8,0 5,3 7,3 7,7 3,7 3,5 0,8 3,11 3,9 7,9 7,13 5,13 8,16 11,13 9,13 9,9 13,9 13,11 16,8 13,5 "/> </g> </svg>
  }
  return <svg style={{width: '100%', height: '100%'}} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <line fill="none" strokeLinecap="round" x1="8.5" x2="8.5" y1="15.5" y2="1.5"/> <line fill="none" strokeLinecap="round" x1="15.5" x2="1.5" y1="8.5" y2="8.5"/> <polyline fill="none" points="6,4 8.5,1.5 11,4 " stroke={color} strokeLinecap="round"/> <polyline fill="none" points="6,13 8.5,15.5 11,13 " stroke={color} strokeLinecap="round"/> <polyline fill="none" points="4,11 1.5,8.5 4,6 " stroke={color} strokeLinecap="round"/> <polyline fill="none" points="13,11 15.5,8.5 13,6 " stroke={color} strokeLinecap="round"/> </g> </svg>
};

const Checkmark = ({ color, fillTrue, title = "Checkmark" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon fill={color} points="5.6,9.6 2.4,6.4 0,8.8 5.6,14.4 16,4 13.6,1.6 "/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <polygon fill="none" points="0.5,9 2.5,7 5.5,10 13.5,2 15.5,4 5.5,14 " stroke={color} strokeLinecap="round"/> </g> </svg>
};

const Pen = ({ color, fillTrue, strokeWidth, title = "Pen" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M8.1,3.5l-7.8,7.8C0.1,11.5,0,11.7,0,12v3c0,0.6,0.4,1,1,1h3c0.3,0,0.5-0.1,0.7-0.3l7.8-7.8L8.1,3.5z" fill={color}/> <path d="M15.7,3.3l-3-3c-0.4-0.4-1-0.4-1.4,0L9.5,2.1l4.4,4.4l1.8-1.8C16.1,4.3,16.1,3.7,15.7,3.3z"/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="10" x2="13" y1="3" y2="6"/> <polygon fill="none" points="12,1 15,4 5,14 1,15 2,11 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Broadcast = ({ color, fillTrue, title = "Broadcast" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M9,14V7.723a2,2,0,1,0-2,0V14H5v2h6V14Z" fill={color}/> <path d="M4.25,9.308a5,5,0,0,1,0-6.616l1.5,1.325a3,3,0,0,0,0,3.966Z"/> <path d="M2.285,11.6a8,8,0,0,1,0-11.2L3.714,1.8a6,6,0,0,0,0,8.4Z"/> <path d="M11.75,9.308a5,5,0,0,0,0-6.616l-1.5,1.325a3,3,0,0,1,0,3.966Z"/> <path d="M13.715,11.6a8,8,0,0,0,0-11.2L12.286,1.8a6,6,0,0,1,0,8.4Z"/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <circle cx="7.5" cy="5.5" fill="none" r="1" stroke={color} strokeLinecap="round"/> <line fill="none" stroke={color} strokeLinecap="round" x1="7.5" x2="7.5" y1="15.5" y2="6.5"/> <line fill="none" stroke={color} strokeLinecap="round" x1="4.5" x2="10.5" y1="15.5" y2="15.5"/> <path d="M10.328,8.328a4,4,0,0,0,0-5.656" fill="none" strokeLinecap="round"/> <path d="M12.45,10.45a7,7,0,0,0,0-9.9" fill="none" strokeLinecap="round"/> <path d="M4.672,8.328a4,4,0,0,1,0-5.656" fill="none" strokeLinecap="round"/> <path d="M2.55,10.45a7,7,0,0,1,0-9.9" fill="none" strokeLinecap="round"/> </g> </svg>
};


const Link = ({ color, fillTrue, title = "Link" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M4.5,16c-1.2,0-2.3-0.5-3.2-1.3c-1.8-1.8-1.8-4.6,0-6.4L2,7.6L3.4,9L2.7,9.7 c-1,1-1,2.6,0,3.6c1,1,2.6,1,3.6,0l3-3c1-1,1-2.6,0-3.6L8.6,6L10,4.6l0.7,0.7c1.8,1.8,1.8,4.6,0,6.4l-3,3C6.9,15.5,5.7,16,4.5,16z"/> <path d="M6,11.4l-0.7-0.7c-1.8-1.8-1.8-4.6,0-6.4l3-3c0.9-0.9,2-1.3,3.2-1.3s2.3,0.5,3.2,1.3c1.8,1.8,1.8,4.6,0,6.4 L14,8.4L12.6,7l0.7-0.7c1-1,1-2.6,0-3.6c-1-1-2.6-1-3.6,0l-3,3c-1,1-1,2.6,0,3.6L7.4,10L6,11.4z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeMiterlimit="10" strokeWidth="1"> <path d="M8.995,7.005 L8.995,7.005c1.374,1.374,1.374,3.601,0,4.975l-1.99,1.99c-1.374,1.374-3.601,1.374-4.975,0l0,0c-1.374-1.374-1.374-3.601,0-4.975 l1.748-1.698" fill="none" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.005,8.995 L7.005,8.995c-1.374-1.374-1.374-3.601,0-4.975l1.99-1.99c1.374-1.374,3.601-1.374,4.975,0l0,0c1.374,1.374,1.374,3.601,0,4.975 l-1.748,1.698" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Person = ({ color, fillTrue, title = "Person" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M8,0C5.794,0,4,1.794,4,4v1c0,2.206,1.794,4,4,4s4-1.794,4-4V4C12,1.794,10.206,0,8,0z" fill={color}/> <path d="M12.036,9.426C10.969,10.4,9.555,11,8,11s-2.969-0.6-4.036-1.574C2.203,10,1,11.311,1,13v3 h14v-3C15,11.311,13.797,10,12.036,9.426z"/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <path d="M2.562,10A4,4,0,0,0,.5,13.5v2h15v-2A4,4,0,0,0,13.438,10" fill="none" strokeLinecap="round" strokeLinejoin="round"/> <rect height="9" width="7" fill="none" rx="3.5" ry="3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" x="4.5" y="0.5"/> </g> </svg>
};

const Skull = ({ color, fillTrue, title = "Skull" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M8,0A6.957,6.957,0,0,0,1,7a6.827,6.827,0,0,0,3,5.7V15a.945.945,0,0,0,1,1H6V14.5a.5.5,0,0,1,1,0V16H9V14.5a.5.5,0,0,1,1,0V16h1a.945.945,0,0,0,1-1V12.7A7.1,7.1,0,0,0,15,7,6.957,6.957,0,0,0,8,0ZM5,10A2.05,2.05,0,0,1,3,8,2.05,2.05,0,0,1,5,6,2.05,2.05,0,0,1,7,8,2.05,2.05,0,0,1,5,10Zm6,0A2.05,2.05,0,0,1,9,8a2,2,0,0,1,4,0A2.05,2.05,0,0,1,11,10Z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <path d="M14.5,7a6.5,6.5,0,1,0-11,4.684V15.5h9V11.684A6.476,6.476,0,0,0,14.5,7Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="6.5" y1="13.5" y2="15.5"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="9.5" x2="9.5" y1="13.5" y2="15.5"/> <circle cx="5" cy="8" fill="none" r="1.5" strokeLinecap="round" strokeLinejoin="round"/> <circle cx="11" cy="8" fill="none" r="1.5" strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const LineEdit = ({ color, fillTrue, title = "LineEdit" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <rect height="2" width="16" y="14"/> <path d="M11.7,3.3c0.4-0.4,0.4-1,0-1.4l-1.6-1.6c-0.4-0.4-1-0.4-1.4,0L0,9v3h3L11.7,3.3z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeMiterlimit="10" strokeWidth="1"> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="1" x2="15" y1="15.5" y2="15.5"/> <polygon fill="none" points="10.5,0.5 13.5,3.5 5,12 1,13 2,9 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Minimize = ({ color, fillTrue, title = "Minimize" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M15.70703,1.70703c0.39062-0.39062,0.39062-1.02344,0-1.41406s-1.02344-0.39062-1.41406,0 l-2.5,2.5L9,0v7h7l-2.79297-2.79297L15.70703,1.70703z"/> <path d="M2.79297,11.79297l-2.5,2.5c-0.39062,0.39062-0.39062,1.02344,0,1.41406C0.48828,15.90234,0.74414,16,1,16 s0.51172-0.09766,0.70703-0.29297l2.5-2.5L7,16V9H0L2.79297,11.79297z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth="1"> <polyline fill="none" points="14.5 6.5 9.5 6.5 9.5 1.5" strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="6.5 14.5 6.5 9.5 1.5 9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="9.5" y1="0.5" y2="6.5"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="6.5" y1="15.5" y2="9.5"/> </g> </svg>
};

const Enlarge = ({ color, fillTrue, title = "Enlarge" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon points="7.4,10 6,8.6 3.3,11.3 0,8 0,16 8,16 4.7,12.7 "/> <polygon fill={color} points="11.3,3.3 8.6,6 10,7.4 12.7,4.7 16,8 16,0 8,0 "/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeMiterlimit="10" strokeWidth="1"> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="12.5" x2="9.5" y1="3.5" y2="6.5"/> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="3.5" y1="9.5" y2="12.5"/> <polygon fill="none" points="9.5,0.5 15.5,0.5 15.5,6.5 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <polygon fill="none" points="0.5,9.5 0.5,15.5 6.5,15.5 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const ArrowsOut = ({ color, fillTrue, title = "Arrows Out" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon fill={color} points="4,4 0,8 4,12 4,9 7,9 7,7 4,7 "/> <polygon points="16,8 12,4 12,7 9,7 9,9 12,9 12,12 "/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeMiterlimit="10" strokeWidth="1"> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="9.5" x2="15.5" y1="8.5" y2="8.5"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="0.5" y1="8.5" y2="8.5"/> <polyline fill="none" points="12.5,5.5 15.5,8.5 12.5,11.5 " strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="3.5,5.5 0.5,8.5 3.5,11.5 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const ArrowsIn = ({ color, fillTrue, title = "Arrows In" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <polygon points="13,4 9,8 13,12 13,9 16,9 16,7 13,7 "/> <polygon fill={color} points="7,8 3,4 3,7 0,7 0,9 3,9 3,12 "/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeMiterlimit="10" strokeWidth="1"> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="9.5" y1="8.5" y2="8.5"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="6.5" y1="8.5" y2="8.5"/> <polyline fill="none" points="12.5,5.5 9.5,8.5 12.5,11.5 " strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="3.5,5.5 6.5,8.5 3.5,11.5 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const HorisontalLines = ({ color, fillTrue, strokeWidth = 2, title = "Horisontal Lines" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeWidth={strokeWidth}> <g id="Magic-List" stroke={color} strokeWidth={strokeWidth} transform="translate(-412 -108)"> <g id="Group" transform="translate(413 108.5)"> <path id="Line" d="M0 .5h14"/> <path id="Line-Copy" d="M0 7.5h14"/> <path id="Line-Copy-2" d="M0 14.5h14"/> </g> </g> </g> </g> </svg>
};

const VerticalLines = ({ color, fillTrue, strokeWidth, title = "Vertical Lines" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeWidth={strokeWidth}> <g id="Magic-List" stroke={color} strokeWidth={strokeWidth} transform="translate(-432 -108)"> <g id="Group-Copy" transform="rotate(90 169.25 278.25)"> <path id="Line" d="M0 .5h14"/> <path id="Line-Copy" d="M0 7.5h14"/> <path id="Line-Copy-2" d="M0 14.5h14"/> </g> </g> </g> </g> </svg>
};

const SingleCells = ({ color, fillTrue, strokeWidth, title = "Single Cells" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeWidth={strokeWidth}> <g id="Magic-List" stroke={color} strokeWidth={strokeWidth} transform="translate(-455 -108)"> <g id="Group-Copy-2" transform="rotate(-180 235 61.75)"> <path id="Line" d="M0 .5h5"/> <path id="Line-Copy" d="M0 7.5h5"/> <path id="Line-Copy-2" d="M0 14.5h5"/> <path id="Line-Copy-5" d="M9 .5h5"/> <path id="Line-Copy-4" d="M9 7.5h5"/> <path id="Line-Copy-3" d="M9 14.5h5"/> </g> </g> </g> </g> </svg>
};

const Save = ({ color, fillTrue, strokeWidth, title = "Save" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M15.7,4.3l-4-4A.908.908,0,0,0,11,0H10V3A1,1,0,0,1,9,4H4A1,1,0,0,1,3,3V0H2A2,2,0,0,0,0,2V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V5A.908.908,0,0,0,15.7,4.3ZM13,12a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9h8a1,1,0,0,1,1,1Z" fill={color}/> <rect height="3" width="2" x="7"/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <path d="M2,.5h9.5l4,4V14A1.5,1.5,0,0,1,14,15.5H2A1.5,1.5,0,0,1,.5,14V2A1.5,1.5,0,0,1,2,.5Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="9.5 2.5 9.5 5.5 3.5 5.5 3.5 2.5" strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="12.5 13.5 12.5 9.5 3.5 9.5 3.5 13.5" strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Archive = ({ color, fillTrue, strokeWidth, title = "Archive" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M3,2h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H3C2.4,0,2,0.4,2,1S2.4,2,3,2z"/> <path d="M15,4H1C0.4,4,0,4.4,0,5v10c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V5C16,4.4,15.6,4,15,4z M12,11H4V7h2v2h4V7h2 V11z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="2.5" x2="13.5" y1="0.5" y2="0.5"/> <polyline fill="none" points=" 11.5,6.5 11.5,8.5 4.5,8.5 4.5,6.5 " strokeLinecap="round" strokeLinejoin="round"/> <rect height="11" width="15" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x="0.5" y="3.5"/> </g> </svg>
};

const Ghost = ({ color, fillTrue, strokeWidth, title = "Ghost" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M8,0C4.1,0,1,3.1,1,7v9h14V7C15,3.1,11.9,0,8,0z M5,6c0-0.6,0.4-1,1-1s1,0.4,1,1S6.6,7,6,7S5,6.6,5,6z M8,12 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S9.1,12,8,12z M10,7C9.4,7,9,6.6,9,6s0.4-1,1-1s1,0.4,1,1S10.6,7,10,7z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M8,0C4.1,0,1,3.1,1,7v9h14V7C15,3.1,11.9,0,8,0z M13,14H3V7c0-2.8,2.2-5,5-5s5,2.2,5,5V14z" fill={color}/> <circle cx="6" cy="6" r="1"/> <circle cx="10" cy="6" r="1"/> <circle cx="8" cy="10" r="2"/> </g> </svg>
};

const Menu = ({ color, fillTrue, strokeWidth, title = "Menu" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z"/> <path d="M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z" fill={color}/> <path d="M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z" fill={color}/> </g> </svg>
};

const Discord = ({ color, fillTrue, strokeWidth, title = "Discord" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 32 25" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <g clipPath="url(#clip0)"> <path d="M27.05 2.2a26.35 26.35 0 0 0-6.51-2.01 0.1 0.1 0 0 0-0.1 0.05 18.35 18.35 0 0 0-0.81 1.66c-2.46-0.37-4.9-0.37-7.3 0-0.22-0.52-0.54-1.16-0.83-1.66a0.1 0.1 0 0 0-0.1-0.05 26.27 26.27 0 0 0-6.5 2.01 0.09 0.09 0 0 0-0.05 0.04c-4.14 6.19-5.28 12.22-4.72 18.19a0.11 0.11 0 0 0 0.04 0.07c2.73 2.01 5.38 3.23 7.98 4.03a0.1 0.1 0 0 0 0.11-0.03 18.94 18.94 0 0 0 1.64-2.66 0.1 0.1 0 0 0-0.06-0.14 17.45 17.45 0 0 1-2.49-1.19 0.1 0.1 0 0 1-0.01-0.17 14.03 14.03 0 0 0 0.49-0.38 0.1 0.1 0 0 1 0.11-0.02c5.23 2.39 10.89 2.39 16.05 0a0.1 0.1 0 0 1 0.11 0.01c0.16 0.13 0.33 0.26 0.49 0.39a0.1 0.1 0 0 1-0.01 0.17 16.37 16.37 0 0 1-2.49 1.19 0.1 0.1 0 0 0-0.05 0.14 21.26 21.26 0 0 0 1.63 2.66 0.1 0.1 0 0 0 0.11 0.03c2.61-0.81 5.26-2.03 7.99-4.03a0.1 0.1 0 0 0 0.04-0.07c0.67-6.89-1.12-12.88-4.72-18.19a0.08 0.08 0 0 0-0.04-0.04z m-16.37 14.6c-1.57 0-2.87-1.44-2.87-3.22 0-1.77 1.27-3.22 2.87-3.22 1.61 0 2.9 1.46 2.87 3.22 0 1.78-1.27 3.22-2.87 3.22z m10.61 0c-1.57 0-2.87-1.44-2.87-3.22 0-1.77 1.27-3.22 2.87-3.22 1.61 0 2.9 1.46 2.87 3.22 0 1.78-1.26 3.22-2.87 3.22z" fill={color}/> </g> <defs> <clipPath id="clip0"> <path d="M0 0h31.95v24.75h-31.95z" fill="#fff"/> </clipPath> </defs> </g> </svg>
  );
};

const Robot = ({ color, fillTrue, strokeWidth, title = "Robot" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" > <rect height="7.5" width="12.5" y="5.75" x="1.75" /> <path d="m10.75 8.75v1.5m-5.5-1.5v1.5m-.5-7.5 3.25 3 3.25-3" /> </svg>
  );
};

const Clock = ({ color, fillTrue, strokeWidth, title = "Clock" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color}> <path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,14a6,6,0,1,1,6-6A6.018,6.018,0,0,1,8,14Z" fill={color}/> <path d="M11.5,7H9V4.5a1,1,0,0,0-2,0V8A1,1,0,0,0,8,9h3.5a1,1,0,0,0,0-2Z"/> </g> </svg>
  );
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <title>{title}</title> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <circle cx="8.5" cy="8.5" fill="none" r="7" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points=" 8.5,4.5 8.5,8.5 12.5,8.5 " strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Team = ({ color, fillTrue, strokeWidth, title = "Team" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <circle cx="6" cy="6" r="3"/> <circle cx="26" cy="6" r="3"/> <circle cx="16" cy="4" fill={color} r="4"/> <path d="M7,25V15c0-1.479,0.464-2.85,1.25-3.982C8.167,11.013,8.084,11,8,11H4c-2.206,0-4,1.794-4,4 v6h2v7h7v-3H7z"/> <path d="M25,25V15c0-1.479-0.464-2.85-1.25-3.982C23.833,11.013,23.916,11,24,11h4 c2.206,0,4,1.794,4,4v6h-2v7h-7v-3H25z"/> <path d="M18,10h-4c-2.757,0-5,2.243-5,5v8h3v8h8v-8h3v-8C23,12.243,20.757,10,18,10z" fill={color}/> </g> </svg>
  );
  return <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth}> <path d="M10.713,13.719 C10.232,12.705,9.197,12,8,12H4c-1.657,0-3,1.343-3,3v5h2v7h6" fill="none"/> <path d="M21.287,13.719 C21.768,12.705,22.803,12,24,12h4c1.657,0,3,1.343,3,3v5h-2v7h-6" fill="none"/> <path d="M19,31h-6v-8h-3v-7 c0-2.209,1.791-4,4-4h4c2.209,0,4,1.791,4,4v7h-3V31z" fill="none" stroke={color}/> <circle cx="6" cy="6" fill="none" r="3"/> <circle cx="26" cy="6" fill="none" r="3"/> <circle cx="16" cy="5" fill="none" r="4" stroke={color}/> </g> </svg>
};

const Warning = ({ color, fillTrue, strokeWidth, title = "Warning" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g> <path d="M15.02 12.88l-5.94-11.19a1.31 1.31 0 0 0-2.32 0l-5.94 11.19a1.34 1.34 0 0 0 0.03 1.33 1.31 1.31 0 0 0 1.13 0.64h11.88a1.31 1.31 0 0 0 1.13-0.64 1.34 1.34 0 0 0 0.03-1.33z" fill="#f7bf26"/> <path d="M8.67 5.28l-0.37 5.47h-0.76l-0.37-5.47h1.5z" fill="#363636"/> <path d="M7.92 11.55a0.99 0.99 0 1 0 0 1.98 0.99 0.99 0 1 0 0-1.98z" fill="#363636"/> </g> </svg>
};

const SplitSignup = ({ color, fillTrue, strokeWidth, title = "Split signup" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M3.414,2H6V0H1C0.448,0,0,0.448,0,1v5h2V3.414l5,5V16h2V7.586L3.414,2z" fill={color}/> <path d="M15,0h-5v2h2.586L9.414,5.172l1.414,1.414L14,3.414V6h2V1C16,0.448,15.552,0,15,0z"/> </g> </svg>
  )
  return (
    <svg viewBox="0 0 16.5 16.5" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1.5"> <polyline fill="none" points="0.5 5.5 0.5 0.5 5.5 0.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" /> <polyline fill="none" points="15.5 5.5 15.5 0.5 10.5 0.5" strokeLinecap="round" strokeLinejoin="round" /> <polyline fill="none" points="0.5 0.5 7.5 7.5 7.5 15.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" /> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="9.5" y1="0.5" y2="6.5" /> </g> </svg>
  )
};

const Img = ({ color, fillTrue, strokeWidth, title = "Image" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M10.447,6.276A.5.5,0,0,0,10.023,6a.469.469,0,0,0-.447.234L7.409,9.7,5.854,8.146A.525.525,0,0,0,5.451,8a.5.5,0,0,0-.367.221l-2,3A.5.5,0,0,0,3.5,12h9a.5.5,0,0,0,.447-.724Z"/> <path d="M14,0H2A2,2,0,0,0,0,2V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V2A2,2,0,0,0,14,0ZM2,14V2H14l0,12Z" fill={color}/> <circle cx="5" cy="5" r="1"/> </g> </svg>
  );
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1"> <polyline fill="none" points="0.5 12.5 3.5 9.5 5.5 11.5 10.5 6.5 15.5 11.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M14,15.5H2A1.5,1.5,0,0,1,.5,14V2A1.5,1.5,0,0,1,2,.5H14A1.5,1.5,0,0,1,15.5,2V14A1.5,1.5,0,0,1,14,15.5Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <circle cx="5" cy="5" fill="none" r="1.5" strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const BulletList = ({ color, fillTrue, title = "bullet list" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <rect height="2" width="10" fill={color} x="6" y="1"/> <rect height="2" width="10" fill={color} x="6" y="7"/> <rect height="2" width="10" fill={color} x="6" y="13"/> <path d="M3,0H1C0.4,0,0,0.4,0,1v2c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V1C4,0.4,3.6,0,3,0z"/> <path d="M3,6H1C0.4,6,0,6.4,0,7v2c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V7C4,6.4,3.6,6,3,6z"/> <path d="M3,12H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-2C4,12.4,3.6,12,3,12z"/> </g> </svg>
  );
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1"> <rect height="3" width="3" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x="0.5" y="0.5"/> <rect height="3" width="3" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x="0.5" y="6.5"/> <rect height="3" width="3" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x="0.5" y="12.5"/> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="15.5" y1="2.5" y2="2.5"/> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="15.5" y1="8.5" y2="8.5"/> <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="6.5" x2="15.5" y1="14.5" y2="14.5"/> </g> </svg>
};

const Potion = ({ color, fillTrue, strokeWidth = 1.5, title = "potion" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M11,4.809V2h2V0H3.031V2H5V4.809a6,6,0,1,0,6,0ZM7,6.141V2H9V6.141a4,4,0,0,1,2.948,3.25A5.359,5.359,0,0,0,8,10a5.354,5.354,0,0,1-3.938.611A3.965,3.965,0,0,1,4,10,3.992,3.992,0,0,1,7,6.141Z" fill={color}/> </g> </svg>
  )
  return <svg viewBox="0 0 16.5 16.5" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <path d="M13.424,9.129A4.986,4.986,0,0,0,8,9.5a4.987,4.987,0,0,1-5.491.325" fill="none" strokeLinecap="round" strokeLinejoin="round"/> <path d="M10.5,5.105V.5h-5V5.105a5.5,5.5,0,1,0,5,0Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="4.5" x2="11.5" y1="0.75" y2="0.75"/> </g> </svg>
};

const Sync = ({ color, fillTrue, strokeWidth = 1.5, title = "potion" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M1,8A1,1,0,0,0,2,7V5h9V7.5a.5.5,0,0,0,.829.376l4-3.5a.5.5,0,0,0,0-.752l-4-3.5A.5.5,0,0,0,11,.5V3H2A2,2,0,0,0,0,5V7A1,1,0,0,0,1,8Z" fill={color}/> <path d="M15,8a1,1,0,0,0-1,1v2H5V8.5a.5.5,0,0,0-.293-.455.493.493,0,0,0-.536.079l-4,3.5a.5.5,0,0,0,0,.752l4,3.5A.5.5,0,0,0,4.5,16a.5.5,0,0,0,.5-.5V13h9a2,2,0,0,0,2-2V9A1,1,0,0,0,15,8Z"/> </g> </svg>
  )
  return <svg viewBox="0 0 16.5 16.5" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1.5"> <polyline fill="none" points="3.5 9.5 0.5 12.5 3.5 15.5" strokeLinecap="round" strokeLinejoin="round"/> <polyline fill="none" points="12.5 6.5 15.5 3.5 12.5 0.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <path d="M.5,12.5H13A1.5,1.5,0,0,0,14.5,11V8.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/> <path d="M15.5,3.5H3A1.5,1.5,0,0,0,1.5,5V7.5" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const StarRounded = ({ color, fillTrue, strokeWidth = 1.5, title = "potion" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15.144,5.438l-4.316-0.627L8.897,0.9c-0.338-0.683-1.456-0.683-1.794,0l-1.93,3.911L0.856,5.438 C0.039,5.557-0.292,6.565,0.302,7.145l3.124,3.044l-0.737,4.299c-0.139,0.814,0.717,1.439,1.451,1.054L8,13.513l3.861,2.03 c0.728,0.381,1.591-0.234,1.451-1.054l-0.737-4.299l3.124-3.044C16.292,6.565,15.961,5.557,15.144,5.438z" fill={color}/> </g> </svg>
  )
  return <svg viewBox="0 0 16.5 16.5" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1.5"> <polygon fill="none" points="8,0.867 10.318,5.563 15.5,6.316 11.75,9.971 12.635,15.133 8,12.696 3.365,15.133 4.25,9.971 0.5,6.316 5.682,5.563 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Info = ({ color, fillTrue, strokeWidth = 1.5, title = "info" }: ShapeProps) => {
  if(fillTrue) return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z" fill={color}/> <circle cx="7.5" cy="2.5" r="1.5"/> </g> </svg>
  )
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth="1"> <circle cx="8.5" cy="5" r="1" stroke="none"/> <circle cx="8.5" cy="8.5" fill="none" r="7" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="8.5" x2="8.5" y1="12.5" y2="8.5"/> </g> </svg>
};

const LeftAlign = ({ color, fillTrue, strokeWidth = 1.5, title = "info" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <rect height="16" width="2"/> <rect height="4" width="12" fill={color} x="4" y="3"/> <rect height="4" width="8" fill={color} x="4" y="9"/> </g> </svg>
};
const RightAlign = ({ color, fillTrue, strokeWidth = 1.5, title = "info" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <rect height="16" width="2" x="14"/> <rect height="4" width="12" fill={color} x="0" y="3"/> <rect height="4" width="8" fill={color} x="4" y="9"/> </g> </svg>
};
const CenterHorizontalAlign = ({ color, fillTrue, strokeWidth = 1.5, title = "info" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <rect height="16" width="2" x="7"/> <rect height="4" width="12" fill={color} x="2" y="3"/> <rect height="4" width="8" fill={color} x="4" y="9"/> </g> </svg>
};
const Refresh = ({ color, fillTrue, strokeWidth = 1.5, title = "reresh" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M14.413.389a.5.5,0,0,0-.849-.235L12.076,1.713a7.494,7.494,0,1,0,3.062,8.595,1,1,0,0,0-1.9-.616,5.513,5.513,0,1,1-2.573-6.5l-1.6,1.68a.5.5,0,0,0,.269.837L15.158,6.8a.478.478,0,0,0,.092.009.5.5,0,0,0,.487-.612Z" fill={color}/> </g> </svg>
};
const Sort = ({ color, fillTrue, strokeWidth = 1.5, title = "sort" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M5.343.344A.5.5,0,0,0,4.868,0H3.08a.5.5,0,0,0-.475.345l-1.95,6A.5.5,0,0,0,1.13,7h1a.5.5,0,0,0,.483-.37l.253-.937H5.13l.245.934A.5.5,0,0,0,5.859,7h.98a.5.5,0,0,0,.475-.656Zm-2.089,3.8q.434-1.642.56-2.2t.169-.8c.034.175.1.465.2.868s.186.732.255.987l.3,1.149Z" fill={color}/> <path d="M6.214,14.473H3.607l2.94-4.18a.5.5,0,0,0,.091-.287V9.5A.492.492,0,0,0,6.124,9H1.833a.5.5,0,0,0-.5.5v.531a.5.5,0,0,0,.5.5H4.292L1.352,14.7a.505.505,0,0,0-.091.289v.442A.53.53,0,0,0,1.829,16H6.214a.5.5,0,0,0,.5-.5v-.527A.5.5,0,0,0,6.214,14.473Z" fill={color}/> <path d="M15.992,11.294A.5.5,0,0,0,15.536,11H13V1a1,1,0,0,0-2,0V11H8.464a.5.5,0,0,0-.374.831l3.535,4a.5.5,0,0,0,.75,0l3.535-4A.5.5,0,0,0,15.992,11.294Z"/> </g> </svg>
};

const Eye = ({ color, fillTrue, strokeWidth = 1.5, title = "eye" }: ShapeProps) => {
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <path d="M0.5,8 c0,0,3-5.5,7.5-5.5S15.5,8,15.5,8s-3,5.5-7.5,5.5S0.5,8,0.5,8z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <circle cx="8" cy="8" fill="none" r="2.5" strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Banned = ({ color, fillTrue, strokeWidth = 1.5, title = "banned" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,2c1.3,0,2.5,0.4,3.5,1.1l-8.4,8.4 C2.4,10.5,2,9.3,2,8C2,4.7,4.7,2,8,2z M8,14c-1.3,0-2.5-0.4-3.5-1.1l8.4-8.4C13.6,5.5,14,6.7,14,8C14,11.3,11.3,14,8,14z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <circle cx="8" cy="8" fill="none" r="7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="2.697" x2="13.303" y1="13.303" y2="2.697"/> </g> </svg>
};

const Filter = ({ color, fillTrue, strokeWidth = 1.5, title = "filter" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15,0H1C0.4,0,0,0.4,0,1v2c0,0.3,0.1,0.6,0.4,0.8L6,8.5V15c0,0.4,0.2,0.8,0.6,0.9C6.7,16,6.9,16,7,16 c0.3,0,0.5-0.1,0.7-0.3l2-2C9.9,13.5,10,13.3,10,13V8.5l5.6-4.7C15.9,3.6,16,3.3,16,3V1C16,0.4,15.6,0,15,0z" fill={color}/> </g> </svg>
  }
  return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <polygon fill="none" points="0.5,0.5 0.5,3.5 6.5,7.5 6.5,15.5 9.5,13.5 9.5,7.5 15.5,3.5 15.5,0.5 " stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const Arrow = ({ color, fillTrue, strokeWidth = 1.5, title = "arrow" }: ShapeProps) => {
  if(fillTrue){
    return <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M10.625,3.219A1,1,0,0,0,9,4V7H1A1,1,0,0,0,1,9H9v3a1,1,0,0,0,1.625.781l5-4a1,1,0,0,0,0-1.562Z" fill={color}/> </g> </svg>
  }
  return <svg  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color} stroke={color} strokeWidth={strokeWidth}> <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="9.5" y1="8" y2="8"/> <polygon fill="none" points="9.5 12.5 15.5 8 9.5 3.5 9.5 12.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/> </g> </svg>
};

const ArrowZigZag = ({ color, fillTrue, strokeWidth = 1.5, title = "arrow zig zag" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g fill={color}>
        <g className="ldl-scale">
          <path id="1684337510027-3009215_XMLID_256_" d="M90.778 45.008L72.029 7.531l-18.75 37.477h12.035V66.36L26.006 31.793c-2.031-1.785-4.761-2.088-7.049-.78-2.287 1.307-3.735 3.999-3.735 6.945v54.573h13.429V53.606L67.96 88.173c2.029 1.785 4.762 2.089 7.049.78 2.287-1.308 3.734-3.999 3.734-6.945v-37h12.035z" fill={color}/>
        </g>
      </g>
    </svg>
  )
};

const Lock = ({ color, fillTrue, strokeWidth = 1.5, title = "lock" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M12,6H10V4A2,2,0,0,0,6,4V6H4V4a4,4,0,0,1,8,0Z"/> <path d="M14,7H2A1,1,0,0,0,1,8v7a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V8A1,1,0,0,0,14,7ZM8,13a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,8,13Z" fill={color}/> </g> </svg>
  )
};

const Reorder = ({ color, fillTrue, strokeWidth = 1.5, title = "reorder" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M5.829,1.124A.5.5,0,0,0,5,1.5V4A5,5,0,0,0,5,14H8a1,1,0,0,0,0-2H5A3,3,0,0,1,5,6V8.5a.5.5,0,0,0,.829.376l4-3.5a.5.5,0,0,0,0-.752Z"/> <path d="M15,12H12a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z" fill={color}/> <path d="M15,8H12a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z" fill={color}/> <path d="M12,6h3a1,1,0,0,0,0-2H12a1,1,0,0,0,0,2Z" fill={color}/> </g> </svg>
  )
};

const VideoPlayer = ({ color, fillTrue, strokeWidth = 1.5, title = "video player" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15,1H1C0.4,1,0,1.4,0,2v12c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V2C16,1.4,15.6,1,15,1z M14,13H2V3h12V13z" fill={color}/> <polygon points="6,5 6,11 10,8 "/> </g> </svg>
  )
};

const Code = ({ color, fillTrue, strokeWidth = 1.5, title = "code" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"> <g fill="none"> <path d="M9.964 2.686a.5.5 0 1 0-.928-.372l-4 10a.5.5 0 1 0 .928.372l4-10zm-6.11 2.46a.5.5 0 0 1 0 .708L2.207 7.5l1.647 1.646a.5.5 0 1 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708 0zm7.292 0a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L12.793 7.5l-1.647-1.646a.5.5 0 0 1 0-.708z" fill={color} fillRule="evenodd"/> </g> </svg>
  )
};

const Italic = ({ color, fillTrue, strokeWidth = 1.5, title = "italic" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"> <g fill="none"> <path d="M5.675 3.5a.45.45 0 0 1 .45-.45h4.5a.45.45 0 1 1 0 .9h-1.62l-1.774 7.1h1.644a.45.45 0 0 1 0 .9h-4.5a.45.45 0 1 1 0-.9h1.619l1.775-7.1H6.125a.45.45 0 0 1-.45-.45z" fill={color} fillRule="evenodd"/> </g> </svg>
  )
};

const Bold = ({ color, fillTrue, strokeWidth = 1.5, title = "bold" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"> <g fill="none"> <path d="M5.105 12c-.397 0-.681-.088-.853-.264-.168-.18-.252-.465-.252-.853V4.117c0-.397.086-.681.258-.853.176-.176.458-.264.847-.264H9.03c1.108 0 2.025.982 2.025 2.185 0 .9-.45 1.634-1.35 2.051 1.162.213 1.814 1.392 1.814 2.245 0 1.031-.528 2.519-2.24 2.519H5.104zm3.274-3.997H5.8v2.628h2.579c.521 0 1.25-.51 1.25-1.332 0-.823-.729-1.296-1.25-1.296zM5.8 4.37v2.327h2.38c.36 0 1.097-.337 1.097-1.196 0-.86-.797-1.131-1.097-1.131H5.8z" fill={color}/> </g> </svg>
  )
};

const FontSize = ({ color, fillTrue, strokeWidth = 1.5, title = "bold" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"> <g fill="none"> <path d="M2.782 2.217a.4.4 0 0 0-.565 0l-2 2a.4.4 0 0 0 .565.566L2.1 3.466v8.068L.782 10.217a.4.4 0 0 0-.565.566l2 2a.4.4 0 0 0 .565 0l2-2a.4.4 0 0 0-.565-.566l-1.318 1.317V3.466l1.318 1.317a.4.4 0 0 0 .565-.566l-2-2zm7.718.533a.5.5 0 0 1 .47.33l3 8.32a.5.5 0 0 1-.94.34l-.982-2.724H8.952l-.982 2.723a.5.5 0 0 1-.94-.34l3-8.319a.5.5 0 0 1 .47-.33zm0 1.974l1.241 3.442H9.26L10.5 4.724z" fill={color} fillRule="evenodd"/> </g> </svg>
  )
};


const Undo = ({ color, fillTrue, strokeWidth = 1.5, title = "undo" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15.584,7.44A12.98,12.98,0,0,0,2.848,6.07l.763-2.154a1,1,0,1,0-1.884-.668L.058,7.961A1,1,0,0,0,.666,9.238l4.713,1.668a1,1,0,0,0,.668-1.885L3.305,8.05A11.011,11.011,0,0,1,14.416,9.064,1,1,0,1,0,15.584,7.44Z" fill={color}/> </g> </svg>
  )
};

const Redo = ({ color, fillTrue, strokeWidth = 1.5, title = "redo" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M15.943,7.961,14.274,3.247a1,1,0,0,0-1.886.668l.763,2.155A12.978,12.978,0,0,0,.416,7.44,1,1,0,1,0,1.584,9.063,11.014,11.014,0,0,1,12.7,8.05l-2.742.971a1,1,0,1,0,.668,1.885l4.713-1.669A1,1,0,0,0,15.943,7.961Z" fill={color}/> </g> </svg>
  )
};

const SwapHorizontal = ({ color, fillTrue, strokeWidth = 1.5, title = "swap horizontal" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M10.787,8.09A.5.5,0,0,0,10,8.5V11H3a1,1,0,0,0,0,2h7v2.5a.5.5,0,0,0,.787.41l5-3.5a.5.5,0,0,0,0-.82Z"/> <path d="M5.5,8A.5.5,0,0,0,6,7.5V5h7a1,1,0,0,0,0-2H6V.5A.5.5,0,0,0,5.213.09l-5,3.5a.5.5,0,0,0,0,.82l5,3.5A.5.5,0,0,0,5.5,8Z" fill={color}/> </g> </svg>
  )
};

const Revert = ({ color, fillTrue, strokeWidth = 1.5, title = "revert" }: ShapeProps) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <g fill={color}> <path d="M2.765,9.692a1,1,0,0,0-1.9.616,7.469,7.469,0,0,0,.716,1.567,1,1,0,1,0,1.711-1.034A5.517,5.517,0,0,1,2.765,9.692Z"/> <path d="M7.91,13.5a5.455,5.455,0,0,1-2.3-.545,1,1,0,1,0-.871,1.8,7.438,7.438,0,0,0,3.141.745H7.9a1,1,0,0,0,.015-2Z"/> <path d="M14,10.7a1,1,0,0,0-1.383.294,5.568,5.568,0,0,1-1.686,1.662A1,1,0,1,0,12,14.348a7.562,7.562,0,0,0,2.3-2.265A1,1,0,0,0,14,10.7Z"/> <path d="M8,.5A7.511,7.511,0,0,0,3.923,1.713L2.436.155a.5.5,0,0,0-.849.234L.263,6.194a.5.5,0,0,0,.487.612A.486.486,0,0,0,.842,6.8L6.673,5.712a.5.5,0,0,0,.269-.837l-1.6-1.681A5.49,5.49,0,0,1,13.5,8a1,1,0,0,0,2,0A7.508,7.508,0,0,0,8,.5Z" fill={color}/> </g> </svg>
  )
};
