import { dragType, raidAreaType } from '../raid/raid';
import { newID } from '../../types/typeUtils';

export interface iList {
  id: string
  area: raidAreaType
  listName: string
  containType: dragType
  targetIDs: string[]
  // targetIDs_new: {
  //   [targetID: string]: string // fractionalIndex
  // }
  isSortable?: boolean
  noSync?: true
}

export const List = () => {

  const createID = (listIDstr?: string) => {
    if(listIDstr?.startsWith("list-")) return listIDstr
    if(!!listIDstr) return `list-${listIDstr}`
    return "list-" + newID(5)
  }

  const create = (
    listName: string,
    area: raidAreaType,
    containType: dragType,
    targetIDs: string[],
    listIDstr?: string,
    noSync?: true,
    isSortable = false
  ) => {
    const listID = createID(listIDstr)

    const listData: iList = {
      id: listID,
      area,
      listName,
      containType,
      targetIDs: targetIDs || [],
      isSortable
    }
    if(noSync) listData.noSync = noSync

    return listData
  };


  return ({
    // no protection
    create,
    createID
  })
}

export default List;