import { iModal } from "typings";

export const demoModal: iModal = {
  top: "Modal title",
  middle: `Maecenas torquent non vulputate nascetur varius egestas mattis duis, venenatis feugiat cras erat morbi eu hac lacus vitae, cubilia eleifend mollis class dictumst odio elit. Laoreet a tempus dui vehicula inceptos in ultrices.`,
  bottom: "Confirm",
  confirmCallback: () => {
    console.log("confirm");
  },
};

export const newAutomationModal = (confirmCallback?: (...args: any[]) => any): iModal => {
  return {
    top: "New Automation",
    confirmCallback,
  }
}

export const newRaidModal = (confirmCallback?: (...args: any[]) => any): iModal => {
  return {
    top: "New signup",
    options: { alignVertical: "start" },
    confirmCallback,
  }
}

export const editRaidModal = (confirmCallback?: (...args: any[]) => any): iModal => {
  return {
    top: "Edit signup",
    confirmCallback,
    options: {
      alignVertical: "start"
    }
  }
}

export const editorModal = (confirmCallback?: (...args: any[]) => any): iModal => {
  return {
    top: "Text Editor",
    confirmCallback,
  }
}

export const confirmDangerModal = (
  intent: string,
  confirmCallback?: (...args: any[]) => any,
): iModal => {
  return {
    top: `${intent}?`,
    middle: `This action is irreversable, you will not be able to undo.`,
    bottom: intent,
    confirmCallback,
    options: {
      size: "small"
    }
  };
};

export const confirmDeletePanelModal = (
  confirmCallback?: (...args: any[]) => any,
  intent?: string,
): iModal => {
  return {
    top: `⚠️ This panel is linked`,
    middle: `Do you want to delete only this instance of the panel or all instances?`,
    confirmCallback,
    options: {
      size: "medium"
    }
  };
};

export const changeCharacterModal = (
  memberName?: string,
): iModal => {
  return {
    top: `Override character for ${memberName}`,
    options: {
      size: "medium"
    }
  };
};

export const changeSignupModal = (
  title: string,
  confirmCallback?: (...args: any[]) => any,
): iModal => {
  return {
    top: title,
    confirmCallback,
    options: {
      size: "medium"
    }
  };
};