import { atom } from "jotai"
import { capitalize } from "lodash"
import { useRouter } from "next/router"
import toast, { CheckmarkIcon, ErrorIcon } from "react-hot-toast"
import { iMember, iModal, isNever } from "typings"
import Button from "../../components/1_atom/buttons/button"
import SvgIcon from "../../components/1_atom/buttons/svgIcon"
import { ManageBoostsModal } from "../../components/2_molecule/boosts/manageBoostsModal"
import { useModal } from "../hooks"
import { deleteUnsavedChangesState } from "./global"
import { iDistributeLog, iDistributeLogItem } from "functions"
import TextInput from "../../components/1_atom/textInput/textInput"
import { useState } from "react"
import { updateMemberRosterStatusState } from "./updateMemberState"

export const CheckoutSuccessful = ({ toastID }: { toastID: string }) => {
  const [ triggerModal ] = useModal()

  return (
    <div className="flex-col gap1 align-start">
      <span className="text-dark" style={{maxWidth: 200}}>Checkout successful</span>
      <div className="flex-row gap1">
        <Button
          text="Distribute boosts"
          variant="soft"
          size="mini"
          click={() => {

            const modal: iModal = {
              options: { size: "small" },
              top: "Boost Distribution",
              middle: (
                <ManageBoostsModal />
              )
            }
            triggerModal(modal)

            toast.dismiss(toastID)
          }}
        />
      </div>
    </div>
  )
}

const NoPremiumAccessNotification = ({ errorMsg, toastID }: { errorMsg: string, toastID: string }) => {
  const router = useRouter()
  return (
    <div className="flex-col gap1 align-center">
      <span className="text-dark" style={{maxWidth: 200}}>{errorMsg}</span>
      <div className="flex-row gap1">
        <Button
          text="Go to settings"
          variant="soft"
          size="mini"
          click={() => {
            router.push("/settings?show=premium")
            toast.dismiss(toastID)
          }}
        />
      </div>
    </div>
  )
}

export const noPremiumAccessToastState = atom(
  null,
  (get, set, { errorMsg }: { errorMsg: string }) => {
    const toastID = toast.loading('Loading...');
    toast.error((t) => <NoPremiumAccessNotification errorMsg={errorMsg} toastID={toastID} />, {
      id: toastID,
      icon: <ErrorIcon />
    })
  }
)

const SignupDuplicated = ({ raidID }: { raidID: string }) => {
  return (
    <div className="flex-col gap1 align-center">
      <span className="text-dark">Signup duplicated!</span>
      <div className="flex-row gap1">
        <Button
          text="Go to new signup"
          variant="soft"
          size="mini"
          click={async () => {
            console.log("navigate to signup")
            console.log(window.location)
            window.location.href = `${window.location.origin}/raid?raidID=${raidID}`
          }}
        />
      </div>
    </div>
  )
}

const SplitBenchDistributed = ({ log, toastID }: { log: iDistributeLog, toastID: string }) => {
  const [ triggerModal ] = useModal()
  
  const Log = ({ log }: { log: iDistributeLog }) => {
    const [filterBy, setFilterBy] = useState("")

    const LogItem = ({ logItem }: { logItem: iDistributeLogItem }) => {
      if(logItem.type === "header"){
        return <div className="flex-col mt2 p1 rounded bg2">
          <h5>{logItem.title}</h5>
        </div>
      }
  
      if(logItem.type === "allocate-char"){
        const showLogItem = !filterBy || logItem.desc.toLowerCase().includes(filterBy.toLowerCase())
        if(!showLogItem) return <></>
        return <span className="weak-text ml2">{logItem.desc}</span>
      }
  
      isNever(logItem)
      return <></>
    }

    return (
      <div className="flex-col gap0">
        <TextInput
          placeholder="Filter log items"
          onChange={(e) => {
            setFilterBy(e.target.value)
          }}
        />
        {log.map((logItem, i) => (
          <LogItem key={i} logItem={logItem} />
        ))}
      </div>
    )
  }


  return (
    <div className="flex-col gap1 align-center">
      <span className="text-dark">Distribute success!</span>
      <div className="flex-row gap1">
        <Button
          text="Show log"
          variant="soft"
          size="mini"
          click={async () => {
            const modal: iModal = {
              options: { size: "small", alignVertical: "start" },
              top: "Distribution log",
              middle: <Log log={log} />
            }
            triggerModal(modal)

            toast.dismiss(toastID)
          }}
        />
      </div>
    </div>
  )
}

export const signupDuplicatedToastState = atom(
  null,
  (get, set, { toastID, raidID }: { toastID: string; raidID: string }) => {
    toast.success((t) => <SignupDuplicated raidID={raidID} />, {
      id: toastID,
      duration: 10000,
      icon: <CheckmarkIcon />
    })
  }
)

export const showDistributeLogToastState = atom(
  null,
  (get, set, { log }: { log: iDistributeLog }) => {
    toast.success((t) => <SplitBenchDistributed log={log} toastID={"show-distribute-log"} />, {
      id: "show-distribute-log",
      duration: 10000,
      icon: <CheckmarkIcon />
    })
  }
)

export const warnRosterGroupDesyncState = atom(
  null,
  (get, set, members: iMember[]) => {

    toast.error(
      (t) => (
        <div className="flex-col gap1 align-end">
          <span className="text-dark">
            <b>Roster / groups sync conflict detected.</b>
            <br />
            Unable to automatically merge new signups with local unsaved changes.
            <br /> <br />
            Do you want to reset your local changes or remove{" "}
            {members.map(m => capitalize(m?.character?.charName)).join(", ")} from roster?
          </span>
          <div className="flex-row gap1">
            <Button
              text="Reset local changes"
              variant="transparent"
              click={() => {
                set(deleteUnsavedChangesState)
                toast.dismiss(t.id)
              }}
            />
            <Button
              text="Remove from roster"
              click={async () => {
  
                toast.dismiss(t.id)
  
                const toastId = toast.loading('Removing from roster');
                try {
                  
                  await Promise.all(members.map(m => {
                    set(updateMemberRosterStatusState, {memberID: m.userID, toRoster: false})
                  }))
                  toast.success('Members removed from roster', { id: toastId });
  
                } catch (err) {
                  console.error(err);
                  toast.error((err) => `Error: ${err}`, { id: toastId });
                }
              }}
            />
          </div>
        </div>
      ),
      {
        id: "sync-conflict",
        duration: Infinity,
        icon: <SvgIcon icon="warning" height={24} width={24} addClass="no-flex-shrink"/>,
        style: { maxWidth: 430 }
      }
    )
  }
)