import React, { ReactNode, useCallback, useEffect, useMemo, useState, } from "react";
import { firebase } from "api";
import { useAuth } from "./authContext";
import { isSameDay, isSameWeek } from 'date-fns';
import { createContext } from 'use-context-selector';
import { iTeam, iRaid, eFSColl, eTeamPermission, isDefined, eFeature, iOwnerType } from "typings";
import { filterTeamIDsInClaims, getCollectionData, getErrorMessage } from "functions";
import { useAtom, useSetAtom } from "jotai";
import { teamsState } from "../global/state/global";
import { iFeatureAccess, iFeatureAccessLevel, boostTierToAccess, findLowestTierThatHasAccessTo } from "data";
import { teamAccessState } from "../global/state/raid";
import { noPremiumAccessToastState } from "../global/state/notifications";

type iSignupColumns = {
  signups: {
      "this week": iRaid[] | null;
      future: iRaid[] | null;
      "past two weeks": iRaid[] | null;
  };
  templates: iRaid[] | null;
}
type teamLoadState = "not-loaded" | "loading" | "loaded"

type MainContextType = {
  saveTheme: (theme: string) => void
  theme: string
  signups: iRaid[] | null
  teams: iTeam[]
  signupColumns: iSignupColumns
  setNavButtons: React.Dispatch<React.SetStateAction<JSX.Element>>
  setLoadRaids: React.Dispatch<React.SetStateAction<boolean>>
  loadTeamsState: teamLoadState
  startLoadTeams: () => void
  getTeamTemplates: (forceUpdate?: boolean) => Promise<iRaid[]>
  navButtons: JSX.Element
  teamTemplates: iRaid[] | null
  checkHasAccess: (ownerType: iOwnerType, ownerID: string, feature: eFeature, callback?: (...args: any[]) => any, showErrorToast?: boolean) => true | string
}
export const Context = createContext<MainContextType>(null!);

export const Provider = ({ children }: { children: ReactNode }) => {
  
  const auth = useAuth()
  const [theme, setTheme] = useState("dark");
  const [signups, setRaids] = useState<iRaid[] | null>(null)
  const [teams, setTeams] = useAtom(teamsState)
  const [navButtons, setNavButtons] = useState(<></>)
  const [loadRaids, setLoadRaids] = useState(false)
  const [loadTeamsState, setLoadTeamsState] = useState<teamLoadState>("not-loaded")
  const [teamTemplates, setTeamTemplates] = useState<iRaid[] | null>(null)
  const [teamAccess, setTeamAccess] = useAtom(teamAccessState)
  const showNoPremiumAccessToast = useSetAtom(noPremiumAccessToastState)

  const signupColumns: iSignupColumns | null = useMemo(() => {
    if(signups === undefined) return {
      signups: {
        "this week": null,
        future: null,
        "past two weeks": null,
      },
      templates: null
    }
    const templates: iRaid[] = []
    const thisWeek: iRaid[] = []
    const future: iRaid[] = []
    const pastTwoWeeks: iRaid[] = []
    const now = Date.now()

    for (const raid of signups || []) {
      if(raid?.isTemplate) {
        templates.push(raid)
        continue;
      }
      if(isSameDay(raid?.start, now)) {
        thisWeek.push(raid)
        continue;
      }
      if(isSameWeek(raid?.start, now, {weekStartsOn: 1}) && raid?.start > now) {
        thisWeek.push(raid)
        continue;
      }
      // past two weeks
      if(raid?.end < now) {
        pastTwoWeeks.push(raid)
        continue;
      }

      future.push(raid)
    }

    return { signups: {"this week": thisWeek, future, "past two weeks": pastTwoWeeks }, templates }
  }, [signups])

  const db = firebase.firestore();
  
  // subscribe to raids
  useEffect(() => {
    const userID = auth?.userData?.userID;
    if (!userID) return;
    if(!loadRaids) return;

    const raidMemberDocsRef = db.collection(eFSColl.SIGNUPS)
      .where(`members.${userID}.userID`, "==", userID)
      .where(`isArchived`, "==", false)
    const unsubscribeMember = raidMemberDocsRef.onSnapshot((response) => {
      const data = getCollectionData(response);
      if (!data) return;

      setRaids(data);
    });

    return () => {
      unsubscribeMember()
    };
  }, [auth?.userData?.userID, loadRaids]);

  // subscribe to teams
  useEffect(() => {
    const userID = auth?.userData?.userID;
    if (!userID) return;
    if(loadTeamsState === "not-loaded") return;

    const userTeamDocsRef = db.collection(eFSColl.TEAMS)
      .where(`members.${userID}.userID`, "==", userID)

    const unsubscribeTeams = userTeamDocsRef.onSnapshot((response) => {
      const data = getCollectionData(response) as iTeam[]
      if (!data) return setTeams([])

      const teamsAccess: { [teamID: string]: iFeatureAccess } = data.reduce((acc, team) => {
        if(!team?.premium?.tierID) return acc
        return {...acc, [team.id]: boostTierToAccess(team?.premium?.tierID)}
      }, {})

      setTeamAccess(teamsAccess)
      setTeams(data);
      setLoadTeamsState("loaded")
    });

    return () => {
      unsubscribeTeams()
    };
  }, [auth?.userData?.userID, loadTeamsState]);


  const saveTheme = useCallback((theme: string) => {
    setTheme(theme);
    storeUserSetPreference(theme)
  }, [])

  const storeUserSetPreference = (theme: string) => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  };

  const getTeamTemplates = async (forceUpdate = false) => {
    if(teamTemplates && teamTemplates?.length > 0 && forceUpdate === false) return teamTemplates

    const teamIDs = filterTeamIDsInClaims(auth.claims, [
      eTeamPermission.MANAGE_SIGNUPS,
      eTeamPermission.SIGNUPS_ASSISTANT
    ])
    if(teamIDs?.length < 1) return []

    const teamTemplateDocsRef = db.collection(eFSColl.SIGNUPS)
      .where("isTemplate", "==", true)
      .where(`owner.teamID`, "in", teamIDs)

    const teamTemplateSignups = await teamTemplateDocsRef.get()
    const signups = getCollectionData(teamTemplateSignups) as iRaid[]
    setTeamTemplates(signups)
    return signups
  }

  useEffect(() => {
    // init theme
    const userSetPreference = localStorage.getItem("theme");
    if (userSetPreference !== null) setTheme(userSetPreference);
  }, []);

  const getTeamAccessLevel = (teamID: string, feature: eFeature): iFeatureAccessLevel => {
    const access = teamAccess?.[teamID]?.[feature]
    if(isDefined(access) === false) return false
    return access
  }

  const checkHasAccess = (
    ownerType: iOwnerType,
    ownerID: string,
    feature: eFeature,
    callback?: (...args: any[]) => any,
    showErrorToast = true
  ): true | string => {

    if(feature === eFeature.VOID) return true
    try {
      const lowestBoostTier = findLowestTierThatHasAccessTo(feature)?.label
      if (ownerType === "user") {
        throw new Error(`This signup needs to be owned by a team with ${lowestBoostTier} access or higher to access this feature.`)
      }

      const accessLevel = getTeamAccessLevel(ownerID, feature)
      
      if (!accessLevel) {
        throw new Error(`Your team needs to be ${lowestBoostTier} or higher to access this feature.`)
      }

      if(!!callback && typeof callback === "function") callback()
      return true
    } catch (error) {
      if(!!showErrorToast) {
        showNoPremiumAccessToast({ errorMsg: getErrorMessage(error) })
      }
      return getErrorMessage(error)
    }
  }

  const startLoadTeams = (): void => {
    // stop if already loading or loaded
    if(loadTeamsState === "loading" || loadTeamsState === "loaded"){
      return
    }
    setLoadTeamsState("loading")
  }

  return (
    <Context.Provider
      value={{
        saveTheme, theme,
        navButtons, setNavButtons,
        setLoadRaids,
        loadTeamsState, startLoadTeams,
        
        teams,
        signups, 
        signupColumns,
        getTeamTemplates, teamTemplates,

        checkHasAccess
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
