import { atom } from "jotai";
import { iRaid, eTeamPermission, iRaidData } from "typings";
import copy from "fast-copy";
import { setAdminStatusState, ydocAtom } from "./global";
import { splitListsInitialisedState } from "./split";
import { updateSplitMembersState } from "./updateSplitMembersState";
import { initSplitListsAtom } from "./initSplitListsAtom";
import { findLocation, getBasicSmartLists } from "data";
import { basicSmartListsAtom, recalcSmartsListMembersAtom } from "./smartListState";
import { updateRosterState } from "./updateRosterState";
import { raidState, raidFSState, lastRaidImportState, raidStateCore, setGroupingAtom, signupLocationAtom } from "./raid";
import { updateGroupsAtom } from "./updateGroupsState";

// import
export const importSignupAtom = atom(
  null,
  (get, set, raid: iRaid) => {
    const r = copy(raid);
    const location = findLocation(r.location);
    console.log("import raid", r);
    set(signupLocationAtom, location)
    set(setGroupingAtom, { signupInput: r, forceRefresh: true })

    const setRaidState = () => {
      set(raidState, r);
      set(raidFSState, copy(r));
      set(lastRaidImportState, Date.now());
    };

    const updateRoster = () => {
      if (!!r?.splitData) {
        setRaidState();
        const isSplitsInitialised = get(splitListsInitialisedState);
        if (isSplitsInitialised) {
          // fix splits
          set(updateSplitMembersState, {}); // needs raidState to be set
        } else {
          // init split roster lists
          set(initSplitListsAtom, { location, signup: r });
        }
        return;
      }

      // init normal roster - updating is in dndContext useEffect
      const isInitialised = get(raidStateCore);
      if (!isInitialised) {
        set(updateRosterState, { raid: r });
        setRaidState();
        set(updateGroupsAtom);
        return;
      }

      setRaidState();
    };

    updateRoster();

    set(setAdminStatusState, {
      permission: eTeamPermission.MANAGE_SIGNUPS,
      adminPermission: eTeamPermission.SIGNUPS_ASSISTANT
    });

    // set basic smart lists
    const basicSmartLists = get(basicSmartListsAtom);
    if (Object.keys(basicSmartLists).length < 1) {
      set(basicSmartListsAtom, getBasicSmartLists(location, r));
    }

    // update smart lists
    set(recalcSmartsListMembersAtom, r?.members, true);
  }
);


export const importRaidDataAtom = atom(null, (get, set, rData: iRaidData) => {
  const raidData = copy(rData)
  const y = get(ydocAtom)
  // console.log("import raidData", raidData)

  y.doc.transact(() => {
    if (raidData?.lists) {
      for (const list of Object.values(raidData?.lists)) {
        if(list?.noSync === true) continue;
        y.lists.set(list.id, list)
      }
    }
    if (raidData?.targets) {
      for (const target of Object.values(raidData?.targets)) {
        if(target?.noSync === true) continue;
        y.targets.set(target.id, target)
      }
    }
    if (raidData?.smartLists) {
      for (const smartList of Object.values(raidData?.smartLists)) {
        y.smartLists.set(smartList.id, smartList)
      }
    }
    if (raidData?.roles) {
      for (const role of Object.values(raidData?.roles)) {
        y.roles.set(role.id, role)
      }
    }
    if (raidData?.assignments) {
      for (const assignment of Object.values(raidData?.assignments)) {
        y.assignments.set(assignment.id, assignment)
      }
    }
    if (raidData?.areas) {
      for (const [raidAreaName, raidArea] of Object.entries(raidData?.areas)) {
        // console.info(raidAreaName, raidArea)
        y.areas.set(raidAreaName, raidArea)
      }
    }
    if (raidData?.assignmentPos) {
      y.assignmentPos.set(raidData?.assignmentPos)
    }
  })
})