import { arrayEntriesToObject, getCollectionData, tagIDsToPermissions } from 'functions';
import { Path } from 'react-hook-form';
import { eFSColl, iTeam, iTeamPermissionType } from 'typings';
import { firebase } from '../client'
import { standardCRUD } from '../documentAPI';

const teamsAPI = (db: firebase.firestore.Firestore, deleteField: firebase.firestore.FieldValue) => {
  const collectionName = eFSColl.TEAMS
  const { get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath } = standardCRUD<iTeam>(collectionName, db, deleteField)

  const getUserTeams = async (userID: string): Promise<iTeam[]> => {

    const teamsDocRef = db
      .collection(collectionName)
      .where(`members.${userID}.userID`, "==", userID)

    const teamsFound = await teamsDocRef
      .get()
      .then((response) => {
        return getCollectionData(response) as iTeam[]
      })
      .catch((error) => {
        console.error(error)
        return []
      })

    return teamsFound
  }

  const getAllFromDiscordServerIDs = async (discordServerIDs: string[]): Promise<iTeam[]> => {

    const teams: iTeam[] = []

    const getBatch = async (tenServerIDs: string[]) => {
      const teamsDocRef = db
        .collection(collectionName)
        .where(`discordServerID`, "in", tenServerIDs)
  
      const teamsFound = await teamsDocRef
        .get()
        .then((response) => {
          return getCollectionData(response) as iTeam[]
        })
        .catch(error => {
          console.error(error)
          return false
        });

      if(typeof teamsFound === "boolean") return
      teams.push(...teamsFound)
    }

    for (let i = 0; i < discordServerIDs.length; i += 10) {
      const slice = discordServerIDs.slice(i, i + 10)
      await getBatch(slice)
    }
    
    return teams;
  };

  const getTeamsByDiscordServerID = async (discordServerID: string): Promise<iTeam[]> => {
    const teamsDocRef = db
      .collection(collectionName)
      .where(`discordServerID`, "==", discordServerID)

    const teamsFound: iTeam[] = await teamsDocRef
      .get()
      .then((response) => {
        return getCollectionData(response) as iTeam[]
      })
      .catch((error) => {
        console.error(error)
        return []
      })

    return teamsFound
  }

  const updateTagPermission = (
    teamID: string,
    tagID: string,
    permissionString: iTeamPermissionType,
    isOn: boolean
  ) => {
    const teamDocRef = db.collection(collectionName).doc(teamID)

    const path: Path<iTeam> = `tags.${tagID}.permissions.${permissionString}`
    if (isOn === false) {
      return teamDocRef
        .update({ [path]: deleteField })
        .then(() => {
          return true
        })
        .catch((error) => {
          console.error("Error updating document: ", error)
          return false
        })
    }
    return teamDocRef
      .update({ [path]: true })
      .then(() => {
        return true
      })
      .catch((error) => {
        console.error("Error updating document: ", error)
        return false
      })
  }

  const deleteTag = (
    team: iTeam,
    tagID: string,
  ) => {
    const teamDocRef = db.collection(collectionName).doc(team.id)
    const autoAcceptDiscordRoles = team.autoAcceptDiscordRoles.map((item, i) => {
      const role = {...item}
      role.giveTagIDs = item.giveTagIDs.filter(_tagID => _tagID === tagID)
      return role
    })

    const membersWithTagID: [string, string[]][] = Object.entries(team.memberTags)
      .map(([userID, memberTags]) => {
        return [userID, memberTags.filter(_tagID => _tagID !== tagID)]
      })

    const membersWithPermissions: [string, iTeamPermissionType[]][] = membersWithTagID
      .map(([userID, memberTags]) => {
        return [userID, tagIDsToPermissions(team, memberTags)]
      })

    const obj = {
      [`tags.${tagID}`]: deleteField,
      autoAcceptDiscordRoles,
      memberTags: arrayEntriesToObject(membersWithTagID),
      memberPermissions: arrayEntriesToObject(membersWithPermissions)
    };

    return teamDocRef
      .update(obj)
      .then(() => {
        return true
      })
      .catch((error) => {
        console.error("Error updating document: ", error)
        return false
      })
  }

  const deleteMember = (teamID: string, memberUserID: string) => {
    return deleteMultiPath(teamID, [`members.${memberUserID}`, `memberTags.${memberUserID}`, `memberPermissions.${memberUserID}`])
  }

  return {
    get, set, updatePath, updateMerge, updateMultiPath, remove, deleteMultiPath,
    getUserTeams,
    getAllFromDiscordServerIDs,
    getTeamsByDiscordServerID,
    updateTagPermission,
    deleteTag,
    deleteMember
  }
}

export default teamsAPI;