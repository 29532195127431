import axiosAPI, { AxiosResponse } from "axios";
import { dequal } from "dequal";
import { FilterKeys, GroupedArray, Path, SortFunction, TransformFunction, anyFunction, iRaid, isDefined, newID, withDefault } from "typings";
import { CustomError } from "./errorAndFeedback/customError";
import { minMax } from "./mathUtilities";

export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function classNames(...classes: (string | undefined | false)[]) {
  return classes.filter(Boolean).join(' ')
}

export const addNumbers = (a: number, b: number) => {
  return a + b
}

export const getStringLines = (str: string) => {
  return  str.split(/\r\n|\r|\n/).length
}

export const stringCharToNumber = (str: string) => {
  return str.toLowerCase().charCodeAt(0) - 97
}

export const useArrayIndex = <T extends unknown>(arr: T[], index: number) => {
  if(index > arr?.length - 1) return arr[arr?.length - 1]
  if(index < 0) return arr[0]
  return arr[index]
}

export const updateDiscordSyncTo = (raid: iRaid) => {
  if(!raid?.raidID) return
  if(!raid?.syncTo?.discord || raid?.syncTo?.discord?.length < 1) return

  const axios = axiosAPI.create({
    baseURL: getPrepiBotBaseURL(),
    validateStatus: () => true
  })

  try {
    axios.post(`/updateEmbed`, {
      signupID: raid.raidID,
      updateEmbeds: [...raid?.syncTo?.discord]
    })
  } catch (error) {
    console.error(error);
  }
}

export const arrayEntriesToObject = <T>(entries: Array<[string, T]>): {[key: string]: T} => {
  if(!entries) {
    console.warn("No entries", entries);
    return {}
  }
  const obj: { [key: string]: T } = {}
  for (let i = 0; i < entries.length; i++) {
    const [key, curr] = entries[i];
    if(!key || !curr) {
      // console.log(`skipping key ${key} `, curr)
      continue;
    }
    obj[key] = curr
  }
  return obj
}


export const uniqueFilter = <T>(value: T, index: number, self: T[]): boolean => {
  if (typeof value === 'object') {
    return self.findIndex((item) => JSON.stringify(item) === JSON.stringify(value)) === index;
  } else {
    return self.indexOf(value) === index;
  }
}

export const duplicateFilter = (value: unknown, index: number, self: any) => {
  return self.indexOf(value) !== index;
}

export const capitalizeEveryWord = (string: string) => {
  if(!string || typeof string !== "string") return string
  const stringArray = string.split(" ").map((word) => capitalize(word))
  return stringArray.join(" ")
}

export const capitalize = (string?: string): string => {
  if(!string || typeof string !== "string") return ""
  if(typeof string !== "string") {
    console.error(`capitalize error - tried to capitalize ${typeof string}`, string);
    return string
  }
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const filterOutSymbols = (input: string): string => {
  const symbolRegex = /[^a-zA-Z0-9\s]/g;
  return input.replace(symbolRegex, '');
}

export const joinCustom = (stringArr: string[], firstJoin = ", ", lastJoin = " and ") => {
  if (stringArr.length === 1) return stringArr[0];
  const firsts = stringArr.slice(0, stringArr.length - 1);
  const last = stringArr[stringArr.length - 1];
  return `${firsts.join(firstJoin)}${lastJoin}${last}`
}

export const checkPlurality = (arr: unknown[]) => {
  if(!arr || !Array.isArray(arr)) return ""
  return arr?.length > 1 ? "s" : ""
}

export const getErrorMessage = (error: Error | unknown): string => {
  if(error instanceof Error) {
    return error?.message
  }
  if(typeof error === "string") return error
  return ""
}

export const hasDuplicates = (array: unknown[]): boolean => {
  return (new Set(array)).size !== array.length;
}

export const forceBreakLineBefore = (str: string, numberOfCharacters: number): string => {

  const parts = str.split(" ")
  const newParts: string[] = []
  let currentNumberOfChars = 0
  
  for (let i = 0; i < parts.length; i++) {
    const word = parts[i];
    
    if(currentNumberOfChars + word.length > numberOfCharacters){
      newParts.push("\n", word)
      currentNumberOfChars = word.length
      continue;
    }

    newParts.push(word)
    currentNumberOfChars ++
    currentNumberOfChars += word.length
  }

  return newParts.join(" ")
}

export const getLoginRedirectString = ({ loginRedirectURI, discordServers = false, withEmail = false}: {loginRedirectURI: string, withEmail?: boolean, discordServers?: boolean}) => {
  const clientID = "client_id=847775163105148958"
  const state = newID(16)
  sessionStorage.setItem('oauthState', state);
  const stateString = `state=${state}`
  const redirectURI = `redirect_uri=${loginRedirectURI}`
  const responseType = "response_type=code"
  
  let scopes = "scope=identify"
  if(discordServers) scopes += "%20guilds%20guilds.members.read"
  if(withEmail) scopes += "%20email"

  return `https://discord.com/api/oauth2/authorize?${stateString}&${clientID}&${redirectURI}&${responseType}&${scopes}`
}

export const processAxiosResponseCatchMe = async (
  response: AxiosResponse<any, any>,
  loginRedirectURI?: string,
  fallbackFeedback?: string,
  logoutCallback?: () => Promise<void>
) => {
  if (response.data?.action === "login-redirect" && loginRedirectURI) {
    if (!!logoutCallback && typeof logoutCallback === "function") await logoutCallback()
    window.location.replace(getLoginRedirectString({ loginRedirectURI }))
    throw new Error(`Discord login token expired, redirecting..`)
  }
  processAxiosResponse(response, fallbackFeedback)
}

export const processAxiosResponse = (
  response: AxiosResponse<any, any>,
  fallbackFeedback?: string,
) => {
  if (response.status === 429) {
    throw new Error(`Error: Discord API timeout, wait a few seconds before trying again.`)
  }
  if (response.status >= 400 && response.data?.feedback) {
    throw new Error(response.data?.feedback)
  }
  if (response.status >= 400 && response.data?.message) {
    throw new Error(response.data?.message)
  }
  if (response.status >= 400) {
    console.error("axios - unknown error:", response);
    throw new Error(`Error: ${fallbackFeedback || "unknown error."}`)
  }
}

export const asciiNumberToString = (number: number) => {
  if(number > 26) return String.fromCharCode(97 + number)
  return String.fromCharCode(65 + number)
}

export const nextChar = (c: string) => {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

export const nextCharFromKeysIn = (chars: string[]) => {
  if(!chars || chars?.length > 0 === false) return "A"
  const lastCharID = chars.sort().at(-1)
  if(!lastCharID) throw new Error("Couldn't find that letter.")
  if(lastCharID === "Z") return "a"
  if(lastCharID === "z") throw new Error("No letter after z")
  return nextChar(lastCharID)
}

export function generateNextStringID(existingIDs: string[]): string {
  if(existingIDs?.length < 1) return "A"
  const baseCharCode = 'A'.charCodeAt(0);
  const totalChars = 26; // Number of uppercase letters

  // Convert existingIDs to an array of indices
  const indices = existingIDs.map(id => {
    let index = 0;
    for (let i = 0; i < id.length; i++) {
      index = index * totalChars + id.charCodeAt(i) - baseCharCode + 1;
    }
    return index - 1;
  });

  // Find the maximum index in the array
  const maxIndex = Math.max(...indices);

  // Generate the next string ID
  let result = '';
  let index = minMax(maxIndex + 1, 0, 1000);

  while (index >= 0) {
    const charIndex = index % totalChars;
    result = String.fromCharCode(baseCharCode + charIndex) + result;
    index = Math.floor(index / totalChars) - 1;
  }

  return result;
}

export const getKeysToArray = <T extends unknown>(keys: string[]) => {
  const gameRoleTo: Record<string, T[]> = {}
  for (const key of keys) {
    gameRoleTo[key] = []
  }
  return gameRoleTo
}

export const getDatesBetween = (unixStart: number | Date, unixEnd: number | Date, intervalInMinutes: number, includeStartEnd: boolean = false) => {
  const start = typeof unixStart === "number" ? unixStart : unixStart?.getTime()
  const end = typeof unixEnd === "number" ? unixEnd : unixEnd?.getTime()
  const unixInterval = intervalInMinutes * 60000
  const roundedStart = Math.floor(start / unixInterval) * unixInterval
  const roundedEnd = Math.ceil(end / unixInterval) * unixInterval

  const dateArray: Date[] = []
  for (let i = roundedStart; i < roundedEnd; i += unixInterval) {
    if(i === roundedStart && includeStartEnd === false) continue;
    dateArray.push(new Date(i))
  }
  if(includeStartEnd === true) dateArray.push(new Date(unixEnd))
  return dateArray
}

export const getNextFullHour = (addHours: number = 0) => {
  const msInHour = 60 * 60 * 1000;
  const nextFullHr = Math.ceil(Date.now() / msInHour) * msInHour
  return new Date(nextFullHr + (addHours * msInHour));
}

export const arrayFilterIndex = <T extends unknown>(
  array: T[],
  filter: (obj: T) => boolean,
  sort?: (a: T, b: T) => number
): number[] => {
  if (sort) {
    return array
      .map((_, i) => i)
      .filter((index) => filter(array[index]))
      .sort((indexA, indexB) => sort(array[indexA], array[indexB]))
  }
  
  return array.map((_, i) => i).filter((index) => filter(array[index]))
}

export const isInArray = <T extends unknown>(obj: T, array: T[]): boolean => {
  return !!array.find(o => dequal(o, obj))
}

export const findArrayOverlap = <T>(arrayA: T[], arrayB: T[]): T[] => {
  return arrayA.filter(_charID => isInArray(_charID, arrayB))
}

export const toggleIsInArray = <T extends unknown>(obj: T, array: T[]) => {
  const inArrayIndex = array.findIndex(o => dequal(o, obj))
  if(inArrayIndex !== -1) {
    array.splice(inArrayIndex, 1)
  } else {
    array.push(obj)
  }
  return array
}

type RequireDirectionIfNotString<T, K extends keyof T> =
  T[K] extends string ? { sortKey: K; sortDirection?: 'ASC' | 'DESC'; customComparator?: (a: any, b: any) => number; }
  : { sortKey: K; sortDirection: 'ASC' | 'DESC'; customComparator?: (a: any, b: any) => number; };

type SortCriteria<T> = {
  [K in keyof T]: RequireDirectionIfNotString<T, K>
}[keyof T];

export function multiSort<T extends Record<string, any>>(
  arr: T[],
  criteria: SortCriteria<T>[]
): T[] {
  return [...arr].sort((a, b) => {
    for (const { sortKey, sortDirection, customComparator } of criteria) {
      if (!(sortKey in a) || !(sortKey in b)) {
        throw new Error(`Key ${String(sortKey)} is missing from the objects`);
      }

      const aValue = a[sortKey];
      const bValue = b[sortKey];

      // Use custom comparator if provided
      if (customComparator) {
        const customResult = customComparator(aValue, bValue);
        if (customResult !== 0) {
          return sortDirection === 'DESC' ? -customResult : customResult;
        }
        continue;
      }

      // If no sort direction provided, default to ascending
      const effectiveSortDirection = sortDirection || 'ASC';

      // Basic comparison
      if (aValue < bValue) {
        return effectiveSortDirection === 'ASC' ? -1 : 1;
      } else if (aValue > bValue) {
        return effectiveSortDirection === 'ASC' ? 1 : -1;
      }
    }
    return 0; // if all keys are equal or custom comparators return 0
  });
}

export const timeStampToDate = (timeStamp: string) => {
  return new Date(parseInt(timeStamp))
} 

export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const getPrepiBotBaseURL = (forceDev?: boolean) => {
  if (forceDev || process.env.NODE_ENV === "development") return "http://localhost:80"
  return "https://prepi-bot.herokuapp.com"
}

export const toggleAddOrRemoveFromArray = <T extends unknown>(item: T, arr: T[]): T[] => {
  const index = arr?.findIndex(o => o === item)
  if(index === -1){
    arr.push(item)
    return arr
  }
  arr.splice(index, 1)
  return arr
}

export const moveArrayItem = <T extends unknown>(arr: T[], fromIndex: number, toIndex: number) => {
  const removedItem = arr.splice(fromIndex, 1)[0];
  arr.splice(toIndex, 0, removedItem);
  return arr;
}

export const moveElementInArray = <T>(array: T[], currentIndex: number, shiftValue: number): T[] => {
  const newArray = [...array];
  const newIndex = currentIndex + shiftValue;

  if (newIndex < 0) {
    newArray.unshift(newArray.splice(currentIndex, 1)[0]); // Move to the beginning
  } else if (newIndex >= newArray.length) {
    newArray.push(newArray.splice(currentIndex, 1)[0]); // Move to the end
  } else {
    const [removedElement] = newArray.splice(currentIndex, 1);
    newArray.splice(newIndex, 0, removedElement);
  }

  return newArray;
}

// AUTHOR: nischithbm
// LINK: https://gist.github.com/jeneg/9767afdcca45601ea44930ea03e0febf?permalink_comment_id=3515777#gistcomment-3515777
export const getNestedValue = (value: any, path: string, defaultValue?: any) => {
  return String(path).split('.').reduce((acc, v) => {
    try {
      acc = (acc[v] !== undefined && acc[v] !== null) ? acc[v] : defaultValue
    } catch (e) {
      return defaultValue
    }
    return acc
  }, value);
}

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const addNumberSuffix = (num: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = num % 100;

  return `${num}${suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]}`;
}

export function deletePairsByCondition<T>(obj: { [key: string]: T }, condition: (value: T) => boolean): string[] {
  const deletedKeys: string[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && condition(obj[key])) {
      delete obj[key];
      deletedKeys.push(key);
    }
  }

  return deletedKeys;
}


interface TimeRange {
  start: number;
  end?: number;
}

export const setDefaultStartTime = (
  timeRange: TimeRange,
  defaultStart?: number,
  defaultEnd?: number,
  useDate?: number
) => {
  const STANDARD_START_HOUR = 19
  const ONE_HOUR_MS = 3600000
  const standardDuration: number = ONE_HOUR_MS * 2
  const startDate = new Date(timeRange.start)
  
  // set start
  if (defaultStart) {
    const defaultStartDate = new Date(defaultStart)
    const newStartDate = new Date(timeRange.start)

    newStartDate.setHours(defaultStartDate.getHours())
    newStartDate.setMinutes(defaultStartDate.getMinutes())
    if(useDate) {
      newStartDate.setDate(new Date(useDate).getDate())
      newStartDate.setMonth(new Date(useDate).getMonth())
      newStartDate.setFullYear(new Date(useDate).getFullYear())
    }

    timeRange.start = newStartDate.getTime()
  } else {
    timeRange.start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      STANDARD_START_HOUR
    ).getTime()
  }

  // set end
  if (defaultEnd && defaultStart) {
    const DEFAULT_DURATION_MS = defaultEnd - defaultStart
    timeRange.end = timeRange.start + DEFAULT_DURATION_MS
  } else {
    timeRange.end = timeRange.start + standardDuration
  }
}

export class ObjectArrayChecker {
  private hashMap: { [key: string]: boolean } = {};

  constructor(arr: Array<Record<string, any>>) {
    for (const item of arr) {
      this.hashMap[this.generateHash(item)] = true;
    }
  }

  private generateHash(obj: Record<string, any>): string {
    return JSON.stringify(obj);
  }

  public contains(obj: Record<string, any>): boolean {
    return !!this.hashMap[this.generateHash(obj)];
  }
}

export const getWithDefault = <T>(withDefaultObj?: withDefault<T>, key?: string): T | undefined => {
  if(!isDefined(withDefaultObj)) return undefined
  if(!!key && isDefined(withDefaultObj?.[key])) return withDefaultObj?.[key]
  return withDefaultObj?.default
}

export const setWithDefault = <T, U>(
  host: U,
  hostKey: FilterKeys<U, withDefault<T>>,
  value: T,
  withDefaultKey?: string
) => {
  // @ts-ignore
  if(!isDefined(host[hostKey]?.default)){
    const newWithDefault: withDefault<T> = {
      default: value
    }
    // @ts-ignore
    host[hostKey] = newWithDefault
  }

  // @ts-ignore
  if(!!withDefaultKey) host[hostKey][withDefaultKey] = value
}

export const removeWithDefaultKey = <T, U>(
  host: U,
  hostKey: FilterKeys<U, withDefault<T>>,
  withDefaultKey: string
) => {
  // @ts-ignore
  if(withDefaultKey === "default" && isDefined(host[hostKey]?.default)){
    delete host[hostKey]
    return
  }
  // @ts-ignore
  if(isDefined(host?.[hostKey]?.[withDefaultKey])) delete host[hostKey][withDefaultKey]
}



export function findPathsMatchingCondition(
  obj: Record<string, any>,
  condition: (value: any) => boolean,
  currentPath: string[] = [],
  result: string[] = []
): string[] {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const newPath = [...currentPath, key];

      if (condition(value)) {
        result.push(newPath.join('.'));
      }

      if (typeof value === 'object' && value !== null) {
        findPathsMatchingCondition(value, condition, newPath, result);
      }
    }
  }

  return result;
}

export const fuzzyStringEquals = (a?: string, b?: string) => {
  return a?.toLocaleLowerCase() === b?.toLowerCase()
}

export function addCallbacks<T>(
  originalFunction: (...args: any[]) => T = () => undefined as unknown as T,
  callbacksAfter: Array<anyFunction> = [],
  callbacksBefore: Array<anyFunction> = []
): (...args: any[]) => T {
  return (...args: any[]) => {
    for (const callback of callbacksBefore) {
      if (!callback || typeof callback !== "function") continue
      callback()
    }
    const result = originalFunction(...args)
    for (const callback of callbacksAfter) {
      if (!callback || typeof callback !== "function") continue
      callback()
    }
    return result
  }
}

type AsyncFunction<T> = () => Promise<T>;
type ErrorHandler = (error: Error | CustomError) => void;

export async function handleAsyncError<T>(
  asyncFunction: AsyncFunction<T>,
  errorHandler: ErrorHandler
): Promise<T | undefined> {
  try {
    // Execute the async function
    const result = await asyncFunction();
    return result;
  } catch (error) {
    // Handle the error using the provided error handler
    errorHandler(error as Error);
    // Return undefined to signal that an error occurred
    return undefined;
  }
}

export const isURL = (input: string): boolean => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(input);
}

export const removeURLProtocol = (url: string): string => {
  const protocolRegex = /^(https?:\/\/)?(www\.)?/;
  return url.replace(protocolRegex, '');
}

function stringifyKey(value: unknown): string | null {
  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    return value.toString();
  }
  return null;
}

// Overloads
export function groupBy<T>(items: T[], paths: [Path<T>], sortFunction?: SortFunction<T>): GroupedArray<T>;
export function groupBy<T>(items: T[], paths: [Path<T>, Path<T>], sortFunction?: SortFunction<T>): Record<string, GroupedArray<T>>;
export function groupBy<T>(items: T[], paths: [Path<T>, Path<T>, Path<T>], sortFunction?: SortFunction<T>): Record<string, Record<string, GroupedArray<T>>>;

/** 
 * Will group by the values the paths point to.
 * Supports values: string, number, boolean
 */
export function groupBy<T extends unknown> (objects: T[], groupByPaths: Path<T>[], sortFunction?: SortFunction<T>) {
  const results: Record<string, any> = {};

  for (const object of objects) {
    let currentLevel = results;

    for (const path of groupByPaths) {
      const key = stringifyKey(getNestedValue(object, path));
      
      if (key === null) continue;

      if (path === groupByPaths[groupByPaths.length - 1]) {
        if (!currentLevel[key]) currentLevel[key] = [];
        currentLevel[key].push(object);
      } else {
        if (!currentLevel[key]) currentLevel[key] = {};
        currentLevel = currentLevel[key];
      }
    }
  }

  // If a sort function is provided, sort each group
  if (sortFunction) {
    for (const key in results) {
      if (Array.isArray(results[key])) {
        results[key].sort(sortFunction);
      } else {
        // Assuming nested grouping might require sorting at deeper levels
        sortNestedGroups(results[key], sortFunction);
      }
    }
  }

  return results;
};

function sortNestedGroups<T>(group: Record<string, any>, sortFunction: SortFunction<T>): void {
  for (const key in group) {
    if (Array.isArray(group[key])) {
      group[key].sort(sortFunction);
    } else {
      sortNestedGroups(group[key], sortFunction);
    }
  }
}

export function transformGroupedArrays<T, U>(
  groupedObj: Record<string, T[]>,
  transform: TransformFunction<T, U>
): Record<string, U[]> {
  const transformedObj: Record<string, U[]> = {};

  for (const key in groupedObj) {
    if (Array.isArray(groupedObj[key])) {
      transformedObj[key] = groupedObj[key].map(transform);
    } else {
      // Recursively handle nested objects, assuming they follow the same structure
      transformedObj[key] = transformGroupedArrays(groupedObj[key] as unknown as Record<string, T[]>, transform) as unknown as U[];
    }
  }

  return transformedObj;
}

export const transformObjectProperties = (
  obj: Record<string, any>,
  transformValue: (value: any) => any
): Record<string, any> => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      obj[key] = transformValue(value);
    }
  }
  return obj;
};

type ConditionItemPair<T> = [boolean | ((item: T) => boolean), T];
export function conditionalArray<T>(items: ConditionItemPair<T>[]): T[] {
  const result: T[] = [];

  for (const [condition, item] of items) {
    const shouldAdd = typeof condition === 'function' ? condition(item) : condition;
    if (shouldAdd) {
      result.push(item);
    }
  }

  return result;
}

export const isOneOf = <T>(value: T, ...options: T[]): boolean => {
  return options.includes(value);
}

export const callbackIfExists = (callback: (...args: any[]) => any) => {
  if(callback && typeof callback === "function") callback()
}

export const splitArray = <T>(array: T[], numberOfSplits: number): T[][] => {
  const result: T[][] = new Array(numberOfSplits).fill(null).map(() => []);
  for (let i = 0; i < array.length; i++) {
      result[i % numberOfSplits].push(array[i]);
  }
  return result;
}
export const slugify = (input: string): string => {
  return input
    .trim() // Remove leading and trailing whitespace
    .toLowerCase() // Convert the string to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters except hyphens
    .replace(/-{2,}/g, '-'); // Replace consecutive hyphens with a single hyphen
};

export const splitArrayOnCondition = <T>(arr: T[], condition: (item: T) => boolean): [T[], T[]] => {
  return arr.reduce(
    ([positiveArr, negativeArr], currentItem) => {
      if (condition(currentItem)) {
        positiveArr.push(currentItem) // Add to array A (positive results)
      } else {
        negativeArr.push(currentItem) // Add to array B (negative results)
      }
      return [positiveArr, negativeArr]
    },
    [[], []] as [T[], T[]]
  )
}